import { Editor, Node } from "@tiptap/core";
import { TextSelection } from "@tiptap/pm/state";

const BG_COLOR = "#3367D1";
const TEXT_COLOR = "#ffffff";
const NO_COLOR = "transparent"; // Use "unset" to remove background color

export const TextSelectionExtension = Node.create({
  name: "TextSelection",
  defaultOptions: {
    types: ["textStyle"],
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          backgroundColor: {
            renderHTML: (attributes) => {
              // Clear background color if no attribute is present
              if (!attributes.backgroundColor) {
                return;
              }
              // Otherwise, apply the background color
              return {
                style: `background-color: ${attributes.backgroundColor}; color: ${TEXT_COLOR};`,
              };
            },
          },
        },
      },
    ];
  },
  // @ts-ignore
  addCommands() {
    return {
      textSelection: () => ({ chain }: Editor) => {
        localStorage.setItem("textSelection", "true");
        return chain()
          .setMark("textStyle", { backgroundColor: BG_COLOR })
          .run();
      },
      removeTextSelection: () => ({ chain }: Editor) => {
        localStorage.setItem("textSelection", "false");
        return chain().setMark("textStyle", { backgroundColor: null }).run(); // Use `unsetMark`
      },
      //add ctrl + a to select all text
      //call only when ctrl + a is pressed
    };
  },
});
