import { t } from "../../i18n";
export const API_URL = process.env.REACT_APP_EP_URL_WHITEBOARD;
export const ACV_API_URL = process.env.REACT_APP_ACV_BACKEND_API;

export const sidebarText = () => ({
  TEMPLATE: t("collection.community.template.title"),
  STOCKIMAGE: t("collection.community.stokeImage.title"),
  MYCOLLECTIONS: t("collection.personal.myCollections.title"),
  IMAGES: t("collection.personal.images.title"),
  VIDEOS: t("collection.personal.videos.title"),
  AUDIOS: t("collection.personal.audios.title"),
  UNDERREVIEW: t("collection.personal.underReview.title"),
  LIKED: t("collection.personal.liked.title"),
  BOOKMARK: t("collection.personal.bookmark.title"),
});

export const isFirefox =
  "netscape" in window &&
  navigator.userAgent.indexOf("rv:") > 1 &&
  navigator.userAgent.indexOf("Gecko") > 1;

export const studentAccessRevokeMessage = {
  title: "Whiteboard access revoked",
  message:
    (localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")!).displayName
      : "") + ", your teacher has revoked permission to access this lesson.",
  button: "Sign Out",
};

export const studentSessionEndMessage = {
  title: "Whiteboard session is ended",
  message:
    (localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")!).displayName
      : "") +
    ", your session has been terminated, you can download your data from here.",
  button: "Download",
};

export const studentUnAuthorizedMessage = {
  title: "Whiteboard mismatch - Access unauthorized",
  message:
    (localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")!).displayName
      : "") + ", you do not have permission to access this lesson.",
  button: "Sign Out",
};
