import { ExcalidrawElement } from "../../element/types";
import { AppState } from "../../types";
import {
  clearAppStateForLocalStorage,
  getDefaultAppState,
} from "../../appState";
import { clearElementsForLocalStorage } from "../../element";
import { STORAGE_KEYS as APP_STORAGE_KEYS } from "../../constants";
import { getLessonId } from "../api/getuserInfo";

export const STORAGE_KEYS = {
  LOCAL_STORAGE_ELEMENTS: "acv",
  LOCAL_STORAGE_APP_STATE: "acv-state",
  LOCAL_STORAGE_COLLAB: "acv-collab",
  LOCAL_STORAGE_KEY_COLLAB_FORCE_FLAG: "collabLinkForceLoadFlag",
};

export const saveUsernameToLocalStorage = (username: string) => {
  try {
    localStorage.setItem(
      STORAGE_KEYS.LOCAL_STORAGE_COLLAB,
      JSON.stringify({ username }),
    );
  } catch (error) {
    // Unable to access window.localStorage
    console.error(error);
  }
};

export const importUsernameFromLocalStorage = (): string | null => {
  try {
    // const data = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_COLLAB);
    const data = localStorage.getItem("user");
    if (data) {
      // return JSON.parse(data).username;
      return JSON.parse(data).displayName;
    }
  } catch (error) {
    // Unable to access localStorage
    console.error(error);
  }

  return null;
};

export const saveToLocalStorage = async (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
) => {
  const localElement: any[] = [];
  const lessonId = await getLessonId();
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  elements.map((element) => {
    if (element.lessonId === lessonId) {
      return localElement.push({
        ...element,
        teacherEmailId: user?.mail,
      });
    }
  });
  // console.log("localElement", localElement);
  try {
    let studentData = localStorage.getItem("students")
      ? JSON.parse(localStorage.getItem("students") || "")
      : [];
    if (Array.isArray(studentData)) {
      const studData = studentData?.find((stud: any) => {
        return stud.studEmail === user.mail;
      });

      if (studData?.isWhiteboard !== false) {
        localStorage.setItem(
          STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
          JSON.stringify(clearElementsForLocalStorage(localElement)),
        );
        // localStorage.setItem(
        //   STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
        //   JSON.stringify(localElement),
        // );
        localStorage.setItem(
          STORAGE_KEYS.LOCAL_STORAGE_APP_STATE,
          JSON.stringify(clearAppStateForLocalStorage(appState)),
        );
      }
    }
  } catch (error) {
    // Unable to access window.localStorage
    console.error(error);
  }
};

export const importFromLocalStorage = () => {
  let savedElements = null;
  let savedState = null;

  try {
    savedElements = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
    savedState = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_APP_STATE);
  } catch (error) {
    // Unable to access localStorage
    console.error(error);
  }

  let elements: ExcalidrawElement[] = [];
  if (savedElements) {
    try {
      elements = clearElementsForLocalStorage(JSON.parse(savedElements));
    } catch (error) {
      console.error(error);
      // Do nothing because elements array is already empty
    }
  }

  let appState = null;
  if (savedState) {
    try {
      appState = {
        ...getDefaultAppState(),
        ...clearAppStateForLocalStorage(
          JSON.parse(savedState) as Partial<AppState>,
        ),
      };
    } catch (error) {
      console.error(error);
      // Do nothing because appState is already null
    }
  }
  return { elements, appState };
};

export const getElementsStorageSize = () => {
  try {
    const elements = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
    const elementsSize = elements?.length || 0;
    return elementsSize;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getTotalStorageSize = () => {
  try {
    const appState = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_APP_STATE);
    const collab = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_COLLAB);
    const library = localStorage.getItem(
      APP_STORAGE_KEYS.LOCAL_STORAGE_LIBRARY,
    );

    const appStateSize = appState?.length || 0;
    const collabSize = collab?.length || 0;
    const librarySize = library?.length || 0;

    return appStateSize + collabSize + librarySize + getElementsStorageSize();
  } catch (error) {
    console.error(error);
    return 0;
  }
};
