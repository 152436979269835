import React, { useContext } from "react";
import { AppState, LibraryItems } from "../types";
import { ActionManager } from "../actions/manager";
import { t } from "../i18n";
import Stack from "./Stack";
import { showSelectedShapeActions } from "../element";
import { NonDeletedExcalidrawElement } from "../element/types";
import { FixedSideContainer } from "./FixedSideContainer";
import { Island } from "./Island";
import { HintViewer } from "./HintViewer";
import { calculateScrollCenter } from "../scene";
import { SelectedShapeActions, ShapesSwitcher } from "./Actions";
import { Section } from "./Section";
import CollabButton from "./CollabButton";
import { SCROLLBAR_WIDTH, SCROLLBAR_MARGIN } from "../scene/scrollbars";
import { LockButton } from "./LockButton";
import { UserList } from "./UserList";
import { BackgroundPickerAndDarkModeToggle } from "./BackgroundPickerAndDarkModeToggle";
import { LibraryButton } from "./LibraryButton";
import {
  audioRecorder,
  libraryTemplateMenu2,
  notes,
  videoRecorder,
  settings,
} from "./LayerUI";
import { LibraryTemplateButton } from "./LibraryTemplateButton";
import FlexibleGroupButton from "./flexibleGroupButton";
import clsx from "clsx";
import { getStudents } from "../excalidraw-app/api";
import {
  ACV_API_URL,
  studentAccessRevokeMessage,
  studentSessionEndMessage,
} from "../excalidraw-app/api/constant";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import { users } from "./icons";
import SideBar from "./SideBar";
import { StudentMessageContext } from "./contexts/StudentMessage.context";
import { NotesTitleContext } from "./contexts/NotesTitle.context";
import Library from "../data/library";

type MobileMenuProps = {
  appState: AppState;
  actionManager: ActionManager;
  renderJSONExportDialog: () => React.ReactNode;
  renderImageExportDialog: () => React.ReactNode;
  renderSpinWheelDialog: JSX.Element | null;
  renderGPTDialog: () => React.ReactNode;
  renderEditMermaidDiagramDialog: React.ReactNode;
  setAppState: React.Component<any, AppState>["setState"];
  elements: readonly NonDeletedExcalidrawElement[];
  libraryMenu: JSX.Element | null;
  onCollabButtonClick?: () => void;
  onLockToggle: () => void;
  canvas: HTMLCanvasElement | null;
  isCollaborating: boolean;
  renderCustomFooter?: (isMobile: boolean, appState: AppState) => JSX.Element;
  viewModeEnabled: boolean;
  showThemeBtn: boolean;
  onImageAction: (data: { insertOnCanvasDirectly: boolean }) => void;
  togglekeyboardShortcut: (isAdding: boolean) => void;
  renderTopRightUI?: (
    isMobile: boolean,
    appState: AppState,
  ) => JSX.Element | null;
  isStudent: boolean;
  isCollaboratingWithFlexibleGroups: boolean;
  onFlexibleGroupButtonClick: VoidFunction | undefined;
  zenModeEnabled: boolean;
  sidebarWrapper: boolean;
  onPageDelete?: (pageId: string) => void;
  onPageChange?: any;
  toggleZoomInZoomOut: (isZoom: boolean) => void;
  library: Library;
  focusContainer: () => void;
  setLibraryItems: (val: LibraryItems) => void;
  setAddToCollection: (val: number[]) => void;
  setDeleteElement: (val: number[]) => void;
  resetScene: (opts?: { resetLoadingState: boolean }) => void;
};

export const MobileMenu = ({
  appState,
  elements,
  libraryMenu,
  actionManager,
  renderJSONExportDialog,
  renderImageExportDialog,
  renderGPTDialog,
  renderEditMermaidDiagramDialog,
  setAppState,
  onCollabButtonClick,
  onLockToggle,
  canvas,
  isCollaborating,
  renderCustomFooter,
  viewModeEnabled,
  showThemeBtn,
  onImageAction,
  renderTopRightUI,
  togglekeyboardShortcut,
  isStudent,
  isCollaboratingWithFlexibleGroups,
  onFlexibleGroupButtonClick,
  zenModeEnabled,
  sidebarWrapper,
  onPageChange,
  onPageDelete,
  toggleZoomInZoomOut,
  renderSpinWheelDialog,
  library,
  setLibraryItems,
  focusContainer,
  setAddToCollection,
  setDeleteElement,
  resetScene,
}: MobileMenuProps) => {
  const { setStudentMessage } = useContext(StudentMessageContext);
  const renderToolbar = () => {
    return (
      <NotesTitleContext.Provider
        value={{
          fileName: appState.notesTitle,
          color: appState.notesColor,
          onColorChange: (color) => {
            setAppState({
              ...appState,
              notesColor: color,
            });
          },
          onFileNameChange: (title) => {
            setAppState({
              ...appState,
              notesTitle: title,
            });
          },
        }}
      >
        <FixedSideContainer side="top" className="App-top-bar">
          <Section heading="shapes" style={{ maxWidth: "100%" }}>
            {(heading: string) => (
              <Stack.Col gap={4} align="center">
                <Stack.Row
                  gap={1}
                  className="d-flex"
                  style={{ maxWidth: "100%", minWidth: 0 }}
                >
                  <Island padding={1} style={{ maxWidth: "100%", minWidth: 0 }}>
                    {heading}
                    <Stack.Row gap={1} className="d-flex flex-wrap">
                      <ShapesSwitcher
                        canvas={canvas}
                        elementType={appState.elementType}
                        setAppState={setAppState}
                        onImageAction={({ pointerType }) => {
                          onImageAction({
                            insertOnCanvasDirectly: pointerType !== "mouse",
                          });
                        }}
                        togglekeyboardShortcut={togglekeyboardShortcut}
                        toggleZoomInZoomOut={toggleZoomInZoomOut}
                        appState={appState}
                      />
                      {onCollabButtonClick && !isStudent && (
                        <CollabButton
                          isCollaborating={isCollaborating}
                          collaboratorCount={appState.collaborators.size}
                          onClick={onCollabButtonClick}
                        />
                      )}
                      {!isStudent && isCollaboratingWithFlexibleGroups && (
                        <>
                          <Island
                            padding={1}
                            className={`${clsx({
                              "zen-mode collabBtnIsland":
                                zenModeEnabled + " collabBtnIsland",
                            })}`}
                          >
                            <FlexibleGroupButton
                              className="collabIslandBtn"
                              onClick={onFlexibleGroupButtonClick}
                            />
                          </Island>
                        </>
                      )}
                      {!isStudent && isCollaborating && (
                        <Island
                          padding={1}
                          className={`${clsx({
                            "zen-mode": zenModeEnabled + " collabBtnIsland",
                          })}`}
                        >
                          <button
                            type="button"
                            className="btn btn-primary d-none"
                            id="collaborationBtn"
                            data-bs-toggle="modal"
                            data-bs-target="#collaboration"
                          >
                            Collaboration
                          </button>
                          <ToolButton
                            type={ToolButtonEnum.BUTTON}
                            className="w-100 collabIslandBtn"
                            icon={users}
                            onClick={() => {
                              const lessonId =
                                new URLSearchParams(window.location.search)
                                  .get("lessonId")
                                  ?.replace(/\//g, "") || "";
                              const slug =
                                new URLSearchParams(window.location.search)
                                  .get("slug")
                                  ?.replace(/\//g, "") || "";
                              getStudents(
                                `${ACV_API_URL}/api/record/get-student-record-for-whiteBaord/${lessonId}?slug=${slug}`,
                              ).then(async (res) => {
                                const studList = res?.result;
                                const studs = localStorage.getItem("students")
                                  ? JSON.parse(
                                      localStorage.getItem("students") || "",
                                    )
                                  : [];
                                const students = await studList.map(
                                  (stud: any) => {
                                    const student =
                                      studs.length > 0 &&
                                      studs.find(
                                        (data: { studEmail: string }) =>
                                          data.studEmail ===
                                          stud.StudentEmailId,
                                      );

                                    if (student.isWhiteboard === 2) {
                                      setStudentMessage({
                                        ...studentSessionEndMessage,
                                      });
                                    } else if (student.isWhiteboard === false) {
                                      setStudentMessage({
                                        ...studentAccessRevokeMessage,
                                      });
                                    }
                                    return {
                                      studEmail: stud.StudentEmailId,
                                      studName: stud.StudentName,
                                      photo: `${stud.StudentPhoto.Foto}?${res?.SASToken}`,
                                      isWhiteboard: student
                                        ? student.isWhiteboard !== 2
                                          ? student.isWhiteboard
                                          : true
                                        : true,
                                      idle: false,
                                    };
                                  },
                                );
                                window.localStorage.setItem(
                                  "students",
                                  JSON.stringify(students),
                                );
                              });
                              document
                                .getElementById("collaborationBtn")
                                ?.click();
                            }}
                            title={`Collaborators`}
                            aria-label={`Collaborators`}
                          />
                        </Island>
                      )}
                    </Stack.Row>
                  </Island>
                  {renderTopRightUI && renderTopRightUI(true, appState)}
                  <LockButton
                    checked={appState.elementLocked}
                    onChange={onLockToggle}
                    title={t("toolBar.lock")}
                  />
                  <LibraryButton
                    appState={appState}
                    setAppState={setAppState}
                  />
                  {/* <LibraryTemplateButton
                    appState={appState}
                    setAppState={setAppState}
                  /> */}
                  {notes}
                  {settings}
                  {/* <Stack.Row></Stack.Row> */}
                </Stack.Row>
                {libraryMenu}
                {libraryTemplateMenu2}
                {videoRecorder}
                {audioRecorder}
              </Stack.Col>
            )}
          </Section>
          <HintViewer
            appState={appState}
            elements={elements}
            isMobile={true}
            library={library}
            setLibraryItems={setLibraryItems}
            focusContainer={focusContainer}
            setAddToCollection={setAddToCollection}
            setDeleteElement={setDeleteElement}
            setAppState={setAppState}
            resetScene={resetScene}
          />
          <button
            className="bg-brand px-3 py-2 text-white pageBtn"
            onClick={() => {
              setAppState({ sidebarWrapper: true });
            }}
          >
            {t("multipage.pageNumber")}
            {isCollaboratingWithFlexibleGroups && isStudent
              ? ""
              : ` ${appState.currentPage}`}
          </button>
          <SideBar
            // sidebarWrapper={sidebarWrapper}
            appState={appState}
            onPageChange={onPageChange}
            onPageDelete={onPageDelete}
            setAppState={(
              appState:
                | AppState
                | ((
                    prevState: Readonly<AppState>,
                    props: Readonly<any>,
                  ) => AppState | Pick<AppState, keyof AppState> | null)
                | Pick<AppState, keyof AppState>
                | null,
            ) => setAppState(appState)}
            isStudent={isStudent}
          />
        </FixedSideContainer>
      </NotesTitleContext.Provider>
    );
  };

  const renderAppToolbar = () => {
    if (viewModeEnabled) {
      return (
        <div className="App-toolbar-content">
          {actionManager.renderAction("toggleCanvasMenu")}
        </div>
      );
    }
    return (
      <div className="App-toolbar-content">
        {actionManager.renderAction("toggleCanvasMenu")}
        {actionManager.renderAction("toggleEditMenu")}
        {actionManager.renderAction("undo")}
        {actionManager.renderAction("redo")}
        {actionManager.renderAction(
          appState.multiElement ? "finalize" : "duplicateSelection",
        )}
        {actionManager.renderAction("deleteSelectedElements")}
      </div>
    );
  };

  const renderCanvasActions = () => {
    if (viewModeEnabled) {
      return (
        <>
          {renderJSONExportDialog()}
          {renderImageExportDialog()}
        </>
      );
    }
    return (
      <>
        {actionManager.renderAction("clearCanvas")}
        {actionManager.renderAction("loadScene")}
        {renderJSONExportDialog()}
        {renderImageExportDialog()}
        {renderGPTDialog()}
        {/* {renderSpinWheelDialog} */}
        <BackgroundPickerAndDarkModeToggle
          actionManager={actionManager}
          appState={appState}
          setAppState={setAppState}
          showThemeBtn={showThemeBtn}
        />
      </>
    );
  };
  // console.log("viewModeEnabled ", appState.openMenu);

  return (
    <>
      {!viewModeEnabled && renderToolbar()}
      <div
        className="App-bottom-bar"
        style={{
          marginBottom: SCROLLBAR_WIDTH + SCROLLBAR_MARGIN * 2,
          marginLeft: SCROLLBAR_WIDTH + SCROLLBAR_MARGIN * 2,
          marginRight: SCROLLBAR_WIDTH + SCROLLBAR_MARGIN * 2,
        }}
      >
        <Island padding={0}>
          {appState.openMenu === "canvas" ? (
            <Section className="App-mobile-menu" heading="canvasActions">
              <div className="panelColumn">
                <Stack.Col gap={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                      width: "100%",
                      gap: "0.5rem",
                    }}
                  >
                    {renderCanvasActions()}
                    {renderCustomFooter?.(true, appState)}
                    {appState.collaborators.size > 0 && (
                      <fieldset>
                        {/* <legend>{t("labels.collaborators")}</legend> */}
                        <UserList mobile>
                          {Array.from(appState.collaborators)
                            // Collaborator is either not initialized or is actually the current user.
                            .filter(
                              ([_, client]) => Object.keys(client).length !== 0,
                            )
                            .map(([clientId, client]) => (
                              <React.Fragment key={clientId}>
                                {actionManager.renderAction(
                                  "goToCollaborator",
                                  {
                                    id: clientId,
                                  },
                                )}
                              </React.Fragment>
                            ))}
                        </UserList>
                      </fieldset>
                    )}
                  </div>
                </Stack.Col>
              </div>
            </Section>
          ) : appState.openMenu === "shape" &&
            !viewModeEnabled &&
            showSelectedShapeActions(appState, elements) ? (
            <Section className="App-mobile-menu" heading="selectedShapeActions">
              <SelectedShapeActions
                appState={appState}
                elements={elements}
                renderAction={actionManager.renderAction}
                elementType={appState.elementType}
              />
            </Section>
          ) : null}

          <footer className="App-toolbar">
            {renderAppToolbar()}
            {appState.scrolledOutside && !appState.openMenu && (
              <button
                className="scroll-back-to-content"
                onClick={() => {
                  setAppState({
                    ...calculateScrollCenter(elements, appState, canvas),
                  });
                }}
              >
                {t("buttons.scrollBackToContent")}
              </button>
            )}
          </footer>
          {renderEditMermaidDiagramDialog}
        </Island>
      </div>
    </>
  );
};
