import React from "react";
import { ActionManager } from "../actions/manager";
import { AppState } from "../types";
import { useIsMobile } from "./App";

export const BackgroundPickerAndDarkModeToggle = ({
  appState,
  setAppState,
  actionManager,
  showThemeBtn,
  containerId,
}: {
  actionManager: ActionManager;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  showThemeBtn: boolean;
  containerId?: string;
}) => {
  const isMobile = useIsMobile();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: isMobile ? "baseline" : "center",
      }}
    >
      {showThemeBtn && actionManager.renderAction("toggleTheme")}
      {actionManager.renderAction("changeViewBackgroundColor", {}, containerId)}
    </div>
  );
};
