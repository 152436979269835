import { useRef, useEffect } from "react";
import "./ImageCard.scss";

type ImageCardPropsType = {
  svg: SVGSVGElement | null;
  // onClick: () => void;
  selected: boolean;
  toggleLibrarySelect: (e: React.MouseEvent) => void;
};

const ImageCard = ({
  svg,
  toggleLibrarySelect,
  selected,
}: ImageCardPropsType) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current && svg instanceof SVGSVGElement) {
      divRef.current.innerHTML = "";
      divRef.current.appendChild(svg.cloneNode(true));
    }
  }, [svg]);

  return (
    <label
      onClick={(e) => {
        toggleLibrarySelect(e)
      }}
    >
      <input
        className="checkbox-input"
        type="checkbox"
        checked={selected}
        name="engine"
      />
      <span className="checkbox-tile">
        <div className="checkbox-icon" ref={divRef}></div>
      </span>
    </label>
  );
};

export default ImageCard;
