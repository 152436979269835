import React, { useEffect } from "react";
import { CollectionImagePreview } from "./collectionImagePreview";
import "./LibraryCarousel.scss";

export const LibraryCarousel = ({
  images,
  index,
  name,
  className,
  zoomLevel = 1,
  isFullscreen,
  setIndex,
}: {
  images: string[];
  index: number;
  name: string;
  className?: string;
  zoomLevel?: number;
  isFullscreen?: boolean;
  setIndex?: (index_: number) => void;
}) => {
  useEffect(() => {
    const slideshow = document.getElementById(
      `slideshow-${name.replaceAll(" ", "-")}-${index}`,
    );
    const pagination = document.getElementById(
      `pagination-${name.replaceAll(" ", "-")}-${index}`,
    );

    if (!pagination || !slideshow) return;
    let index_ = 0;
    images.forEach((img, imgIndex) => {
      const slide = document.createElement("div");
      slide.className = `slide-${name.replaceAll(" ", "-")}-${index}`;
      if (imgIndex === 0) {
        slide.style.display = "block";
      }
      slideshow.appendChild(slide);
      index_ = imgIndex;
    });
  }, []);

  function showImage(index_: number) {
    setIndex?.(index_);
    const slides = document.querySelectorAll(
      `.slide-${name.replaceAll(" ", "-")}-${index}`,
    );

    // const dots = document.querySelectorAll(".pagination span");
    const dots = document.querySelectorAll(
      `.pagination-${name.replaceAll(" ", "-")}-${index} span`,
    );

    slides.forEach((slide, i) => {
      const slide_ = slide as HTMLElement;
      slide_.style.display = i === index_ ? "block" : "none";
    });

    dots.forEach((dot, i) => {
      dot.classList.toggle("active", i === index_);
    });
  }

  return (
    <div
      className={`d-flex justify-content-center ${className && " preview-img"}`}
    >
      <div
        className={`center ${className && "wrapper"}`}
        style={{ width: "100%" }}
      >
        <div className={isFullscreen ? "fullscreen-container" : "container p-0"}>
          <div
            className={isFullscreen ? "fullscreen-slideshow" : "slideshow"}
            id={`slideshow-${name.replaceAll(" ", "-")}-${index}`}
          >
            {images.map((img, imgIndex) => (
              <div
                className={`slide-${name.replaceAll(" ", "-")}-${index}`}
                style={{
                  display: imgIndex === 0 ? "block" : "none",
                  height: isFullscreen ? "100%" : "100%",
                  width: "100%",
                }}
              >
                <CollectionImagePreview
                  previewURL={img}
                  index={imgIndex}
                  className={className}
                  zoomLevel={zoomLevel}
                />
              </div>
            ))}
          </div>
        </div>
        <div
          // className="pagination"
          className={`pagination pagination-${name.replaceAll(
            " ",
            "-",
          )}-${index} ${
            className && isFullscreen ? "fullscreen-paginate" : "paginate"
          }`}
          id={`pagination-${name.replaceAll(" ", "-")}-${index}`}
          // style={{
          //   alignItems: className && "end",
          //   height: className && "246px",
          // }}
        >
          {images.map((_, imgIndex) => (
            <span
              key={imgIndex}
              onClick={() => showImage(imgIndex)}
              className={`${index === imgIndex + 1 && "active"} dot`}
            >
              {/* {imgIndex + 1} */}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
