/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { downArrow } from "./icons";
import "./MultiSelectDropdown.scss";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import { filteredValueType } from "./LibraryDialog";
import { t } from "../i18n";

type selectArrayObj = {
  title: string;
  label: string[];
  id: string;
  search?: boolean;
};

type ChildProps = {
  selectArray: selectArrayObj[];
  isOpen: boolean;
  removeFilters: () => void;
  filterApply: boolean;
  removeVal: string;
  filterDataWithKeys: filteredValueType;
  setFilterDataWithKeys: (data: filteredValueType) => void;
  setFilterData: (val: string[]) => void;
  filterData: string[];
};

const MultiSelectDropdown = (props: ChildProps) => {
  const {
    selectArray,
    isOpen,
    removeFilters,
    filterApply,
    removeVal,
    setFilterDataWithKeys,
    filterDataWithKeys,
    filterData,
    setFilterData,
  } = props;
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [searchText, setSearchText] = useState("");

  const handleDropdownToggle = (index: number) => {
    setOpenDropdownIndex(index === openDropdownIndex ? -1 : index);
  };

  useEffect(() => {
    if (isOpen === false) {
      setFilterData([]);
      removeFilters();
    }
  }, [isOpen]);

  useEffect(() => {
    const updatedCheckedCategories = filterData.filter(
      (cat) => cat !== removeVal,
    );
    setFilterData(updatedCheckedCategories);
  }, [removeVal]);

  const updateFilterDataWithKeys = (
    checked: boolean,
    val: string,
    title: string,
    id: string,
  ) => {
    if (filterDataWithKeys) {
      const clonedFilterDataWithKeys = JSON.parse(
        JSON.stringify(filterDataWithKeys),
      );
      if (checked) {
        if (id !== "age") {
          clonedFilterDataWithKeys[id.toLowerCase()].push(val);
        } else {
          if (val === t("collection.community.template.filter.options.7day")) {
            clonedFilterDataWithKeys.age = "7";
          } else if (
            val === t("collection.community.template.filter.options.1month")
          ) {
            clonedFilterDataWithKeys.age = "30";
          } else if (
            val === t("collection.community.template.filter.options.3month")
          ) {
            clonedFilterDataWithKeys.age = "90";
          } else if (
            val === t("collection.community.template.filter.options.6month")
          ) {
            clonedFilterDataWithKeys.age = "180";
          }
        }
      } else {
        if (id !== "age") {
          clonedFilterDataWithKeys[id.toLowerCase()] = clonedFilterDataWithKeys[
            id.toLowerCase()
          ].filter((value: string) => value !== val);
        } else {
          clonedFilterDataWithKeys[id.toLowerCase()] = "";
        }
      }
      setFilterDataWithKeys(clonedFilterDataWithKeys);
    }
  };

  const toggle = (checked: boolean, val: string, title: string, id: string) => {
    updateFilterDataWithKeys(checked, val, title, id);
    if (id === "age") {
      if (checked) {
        const ageIndex = selectArray.findIndex((data) => data.id === id);
        const agesData = selectArray[ageIndex];
        const updatedFilterData = filterData.filter(
          (data) => !agesData.label.includes(data),
        );
        updatedFilterData.push(val);
        setFilterData(updatedFilterData);
      } else {
        const ageIndex = selectArray.findIndex((data) => data.id === id);
        const agesData = selectArray[ageIndex];
        const updatedFilterData = filterData.filter(
          (data) => !agesData.label.includes(data),
        );
        setFilterData(updatedFilterData);
      }
    } else {
      if (!checked) {
        const updatedCheckedCategories = filterData.filter(
          (cat) => cat !== val,
        );
        setFilterData(updatedCheckedCategories);
      } else {
        const updatedCheckedCategories = [...filterData];
        updatedCheckedCategories.push(val);
        setFilterData(updatedCheckedCategories);
      }
    }
  };

  return (
    <>
      {selectArray.map((data, index) => {
        const isOpenDropDown = index === openDropdownIndex;

        const filteredOptions = data.label.filter((o) => {
          return o.toLowerCase().includes(searchText.toLowerCase());
        });

        const options = searchText ? filteredOptions : data.label;

        return (
          <div className="multiSelect" key={`${data.title}-${index}`}>
            <div className="dropdown">
              <p
                className="dropdown__select d-flex justify-content-between"
                data-bs-toggle="collapse"
                data-bs-target={`#${data.title}`}
                aria-expanded="false"
                aria-controls="collapseExample"
                onClick={() => handleDropdownToggle(index)}
              >
                {data.title}

                {isOpen && !filterApply && (
                  <ToolButton
                    key="newPage"
                    type={ToolButtonEnum.BUTTON}
                    title="DownArrow"
                    aria-label="DownArrow"
                    aria-labelledby="offcanvasRightLabel"
                    icon={downArrow}
                    className="pe-3"
                  ></ToolButton>
                )}
              </p>
            </div>

            <div
              className={`overflow-auto collapse ${
                isOpenDropDown ? "show" : ""
              }`}
              style={{ maxHeight: "493px" }}
              id={`${data.title}`}
            >
              {data.search && (
                <div className="mx-3 mt-3">
                  <input
                    type="search"
                    id="search"
                    name="search"
                    placeholder={`Search ${data.title}`}
                    className="search-box w-100 "
                    style={{ display: "block" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              )}
              {options?.length > 0 ? (
                options.map((label, index) => {
                  return (
                    <div className="px-5 pt-3 pb-0" key={index}>
                      <input
                        type="checkbox"
                        id={`${label}`}
                        value={`${label}`}
                        checked={filterData.includes(label)}
                        onChange={(e) => {
                          toggle(e.target.checked, label, data.title, data.id);
                        }}
                      />
                      <label htmlFor={`${label}`} className="ms-3">
                        {label}
                      </label>
                      <br />
                    </div>
                  );
                })
              ) : (
                <div className="px-5 pt-3 pb-0">
                  <p>Not found</p>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MultiSelectDropdown;
