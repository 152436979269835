import "./Avatars.scss";
import { groupType } from "./Types";

const Avatars = ({ group }: { group: groupType }) => {
  return (
    <div className="avatars-group-wrapper">
      <div className="avatars">
        {group.students.length > 0
          ? group.students.map((student, i) => {
              return (
                <>
                  {i < 3 &&
                    (student.photo.substring(0, 4) !== "null" ? (
                      <a className="avatars__item" key={`avatar.${i}`}>
                        <img  key={i} className="avatar" src={student.photo} alt="" />
                      </a>
                    ) : (
                      <div className="avatars__item">
                        <p className="user-profile avatar mb-0 mx-0 text-dark">
                          {!!student.studentName
                            ? student.studentName.substring(0, 2)
                            : "NO"}
                        </p>
                      </div>
                    ))}
                </>
              );
            })
          : ""}
        {group.students.length > 3 && (
          <div className="avatars__item">
            <div className="avatar text-center remaining-avatars-count">
              <span>+</span>
              <span>{group.students.length - 3}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Avatars;
