import { useEffect, useState } from "react";
import { AppState } from "../types";
import * as PDFJS from "pdfjs-dist";
import ConfirmDialog from "./ConfirmDialog";
import "./PdfSelectionDialog.scss";

export const PdfPageSelectionDialog = (props: {
  setAppState: React.Component<any, AppState>["setState"];
  open: boolean;
  appState: AppState;
  pdfFile: File | null;
  onConfirmPageSelection: (
    selectedPages: Array<{ index: number; page: string }>,
  ) => void;
}) => {
  const [pdfPages, setPdfPages] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedPages, setSelectedPages] = useState<
    Array<{ index: number; page: string }> | []
  >([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(
    false,
  );

  const handlePageSelect = (index: number, page: string) => {
    setSelectedPages((prevSelectedPages) => {
      if (
        prevSelectedPages.some((selectedPage) => selectedPage.index === index)
      ) {
        return prevSelectedPages.filter(
          (selectedPage) => selectedPage.index !== index,
        );
      }
      return [...prevSelectedPages, { index, page }];
    });
  };

  const handleSelectAll = () => {
    if (selectedPages.length === pdfPages.length) {
      setSelectedPages([]);
    } else {
      setSelectedPages(
        Array.from({ length: pdfPages.length }, (_, i) => ({
          index: i,
          page: pdfPages[i],
        })),
      );
    }
  };

  useEffect(() => {
    if (props.pdfFile) {
      setFileName(props.pdfFile.name);
      const pdfUrl = URL.createObjectURL(props.pdfFile);
      getPdfPages(pdfUrl).then((pdfPages: unknown) => {
        setLoading(false);
        return setPdfPages(pdfPages as string[]);
      });
    }
  }, [props.pdfFile]);

  const getPdfPages = async (pdfUrl: string, pageCount?: number) => {
    try {
      const pdfPages: string[] = [];
      const pdf_doc = await PDFJS.getDocument({ url: pdfUrl }).promise;

      if (pdf_doc.numPages <= 0) {
        return pdfPages;
      }

      const promises = Array.from(
        { length: pageCount || pdf_doc.numPages },
        (_, i) => {
          const pageIndex = i + 1;

          return pdf_doc.getPage(pageIndex).then((page) => {
            const viewport = page.getViewport({ scale: 4 });
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const renderContext = { canvasContext: ctx || {}, viewport };

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            return page.render(renderContext).promise.then(() => {
              const dataURL = canvas.toDataURL();
              pdfPages[pageIndex - 1] = dataURL;
            });
          });
        },
      );

      await Promise.all(promises);

      return pdfPages;
    } catch (error) {
      // Report error with sentry
      console.log(`Failed to load PDF: ${error}`);
      return error;
    }
  };

  return (
    <>
      {openConfirmationDialog ? (
        <ConfirmDialog
          title={"Are you sure?"}
          className={"PdfSelectionDialog"}
          small={true}
          children={
            <>
              <p>If you close this, your selected page will be lost.</p>
            </>
          }
          closeOnClickOutside={false}
          open={openConfirmationDialog}
          setOpen={() => setOpenConfirmationDialog(false)}
          onConfirm={() => {
            setOpenConfirmationDialog(false);
            props.setAppState({
              pdfPageSelectionDialog: false,
              elementType: "selection",
            });
          }}
          onCancel={() => {
            setOpenConfirmationDialog(false);
          }}
        />
      ) : (
        <ConfirmDialog
          title={"Select Page"}
          className={"PdfSelectionDialog"}
          autofocus={false}
          small={false}
          children={
            <>
              <div className="d-flex justify-content-between border-bottom">
                <p className="mb-1">{fileName}</p>
                <div className="select-all-checkbox">
                  <input
                    type="checkbox"
                    checked={selectedPages.length === pdfPages.length}
                    onChange={handleSelectAll}
                    id="select-all"
                  />
                  <label htmlFor="select-all d-flex align-center">
                    Select All
                  </label>
                </div>
              </div>
              <div
                className="d-flex justify-content-center py-3 flex-wrap gap-4 container"
                style={{ height: "100%", overflow: "auto" }}
              >
                {pdfPages.length > 0 ? (
                  pdfPages.map((page, index) => (
                    <div
                      className="d-flex flex-column gap-2 align-items-center"
                      key={index}
                    >
                      <label htmlFor={`page-${index}`}>
                        <div style={{ position: "relative" }}>
                          <img
                            className={`image-container ${
                              selectedPages.some(
                                (selectedPage) => selectedPage.index === index,
                              )
                                ? "selected"
                                : ""
                            }`}
                            src={page}
                            alt={`Page ${index + 1}`}
                            height="250px"
                            width="200px"
                          />
                          <div className="overlay"></div>
                          <input
                            type="checkbox"
                            checked={selectedPages.some(
                              (selectedPage) => selectedPage.index === index,
                            )}
                            onChange={() => handlePageSelect(index, page)}
                            id={`page-${index}`}
                            className="custom-checkbox"
                          />
                          <label
                            htmlFor={`page-${index}`}
                            className="custom-checkbox-label"
                          ></label>
                        </div>
                      </label>
                      <p className="text-center">Page {index + 1}</p>
                    </div>
                  ))
                ) : loading ? (
                  <p>Loading...</p>
                ) : (
                  <p>No pages found</p>
                )}
              </div>
            </>
          }
          closeOnClickOutside={false}
          open={props.open}
          setOpen={() => false}
          onConfirm={() => props.onConfirmPageSelection(selectedPages)}
          onCancel={() => {
            if (selectedPages.length > 0) {
              setOpenConfirmationDialog(true);
            } else {
              props.setAppState({
                pdfPageSelectionDialog: false,
                elementType: "selection",
              });
            }
          }}
        />
      )}
    </>
  );
};
export default PdfPageSelectionDialog;
