import { getLessonId } from "../excalidraw-app/api/getuserInfo";
import { getCollaborationLink } from "../excalidraw-app/api/userAPI";

export const getCollaborationDetails = async () => {
  try {
    const lessonId = await getLessonId();
    const res = await getCollaborationLink(lessonId || "");
    return res.result.isFlexibleGroup;
  } catch (error) {
    console.log("error-from-getCollaborationDetails", error);
  }
};
