import { Dispatch, SetStateAction } from "react";

export enum timerDuration {
  MINUTES = "minutes",
  SECONDS = "seconds",
}

export type SegmentType = {
  wheel: number;
  segment: string[];
  topic: string;
  topicGenerationLimit: number; // Define the limits for generating topics in the spin
  results: string[];
  timer: boolean;
  timerDuration: {
    [timerDuration.MINUTES]: number;
    [timerDuration.SECONDS]: number;
  };
};

export type WheelAndQuestionType = {
  questions: string[];
  wheel: number;
};

// Define the type for questionGenerationResultsType
export type QuestionGenerationResultsType = {
  openResultDialog: boolean;
  setOpenResultDialog: Dispatch<SetStateAction<boolean>>;
  results: Dispatch<SetStateAction<string[]>>;
  resultsData: string[];
};
