/* eslint-disable prettier/prettier */
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import "./Videos.scss";
import { close, del, load, loadVideo, menu, uploadIcon } from "./icons";

import Loader from "../App/shareComponent/Loader";
import { NonDeletedExcalidrawElement } from "../element/types";
import {
  fetchWBLocalVideos,
  uploadWBLocalVideos,
} from "../excalidraw-app/api/collection";
import { deleteWbLocalMedia } from "../excalidraw-app/api/userAPI";
import { t } from "../i18n";
import { Subscription } from "../types";
import { convertIsoToDateString } from "../utils/dates";
import { useIsMobile } from "./App";
import { Avatar } from "./Avatar";
import ConfirmDialog from "./ConfirmDialog";
import { Dialog } from "./Dialog";
import { Toast, ToastType } from "./Toast";
import { ToolButton, ToolButtonEnum } from "./ToolButton";

type videoType = {
  createdAt: string;
  url: string;
  name: string;
  fileSize: string;
  isSelected: boolean;
  isLoading: boolean;
};

const Videos = ({
  insertVideoOnCanvas,
  onClose,
  isSideBarOpen,
  setIsSideBarOpen,
  elements,
  subscription,
}: {
  insertVideoOnCanvas: (file: File) => void;
  onClose: () => void;
  isSideBarOpen: boolean;
  setIsSideBarOpen: (val: boolean) => void;
  elements: readonly NonDeletedExcalidrawElement[];
  subscription?: Subscription;
}) => {
  const recordLimit = (subscription && subscription.videoRecordLimit) || 40;
  const [videos, setVideos] = useState<videoType[]>([]);
  const [filteredVideos, setFilteredVideos] = useState<
    {
      url: string;
      name: string;
      isSelected: boolean;
      createdAt: string;
      isLoading: boolean;
      fileSize: string;
    }[]
  >([]);
  const [url, setUrl] = useState<string>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  // const videoRef = useRef<HTMLVideoElement>(null);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [isDeleteAll, setIsDeleteAll] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [videoFile, setVideoFile] = useState<File>();
  const [record, setRecord] = useState<any>({});
  const [currentBlobName, setCurrentBlobName] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [isExceedVideoLimit, setIsExceedVideoLimit] = useState<boolean>(false);
  const [size, setSize] = useState<Number>();
  const [previewVideoDialog, setPreviewVideoDialog] = useState(false);
  const [previewVideo, setPreviewVideo] = useState("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const [fileUrl, setFileUrl] = useState<File>();

  const videoRef: any = useMemo(
    () =>
      Array(videos.length)
        .fill(0)
        .map((i) => createRef()),
    [videos],
  );

  useEffect(() => {
    fetchUpdatedVideos();
  }, []);

  useEffect(() => {
    videos.length > 0 ? checkSelected() : setIsSelectedAll(false);
  }, [videos]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  const onVideoChange = async (event: any) => {
    const selectedFile = fileInputRef.current?.files?.[0];
    const orignalFileName = selectedFile?.name.replace(/\s+/g, "_");
    const isFileAlreadyExists = videos.some(
      (video) => video.name === orignalFileName,
    );

    const date = new Date();
    const reader = new FileReader();

    reader.onload = async () => {
      try {
        const fileUrl = reader.result as string;

        if (isFileAlreadyExists) {
          setToastMessage("");
          setOpenDialog(true);
          return;
        }

        const video = document.createElement("video");
        video.src = URL.createObjectURL(selectedFile);

        video.onloadedmetadata = async () => {
          if (video.duration > Number(recordLimit)) {
            setToastMessage(
              `You've exceeded the limit. You're allowed to upload a maximum of a ${recordLimit}-second video.`,
            );
            return;
          } else {
            const file_ = event.target.files?.[0];
            const lesson = JSON.parse(localStorage.getItem("lesson") || "");

            // Find file sizes | Bytes, KB, MB, GB
            let bytes = file_.size;
            if (bytes >= 1073741824) {
              bytes = (bytes / 1073741824).toFixed(2) + " GB";
            } else if (bytes >= 1048576) {
              bytes = (bytes / 1048576).toFixed(2) + " MB";
            } else if (bytes >= 1024) {
              bytes = (bytes / 1024).toFixed(2) + " KB";
            } else if (bytes > 1) {
              bytes = bytes + " bytes";
            } else if (bytes == 1) {
              bytes = bytes + " byte";
            } else {
              bytes = "0 bytes";
            }

            const user = JSON.parse(localStorage.getItem("user") || "");
            if (file_ && lesson && user.mail) {
              const formData = new FormData();

              formData.append("file", file_);
              formData.append("classId", lesson?.ClassID as string);
              formData.append("mail", user.mail);
              formData.append("fileSize", bytes);

              setIsLoading(true);

              await uploadWBLocalVideos(formData);

              const newVideo: videoType = {
                createdAt: date,
                fileSize: bytes,
                name: file_?.name,
                url: fileUrl,
                isSelected: false,
                isLoading: true,
              };

              setVideos([...videos, newVideo]);
              setIsLoading(false);
            }
          }
        };

        video.onerror = () => {
          console.error("Error loading video:", video.src);
        };
      } catch (err) {
        console.error("Error processing file:", err);
      }
    };

    reader.readAsDataURL(selectedFile);

    setInputKey(Date.now());
  };

  const handleVideo = (path: string) => {
    setPreviewVideoDialog(true);
    setPreviewVideo(path ? path : "");
    // setUrl(path);
  };

  const insertVideo = async (file: File) => {
    if (!file) return;
    const perPageVideoLimit = subscription ? subscription.videoLimit : 0;
    const nonDeletedVideo = elements.filter(
      (data) => data.type === "video" && data.isDeleted === false,
    );
    setIsExceedVideoLimit(
      nonDeletedVideo.length < Number(perPageVideoLimit) ? false : true,
    );
    if (nonDeletedVideo.length < Number(perPageVideoLimit)) {
      insertVideoOnCanvas(file);
      onClose();
    } else {
      setToastMessage("");
    }
  };
  const user = JSON.parse(localStorage.getItem("user") || "");

  const fetchUpdatedVideos = async () => {
    // const video = videos.map((v) => {
    //   return { ...v, isLoading: false };
    // });
    // setVideos(video)
    try {
      setIsLoading(true);

      const user = JSON.parse(localStorage.getItem("user") || "");

      const { results } = await fetchWBLocalVideos(user.mail);

      setRecord(results.length ? results[0] : {});

      const allLocalVideos = results.reduce(
        (acc: string | any[], curr: { localVideos: any }) => {
          return acc.concat(curr.localVideos);
        },
        [],
      );

      const videos = allLocalVideos.length
        ? allLocalVideos.map((video: { url: string; name: string }) => {
            return {
              ...video,
              isSelected: false,
              isLoading: true,
            };
          })
        : [];

      setVideos(videos.length > 0 ? videos : []);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleSelect = (checked: boolean, i: number) => {
    let clonedVideos = [...videos];
    clonedVideos[i].isSelected = checked;
    setVideos(clonedVideos);
  };

  const handleSelectAll = (checked: boolean) => {
    let clonedVideos = [...videos_];

    setIsSelectedAll(checked);
    clonedVideos = clonedVideos.map((video) => {
      return { ...video, isSelected: checked };
    });
    setVideos(clonedVideos);
  };

  const checkSelected = () => {
    const isAllSelected = videos_.every((video) => video.isSelected);
    if (isAllSelected && !isSelectedAll) {
      setIsSelectedAll(true);
    } else if (!isAllSelected && isSelectedAll) {
      setIsSelectedAll(false);
    }
  };

  const handleDelete = async () => {
    try {
      const selectedAudioNames: string[] = [];

      videos_.filter((video) => {
        if (video.isSelected) {
          selectedAudioNames.push(video.name);
        }
      });

      const body = {
        id: record.id,
        classId: record.classId,
        email: record.email,
        type: "video",
        blobNames: isSelectedAll
          ? selectedAudioNames
          : currentBlobName
          ? [currentBlobName]
          : [],
        typeOfDelete: isSelectedAll ? "all" : "single",
      };
      await deleteWbLocalMedia(body);

      let arr: videoType[] = [];
      const deleteVideoFromVideos = videos.filter((video) => {
        if (video.name !== currentBlobName) {
          arr.push({ ...video });
        }
      });
      setVideos(arr);
    } catch (error) {
      console.log("error in delete video", error);
    }
  };

  const handleSearch = () => {
    if (!!search.trim()) {
      const searchedVideos = videos_.filter((image) => {
        const nameWithoutExtension = image.name
          .split(".")
          .slice(0, -1)
          .join(".");
        return nameWithoutExtension
          .toLowerCase()
          .includes(search.toLowerCase());
      });

      setFilteredVideos(searchedVideos);
    } else {
      setFilteredVideos([]);
    }
  };

  const videos_ = !!search.trim() ? filteredVideos : videos;
  const handleVideoLoading = (i: number) => {
    console.log("i", i);
    const clonedVideos = [...videos_];
    clonedVideos[i].isLoading = false;
    if (!!search.trim()) {
      setFilteredVideos(clonedVideos);
    } else {
      setVideos(clonedVideos);
    }
  };

  const closeModal = () => {
    setPreviewVideoDialog(false);
  };

  return (
    <>
      {previewVideoDialog && (
        <Dialog
          onCloseRequest={closeModal}
          title={
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ToolButton
                  style={{
                    width: "15%",
                    height: "34px",
                    background: "#4B4984",
                    justifyContent: "flex-start",
                  }}
                  type={ToolButtonEnum.BUTTON}
                  icon={
                    <svg
                      className="load_icon"
                      style={{ width: "1rem", height: "1rem" }}
                    >
                      {loadVideo}
                    </svg>
                  }
                  onClick={async () => {
                    if (!videoFile) {
                      try {
                        setIsLoading(true);
                        const response = await fetch(previewVideo);
                        if (!response.ok) {
                          throw new Error(
                            `Failed to fetch video (status ${response.status})`,
                          );
                        }
                        const data = await response.blob();

                        const file_ = new File([data], "video.mp4", {
                          type: data.type || "video/mp4",
                        });
                        setIsLoading(false);
                        insertVideo(file_);
                      } catch (err) {
                        setToastMessage("Error fetching video");
                        console.error("Error fetching video:", err);
                      }
                    } else {
                      insertVideo(videoFile);
                    }
                  }}
                  title={t("collection.personal.audios.uploadBtn")}
                  aria-label={t("labels.alignTop")}
                >
                  <span
                    style={{
                      color: "white",
                      fontSize: "16px",
                      marginRight: "9px",
                    }}
                  >
                    {t("collection.personal.audios.uploadBtn")}
                  </span>
                </ToolButton>
                <span style={{ width: "100%" }}>Preview collection Video</span>
              </div>
            </>
          }
          closeOnClickOutside={true}
          open={previewVideoDialog}
          setOpen={() => true}
          children={
            <>
              <video
                src={`${previewVideo}`}
                controls
                autoPlay
                className="w-100 h-100"
              />
            </>
          }
          className="preview-dialog"
        />
      )}
      {isOpen && (
        <ConfirmDialog
          onCancel={() => {
            setIsOpen(false);
            setIsDeleteAll(false);
          }}
          onConfirm={async () => {
            setIsOpen(false);
            setIsDeleteAll(false);
            await handleDelete();
          }}
          onClose={() => {
            setIsOpen(false);
            setIsDeleteAll(false);
          }}
          confirmText="Delete"
          title={isDeleteAll ? "Delete videos" : "Delete video"}
          open={isOpen}
          setOpen={setIsOpen}
          children={
            isDeleteAll
              ? "Are you sure you want to delete all the videos?"
              : "Are you sure you want to delete the video?"
          }
          isShowInput={false}
          isEditable={false}
        />
      )}
      {isExceedVideoLimit && toastMessage !== null && (
        <Toast
          type={ToastType.ERROR}
          message={`You've exceeded the limit You're allowed to upload a maximum of ${
            subscription && subscription.videoLimit
          } videos per page.`}
          clearToast={() => setToastMessage(null)}
          className="style"
        />
      )}
      {openDialog && toastMessage !== null && (
        <Toast
          type={ToastType.ERROR}
          message={`The selected file name already exists. Please choose a unique file name to proceed.`}
          clearToast={() => setToastMessage(null)}
          className="style"
        />
      )}

      <div className="video_container h-100">
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="fw-bold">{t("collection.personal.videos.title")}</h4>
            <h4 className="text">{t("collection.personal.videos.message")}</h4>
          </div>

          {!url && (
            <div className="btn_div">
              <label className="upload_btn me-2">
                <svg height={15} width={15} className="me-2">
                  {uploadIcon}
                </svg>
                {t("collection.personal.videos.uploadBtn")}
                <input
                  key={inputKey}
                  type="file"
                  onChange={onVideoChange}
                  ref={fileInputRef}
                  accept="video/*"
                />
              </label>
            </div>
          )}
        </div>
        <div className="pb-2 col-md-12">
          <div className="col-md-5 col-sm-12 position-relative">
            <div className="d-flex align-items-center">
              <button
                className={`btn me-3 ${useIsMobile() ? "d-none" : ""}`}
                // data-bs-toggle="offcanvas"
                // data-bs-target="#offcanvasExample"
                // aria-controls="offcanvasExample"
                style={{ width: "28px" }}
                onClick={() => {
                  setIsSideBarOpen(!isSideBarOpen);
                }}
              >
                {menu}
              </button>
              <input
                type="text"
                id="search"
                name="search"
                placeholder="Search by title"
                className="m-0 w-100 mt-lg-0 mt-3 "
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            {/* <button
              className="ToolIcon_type_button ToolIcon_size_medium  position-absolute search-icon-wrapper ToolIcon_type_button--show ToolIcon"
              aria-label="close"
              type="button"
              // style={{ left: "48%" }}
            >
              <div className="ToolIcon__icon d-flex" aria-hidden="true">
                {searchIcon}
              </div>
            </button> */}
          </div>
        </div>
        {videos_?.length > 0 && (
          <div
            className="d-flex align-items-center pb-2"
            // style={{ height: "25px" }}
          >
            Select all
            <input
              className="mx-2"
              checked={isSelectedAll}
              onChange={(e) => {
                handleSelectAll(e.target.checked);
              }}
              type="checkbox"
            />
            <div
              className={`delete-icon-wrapper  ${
                isSelectedAll ? "" : "pe-none"
              }`}
              onClick={() => {
                setIsOpen(true);
                setIsDeleteAll(true);
              }}
            >
              {del}
            </div>
            {url && (
              <div
                style={{ display: "flex", position: "absolute", right: "3%" }}
              >
                <div className="btn_div" style={{ marginLeft: "5px" }}>
                  <label
                    className="uploaded__btn"
                    style={{
                      border: "1.5px solid rgb(196 49 75)",
                      display: "flex",
                    }}
                    onClick={() => setUrl("")}
                  >
                    <svg className="close_icon">{close}</svg>
                    Close
                  </label>
                </div>

                {isLoading ? (
                  <div
                    className="spinner-border load_icon_loader"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div className="btn_div" style={{ marginLeft: "5px" }}>
                    <label
                      className="uploaded_btn"
                      style={{
                        border: "1.5px solid rgb(88, 90, 150)",
                        display: "flex",
                      }}
                      onClick={async () => {
                        if (!videoFile) {
                          try {
                            setIsLoading(true);
                            const response = await fetch(url);
                            if (!response.ok) {
                              throw new Error(
                                `Failed to fetch video (status ${response.status})`,
                              );
                            }
                            const data = await response.blob();

                            const file_ = new File([data], "video.mp4", {
                              type: data.type || "video/mp4",
                            });
                            setIsLoading(false);
                            insertVideo(file_);
                          } catch (err) {
                            setToastMessage("Error fetching video");
                            console.error("Error fetching video:", err);
                          }
                        } else {
                          insertVideo(videoFile);
                        }
                      }}
                    >
                      <svg className="load_icon">{load}</svg>
                      {t("collection.personal.audios.uploadBtn")}
                    </label>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {url ? (
          <div className="video_dialog">
            <video
              src={`${url}`}
              controls
              ref={videoRef}
              // style={{ height: "auto", width: "100%" }}
            />
          </div>
        ) : (
          <>
            {isLoading ? <Loader className="style" /> : null}
            <div
              className="row overflow-auto ps-2"
              style={{ opacity: isLoading ? "0.5" : "" }}
            >
              {toastMessage !== null && (
                <Toast
                  type={ToastType.ERROR}
                  message={toastMessage}
                  clearToast={() => setToastMessage(null)}
                  className="styleChange"
                />
              )}
              {videos_?.length > 0 ? (
                videos_.map((video, i) => {
                  const nameWithoutExtension = video.name
                    .split(".")
                    .slice(0, -1)
                    .join(".");

                  return (
                    <div className="col-md-4 col-sm-12 p-1">
                      <div
                        className={`card video_card p-2 ${
                          isSelectedAll ? "red-border" : ""
                        } placeholder-glow`}
                      >
                        <div className="d-flex align-items-center justify-content-between py-2">
                          <Avatar
                            color="grey"
                            className="flex-shrink-0 overflow-hidden"
                            border=""
                            onClick={() => {}}
                          >
                            {!!user?.UrlPhoto ? (
                              <img
                                key={i}
                                src={user.UrlPhoto}
                                className="w-100 h-auto "
                                alt="user-profile"
                              />
                            ) : (
                              user?.name[0].toUpperCase() || "A"
                            )}
                          </Avatar>
                          <div style={{ width: "73%" }}>
                            <p className="mb-0 text-nowrap video-name w-100 px-2">
                              {nameWithoutExtension}
                            </p>
                            <div style={{ display: "flex" }}>
                              <p className="mb-0 text-nowrap video-name  px-2">
                                {convertIsoToDateString(video.createdAt)}{" "}
                                {video?.fileSize && (
                                  <span style={{ marginLeft: "10px" }}>
                                    Size:{" "}
                                    {video?.fileSize ? video.fileSize : null}
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                          <div
                            className="delete-icon-wrapper-1 position-relative"
                            onClick={() => {
                              setIsOpen(true);
                              setCurrentBlobName(video.name);
                            }}
                          >
                            {del}
                          </div>
                        </div>
                        <div
                          className={`placeholder ${
                            video.isLoading ? "" : "d-none"
                          }`}
                          style={{ height: "240px" }}
                        ></div>
                        <div
                          className={`${
                            video.isLoading ? "d-none" : ""
                          } video-wrapper text-center`}
                          onMouseOver={() => videoRef[i]?.current?.play()}
                          onFocus={() => videoRef[i]?.current?.play()}
                          onMouseOut={() => videoRef[i]?.current?.pause()}
                        >
                          <video
                            ref={videoRef[i]}
                            src={video.url}
                            className="video-tag py-2 img-fluid position-relative"
                            onLoadedData={() => {
                              handleVideoLoading(i);
                            }}
                          />

                          <div className="overlay"></div>
                          <div
                            className="video_icon"
                            onClick={() => handleVideo(video.url)}
                          >
                            <button className="previewBtn">
                              <p className="previewText">Preview</p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 13 12"
                                fill="none"
                              >
                                <path
                                  d="M8.595 1H4.405C2.585 1 1.5 2.085 1.5 3.905V8.09C1.5 9.915 2.585 11 4.405 11H8.59C10.41 11 11.495 9.915 11.495 8.095V3.905C11.5 2.085 10.415 1 8.595 1ZM9.125 6.165C9.125 6.37 8.955 6.54 8.75 6.54C8.545 6.54 8.375 6.37 8.375 6.165V4.655L4.515 8.515C4.44 8.59 4.345 8.625 4.25 8.625C4.155 8.625 4.06 8.59 3.985 8.515C3.84 8.37 3.84 8.13 3.985 7.985L7.845 4.125H6.335C6.13 4.125 5.96 3.955 5.96 3.75C5.96 3.545 6.13 3.375 6.335 3.375H8.75C8.955 3.375 9.125 3.545 9.125 3.75V6.165Z"
                                  fill="#FAF3EC"
                                />
                              </svg>
                            </button>
                          </div>
                          {/* <svg
                          className="video_icon"
                          onClick={() => handleVideo(video.url)}
                        >
                          {previewIcon}
                        </svg> */}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="empty_img text-center mt-5 pt-5">
                    <img
                      src="https://acvstorageprod.blob.core.windows.net/react-images/Empty-pana.svg"
                      alt=""
                      height={292}
                      width={292}
                    />
                  </div>
                  <p className="text-center">
                    {t("collection.personal.videos.emptyMessage")}
                  </p>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Videos;
