import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  bookmarkCollectionAPI,
  fetchFilteredCollectionData,
  fetchMyPublishedCollectionAPI,
  likeUnlikeCollectionAPI,
  withdrawCollectionApprovalRequestAPI,
} from "../../../../../../excalidraw-app/api/collection";
import { getUserInfo } from "../../../../../../excalidraw-app/api/getuserInfo";
import { CardWithImage } from "../../../../components/card";
import { CollocationType } from "../../../../../../types";
import Loader from "../../../../../../App/shareComponent/Loader";
import { TemplateCollectionProps } from "../template";
import { Toast, ToastType } from "../../../../../Toast";
import { FilterRef, FilteredValueType } from "../../CollectionsTab";
import { t } from "../../../../../../i18n";

export const MyCollection = forwardRef<FilterRef, TemplateCollectionProps>(
  (
    {
      searchVal,
      setFullScreenCollectionPreview,
      setPreviewTemplateData,
      selectedSortBy,
    },
    ref,
  ) => {
    const [mycollections, setMycollections] = useState<CollocationType[]>([]);
    const [filteredMyCollections, setFilteredMyCollections] = useState<
      CollocationType[]
    >([]);
    const [toastMessage, setToastMessage] = useState<{
      message: string;
      type: ToastType;
    } | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const fetchMyPublishedCollections = async () => {
      try {
        setIsLoading(true);
        const user = await getUserInfo();
        const apiBody: {
          userId: string;
        } = {
          userId: user.mail,
        };

        const { result } = await fetchMyPublishedCollectionAPI(apiBody);

        setMycollections(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    useEffect(() => {
      fetchMyPublishedCollections();
    }, []);

    useEffect(() => {
      const onSortCollection = async (index: number) => {
        setIsLoading(true);
        try {
          const user = await getUserInfo();
          const apiBody = {
            userId: user.mail,
            sort: index,
          };

          //index 1: newest, 2: most like, 3: title(a-z), 4: author(a-z)
          const { result } = await fetchMyPublishedCollectionAPI(apiBody);
          setFilteredMyCollections(result);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.log(error);
        }
      };

      onSortCollection(selectedSortBy ? selectedSortBy : 0);
    }, [selectedSortBy]);

    useEffect(() => {
      if (searchVal) {
        const searchResult = mycollections.filter((template) =>
          template.name.toLowerCase().includes(searchVal.toLowerCase()),
        );
        return setFilteredMyCollections(searchResult);
      }
      setFilteredMyCollections(mycollections);
    }, [searchVal, mycollections]);

    const fetchFilteredData = async (filterDataWithKeys: FilteredValueType) => {
      try {
        setIsLoading(true);
        const user = await getUserInfo();

        const { result } = await fetchFilteredCollectionData({
          ...filterDataWithKeys,
          userId: user.mail,
          myPublished: true,
        });

        setFilteredMyCollections(result.resources);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("error-in-fetchFilteredData", error);
      }
    };

    const removeFilters = async () => {
      try {
        setIsLoading(true);
        const user = await getUserInfo();
        const apiBody: {
          userId: string;
        } = {
          userId: user.mail,
        };
        const { result } = await fetchMyPublishedCollectionAPI(apiBody);

        setFilteredMyCollections(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    useImperativeHandle(ref, () => ({
      onApply: fetchFilteredData,
      onClear: removeFilters,
    }));

    return (
      <div className="row overflow-auto" style={{ height: "calc(100% - 28%)" }}>
        {isLoading ? (
          <Loader className="h-100" />
        ) : (
          filteredMyCollections.map((item: any, index: number) => (
            <CardWithImage
              key={index}
              created={item.created}
              images={item.preview}
              name={item.name}
              userProfile={item.authors[0]?.url}
              userName={item.authors[0]?.name}
              likes={item.likeCount}
              isLikefill={item.isLiked}
              onLike={async () => {
                const res = await likeUnlikeCollectionAPI(item.id);
                return res.result.likeCount;
              }}
              isBookMarkFill={item.isBookmark}
              onBookmark={async () => {
                await bookmarkCollectionAPI(item.id);
              }}
              setFullScreenCollectionPreview={setFullScreenCollectionPreview}
              onPreview={() => {
                setPreviewTemplateData(item);
              }}
              buttonText={t("cards.unPublish")}
              onAddtoCollectionBtnClick={async () => {
                await withdrawCollectionApprovalRequestAPI({ id: item.id });
                setFilteredMyCollections(
                  filteredMyCollections.filter(
                    (collection) => collection.id !== item.id,
                  ),
                );
                setToastMessage({
                  message: "Successfully unpublished collection.",
                  type: ToastType.ERROR,
                });
              }}
              isHoverUseThisTemplateBtn={false}
              isMove={true}
              id={item.id}
              onAfterMovedToCategory={(category: string[]) => {
                if (category.length) {
                  const addCategory = category?.filter(
                    (category: string) => category !== item.id,
                  );
                  const index = filteredMyCollections.findIndex(
                    (data) => data.id === item.id,
                  );
                  if (addCategory) {
                    const data = [...filteredMyCollections];

                    data[index].category = [...addCategory];

                    setFilteredMyCollections([...data]);
                  }
                }
                setToastMessage({
                  message: "Collection successfully imported.",
                  type: ToastType.SUCCESS,
                });
              }}
              onAfterDeleteCategory={(id) => {
                if (item.id !== id) {
                  const updatedCategory = item.category?.filter(
                    (category: string) => category !== id,
                  );
                  const index = filteredMyCollections.findIndex(
                    (data) => data.id === item.id,
                  );
                  if (updatedCategory) {
                    const data = [...filteredMyCollections];

                    data[index].category = [...updatedCategory];

                    setFilteredMyCollections([...data]);
                  }
                }
              }}
              templateCategory={item.category}
            />
          ))
        )}
        {toastMessage !== null && (
          <Toast
            type={toastMessage.type}
            message={toastMessage.message}
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
      </div>
    );
  },
);
