/* eslint-disable prettier/prettier */
import axios from "axios";
import { KeyboardEvent, useEffect, useState } from "react";
import { t } from "../i18n";
import { CollocationType } from "../types";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import { ReactSVG } from "react-svg";

type ChildProps = {
  handleClose: () => void;
  changeTab: (val: string) => void;
  libraryData?: CollocationType;
};

interface PublishLibraryDataParams {
  name: string | undefined;
  description: string | undefined;
}

const EditLibrary = ({ handleClose, changeTab, libraryData }: ChildProps) => {
  const [libData, setLibData] = useState<PublishLibraryDataParams>({
    name: "",
    description: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    description: "",
  });

  const [tags, setTags] = useState<string[]>([]);
  const [tag, setTag] = useState("");
  const addTags = (event: KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.key === "Enter" && tag) {
      setTags([...tags, tag]);
      setTag("");
    }
  };

  const removeTags = (index: number) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  const onInputChange = (event: any) => {
    setLibData({
      ...libData,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    setLibData({
      name: libraryData?.name,
      description: libraryData?.description,
    });
    setTags(libraryData?.tag || []);
  }, []);

  const onSubmit = () => {
    let isError = false;
    if (!libData.description || !libData.name) {
      isError = true;
      setErrors({
        ...errors,
        description: !libData.description
          ? t("publishDialog.errors.required")
          : "",
        name: !libData.name ? t("publishDialog.errors.required") : "",
      });
    } else {
      isError = false;
      setErrors({ ...errors, description: "", name: "" });
    }

    if (isError) return;

    axios
      .put(
        `${process.env.REACT_APP_EP_URL_WHITEBOARD}/edit-collection?id=${libraryData?.id}`,
        { ...libData, tag: tags },
      )
      .then((res) => {
        if (res.data.result) {
          changeTab("Under review");
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Something went wrong");
      });
  };

  return (
    <>
      <div className="row publish-library">
        <h1 className="title m-3">Edit library</h1>
        <div className="text-center">
          {libraryData?.preview?.split(".")?.pop() == "svg" ? (
            <ReactSVG src={libraryData.preview} className="collectionImage" />
          ) : (
            <img src={libraryData?.preview} alt="" height={150} width={150} />
          )}
        </div>
        <div className="p-5">
          <div className="publish-library__fields">
            <label>
              <div>
                <span>{t("publishDialog.libraryName")}</span>
                <span aria-hidden="true" className="required">
                  *
                </span>
              </div>
              <div style={{ position: "relative", width: "70%" }}>
                <input
                  type="text"
                  name="name"
                  required
                  value={libData.name}
                  onChange={onInputChange}
                  placeholder={t("publishDialog.placeholder.libraryName")}
                />
                <span className="error">{errors.name}</span>
              </div>
            </label>
            <label style={{ alignItems: "flex-start" }}>
              <div>
                <span>{t("publishDialog.libraryDesc")}</span>
                <span aria-hidden="true" className="required">
                  *
                </span>
              </div>
              <div style={{ position: "relative", width: "70%" }}>
                <textarea
                  name="description"
                  rows={4}
                  required
                  value={libData?.description}
                  onChange={onInputChange}
                  placeholder={t("publishDialog.placeholder.libraryDesc")}
                />
                <span className="error">{errors.description}</span>
              </div>
            </label>

            <label>
              <span>{"Tag"}</span>
              <div className="tags-input">
                <ul id="tags">
                  {tags.length > 0 &&
                    tags.map((tag, index) => (
                      <li key={index} className="tag">
                        <span className="tag-title">{tag}</span>
                        <span
                          className="tag-close-icon"
                          onClick={() => removeTags(index)}
                        >
                          {" "}
                          &#x2715;
                        </span>
                      </li>
                    ))}
                </ul>
                <input
                  type="text"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  onKeyUp={addTags}
                  placeholder="Press enter to add tags"
                  style={{
                    flex: "1 1",
                    border: "none",
                    height: "46px",
                    fontSize: "14px",
                    padding: "0.6rem",
                  }}
                />
              </div>
            </label>
          </div>
          <div className="publish-library__buttons mt-3">
            <ToolButton
              type={ToolButtonEnum.BUTTON}
              title={t("buttons.cancel")}
              aria-label={t("buttons.cancel")}
              label={t("buttons.cancel")}
              onClick={() => changeTab("")}
              data-testid="cancel-clear-canvas-button"
              className="publish-library__buttons--cancel"
            />
            <ToolButton
              type="submit"
              title={t("buttons.submit")}
              aria-label={t("buttons.submit")}
              label={t("buttons.submit")}
              className="publish-library__buttons--confirm"
              // isLoading={isSubmitting}
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditLibrary;
