import { useState } from "react";
import "./SelectMenu.scss";

const SelectMenu = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="multiselect">
      <div
        className="selectBox"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <select className="border-0 custom-select">
          <option className="select-title">{props.title}</option>
        </select>
        <div className="overSelect" />
      </div>
      <div id="checkboxes" className={`${isOpen ? "d-block" : "d-none"}`}>
        {props.children}
      </div>
    </div>
  );
};

export default SelectMenu;
