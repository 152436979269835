import React, { useRef, useState } from "react";
import { copyTextToSystemClipboard } from "../../clipboard";
import { Dialog } from "../../components/Dialog";
import {
  start,
  stop,
  share,
  shareIOS,
  shareWindows,
  copy,
} from "../../components/icons";
import { ToolButton, ToolButtonEnum } from "../../components/ToolButton";
import { t } from "../../i18n";
import "./RoomDialog.scss";
import Stack from "../../components/Stack";
import { AppState } from "../../types";
import ConfirmDialog from "../../components/ConfirmDialog";

const getShareIcon = () => {
  const navigator = window.navigator as any;
  const isAppleBrowser = /Apple/.test(navigator.vendor);
  const isWindowsBrowser = navigator.appVersion.indexOf("Win") !== -1;

  if (isAppleBrowser) {
    return shareIOS;
  } else if (isWindowsBrowser) {
    return shareWindows;
  }

  return share;
};

const RoomDialog = ({
  handleClose,
  activeRoomLink,
  username,
  onUsernameChange,
  onRoomCreate,
  onRoomDestroy,
  setErrorMessage,
  theme,
  openFlexibleGroupsDialog,
  onStartFlexibleGroupCollaboration,
}: {
  handleClose: () => void;
  activeRoomLink: string;
  username: string;
  onUsernameChange: (username: string) => void;
  onRoomCreate: (val: boolean) => void;
  onRoomDestroy: () => void;
  setErrorMessage: (message: string) => void;
  theme: AppState["theme"];
  openFlexibleGroupsDialog: VoidFunction;
  onStartFlexibleGroupCollaboration: () => void;
}) => {
  const roomLinkInput = useRef<HTMLInputElement>(null);
  const [isShowModel, setIsShowModel] = useState<boolean>(false);

  const copyRoomLink = async () => {
    try {
      await copyTextToSystemClipboard(activeRoomLink);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    if (roomLinkInput.current) {
      roomLinkInput.current.select();
    }
  };

  const shareRoomLink = async () => {
    try {
      await navigator.share({
        title: t("roomDialog.shareTitle"),
        text: t("roomDialog.shareTitle"),
        url: activeRoomLink,
      });
    } catch (error) {
      // Just ignore.
    }
  };

  const selectInput = (event: React.MouseEvent<HTMLInputElement>) => {
    if (event.target !== document.activeElement) {
      event.preventDefault();
      (event.target as HTMLInputElement).select();
    }
  };

  const renderRoomDialog = () => {
    return (
      <div className="RoomDialog-modal">
        {!activeRoomLink && (
          <>
            <p className="intro">{t("roomDialog.desc_intro")}</p>
            <p>{`🔐${t("roomDialog.desc_privacy")}`}</p>
            <div className="RoomDialog-sessionStartButtonContainer">
              <ToolButton
                className="RoomDialog-startSession"
                type={ToolButtonEnum.BUTTON}
                icon={start}
                title={t("roomDialog.button_startSession")}
                aria-label={t("roomDialog.button_startSession")}
                showAriaLabel={true}
                onClick={() => {
                  setIsShowModel(true);
                }}
                id="createRoomBtn"
              />
              {/* {() => {
                let element: HTMLElement = document.querySelector(
                  "createRoomBtn",
                ) as HTMLElement;
                element.click();
              }} */}
            </div>
          </>
        )}
        {activeRoomLink && (
          <>
            {/* <p>{t("roomDialog.desc_inProgressIntro")}</p> */}
            <p className="intro">{t("roomDialog.desc_shareLink")}</p>
            <div className="RoomDialog-linkContainer">
              <input
                value={activeRoomLink}
                readOnly={true}
                className="RoomDialog-link"
                ref={roomLinkInput}
                onPointerDown={selectInput}
              />
              <Stack.Row gap={2}>
                <ToolButton
                  type={ToolButtonEnum.BUTTON}
                  icon={copy}
                  title={t("labels.copy")}
                  aria-label={t("labels.copy")}
                  onClick={copyRoomLink}
                />
                {"share" in navigator ? (
                  <ToolButton
                    type={ToolButtonEnum.BUTTON}
                    icon={getShareIcon()}
                    title={t("labels.share")}
                    aria-label={t("labels.share")}
                    onClick={shareRoomLink}
                  />
                ) : null}
              </Stack.Row>
            </div>
            <div className="RoomDialog-usernameContainer">
              <label className="RoomDialog-usernameLabel" htmlFor="username">
                {t("labels.yourName")}
              </label>
              <input
                id="username"
                value={username || ""}
                className="RoomDialog-username TextInput"
                onChange={(event) => onUsernameChange(event.target.value)}
                onKeyPress={(event) => event.key === "Enter" && handleClose()}
              />
            </div>
            {/* <p>
              <span role="img" aria-hidden="true" className="RoomDialog-emoji">
                {"🔒"}
              </span>{" "}
              {t("roomDialog.desc_privacy")}
            </p> */}
            <p>{t("roomDialog.desc_exitSession")}</p>
            <div
              className="RoomDialog-sessionStartButtonContainer"
              style={{ gap: "10px" }}
            >
              <ToolButton
                type={ToolButtonEnum.BUTTON}
                icon={start}
                title="Continue working"
                aria-label="Continue working"
                showAriaLabel={true}
                onClick={handleClose}
                style={{ background: "#585A96" }}
              />

              <ToolButton
                className="RoomDialog-stopSession"
                type={ToolButtonEnum.BUTTON}
                icon={stop}
                title={t("roomDialog.button_stopSession")}
                aria-label={t("roomDialog.button_stopSession")}
                showAriaLabel={true}
                onClick={onRoomDestroy}
              />
            </div>
          </>
        )}
      </div>
    );
  };
  return (
    <Dialog
      onCloseRequest={handleClose}
      title={t("labels.liveCollaboration")}
      theme={theme}
    >
      <div className="room-dialog-wrapper">
        {renderRoomDialog()}
        {isShowModel && (
          <ConfirmDialog
            cancelText={t("whiteBoardModel.cancelBtn")}
            confirmText={t("whiteBoardModel.okBtn")}
            onCancel={() => {
              onRoomCreate(false);
              setIsShowModel(false);
            }}
            onClose={() => {
              setIsShowModel(false);
            }}
            onConfirm={() => {
              handleClose();
              setIsShowModel(false);
              openFlexibleGroupsDialog();
            }}
            children={
              <>
                <p>{t(`labels.collaborationConfirmMsg`)}</p>
              </>
            }
            open={isShowModel}
            title={t(`labels.flexibleGroup`)}
            setOpen={setIsShowModel}
            isEditable={false}
            isShowInput={false}
          />
        )}
      </div>
    </Dialog>
  );
};

export default RoomDialog;
