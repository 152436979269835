/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";

import { CollocationType, Filter, LibraryItem } from "../types";
import { edit, reviewIcon } from "./icons";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import "./UnderReview.scss";
import { t } from "../i18n";
import moment from "moment";
import Loader from "../App/shareComponent/Loader";
import { Avatar } from "./Avatar";
import LibraryFilterMenu from "./LibraryFilterMenu";
import { getUserInfo } from "../excalidraw-app/api/getuserInfo";
import {
  fetchAllCollection,
  fetchMyPublishedCollectionAPI,
  fetchUnderReviewCollectionsAPI,
} from "../excalidraw-app/api/collection";
import MultiSelectDropdown from "./MultiSelectDropdown";
import { filteredValueType } from "./LibraryDialog";
import { ReactSVG } from "react-svg";
import { LibraryCarousel } from "./LibraryCarousel";
import { CollectionImagePreview } from "./collectionImagePreview";

export type UnderReviewInput = {
  userId: string;
};

type UnderReviewPropsType = {
  underReviewData: CollocationType[];
  withdrawCollectionApprovalRequest: (id: string) => void;
  handleEditLibData: (data: CollocationType) => void;
  changeTab: (val: string) => void;
  isLoading: boolean;
  setIsLoading: (val: boolean) => void;
  setFilterData: (val: string[]) => void;
  setFilterDataWithKeys: (data: filteredValueType) => void;
  filterDataWithKeys: filteredValueType;
  filterOption: Filter;
  fetchFilteredData: (data: filteredValueType) => void;
  filterData: string[];
  isSideBarOpen: boolean;
  setIsSideBarOpen: (val: boolean) => void;
  onInsertShape: (elements: LibraryItem["elements"]) => void;
  closeLibraryDialog?: () => void;
};

const UnderReview = ({
  underReviewData,
  withdrawCollectionApprovalRequest,
  handleEditLibData,
  changeTab,
  isLoading,
  setIsLoading,
  setFilterData,
  setFilterDataWithKeys,
  filterDataWithKeys,
  filterOption,
  filterData,
  fetchFilteredData,
  isSideBarOpen,
  setIsSideBarOpen,
  onInsertShape,
  closeLibraryDialog,
}: UnderReviewPropsType) => {
  const [filteredUnderReviewData, setFilteredUnderReviewData] = useState<
    CollocationType[]
  >([]);

  const [statusReason, setStatusReason] = useState("");
  const [status, setStatus] = useState("Under review");
  const [search, setSearch] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState(0);
  const [open, setOpen] = useState<boolean>(false);
  const [isFilterApply, setFilterApply] = useState<boolean>(false);
  const [removeVal, setRemoveVal] = useState<string>("");

  const selectArray = [
    {
      title: t("collection.community.template.filter.age"),
      label: [
        t("collection.community.template.filter.options.7day"),
        t("collection.community.template.filter.options.1month"),
        t("collection.community.template.filter.options.3month"),
        t("collection.community.template.filter.options.6month"),
      ],

      id: "age",
      search: false,
    },
    {
      title: t("collection.community.template.filter.author"),
      label: filterOption.authors,
      id: "author",
      search: true,
    },
    {
      title: t("collection.community.template.filter.tags"),
      label: filterOption.tags,
      id: "tags",
      search: true,
    },
  ];

  const handleEdit = async (data: CollocationType) => {
    const response = await fetch(
      "https://acvstorageprod.blob.core.windows.net/collecations/super-admin-test-1699264889445.acv",
    );
    const result = await response.json();
    onInsertShape(result.libraryItems[0].elements);
    closeLibraryDialog && closeLibraryDialog();
  };

  useEffect(() => {
    handleSearch();
  }, [search]);

  const handleSearch = () => {
    if (!!search.trim()) {
      const searchedCollections = underReviewData.filter((collection) =>
        collection.name.toLowerCase().includes(search.toLowerCase()),
      );
      setFilteredUnderReviewData(searchedCollections);
    } else {
      setFilteredUnderReviewData([]);
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue: any = event.target.value;
    setSelectedValue(newValue);
    onSortCollection(newValue);
  };

  const onSortCollection = async (index: number) => {
    setIsLoading(true);
    try {
      setSearch("");
      // used index because it's tricky to handle when languages are change
      //index 1: newest, 2: most like, 3: title(a-z), 4: author(a-z)
      const { result } = await fetchAllCollection({ sort: index });
      setFilteredUnderReviewData(result.resources);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const searchAPI = async () => {
    try {
      setIsLoading(true);
      if (search) {
        const lessonId = new URLSearchParams(window.location.search)
          .get("lessonId")
          ?.replace(/\//g, "") as string;
        const user = await getUserInfo();
        const apiBody: {
          userId: string;
          search: string;
        } = {
          userId: user.mail,
          search,
        };
        const { result } = await fetchUnderReviewCollectionsAPI(apiBody);
        setFilteredUnderReviewData(result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const removeFilters = async () => {
    try {
      setFilterData([]);
      setIsLoading(true);
      const { result } = await fetchAllCollection({});
      setFilteredUnderReviewData(result.resources);
      setFilterDataWithKeys({
        ...filterDataWithKeys,
        age: "",
        author: [],
        tags: [],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const underReviewData_ = !!search.trim()
    ? filteredUnderReviewData
    : underReviewData;

  return (
    <>
      <div className="review_container h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="fw-bold">
              {t("collection.personal.underReview.title")}
            </h4>
            <h4 className="text">
              {t("collection.personal.underReview.message")}
            </h4>
          </div>
        </div>
        <LibraryFilterMenu
          collections={underReviewData}
          handleSelectChange={handleSelectChange}
          onSortCollection={onSortCollection}
          searchAPI={searchAPI}
          selectedValue={selectedValue}
          setFilteredCollections={setFilteredUnderReviewData}
          setOpen={setOpen}
          searchVal={search}
          setSearchVal={setSearch}
          isSideBarOpen={isSideBarOpen}
          setIsSideBarOpen={setIsSideBarOpen}
        />
        <div className="d-flex flex-wrap gap-3 justify-content-center overflow-auto h-100">
          {isLoading ? (
            <Loader className="h-100" />
          ) : underReviewData_.length > 0 ? (
            underReviewData_.map((collection: CollocationType, index) => {
              return (
                <div className=" item">
                  <div className="card item-card card-block mt-4">
                    <h4 className="card-title d-flex justify-content-between">
                      {collection.name}
                      <svg
                        height={23}
                        width={20}
                        className="cursor-pointer"
                        onClick={() => {
                          handleEdit(collection);
                        }}
                      >
                        {edit}
                      </svg>
                    </h4>
                    {Array.isArray(collection.preview) ? (
                      collection.preview.length > 0 &&
                      collection.preview.length > 1 ? (
                        <LibraryCarousel
                          images={(collection.preview as unknown) as string[]}
                          index={index}
                          name={collection.name}
                        />
                      ) : (
                        <CollectionImagePreview
                          previewURL={collection.preview[0]}
                          index={index}
                        />
                      )
                    ) : (
                      <CollectionImagePreview
                        previewURL={collection.preview}
                        index={index}
                      />
                    )}

                    <div className="item-card-title mt-3 mb-3 d-flex justify-content-between">
                      <span className="d-flex">
                        {collection.authors[0].url &&
                        collection.authors[0].url !== "null" ? (
                          <img
                            key={index}
                            src={collection.authors[0].url}
                            alt=""
                            className="Avatar"
                          />
                        ) : (
                          <Avatar
                            onClick={() => {
                              // const msg =
                              //   user?.displayName +
                              //   " clicked profile!\nYour Email is : " +
                              //   user?.mail;
                              // alert(msg);
                            }}
                            color={"#4c6ef5"}
                            border={"#4c6ef5"}
                          >
                            {collection.authors[0].name.substring(0, 2)}
                          </Avatar>
                        )}
                        <span className="ms-2 mt-2">
                          {collection.authors[0].name}
                        </span>
                        {/* <img
                          src={
                            collection.authors[0].url
                              ? collection.authors[0].url
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQR0_bE8-LhxBz4Uxq8Fs4Tp1-rLOYjJsYPQQ&usqp=CAU"
                          }
                          alt="authorImage"
                          className="rounded-circle author-image"
                          height="24px"
                          width="24px"
                        /> */}
                      </span>
                      <p className="date">
                        {moment
                          .utc(collection.created)
                          .local()
                          .format("YYYY-MM-DD")}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p
                        onMouseEnter={() => {
                          setStatusReason(collection.reason);
                          setStatus(collection.status);
                        }}
                        data-tip="React-tooltip"
                        data-for="happyFace"
                        className={`${
                          collection.status === "Under review"
                            ? "warning_text"
                            : "reject_colllection"
                        }`}
                      >
                        <span>
                          <svg height={20} width={20}>
                            {reviewIcon}
                          </svg>
                        </span>
                        {collection.status}
                      </p>

                      <p className="card-text">
                        <ToolButton
                          className="pr-2 withdraw_btn"
                          type={ToolButtonEnum.BUTTON}
                          title="Withdraw"
                          aria-label="Withdraw"
                          onClick={() =>
                            withdrawCollectionApprovalRequest(collection.id)
                          }
                        >
                          <span>
                            {t("collection.personal.underReview.withdraw")}
                          </span>
                        </ToolButton>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <div className="empty_img text-center mt-5 pt-5">
                <img
                  src="https://acvstorageprod.blob.core.windows.net/react-images/Empty-pana.svg"
                  alt=""
                  height={292}
                  width={292}
                />
                <p className="text-center">
                  {t("collection.personal.underReview.emptyMessage")}
                </p>{" "}
              </div>
            </>
          )}
        </div>

        <ReactTooltip
          id="happyFace"
          place="bottom"
          type={status === "Under review" ? "info" : "error"}
          effect="solid"
          multiline={true}
        >
          <p style={{ fontWeight: "500", width: "200px" }}>
            {status === "Under review"
              ? t("collection.personal.underReview.successMsg")
              : statusReason
              ? statusReason
              : t("collection.personal.underReview.rejectMsg")}
          </p>
        </ReactTooltip>
        <div
          className="offcanvas offcanvas-end"
          // tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
          data-bs-scroll={false}
        >
          <div className="offcanvas-header">
            <h5 id="offcanvasRightLabel" className="title mt-4">
              {t("collection.community.template.filter.title")}
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                setOpen(false);
                removeFilters();
                setFilterApply(false);
              }}
            ></button>
          </div>
          <div className="offcanvas-body overflow-hidden d-flex flex-column h-100">
            <MultiSelectDropdown
              selectArray={selectArray}
              isOpen={open}
              removeFilters={removeFilters}
              filterApply={isFilterApply}
              removeVal={removeVal}
              setFilterDataWithKeys={setFilterDataWithKeys}
              filterDataWithKeys={filterDataWithKeys}
              filterData={filterData}
              setFilterData={setFilterData}
            />
          </div>
          <div className="footer">
            <button
              type="button"
              className="bg-brand text-white pr-2 infoBtn"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                setOpen(true);
                setFilterApply(true);
                fetchFilteredData(filterDataWithKeys);
              }}
            >
              {t("collection.community.template.filter.apply")}
            </button>
            <button
              type="button"
              className="infoBtn text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                setOpen(false);
                removeFilters();
                setFilterApply(false);
              }}
            >
              {t("collection.community.template.filter.cancel")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

// export default UnderReview;
//           </p>
//         </ReactTooltip>
//       </div>
//     </>
//   );
// };

// export default UnderReview;
//             {statusReason
//               ? statusReason
//               : "Your collection is under review. Please wait for the review to complete. we are working hard to review your collection as soon as possible."}
//           </p>
//         </ReactTooltip>
//       </div>
//     </>
//   );
// };

export default UnderReview;
