import { loadLibraryFromBlob } from "./blob";
import { LibraryItems, LibraryItem } from "../types";
import { restoreElements, restoreLibraryItems } from "./restore";
import { getNonDeletedElements } from "../element";
import type App from "../components/App";
import { ExcalidrawElement } from "../element/types";
import { getCommonBoundingBox } from "../element/bounds";

class Library {
  private libraryCache: LibraryItems | null = null;
  private app: App;

  constructor(app: App) {
    this.app = app;
  }

  resetLibrary = async () => {
    await this.app.props.onLibraryChange?.([]);
    this.libraryCache = [];
  };

  restoreLibraryItem = (libraryItem: LibraryItem): LibraryItem | null => {
    const elements = getNonDeletedElements(
      restoreElements(libraryItem.elements, null),
    );
    return elements.length ? { ...libraryItem, elements } : null;
  };

  /** Imports library (currently merges, removing duplicates) */
  async importLibrary(
    blobs: Blob | Blob[],
    defaultStatus: "published" | "unpublished" = "unpublished",
    status?: string,
  ) {
    // Convert blobs to an array if it's a single blob
    const blobArray = Array.isArray(blobs) ? blobs : [blobs];

    // Define isUniqueItem function
    const isUniqueItem = (
      existingLibraryItems: LibraryItem[],
      targetLibraryItem: LibraryItem,
    ) => {
      return !existingLibraryItems.find((libraryItem) => {
        if (libraryItem.elements.length !== targetLibraryItem.elements.length) {
          return false;
        }

        // Detect z-index difference by checking the excalidraw elements
        // are in order
        return libraryItem.elements.every((libItemExcalidrawItem, idx) => {
          return (
            libItemExcalidrawItem.id === targetLibraryItem.elements[idx].id &&
            libItemExcalidrawItem.versionNonce ===
              targetLibraryItem.elements[idx].versionNonce
          );
        });
      });
    };

    // Loop through each blob in the array
    for (const blob of blobArray) {
      const libraryFile = await loadLibraryFromBlob(blob);
      if (
        !libraryFile ||
        !(libraryFile.libraryItems || libraryFile.libraryItems)
      ) {
        return;
      }

      let existingLibraryItems = await this.loadLibrary();
      const library =
        libraryFile.libraryItems || libraryFile.libraryItems || [];
      const restoredLibItems = restoreLibraryItems(library, defaultStatus);

      const filteredItems = restoredLibItems.reduce(
        (acc: LibraryItem[], item: LibraryItem) => {
          const restoredItem = this.restoreLibraryItem(item);
          if (
            restoredItem &&
            isUniqueItem(Array.from(existingLibraryItems), restoredItem)
          ) {
            acc.push(restoredItem);
          }
          return acc;
        },
        [],
      );
      if (filteredItems.length === 0) {
        existingLibraryItems.forEach((libItem) => {
          libItem.status = status ? "unpublished" : "published";
        });
      }
      localStorage.setItem(
        "uploadedLibraryItems",
        JSON.stringify(filteredItems),
      );

      await this.saveLibrary([...filteredItems, ...existingLibraryItems]);
    }
  }

  loadLibrary = (): Promise<LibraryItems> => {
    return new Promise(async (resolve) => {
      if (this.libraryCache) {
        return resolve(JSON.parse(JSON.stringify(this.libraryCache)));
      }

      try {
        //after 2 second the libraryItemsFromStorage has been set from app.tsx (initializeScene)
        setTimeout(() => {
          const libraryItems = this.app.libraryItemsFromStorage;
          if (!libraryItems) {
            return resolve([]);
          }

          const items = libraryItems.reduce((acc, item) => {
            const restoredItem = this.restoreLibraryItem(item);
            if (restoredItem) {
              acc.push(item);
            }
            return acc;
          }, [] as Mutable<LibraryItems>);

          // clone to ensure we don't mutate the cached library elements in the app
          this.libraryCache = JSON.parse(JSON.stringify(items));

          resolve(items);
        }, 3000);
      } catch (error: any) {
        console.error(error);
        resolve([]);
      }
    });
  };

  saveLibrary = async (items: LibraryItems) => {
    const prevLibraryItems = this.libraryCache;
    try {
      const serializedItems = JSON.stringify(items);
      // cache optimistically so that the app has access to the latest
      // immediately
      this.libraryCache = JSON.parse(serializedItems);
      await this.app.props.onLibraryChange?.(items);
    } catch (error: any) {
      this.libraryCache = prevLibraryItems;
      throw error;
    }
  };
}

export default Library;

export const distributeLibraryItemsOnSquareGrid = (
  libraryItems: LibraryItems,
) => {
  const PADDING = 50;
  const ITEMS_PER_ROW = Math.ceil(Math.sqrt(libraryItems.length));

  const resElements: ExcalidrawElement[] = [];

  const getMaxHeightPerRow = (row: number) => {
    const maxHeight = libraryItems
      .slice(row * ITEMS_PER_ROW, row * ITEMS_PER_ROW + ITEMS_PER_ROW)
      .reduce((acc, item) => {
        const { height } = getCommonBoundingBox(item.elements);
        return Math.max(acc, height);
      }, 0);
    return maxHeight;
  };

  const getMaxWidthPerCol = (targetCol: number) => {
    let index = 0;
    let currCol = 0;
    let maxWidth = 0;
    for (const item of libraryItems) {
      if (index % ITEMS_PER_ROW === 0) {
        currCol = 0;
      }
      if (currCol === targetCol) {
        const { width } = getCommonBoundingBox(item.elements);
        maxWidth = Math.max(maxWidth, width);
      }
      index++;
      currCol++;
    }
    return maxWidth;
  };

  let colOffsetX = 0;
  let rowOffsetY = 0;

  let maxHeightCurrRow = 0;
  let maxWidthCurrCol = 0;

  let index = 0;
  let col = 0;
  let row = 0;

  for (const item of libraryItems) {
    if (index && index % ITEMS_PER_ROW === 0) {
      rowOffsetY += maxHeightCurrRow + PADDING;
      colOffsetX = 0;
      col = 0;
      row++;
    }

    if (col === 0) {
      maxHeightCurrRow = getMaxHeightPerRow(row);
    }
    maxWidthCurrCol = getMaxWidthPerCol(col);

    const { minX, minY, width, height } = getCommonBoundingBox(item.elements);
    const offsetCenterX = (maxWidthCurrCol - width) / 2;
    const offsetCenterY = (maxHeightCurrRow - height) / 2;
    resElements.push(
      // eslint-disable-next-line no-loop-func
      ...item.elements.map((element) => ({
        ...element,
        x:
          element.x +
          // offset for column
          colOffsetX +
          // offset to center in given square grid
          offsetCenterX -
          // subtract minX so that given item starts at 0 coord
          minX,
        y:
          element.y +
          // offset for row
          rowOffsetY +
          // offset to center in given square grid
          offsetCenterY -
          // subtract minY so that given item starts at 0 coord
          minY,
      })),
    );
    colOffsetX += maxWidthCurrCol + PADDING;
    index++;
    col++;
  }

  return resElements;
};
