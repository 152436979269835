import { Editor, Node } from "@tiptap/core";

export const FontsizeExtension = Node.create({
  name: "fontsize",
  defaultOptions: {
    types: ["textStyle"],
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: 20,
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {};
              }
              return {
                style: `font-size: ${
                  attributes.fontSize?.fontSize || attributes.fontSize
                }px; line-height: normal; font-family: ${
                  attributes.fontFamily
                };`,
              };
            },
            parseHTML: (element) => {
              return {
                fontSize: element.style.fontSize.replace("px", ""),
              };
            },
          },
          fontFamily: {
            default: "Serif",
          },
        },
      },
    ];
  },
  // @ts-ignore
  addCommands() {
    return {
      setFontsize: (fontSize: number) => ({ chain }: Editor) => {
        return chain().setMark("textStyle", { fontSize }).run();
      },
    };
  },
});
