import { useState } from "react";
import { Tabs } from "../../../../../../tabs";
import {
  QuestionGenerationEntries,
  QuestionGenerationEntriesTypes,
} from "./entries";
import { QuestionGenerationResults } from "./results/QuestionGenerationResults";

enum TabName {
  ENTRIES = "Entries",
  RESULTS = "Result",
}

export interface LetsPlayQuestionEntriesAndResultsProps {
  componentsProps: {
    questionGenerationEntries: QuestionGenerationEntriesTypes;
  };
  languages: string[];
  language: string;
  handleSelect: (value: string) => void;
  theme: string;
}

export const LetsPlayQuestionEntriesAndResults = ({
  componentsProps,
  languages,
  language,
  handleSelect,
  theme,
}: LetsPlayQuestionEntriesAndResultsProps) => {
  const [selectedTab, setSelectedTab] = useState<string>(TabName.ENTRIES);

  return (
    <div className="d-flex flex-column gap-2 mt-2">
      <Tabs
        tabs={[
          {
            label: TabName.ENTRIES,
            value: TabName.ENTRIES,
            content: (
              <QuestionGenerationEntries
                {...componentsProps.questionGenerationEntries}
              />
            ),
          },
          {
            label: TabName.RESULTS,
            value: TabName.RESULTS,
            content: <QuestionGenerationResults />,
          },
        ]}
        tab={selectedTab}
        onChange={setSelectedTab}
      />
    </div>
  );
};
