import { createContext } from "react";

type NotesTitleContextType = {
  fileName: string | null;
  onFileNameChange: (val: string) => void;
  color: string;
  onColorChange: (val: string) => void;
};

const NotesTitleContextDefaultValue: NotesTitleContextType = {
fileName: null,
  onFileNameChange: () => {},
  color: "#000000",
  onColorChange: () => {},
};

export const NotesTitleContext = createContext<NotesTitleContextType>(
  NotesTitleContextDefaultValue,
);
