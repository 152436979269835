import { apiDelete, apiGet, apiPost, apiPut } from ".";
import { CommentType } from "../../types";
import { API_URL } from "./constant";
require("dotenv").config();

export const storeElemetDataToDatabase = async (
  lessonId: string | null,
  page: number,
  teacherEmailId: string,
  body: Object[],
  viewBackgroundColor: string,
  // imageData: any
) => {
  const lessonIds = lessonId?.replace(/\//g, "") || "";

  const postData = await apiPost(
    `${API_URL}/post-whiteboard-data/${lessonIds}/${page}/${teacherEmailId}`,
    { element: body, canvasBackgroundColor: viewBackgroundColor },
  );

  return postData;
};

export const setPages = async (lessonId: string | null) => {
  const getPageData = await getElemetDataFromDatabase(lessonId, false);
  localStorage.setItem("pages", JSON.stringify(getPageData.result));
  localStorage.setItem("ACTIVE_PAGE", getPageData.activePage);
};

export const getElemetDataFromDatabase = (
  lessonId: string | null,
  page?: number | boolean,
) => {
  const lessonIds = lessonId?.replace(/\//g, "");
  if (page) {
    return apiGet(
      `${API_URL}/get-whiteboard-data?lessonId=${lessonIds}&page=${page}`,
    );
  } else {
    return apiGet(`${API_URL}/get-whiteboard-data?lessonId=${lessonIds}`);
  }
};

export const updatePagesDetails = async (
  lessonId: string | null,
  body: any,
) => {
  try {
    const response = await apiPut(
      `${API_URL}/update-whiteboard-data?lessonId=${lessonId}`,
      body,
    );
    return response;
  } catch (error) {
    console.log("updatePagesDetails:error", error);
  }
};

export const delElemetDataFromDatabase = (id: string) => {
  const lessonIds = id ? id.toString().replace(/\//g, "") : "";
  return apiDelete(`${API_URL}/delete-whiteboard-data?id=${lessonIds}`);
};

export const getTagWiseTemplate = (data: string) => {
  const tag = data?.trim();
  return apiGet(`${API_URL}/get-tag-wise-template?tag=${tag}`);
};

export const getAuthorWiseTemplate = (data: string) => {
  const userId = data?.trim();
  return apiGet(`${API_URL}/get-author-wise-template?userId=${userId}`);
};

export const getComments = (data: string) => {
  const libraryId = data?.trim();
  return apiGet(`${API_URL}/comment?libraryId=${libraryId}`);
};

export const createComments = (data: CommentType) => {
  return apiPost(`${API_URL}/comment`, data);
};

export const editComments = (data: CommentType) => {
  return apiPut(`${API_URL}/comment`, data);
};

export const deleteComments = (data: string) => {
  const id = data?.trim();
  return apiDelete(`${API_URL}/comment?id=${id}`);
};

interface updatePageBody {
  pageName: String;
  data: Object;
}
interface updateActivePage {
  link: String;
  activePage: Number;
}

export const updatePageName = (body: updatePageBody) => {
  return apiPost(`${API_URL}/update-whiteboard-page-name`, body);
};

//add current page in active session which teacher are currently working on that
export const updateActivePage = (body: updateActivePage) => {
  return apiPost(`${API_URL}/add-active-page`, body);
};
