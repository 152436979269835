/* eslint-disable prettier/prettier */
import React, { RefObject, useEffect, useRef, useState } from "react";
import {
  uploadIcon,
  playIcon,
  pauseIcon,
  load,
  close,
  deleteIcon,
  menu,
  audioIcon,
  del,
} from "./icons";

import "./Audio.scss";

import {
  uploadWBLocalAudios,
  fetchWBLocalAudios,
} from "../excalidraw-app/api/collection";
import { t } from "../i18n";
import Loader from "../App/shareComponent/Loader";
import { Toast, ToastType } from "./Toast";
import ConfirmDialog from "./ConfirmDialog";
import { deleteWbLocalMedia } from "../excalidraw-app/api/userAPI";
import { convertIsoToDateString } from "../utils/dates";
import { Avatar } from "./Avatar";
import { useIsMobile } from "./App";
import { AppState, Subscription } from "../types";
import { NonDeletedExcalidrawElement } from "../element/types";

type audioType = {
  url: string;
  name: string;
  isPlay: boolean;
};

const Audios = ({
  insertAudioOnCanvas,
  onClose,
  isSideBarOpen,
  setIsSideBarOpen,
  appState,
  elements,
  subscription,
}: {
  insertAudioOnCanvas: (file: File) => void;
  onClose: () => void;
  isSideBarOpen: boolean;
  setIsSideBarOpen: (val: boolean) => void;
  appState: AppState;
  elements: readonly NonDeletedExcalidrawElement[];
  subscription?: Subscription;
}) => {
  const recordLimit = (subscription && subscription.audioRecordLimit) || 30;
  const [audios, setAudios] = useState<
    {
      url: string;
      name: string;
      isSelected: boolean;
      createdAt: string;
      fileSize: string;
    }[]
  >([]);
  const [filteredAudios, setFilteredAudios] = useState<
    {
      url: string;
      name: string;
      isSelected: boolean;
      createdAt: string;
      fileSize: string;
    }[]
  >([]);
  const [record, setRecord] = useState<any>({});
  const fileInputRef = useRef<HTMLInputElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [file, setFile] = useState<File>();
  const [url, setUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [isDeleteAll, setIsDeleteAll] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentBlobName, setCurrentBlobName] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [isExceedAudioLimit, setIsExceedAudioLimit] = useState<boolean>(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    const fetchdata = async () => {
      await fetchUpdatedAudios();
    };
    fetchdata();
  }, []);

  useEffect(() => {
    audios.length > 0 ? checkSelected() : setIsSelectedAll(false);
  }, [audios]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  const handlePlayClick = (path: string) => {
    setUrl(path);
  };

  const onAudioChange = async (event: any) => {
    const selectedFile = fileInputRef.current?.files?.[0];
    const orignalFileName = selectedFile?.name.replace(/\s+/g, "_");

    const getSameNameFile = audios.map((audio, i) => {
      if (audio.name === orignalFileName) {
        setToastMessage("");
        setOpenDialog(true);
      }
    });

    if (selectedFile) {
      const audio = document.createElement("audio");
      audio.src = URL.createObjectURL(selectedFile);

      audio.onloadedmetadata = async () => {
        if (audio.duration > Number(recordLimit)) {
          setToastMessage(
            `You've exceeded the limit. You're allowed to upload a maximum of a ${recordLimit}-second audio.`,
          );
          return;
        } else {
          try {
            if (event.target.files && event.target.files[0]) {
              // setAudios([...audios, URL.createObjectURL(event.target.files[0])]);
              setFile(selectedFile);
            }

            const file_ = event.target.files?.[0];
            const lesson = JSON.parse(localStorage.getItem("lesson") || "");

            const user = JSON.parse(localStorage.getItem("user") || "");
            if (file_ && lesson && user.mail) {
              const formData = new FormData();
              var fileSizeMB = (file_.size / (1024 * 1024)).toFixed(2);

              formData.append("file", file_);
              formData.append("classId", lesson?.ClassID as string);
              formData.append("mail", user.mail);
              formData.append("fileSize", fileSizeMB);

              setIsLoading(true);
              await uploadWBLocalAudios(formData);
              await fetchUpdatedAudios();
              setIsLoading(false);
            }
          } catch (err) {
            console.log(err);
            setToastMessage("Error in uploading audio");
          }
        }
        return;
      };
    }
    setInputKey(Date.now());
  };

  const insertAudio = async (file: File) => {
    if (!file) return;
    const perPageAudioLimit = subscription ? subscription?.audioLimit : 0;

    const nonDeletedAudio = elements.filter(
      (data) => data.type === "audio" && data.isDeleted === false,
    );
    setIsExceedAudioLimit(
      nonDeletedAudio.length < Number(perPageAudioLimit) ? false : true,
    );

    if (nonDeletedAudio.length < Number(perPageAudioLimit)) {
      insertAudioOnCanvas(file);
      onClose();
    } else {
      setToastMessage("");
    }
  };

  const user = JSON.parse(localStorage.getItem("user") || "");
  const fetchUpdatedAudios = async () => {
    try {
      setIsLoading(true);

      const user = JSON.parse(localStorage.getItem("user") || "");

      const { results } = await fetchWBLocalAudios(user.mail);

      const allLocalAudios = results.reduce(
        (acc: string | any[], curr: { localAudios: any }) => {
          return acc.concat(curr.localAudios);
        },
        [],
      );
      setAudios(allLocalAudios);
      setRecord(results[0] ? results[0] : {});

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleSelect = (checked: boolean, i: number) => {
    let clonedAudios = [...audios];
    clonedAudios[i].isSelected = checked;
    setAudios(clonedAudios);
  };

  const handleSelectAll = (checked: boolean) => {
    let clonedAudios = [...audios_];

    setIsSelectedAll(checked);
    clonedAudios = clonedAudios.map((audio) => {
      return { ...audio, isSelected: checked };
    });
    setAudios(clonedAudios);
  };

  const checkSelected = () => {
    const isAllSelected = audios_.length
      ? audios_.every((audio) => audio.isSelected)
      : false;
    if (isAllSelected && !isSelectedAll) {
      setIsSelectedAll(true);
    } else if (!isAllSelected && isSelectedAll) {
      setIsSelectedAll(false);
    }
  };

  const handleDelete = async () => {
    try {
      const selectedAudioNames: string[] = [];

      audios_.filter((audio) => {
        if (audio.isSelected) {
          selectedAudioNames.push(audio.name);
        }
      });

      const body = {
        id: record.id,
        classId: record.classId,
        email: record.email,
        type: "audio",
        blobNames: isSelectedAll
          ? selectedAudioNames
          : currentBlobName
          ? [currentBlobName]
          : [],
        typeOfDelete: isSelectedAll ? "all" : "single",
      };
      await deleteWbLocalMedia(body);
      await fetchUpdatedAudios();
    } catch (error) {
      console.log("error in delete audio", error);
    }
  };

  const handleSearch = () => {
    if (!!search.trim()) {
      const searchedAudios = audios.filter((audio) => {
        const nameWithoutExtension = audio.name
          .split(".")
          .slice(0, -1)
          .join(".");
        return nameWithoutExtension
          .toLowerCase()
          .includes(search.toLowerCase());
      });

      setFilteredAudios(searchedAudios);
    } else {
      setFilteredAudios([]);
    }
  };

  const audios_ = !!search.trim() ? filteredAudios : audios;

  return (
    <>
      {isOpen && (
        <ConfirmDialog
          onCancel={() => {
            setIsOpen(false);
            setIsDeleteAll(false);
          }}
          onConfirm={() => {
            setIsOpen(false);
            setIsDeleteAll(false);
            handleDelete();
          }}
          onClose={() => {
            setIsOpen(false);
            setIsDeleteAll(false);
          }}
          confirmText="Delete"
          title={isDeleteAll ? "Delete audios" : "Delete audio"}
          open={isOpen}
          setOpen={setIsOpen}
          children={
            isDeleteAll
              ? "Are you sure you want to delete all the audios?"
              : "Are you sure you want to delete the audio?"
          }
          isShowInput={false}
          isEditable={false}
        />
      )}
      {isExceedAudioLimit && toastMessage !== null && (
        <Toast
          type={ToastType.ERROR}
          message={`You've exceeded the limit You're allowed to upload a maximum of ${
            subscription ? subscription?.audioLimit : 0
          } audios per page.`}
          clearToast={() => setToastMessage(null)}
          className="style"
        />
      )}
      {openDialog && toastMessage !== null && (
        <Toast
          type={ToastType.ERROR}
          message={`The selected file name already exists. Please choose a unique file name to proceed.`}
          clearToast={() => setToastMessage(null)}
          className="style"
        />
      )}

      <div className="audio_container h-100">
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="fw-bold">{t("collection.personal.audios.title")}</h4>
            <h4 className="text">{t("collection.personal.audios.message")}</h4>
          </div>
          <div className="btn_div">
            <label className="upload_btn me-2">
              <svg height={15} width={15} className="me-2">
                {uploadIcon}
              </svg>
              {t("collection.personal.audios.uploadBtn")}
              <input
                key={inputKey}
                type="file"
                onChange={onAudioChange}
                ref={fileInputRef}
                accept="audio/*"
              />
            </label>
          </div>
        </div>
        <div className="pb-2 col-md-12">
          <div className="col-md-5 col-sm-12 position-relative">
            <div className="d-flex align-items-center">
              <button
                className={`btn me-3 ${useIsMobile() ? "d-none" : ""}`}
                style={{ width: "28px" }}
                onClick={() => {
                  setIsSideBarOpen(!isSideBarOpen);
                }}
              >
                {menu}
              </button>
              <input
                type="text"
                id="search"
                name="search"
                placeholder="Search by title"
                className="m-0 w-100 mt-lg-0 mt-3 "
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        {audios_?.length > 0 && (
          <div
            className="d-flex align-items-center pb-2"
            style={{ height: "25px" }}
          >
            Select all
            <input
              className="mx-2"
              checked={isSelectedAll}
              onChange={(e) => {
                handleSelectAll(e.target.checked);
              }}
              type="checkbox"
            />
            <div
              className={`delete-icon-wrapper  ${
                isSelectedAll ? "" : "pe-none"
              }`}
              onClick={() => {
                setIsOpen(true);
                setIsDeleteAll(true);
              }}
            >
              {del}
            </div>
          </div>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="row audio_div  ps-2">
            {toastMessage !== null && (
              <Toast
                type={ToastType.ERROR}
                message={toastMessage}
                clearToast={() => setToastMessage(null)}
                className="styleChange"
              />
            )}
            {audios_.length > 0 ? (
              audios_.map((audio, index) => {
                const nameWithoutExtension = audio.name
                  .split(".")
                  .slice(0, -1)
                  .join(".");

                return (
                  <>
                    <div className="col-md-4 col-sm-12 p-1 card-width">
                      <div
                        className={`card audio_card p-0 p-2 ${
                          isSelectedAll ? "red-border" : ""
                        }`}
                      >
                        <div className="d-flex align-items-center justify-content-between ">
                          {/* <Avatar
                            color="grey"
                            className="flex-shrink-0 overflow-hidden"
                            border=""
                            onClick={() => {}}
                          >
                            {!!user?.UrlPhoto ? (
                              <img
                                key={index}
                                src={user.UrlPhoto}
                                className="w-100 h-auto "
                                alt="user-profile"
                              />
                            ) : (
                              user?.name[0].toUpperCase() || "A"
                            )}
                          </Avatar> */}
                          {isLoading ? (
                            <div
                              className="spinner-border load_icon_loader"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            <div className="btn_div">
                              <label
                                className="uploaded_btn"
                                style={{
                                  border: "1.5px solid rgb(88, 90, 150)",
                                  display: "flex",
                                }}
                                onClick={() => {
                                  if (!file) {
                                    setIsLoading(true);
                                    fetch(audio.url)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        const extension = audio.url
                                          .split(".")
                                          .pop();
                                        // You can use a mapping of file extensions to MIME types if needed
                                        // For simplicity, here we just use a basic mapping for common audio types
                                        const mimeTypes: {
                                          [key: string]: string;
                                        } = {
                                          mp3: "audio/mpeg",
                                          ogg: "audio/ogg",
                                          wav: "audio/wav",
                                          // Add more as needed
                                        };
                                        const type = extension
                                          ? mimeTypes[extension?.toLowerCase()]
                                          : "audio/mpeg";
                                        const audioFile = new File(
                                          [blob],
                                          "audio.mp3",
                                          {
                                            type: type,
                                          },
                                        );
                                        insertAudio(audioFile);
                                        setIsLoading(false);
                                      })
                                      .catch((error) => {
                                        setIsLoading(false);
                                        console.error(
                                          "Error loading audio:",
                                          error,
                                        );
                                      });
                                  } else {
                                    insertAudio(file);
                                  }
                                }}
                              >
                                <svg className="load_icon">{load}</svg>
                                {t("collection.personal.audios.uploadBtn")}
                              </label>
                            </div>
                          )}
                          <div style={{ width: "60%" }}>
                            <p className="mb-0 text-nowrap audio-name w-100 px-2">
                              {nameWithoutExtension}
                            </p>
                            <div style={{ display: "flex" }}>
                              <p className="mb-0 text-nowrap audio-name  px-2">
                                {convertIsoToDateString(audio.createdAt)}{" "}
                                {audio?.fileSize && (
                                  <span style={{ marginLeft: "10px" }}>
                                    Size:{" "}
                                    {audio?.fileSize ? audio.fileSize : null}MB
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                          <div
                            className="delete-icon-wrapper-1 position-relative"
                            onClick={() => {
                              setIsOpen(true);
                              setCurrentBlobName(audio.name);
                            }}
                          >
                            {del}
                          </div>
                        </div>
                        <audio
                          controls
                          controlsList="noplaybackrate nodownload"
                          id={`audio${index}`}
                          src={`${audio.url}`}
                          ref={audioRef}
                        ></audio>
                        {/* <div
                          className="custom_audio flex-grow-1 d-flex justify-content-center align-items-center py-1"
                          onClick={() => handlePlayClick(audio.url)}
                        >
                          <svg height={52} width={52}>
                            {playIcon}
                          </svg>
                        </div> */}
                        <div className="row audio_dialog">
                          <audio
                            src={`${audio.url}`}
                            controls
                            ref={audioRef}
                            style={{
                              margin: 5,
                              display: "block",
                              width: "100%",
                            }}
                          />
                          {/* <svg
                            className="close_icon"
                            onClick={() => setUrl("")}
                          >
                            {close}
                          </svg> */}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div className="empty_img text-center mt-5 pt-5">
                <img
                  src="https://acvstorageprod.blob.core.windows.net/react-images/Empty-pana.svg"
                  alt=""
                  height={292}
                  width={292}
                />
                <p className="text-center">
                  {t("collection.personal.audios.emptyMessage")}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Audios;
