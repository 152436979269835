import { useEffect, useState } from "react";
import docIcon from "../../../../../../assests/doc.png";
import pdfIcon from "../../../../../../assests/pdf.png";
import pptIcon from "../../../../../../assests/pptx.png";
import { formatFileSize } from "../../../../../../utils/fileSize";
import { Dialog } from "../../../../../Dialog";
import { extractTextFromDOC, getDOCPageCount } from "./media/doc";
import { extractTextFromPDF, getPDFPageCount } from "./media/pdf";
import { extractTextFromPPT, getPPTSlideCount } from "./media/ppt";
import "./styles.scss";

interface AttachementModalProps {
  setOpen: (open: boolean) => void;
  open: boolean;
  selectedFiles: File[];
  // setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  // setExtractedText: React.Dispatch<React.SetStateAction<string>>;
  onAttachFiles: (files: File[], extractedText: string) => void;
}

export const AttachementModal = (props: AttachementModalProps) => {
  const { setOpen, selectedFiles: exsitingFiles, open, onAttachFiles } = props;

  const [extractedText, setExtractedText] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>(exsitingFiles);

  // Handle file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      // setSelectedFiles((prevFiles) => [ , ...Array.from(files)]);
      setSelectedFiles((prevFiles) => [...Array.from(files)]);
    }
  };

  // Remove file from the list
  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Helper function to generate file previews or names
  const renderFilePreview = async (file: File, index: number) => {
    const fileSize = formatFileSize(file.size);

    const fileExtension = file.name.split(".").pop()?.toUpperCase();

    if (file.type.startsWith("image/")) {
      setExtractedText("");
    } else if (fileExtension === "PDF") {
      const extractedText = await extractTextFromPDF(file);
      setExtractedText(extractedText);
    } else if (fileExtension === "DOC" || fileExtension === "DOCX") {
      const extractedText = await extractTextFromDOC(file);
      setExtractedText(extractedText);
    } else if (fileExtension === "PPT" || fileExtension === "PPTX") {
      const extractedText = await extractTextFromPPT(file);
      setExtractedText(extractedText);
    }

    const fetchFileDetails = async () => {
      let pages = 0;

      if (fileExtension === "PDF") {
        pages = await getPDFPageCount(file);
      } else if (fileExtension === "DOC" || fileExtension === "DOCX") {
        pages = await getDOCPageCount(file);
      } else if (fileExtension === "PPT" || fileExtension === "PPTX") {
        pages = await getPPTSlideCount(file); // For PPT/PPTX, count the slides
      }

      return { size: fileSize, pages };
    };

    const data = await fetchFileDetails();

    const fileType = file.type;

    // Define icons for PDF and DOC
    const isPDF = fileExtension === "PDF";
    const isDOC = fileExtension === "DOC" || fileExtension === "DOCX";
    const isPPT = fileExtension === "PPT" || fileExtension === "PPTX";

    if (fileType.startsWith("image/")) {
      // Render image preview if the file is an image
      return (
        <div key={index} className="file-preview m-2">
          <div className="d-flex justify-content-between w-100 p-2">
            <div className="file-info d-flex flex-column justify-content-start">
              <div className="file-name">{file.name.trim()}</div>
              <div className="file-type">
                {data.size}
                {" • "}
                {fileExtension}
              </div>
            </div>
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              className="preview-image"
            />
          </div>
          <button
            className="remove-btn"
            onClick={() => handleRemoveFile(index)}
          >
            &times; {/* Close icon */}
          </button>
        </div>
      );
    } else if (isPDF || isDOC || isPPT) {
      // Render file card for PDF and DOC files
      return (
        <div key={index} className="file-preview m-2">
          <div className="d-flex justify-content-between w-100 p-2">
            <div className="file-info d-flex flex-column justify-content-start">
              <div className="file-name">{file.name.trim()}</div>
              <div className="file-type">
                {data.size}, {data.pages} Pages{" • "}
                {fileExtension}
              </div>
            </div>
            <div className="file-icon">
              <img
                src={isPDF ? pdfIcon : isDOC ? docIcon : pptIcon}
                alt="PDF Icon"
                className="file-icon-img"
              />
            </div>
          </div>
          <button
            className="remove-btn"
            onClick={() => handleRemoveFile(index)}
          >
            &times; {/* Close icon */}
          </button>
        </div>
      );
    }
  };

  const [renderContent, setRenderContent] = useState<JSX.Element[]>([]);

  useEffect(() => {
    setRenderContent([]);
    selectedFiles.map((file, index) => {
      renderFilePreview(file, index).then((content) => {
        content && setRenderContent((prevContent) => [...prevContent, content]);
      });
    });
  }, [selectedFiles]);

  return (
    <>
      <Dialog
        isShowFullScreenButton={true}
        children={
          <div className="attachment-modal d-flex flex-column p-3">
            <div className="d-flex flex-column flex-grow-1 gap-3">
              <p className="text-secondary attachment-note">
                Files and Library will be added to your ACV blob storage
              </p>
              <div>
                <label
                  htmlFor="file-upload"
                  className="file-btn btn btn-outline"
                >
                  Add File
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept=".pdf,.doc,.docx,.ppt,.pptx"
                  // accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  // multiple // Allow multiple files
                />
              </div>
              {selectedFiles.length > 0 && (
                <div className="attachment-body my-2">
                  <div className="file-preview-container ">{renderContent}</div>
                </div>
              )}
              <div>
                <label
                  htmlFor="library-upload"
                  className="file-btn btn btn-outline"
                >
                  Add Library
                </label>
              </div>
              <div>
                <label htmlFor="acv-blob" className="file-btn btn btn-outline">
                  Select from my ACV Blob
                </label>
              </div>
              <div>
                <label htmlFor="add-url" className="file-btn btn btn-outline">
                  Add URL
                </label>
              </div>
              <div>
                <label
                  htmlFor="open-source"
                  className="file-btn btn btn-outline"
                >
                  Add Azure open ID Source..
                </label>
              </div>
            </div>

            <div className="attachment-footer d-flex justify-content-between mt-3 flex-wrap">
              <div className="d-flex flex-column">
                <div className="footer-note">
                  <span
                    style={{
                      color: "#494b83",
                    }}
                  >
                    127.5 MB{" "}
                  </span>{" "}
                  of 500 MB used
                </div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "45%",
                    }}
                    aria-valuenow={25}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <div className="footer-note text-secondary">storage usage</div>
              </div>

              <div className="d-flex gap-3 flex-wrap">
                <button className="cancel-btn" onClick={() => setOpen(false)}>
                  Cancel
                </button>
                <button
                  className="update-btn"
                  disabled={!exsitingFiles.length && !selectedFiles.length}
                  onClick={() => {
                    onAttachFiles(selectedFiles, extractedText);
                    setOpen(false);
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        }
        onCloseRequest={() => setOpen(false)}
        closeOnClickOutside={false}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};
