import { useEffect } from "react";
import { t } from "../../i18n";
import { PlusIcon } from "../icons";
import { pagesPropsType } from "./Types";
import "./pages.scss";

const Pages = (props: pagesPropsType) => {
  const {
    appState,
    group,
    setGroup,
    onSave,
    pages,
    setPages,
    isEditable,
    setIsEditable,
    isPagesLoading,
  } = props;

  const toggleAssigned = (checked: boolean, i: number) => {
    let clonedPages = [...pages];
    let clonedGroup = group;
    if (clonedGroup?.page?.isPage) {
      if (clonedGroup?.page.id !== clonedPages[i].id) {
        const index = clonedPages.findIndex(
          (page) => page.id === clonedGroup?.page?.id,
        );
        clonedPages[index].isAssignedGroup = false;
      }
    }
    clonedPages[i].isAssignedGroup = checked;
    setPages(clonedPages);
    if (checked) {
      if (clonedGroup)
        clonedGroup = {
          ...clonedGroup,
          page: {
            ...clonedGroup.page,
            id: clonedPages[i].id,
            no: clonedPages[i].page,
            name: clonedPages[i]?.pageName || "",
            dataURL: clonedPages[i].dataURL,
            isPage: true,
          },
        };
    } else {
      if (clonedGroup)
        clonedGroup = {
          ...clonedGroup,
          page: {
            ...clonedGroup.page,
            id: "",
            no: "",
            name: "",
            isPage: false,
          },
        };
    }
    if (clonedGroup) {
      // onSave(clonedGroup);
      setGroup({ ...clonedGroup });
    }
  };

  const findAssignedPageThumbnail = () => {
    if (group?.page.isPage) {
      const assignedPage = pages.find(
        (page: { id: string }) => page.id === group?.page.id,
      );
      return assignedPage ? assignedPage.dataURL : "";
    }
    return "";
  };

  // const isAllPagesAssigned = pages.every(
  //   (page: { isAssignedGroup: boolean }) => page.isAssignedGroup,
  // );

  return (
    <div
      className={`pages-wrapper row mx-0 d-flex${
        !isEditable || isPagesLoading || pages?.length === 0 ? "h-100" : ""
      }`}
      style={{
        flexWrap: "nowrap",
        justifyContent: "flex-start !important",
        gap: "70px",
        height: "100%",
      }}
    >
      {isEditable ? (
        isPagesLoading ? (
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : pages?.length > 0 ? (
          pages?.map(
            (
              page: {
                id: string;
                page: number | string;
                dataURL: string;
                isAssignedGroup: boolean;
                pageName?: string;
              },
              i: number,
            ) => {
              const isPageInGroup = group ? group.page.id === page.id : false;
              return (
                <div
                  className="checkbox-wrapper-16 col-5 px-0 noselect"
                  // style={{ width: "215px" }}
                >
                  <label className="checkbox-wrapper noselect">
                    <input
                      type="checkbox"
                      checked={page.isAssignedGroup}
                      className="checkbox-input"
                      onChange={(e) => toggleAssigned(e.target.checked, i)}
                    />
                    <span className="checkbox-tile" style={{ width: "200px" }}>
                      <span
                        className="checkbox-icon noselect"
                        style={{
                          height: "200px",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <img
                          key={i}
                          src={
                            page?.dataURL
                              ? page?.dataURL
                              : "https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg"
                          }
                          className="w-100 h-auto"
                          alt=""
                          style={{ objectFit: "contain" }}
                        />
                      </span>
                      <span className="checkbox-label">
                        {page.pageName ? page.pageName : `Page ${page.page}`}
                      </span>
                    </span>
                  </label>
                </div>
              );
            },
          )
        ) : (
          <div className="d-flex align-items-center justify-content-center h-100">
            <p className="mb-0">{t("labels.noPages")}</p>
          </div>
        )
      ) : !group?.page.isPage ? (
        <div className="d-flex flex-column align-items-center justify-content-evenly h-100">
          <div
            className="square-box my-3 d-flex align-items-center justify-content-center cursor-pointer"
            onClick={() => {
              window.parent.postMessage(
                { type: "EDIT_MODAL", isEditable: true },
                `${process.env.REACT_APP_PARENT_APP}`,
              );
              setIsEditable(true);
            }}
          >
            <div className="square-box-icon">
              <PlusIcon color="#DBDBDB" />
            </div>
          </div>
          <p className="text-center fw-bold mb-0">
            {t("labels.assignWhiteBoard").toUpperCase()}
          </p>
        </div>
      ) : (
        <div className="d-flex flex-column">
          <p className="text-center">{t("labels.assignedPage")}</p>
          <div
            className="d-flex align-items-end preview-assigned-page flex-grow-1"
            style={{ backgroundImage: `url(${findAssignedPageThumbnail()})` }}
          >
            <p className="text-center fw-bold text-capitalize ms-2 mb-1">
              {group.page.name ? group.page.name : `Page ${group.page.no}`}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pages;
