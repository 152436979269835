import { useState, useContext, useEffect } from "react";
import "./AudioVideoTitleSelect.scss";
// import { TwitterPicker } from "react-color";
import { NotesTitleContext } from "./contexts/NotesTitle.context";
import { t } from "../i18n";
import { ColorPicker } from "./ColorPicker";
import { useIsMobile } from "./App";

export enum Titles {
  DIRECTIONS = "Directions",
  COMMENTS = "Comments",
  FEEDBACK = "Feedback",
  CUSTOM = "+ Custom",
}

export const TitlesColorMap = {
  [Titles.DIRECTIONS]: "#494B83",
  [Titles.COMMENTS]: "#198754",
  [Titles.FEEDBACK]: "#ffc107",
  [Titles.CUSTOM]: "#526D82",
};

const TitleSelect = () => {
  const [showOption, setShowOption] = useState<boolean>(false);
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [title, setTitle] = useState<Titles | null>(null);
  const isMobile = useIsMobile();

  const { onFileNameChange, onColorChange, color, fileName } = useContext(
    NotesTitleContext,
  );

  const handleToggleOptions = () => {
    setShowOption(!showOption);
  };

  const styles = {
    color: {
      width: "36px",
      height: "100%",
      borderRadius: "2px",
      background: `${color}`,
    },
    swatch: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
    },
  };

  useEffect(() => {
    if (title) onFileNameChange(title);
  }, [title]);

  return (
    <div className="select">
      {title === Titles.CUSTOM ? (
        <div className="d-flex" style={{ gap: 4 }}>
          <div className="input-wrapper">
            <input
              type="text"
              className="form-control"
              id="custom"
              placeholder={t("notes.select.placeHolder")}
              value={(fileName !== Titles.CUSTOM && fileName) || ""}
              onChange={(e) => {
                onFileNameChange(e.target.value);
              }}
            />
            <span
              className="close-icon"
              onClick={() => setShowOption(!showOption)}
            ></span>
          </div>
          <div className="d-flex">
            <div
              style={styles.swatch}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              <div style={styles.color} />
            </div>
            {showColorPicker ? (
              <div className="color-popover">
                <div
                  className="cover"
                  onClick={() => setShowColorPicker(true)}
                />
                {/* <TwitterPicker
                  color={color}
                  onChange={(color) => {
                    onColorChange(color.hex);
                    setShowColorPicker(!showColorPicker);
                  }}
                /> */}
                <ColorPicker
                  label={isMobile ? "" : t("labels.canvasBackground")}
                  type="elementStroke"
                  color={color}
                  onChange={(color: string) => {
                    onColorChange(color);
                  }}
                  isActive={showColorPicker}
                  setActive={() => {
                    setShowColorPicker(!showColorPicker);
                  }}
                  data-testid="canvas-background-picker"
                  left={-245}
                  top={0}
                  isShowInput={false}
                  isMobile={isMobile}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <>
          <div className="select-box" onClick={handleToggleOptions}>
            {fileName ? (
              <span
                className={`title-option text-white rounded`}
                style={{
                  backgroundColor: title ? TitlesColorMap[title] : "",
                }}
              >
                {title}
              </span>
            ) : (
              "Select Title"
            )}
          </div>
        </>
      )}
      <div
        className={`option-container ${
          showOption ? "open" : ""
        } d-flex flex-wrap gap-3 justify-content-center`}
        style={{ height: showOption ? "auto" : 0 }}
      >
        {[
          Titles.DIRECTIONS,
          Titles.COMMENTS,
          Titles.FEEDBACK,
          Titles.CUSTOM,
        ].map((val) => (
          <span
            className="title-option text-white mx-1 rounded"
            onClick={() => {
              setTitle(val);
              if (val !== Titles.CUSTOM) onColorChange(TitlesColorMap[val]);
              else onColorChange("#000000");
              setShowOption(!showOption);
            }}
            style={{
              backgroundColor: TitlesColorMap[val],
            }}
          >
            {val}
          </span>
        ))}
      </div>
    </div>
  );
};

export default TitleSelect;
