import OpenColor from "open-color";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Loader from "../../../../../../App/shareComponent/Loader";
import { MIME_TYPES } from "../../../../../../constants";
import { ExcalidrawElement, FileId } from "../../../../../../element/types";
import {
  bookmarkCollectionAPI,
  fetchAllCollection,
  fetchFilteredCollectionData,
  likeUnlikeCollectionAPI,
} from "../../../../../../excalidraw-app/api/collection";
import { t } from "../../../../../../i18n";
import { exportToSvg } from "../../../../../../scene/export";
import {
  AppState,
  BinaryFileData,
  BinaryFiles,
  CollocationType,
  LibraryFileType,
  LibraryItems,
} from "../../../../../../types";
import { LibraryType } from "../../../../../TemplateInfo";
import { Toast, ToastType } from "../../../../../Toast";
import { CardWithImage } from "../../../../components/card";
import { LibraryTabsEnum } from "../../../../LibraryItemsDialog";
import { FilterRef, FilteredValueType } from "../../CollectionsTab";
import { SidebarItemsEnum } from "../../../../components/constant/SidebarItems";

export interface TemplateCollectionProps {
  searchVal: string;
  setFullScreenCollectionPreview: () => void;
  setPreviewTemplateData: (data: CollocationType) => void;
  loadElementsFromDB: (url: string) => void;
  selectedSortBy: number | null;
}

interface TemplateCollectionPropsTypes {
  setSelectedTemplate: (val: string[]) => void;
  selectedTemplate: string[];
  setAppState: React.Component<any, AppState>["setState"];
  appState: AppState;
  editCollection: (val: LibraryItems) => void;
  setSelectedSection: (val: LibraryTabsEnum) => void;
}

export const TemplateCollection = forwardRef<
  FilterRef,
  TemplateCollectionProps & TemplateCollectionPropsTypes
>(
  (
    {
      searchVal,
      setFullScreenCollectionPreview,
      setPreviewTemplateData,
      loadElementsFromDB,
      selectedTemplate,
      setSelectedTemplate,
      setAppState,
      appState,
      editCollection,
      setSelectedSection,
      selectedSortBy,
    },
    ref,
  ) => {
    const [templates, setTemplates] = useState<CollocationType[]>([]);
    const [filteredTemplates, setFilteredTemplates] = useState<
      CollocationType[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState<{
      message: string;
      type: ToastType;
    } | null>(null);

    useEffect(() => {
      setIsLoading(true);
      setAppState({ defaultLibraryTab: SidebarItemsEnum.WorkInProgress });
      const fetchCollection = async () => {
        try {
          const { result } = await fetchAllCollection({});
          setTemplates(result?.resources);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      };

      fetchCollection();
    }, []);

    useEffect(() => {
      const onSortCollection = async (index: number) => {
        setIsLoading(true);
        try {
          //index 1: newest, 2: most like, 3: title(a-z), 4: author(a-z)
          const { result } = await fetchAllCollection({ sort: index });
          setFilteredTemplates(result.resources);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.log(error);
        }
      };

      onSortCollection(selectedSortBy ? selectedSortBy : 0);
    }, [selectedSortBy]);

    const fetchFilteredData = async (filterDataWithKeys: FilteredValueType) => {
      try {
        setIsLoading(true);
        const { result } = await fetchFilteredCollectionData(
          filterDataWithKeys,
        );

        setFilteredTemplates(result.resources);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("error-in-fetchFilteredData", error);
      }
    };

    const removeFilters = async (hasFilterApplied: boolean) => {
      try {
        setIsLoading(true);
        if (hasFilterApplied) {
          const { result } = await fetchAllCollection({});
          setFilteredTemplates(result.resources);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    useImperativeHandle(ref, () => ({
      onApply: fetchFilteredData,
      onClear: removeFilters,
    }));

    useEffect(() => {
      if (searchVal) {
        const searchResult = templates.filter((template) =>
          template.name.toLowerCase().includes(searchVal.toLowerCase()),
        );
        return setFilteredTemplates(searchResult);
      }
      setFilteredTemplates(templates);
    }, [searchVal, templates]);

    const onAddtoCollectionBtnClick = (collectionSource: string) => {
      if (selectedTemplate.includes(collectionSource)) {
        // If the source is already selected, remove it from the array
        setSelectedTemplate(
          selectedTemplate.filter(
            (source: string) => source !== collectionSource,
          ),
        );
      } else {
        setSelectedTemplate([...selectedTemplate, collectionSource]);
      }
    };

    const onEdit = async (item: CollocationType) => {
      const response = await fetch(item.source);
      const result = await response.json();

      setAppState({
        editingLibrary: {
          ...appState.editingLibrary,
          libraryId: appState.editingLibrary.libraryInfo.id,
          isEditing: true,
          libraryInfo: item,
          libraryItems: result?.libraryItems[0]?.elements,
          oldElements: [],
        },
      });
      const modifiedLibraryItems = result?.libraryItems?.length
        ? result?.libraryItems.map((libraryItem: LibraryType) => {
            return { ...libraryItem, isSelected: false };
          })
        : [];

      editCollection(modifiedLibraryItems);
      generateSvgForLibrary(modifiedLibraryItems, item);
      setSelectedSection(LibraryTabsEnum.MyLibrary);
    };

    const getFilesByLibraryElements = (
      elements: ExcalidrawElement[],
      templateInfo: CollocationType,
    ) => {
      let files: BinaryFiles = {};
      for (let index = 0; index < elements.length; index++) {
        const element: ExcalidrawElement = elements[index];
        if (
          element.type === "image" ||
          element.type === "formula" ||
          element.type === "mermaidDiagram" ||
          element.type === "video" ||
          element.type === "audio" ||
          element.type === "textWithStyles"
        ) {
          if (element?.fileId !== null) {
            if (appState.files.hasOwnProperty(element.fileId)) {
              files[element.fileId] = appState.files[element.fileId];
            }
            if (templateInfo && templateInfo.files) {
              const elementFileIndex = templateInfo.files.findIndex(
                (file: { id: FileId }) => file.id == element.fileId,
              );

              if (elementFileIndex !== -1) {
                const elementFile = templateInfo.files[
                  elementFileIndex
                ] as LibraryFileType;
                files[elementFile.id as string] = {
                  mimeType:
                    elementFile.mimeType ||
                    (MIME_TYPES.binary as BinaryFileData["mimeType"]),
                  id: elementFile.id as BinaryFileData["id"],
                  dataURL: elementFile.url as BinaryFileData["dataURL"],
                  created: elementFile.created,
                  isPublished: true,
                };
              }
            }
          }
        }
      }
      return files ? files : null;
    };

    const generateSvgForLibrary = async (
      libraryItems: LibraryType[],
      item: CollocationType,
    ) => {
      const librariesWithSvg: LibraryType[] = await Promise.all(
        libraryItems.map(async (libraryItem: LibraryType) => {
          const files = getFilesByLibraryElements(libraryItem.elements, item);
          const svg = libraryItem?.elements?.length
            ? await exportToSvg(
                libraryItem.elements,
                {
                  exportBackground: false,
                  viewBackgroundColor: OpenColor.white,
                },
                files,
              )
            : null;
          return {
            ...libraryItem,
            svg,
          };
        }),
      );
    };

    const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;

    return (
      <div className="row overflow-auto" style={{ height: "calc(100% - 28%)" }}>
        {isLoading ? (
          <Loader className="h-100" />
        ) : (
          filteredTemplates.map((template, index) => {
            const isUserTemplate = template.userId === userMail;
            return (
              <CardWithImage
                key={index}
                images={template.preview}
                userProfile={template.authors[0]?.url}
                userName={template.authors[0]?.name}
                created={template.created}
                name={template.name}
                likes={template.likeCount}
                isLikefill={template.isLiked}
                onLike={async () => {
                  const res = await likeUnlikeCollectionAPI(template.id);
                  return res.result.likeCount;
                }}
                isBookMarkFill={template.isBookmark}
                onBookmark={async () => {
                  await bookmarkCollectionAPI(template.id);
                }}
                setFullScreenCollectionPreview={setFullScreenCollectionPreview}
                onPreview={() => {
                  setPreviewTemplateData(template);
                }}
                onUseThisTemplate={() => loadElementsFromDB(template.source)}
                source={template.source}
                onAddtoCollectionBtnClick={() =>
                  onAddtoCollectionBtnClick(template.source)
                }
                selectedTemplate={selectedTemplate}
                isEdit={isUserTemplate}
                onEdit={() => {
                  isUserTemplate && onEdit(template);
                  localStorage.removeItem("selectedElement");
                }}
                buttonText={t("cards.addToMyLibrary")}
                isMove={true}
                id={template.id}
                onAfterMovedToCategory={(category: string[]) => {
                  if (category.length) {
                    const addCategory = category?.filter(
                      (category: string) => category !== template.id,
                    );
                    const index = filteredTemplates.findIndex(
                      (data) => data.id === template.id,
                    );
                    if (addCategory) {
                      const data = [...filteredTemplates];

                      data[index].category = [...addCategory];

                      setFilteredTemplates([...data]);
                    }
                  }
                  setToastMessage({
                    message: "Collection successfully imported.",
                    type: ToastType.SUCCESS,
                  });
                }}
                onAfterDeleteCategory={(id) => {
                  if (template.id !== id) {
                    const updatedCategory = template.category?.filter(
                      (category) => category !== id,
                    );
                    const index = filteredTemplates.findIndex(
                      (data) => data.id === template.id,
                    );
                    if (updatedCategory) {
                      const data = [...filteredTemplates];

                      data[index].category = [...updatedCategory];

                      setFilteredTemplates([...data]);
                    }
                  }
                }}
                templateCategory={template.category}
                isCustomCatagory={false}
              />
            );
          })
        )}
        {toastMessage !== null && (
          <Toast
            type={toastMessage.type}
            message={toastMessage.message}
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
      </div>
    );
  },
);
