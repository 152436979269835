import Group from "./Group";
import { useEffect, useState } from "react";
import "./index.scss";
import { flexibleGroupsPropsType, groupType, studentType } from "./Types";
import { apiDelete, apiPost, apiPut } from "../../excalidraw-app/api";
import { API_URL } from "../../excalidraw-app/api/constant";
import { AppState } from "../../types";
import ConfirmDialog from "../ConfirmDialog";

const FlexibleGroups = (props: flexibleGroupsPropsType) => {
  const {
    groups,
    setGroups,
    setStudents,
    students,
    appState,
    isLoading,
    setIsEditable,
    isEditable,
  } = props;

  const [isShowModel, setIsShowModel] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedStudent, setSelectedStudent] = useState<boolean>(false);

  // current group id
  const [id, setId] = useState<string>("");
  const [index, setIndex] = useState<number>(0);

  const handleDelete = async (id: string, i: number) => {
    const clonedGroups = [...groups];
    const clonedStudents = students;
    try {
      if (id) {
        const res = await apiDelete(`${API_URL}/flexible-group?id=${id}`);
        if (res?.status === "Success") {
          const findedGroup = clonedGroups.find(
            (group: groupType) => group.id === id,
          );
          findedGroup &&
            findedGroup.students.map((student) => {
              const index = students.findIndex(
                (student_) => student_.studentEmail === student.studentEmail,
              );
              clonedStudents[index].isAssignedGroup = false;
            });
          setStudents(clonedStudents);

          const filteredGroups =
            clonedGroups.length > 0
              ? clonedGroups.filter((group) => group.id !== id)
              : [];
          setGroups(filteredGroups);
        } else {
          window.alert("Group has been not deleted!");
        }
      } else {
        const findedGroup = clonedGroups[i];
        findedGroup &&
          findedGroup.students.map((student: studentType) => {
            const index = students.findIndex(
              (student_) => student_.studentEmail === student.studentEmail,
            );
            clonedStudents[index].isAssignedGroup = false;
          });
        setStudents(clonedStudents);
        const filteredGroups =
          clonedGroups.length > 0
            ? clonedGroups.filter((group, groupIndex) => groupIndex !== i)
            : [];
        setGroups(filteredGroups);
      }
    } catch (error) {
      console.log("error-from-'handleDelete", error);
    }
  };

  const handleModalShow = (id: string, index: number) => {
    setId(id);
    setIndex(index);
    setIsShowModel(true);
  };

  const handleUpdate = async (group: groupType, i: number) => {
    const clonedGroups = [...groups];
    if (group.students.length > 0) {
      if (group.id) {
        const updatedGroup = await apiPut(`${API_URL}/flexible-group`, group);
        clonedGroups[i] = group;
        setGroups(clonedGroups);
      } else {
        const createdGroup = await apiPost(`${API_URL}/flexible-group`, group);
        if (createdGroup.status === "Success") {
          clonedGroups[i] = createdGroup.results;
          setGroups(clonedGroups);
        } else {
          window.alert("Group has been not created!");
        }
      }
    } else {
      setIsOpen(true);
    }
  };

  const handleToggle = (i: number) => {
    let clonedGroups = [...groups];
    const isAnyActive = clonedGroups.some((group: groupType) => group.isOpen);
    if (isAnyActive) {
      const activeIndex = clonedGroups.findIndex(
        (group: groupType) => group.isOpen,
      );
      if (activeIndex !== i) {
        clonedGroups = clonedGroups.map((group: groupType) => {
          return {
            ...group,
            isOpen: false,
          };
        });
      }
    }
    clonedGroups[i].isOpen = !clonedGroups[i].isOpen;
    setGroups(clonedGroups);
  };

  const handleChange = (group: groupType, i: number) => {
    const clonedGroups = [...groups];
    clonedGroups[i] = group;
    setGroups(clonedGroups);
  };

  useEffect(() => {
    const allGroupsStudents =
      groups.length > 0
        ? groups?.map((group) => {
            const groupStudent =
              group.students.length > 0
                ? group.students.filter((stud) => {
                    if (stud.isAssignedGroup === true) {
                      return stud;
                    }
                  })
                : [];
            return {
              ...group,
              students: groupStudent,
            };
          })
        : [];
    groups.length > 0 && setGroups(allGroupsStudents);
  }, [JSON.stringify({ groups })]);

  return (
    <div>
      {isLoading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        groups?.length > 0 &&
        groups.map((group, i) => {
          return (
            <div className="mb-2" key={`group-${i + 1}`}>
              <Group
                group={group}
                groups={groups}
                onDelete={handleModalShow}
                onSave={handleUpdate}
                onChange={handleChange}
                i={i}
                students={students}
                setStudents={setStudents}
                appState={appState}
                handleToggle={handleToggle}
                isOpen={isOpen}
                setSelectedStudent={setSelectedStudent}
                selectedStudent={selectedStudent}
                setIsEditable={setIsEditable}
                isEditable={isEditable}
                setGroups={setGroups}
              />
            </div>
          );
        })
      )}
      {isShowModel && (
        <ConfirmDialog
          onCancel={() => {
            setIsShowModel(false);
          }}
          onConfirm={() => {
            setIsShowModel(false);
            handleDelete(id, index);
          }}
          title={`Are you sure?`}
          children={
            <>
              <p className="mb-0">
                It will be remove permanently from the flexible groups.
              </p>
              Do you want to delete the group ?
            </>
          }
          open={isShowModel}
          setOpen={setIsShowModel}
          isEditable={false}
          isShowInput={false}
        />
      )}
    </div>
  );
};

export default FlexibleGroups;
