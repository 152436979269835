import axios from "axios";
import { apiPost } from ".";

const tokenData = localStorage.getItem("access_token");

export const sendMessage = (
  body: object,
  groupId: string,
  channelId: string,
) => {
  axios.post(
    `https://graph.microsoft.com/beta/teams/${groupId}/channels/${channelId}/messages`,
    body,
    {
      headers: {
        Authorization: `Bearer ${tokenData}` || "",
        "Content-Type": "application/json",
      },
    },
  );
};
