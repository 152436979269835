import mammoth from "mammoth";

// Helper function to convert DOC/DOCX file to text
export const extractTextFromDOC = async (file: File) => {
  const arrayBuffer = await file.arrayBuffer();
  const result = await mammoth.extractRawText({ arrayBuffer });
  return result.value;
};

// Extract DOC/DOCX page count (approximation using mammoth)
export const getDOCPageCount = async (file: File) => {
  const arrayBuffer = await file.arrayBuffer();
  const result = await mammoth.extractRawText({ arrayBuffer });
  const text = result.value;
  // Approximate page count based on line breaks (not exact but gives an estimate)
  return Math.ceil(text.length / 1500); // Approximate chars per page
};
