import { register } from "./register";
import { getSelectedElements } from "../scene";
import { getElementMap, getNonDeletedElements } from "../element";
import { mutateElement } from "../element/mutateElement";
import {
  ExcalidrawElement,
  NonDeleted,
  NonDeletedSceneElementsMap,
} from "../element/types";
import {
  normalizeAngle,
  resizeMultipleElements_,
  resizeSingleElement,
} from "../element/resizeElements";
import { AppClassProperties, AppState } from "../types";
import { getTransformHandles } from "../element/transformHandles";
import { isFreeDrawElement, isLinearElement } from "../element/typeChecks";
import {
  bindOrUnbindLinearElements,
  isBindingEnabled,
  updateBoundElements,
} from "../element/binding";
import { LinearElementEditor } from "../element/linearElementEditor";
import { getCommonBoundingBox } from "../element/bounds";
import { arrayToMap } from "../utils";
import { updateFrameMembershipOfSelectedElements } from "../frame";

const enableActionFlipHorizontal = (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
) => {
  const eligibleElements = getSelectedElements(
    getNonDeletedElements(elements),
    appState,
  );
  return eligibleElements.length === 1 && eligibleElements[0].type !== "text";
};

const enableActionFlipVertical = (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
) => {
  const eligibleElements = getSelectedElements(
    getNonDeletedElements(elements),
    appState,
  );
  return eligibleElements.length === 1;
};

export const actionFlipHorizontal = register({
  name: "flipHorizontal",
  perform: (elements, appState, _, app) => {
    return {
      elements: updateFrameMembershipOfSelectedElements(
        flipSelectedElements(
          elements,
          app.scene.getNonDeletedElementsMap(),
          appState,
          "horizontal",
          app,
        ),
        appState,
        app,
      ),
      appState,
      commitToHistory: true,
    };
  },
  keyTest: (event) => event.shiftKey && event.code === "KeyH",
  contextItemLabel: "labels.flipHorizontal",
  contextItemPredicate: (elements, appState) =>
    enableActionFlipHorizontal(elements, appState),
});

export const actionFlipVertical = register({
  name: "flipVertical",
  perform: (elements, appState, _, app) => {
    return {
      elements: updateFrameMembershipOfSelectedElements(
        flipSelectedElements(
          elements,
          app.scene.getNonDeletedElementsMap(),
          appState,
          "vertical",
          app,
        ),
        appState,
        app,
      ),
      appState,
      commitToHistory: true,
    };
  },
  keyTest: (event) => event.shiftKey && event.code === "KeyV",
  contextItemLabel: "labels.flipVertical",
  contextItemPredicate: (elements, appState) =>
    enableActionFlipVertical(elements, appState),
});

const flipSelectedElements = (
  elements: readonly ExcalidrawElement[],
  elementsMap: NonDeletedSceneElementsMap,
  appState: Readonly<AppState>,
  flipDirection: "horizontal" | "vertical",
  app: AppClassProperties,
) => {
  const selectedElements = getSelectedElements(
    getNonDeletedElements(elements),
    appState,
    {
      includeBoundTextElement: true,
      includeElementsInFrames: true,
    },
  );

  const updatedElements = flipElements(
    selectedElements,
    elementsMap,
    appState,
    flipDirection,
    app,
  );

  const updatedElementsMap = arrayToMap(updatedElements);

  return elements.map(
    (element) => updatedElementsMap.get(element.id) || element,
  );
};

// const flipElements_ = (
//   elements: NonDeleted<ExcalidrawElement>[],
//   appState: AppState,
//   flipDirection: "horizontal" | "vertical",
// ): ExcalidrawElement[] => {
//   for (let i = 0; i < elements.length; i++) {
//     flipElement(elements[i], appState);
//     // If vertical flip, rotate an extra 180
//     if (flipDirection === "vertical") {
//       rotateElement(elements[i], Math.PI);
//     }
//   }
//   return elements;
// };

const flipElements = (
  selectedElements: NonDeleted<ExcalidrawElement>[],
  elementsMap: NonDeletedSceneElementsMap,
  appState: AppState,
  flipDirection: "horizontal" | "vertical",
  app: AppClassProperties,
): ExcalidrawElement[] => {
  const { minX, minY, maxX, maxY } = getCommonBoundingBox(selectedElements);

  resizeMultipleElements_(
    elementsMap,
    selectedElements,
    elementsMap,
    "nw",
    true,
    true,
    flipDirection === "horizontal" ? maxX : minX,
    flipDirection === "horizontal" ? minY : maxY,
  );

  bindOrUnbindLinearElements(
    selectedElements.filter(isLinearElement),
    elementsMap,
    isBindingEnabled(appState),
    [],
  );

  return selectedElements;
};

// const flipElement = (
//   element: NonDeleted<ExcalidrawElement>,
//   appState: AppState,
// ) => {
//   const originalX = element.x;
//   const originalY = element.y;
//   const width = element.width;
//   const height = element.height;
//   const originalAngle = normalizeAngle(element.angle);

//   let finalOffsetX = 0;
//   if (isLinearElement(element) || isFreeDrawElement(element)) {
//     finalOffsetX =
//       element.points.reduce((max, point) => Math.max(max, point[0]), 0) * 2 -
//       element.width;
//   }

//   // Rotate back to zero, if necessary
//   mutateElement(element, {
//     angle: normalizeAngle(0),
//   });
//   // Flip unrotated by pulling TransformHandle to opposite side
//   const transformHandles = getTransformHandles(element, appState.zoom);
//   let usingNWHandle = true;
//   let newNCoordsX = 0;
//   let nHandle = transformHandles.nw;
//   if (!nHandle) {
//     // Use ne handle instead
//     usingNWHandle = false;
//     nHandle = transformHandles.ne;
//     if (!nHandle) {
//       mutateElement(element, {
//         angle: originalAngle,
//       });
//       return;
//     }
//   }

//   if (isLinearElement(element)) {
//     for (let i = 1; i < element.points.length; i++) {
//       LinearElementEditor.movePoint(element, i, [
//         -element.points[i][0],
//         element.points[i][1],
//       ]);
//     }
//     LinearElementEditor.normalizePoints(element);
//   } else {
//     // calculate new x-coord for transformation
//     newNCoordsX = usingNWHandle ? element.x + 2 * width : element.x - 2 * width;
//     resizeSingleElement(
//       element,
//       true,
//       element,
//       usingNWHandle ? "nw" : "ne",
//       false,
//       newNCoordsX,
//       nHandle[1],
//     );
//     // fix the size to account for handle sizes
//     mutateElement(element, {
//       width,
//       height,
//     });
//   }

//   // Rotate by (360 degrees - original angle)
//   let angle = normalizeAngle(2 * Math.PI - originalAngle);
//   if (angle < 0) {
//     // check, probably unnecessary
//     angle = normalizeAngle(angle + 2 * Math.PI);
//   }
//   mutateElement(element, {
//     angle,
//   });

//   // Move back to original spot to appear "flipped in place"
//   mutateElement(element, {
//     x: originalX + finalOffsetX,
//     y: originalY,
//   });

//   updateBoundElements(element);
// };

const rotateElement = (element: ExcalidrawElement, rotationAngle: number) => {
  const originalX = element.x;
  const originalY = element.y;
  let angle = normalizeAngle(element.angle + rotationAngle);
  if (angle < 0) {
    // check, probably unnecessary
    angle = normalizeAngle(2 * Math.PI + angle);
  }
  mutateElement(element, {
    angle,
  });

  // Move back to original spot
  mutateElement(element, {
    x: originalX,
    y: originalY,
  });
};
