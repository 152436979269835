import oc from "open-color";
import { RefObject, useEffect, useRef } from "react";
import { t } from "../i18n";
import { exportToSvg } from "../packages/utils";
import { AppState, BinaryFiles, LibraryItem } from "../types";
import { close } from "./icons";

import "./SingleLibraryItem.scss";
import { ToolButton, ToolButtonEnum } from "./ToolButton";

const SingleLibraryItem = ({
  libItem,
  appState,
  index,
  onChange,
  onRemove,
  files,
  svgRef,
}: {
  libItem: LibraryItem;
  appState: AppState;
  index: number;
  onChange: (val: string, index: number) => void;
  onRemove?: (id: string) => void;
  files: BinaryFiles;
  svgRef: RefObject<HTMLDivElement>;
}) => {
  // const svgRef_ = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const node = svgRef.current;
    if (!node) {
      return;
    }
    (async () => {
      const svg = await exportToSvg({
        elements: libItem.elements,
        appState: {
          ...appState,
          viewBackgroundColor: oc.white,
          exportBackground: true,
        },
        files: files,
      });
      node.innerHTML = svg.outerHTML;
      // }
    })();
  }, [libItem.elements, appState]);

  return (
    <>
      <div
        style={{
          display: "flex",
          margin: "0.8rem 0.3rem",
          width: "100%",
          fontSize: "14px",
          fontWeight: 500,
          flexDirection: "column",
        }}
      >
        <label
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div style={{ padding: "0.5em 0" }}>
            <span
              style={{
                fontWeight: 500,
                color: "var(--text-primary-color)",
                fontSize: "1rem",
              }}
            >
              {t("publishDialog.itemName")}
            </span>
            <span aria-hidden="true" className="required">
              *
            </span>
          </div>
          <input
            type="text"
            ref={inputRef}
            style={{
              width: "95%",
              padding: "0.4rem",
              borderRadius: 4,
              border: "1px solid #767676",
            }}
            defaultValue={libItem.name}
            placeholder="Item name"
            onChange={(event) => {
              onChange(event.target.value, index);
            }}
          />
        </label>
        <span className="error" style={{ marginLeft: "-10px" }}>
          {libItem.error}
        </span>
      </div>
      <div className="single-library-item">
        <div ref={svgRef} className="single-library-item__svg" />
        <ToolButton
          aria-label={t("buttons.remove")}
          type={ToolButtonEnum.BUTTON}
          icon={close}
          className="single-library-item--remove"
          onClick={onRemove && onRemove.bind(null, libItem.id)}
          title={t("buttons.remove")}
        />
      </div>
    </>
  );
};

export default SingleLibraryItem;
