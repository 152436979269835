import "./Avatar.scss";

import React from "react";

type AvatarProps = {
  children: any;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  color: string;
  border: string;
  className?: string;
};

export const Avatar = ({
  children,
  color,
  border,
  onClick,
  className,
}: AvatarProps) => (
  <div
    className={`Avatar ${className}`}
    style={{
      background: color,
      border: `1px solid ${border}`,
      display: "flex",
      flexDirection: "column",
      gap: "5px",
    }}
    onClick={onClick}
  >
    {children}
  </div>
);
