/* eslint-disable prettier/prettier */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import { sidebarText } from "../excalidraw-app/api/constant";

import { t } from "../i18n";

import {
  whiteTemplateIcon,
  templateIcon,
  stockIcon,
  collectionIcon,
  imageIcon,
  videoIcon,
  audioIcon,
  reviewIcon,
  likeIcon,
  whiteStockIcon,
  whiteCollectionIcon,
  whiteImageIcon,
  whiteReviewIcon,
  whiteLikeIcon,
  bookmarkIcon,
} from "./icons";
import { ToolButton } from "./ToolButton";
import { useIsMobile } from "./App";
import { useOnClickOutside } from "./LibraryMenu";

interface side {
  text: string;
  additionalStyle: boolean;
  icon: any;
  active: boolean;
}

type ChildProps = {
  changeTab: (val: string) => void;
  isSideBarOpen: boolean;
  setIsSideBarOpen: (val: boolean) => void;
  currentTab: string;
};

const LibrarySidebar = (props: ChildProps) => {
  const sidebarContent: side[] = [
    {
      text: t("collection.community.title"),
      additionalStyle: true,
      icon: "",
      active: false,
    },
    {
      text: sidebarText().TEMPLATE,
      additionalStyle: false,
      icon: whiteTemplateIcon,
      active: false,
    },
    {
      text: sidebarText().STOCKIMAGE,
      additionalStyle: false,
      icon: stockIcon,
      active: false,
    },
    {
      text: t("collection.personal.title"),
      additionalStyle: true,
      icon: "",
      active: false,
    },
    {
      text: sidebarText().MYCOLLECTIONS,
      additionalStyle: false,
      icon: collectionIcon,
      active: false,
    },
    {
      text: sidebarText().IMAGES,
      additionalStyle: false,
      icon: imageIcon,
      active: false,
    },
    {
      text: sidebarText().VIDEOS,
      additionalStyle: false,
      icon: videoIcon,
      active: false,
    },
    {
      text: sidebarText().AUDIOS,
      additionalStyle: false,
      icon: audioIcon,
      active: false,
    },
    {
      text: sidebarText().UNDERREVIEW,
      additionalStyle: false,
      icon: reviewIcon,
      active: false,
    },
    {
      text: sidebarText().LIKED,
      additionalStyle: false,
      icon: likeIcon,
      active: false,
    },
    {
      text: sidebarText().BOOKMARK,
      additionalStyle: false,
      icon: bookmarkIcon,
      active: false,
    },
  ];

  const { changeTab } = props;
  const [sidebarData, setSidebarData] = useState<side[]>([]);

  useEffect(() => {
    const activeTab = sidebarContent.find(
      (tab) => tab.text.toLowerCase() === props.currentTab.toLowerCase(),
    );
    changeTab(activeTab?.text || "Template");
    setSidebarData(
      sidebarContent.map((tab) => {
        return {
          ...tab,
          active: tab.text.toLowerCase() === props.currentTab.toLowerCase(),
        };
      }),
    );
  }, []);

  const handleActiveButton = (text: string) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps

    sidebarData.map((obj) => {
      if (obj.text === text) {
        return (
          (obj.active = true),
          obj.text === sidebarText().TEMPLATE
            ? (obj.icon = whiteTemplateIcon)
            : obj.text === sidebarText().STOCKIMAGE
            ? (obj.icon = whiteStockIcon)
            : obj.text === sidebarText().MYCOLLECTIONS
            ? (obj.icon = whiteCollectionIcon)
            : obj.text === sidebarText().IMAGES
            ? (obj.icon = whiteImageIcon)
            : obj.text === sidebarText().VIDEOS
            ? (obj.icon = videoIcon)
            : obj.text === sidebarText().AUDIOS
            ? (obj.icon = audioIcon)
            : obj.text === sidebarText().LIKED
            ? (obj.icon = whiteLikeIcon)
            : obj.text === sidebarText().BOOKMARK
            ? bookmarkIcon
            : (obj.icon = whiteReviewIcon)
        );
      }
      return (
        (obj.active = false),
        obj.text === sidebarText().TEMPLATE
          ? (obj.icon = templateIcon)
          : obj.text === sidebarText().STOCKIMAGE
          ? (obj.icon = stockIcon)
          : obj.text === sidebarText().MYCOLLECTIONS
          ? (obj.icon = collectionIcon)
          : obj.text === sidebarText().IMAGES
          ? (obj.icon = imageIcon)
          : obj.text === sidebarText().VIDEOS
          ? (obj.icon = videoIcon)
          : obj.text === sidebarText().AUDIOS
          ? (obj.icon = audioIcon)
          : obj.text === sidebarText().LIKED
          ? (obj.icon = likeIcon)
          : obj.text === sidebarText().BOOKMARK
          ? bookmarkIcon
          : (obj.icon = reviewIcon)
      );
    });

    setSidebarData(sidebarData);
    changeTab(text);
  };
  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(ref, (event) => {
    // If click on the library icon, do nothing.
    if ((event.target as Element).closest(".ToolIcon__library")) {
      return;
    }
    props.setIsSideBarOpen(false);
  });

  return (
    <>
      <div
        className="offcanvas offcanvas-start show"
        id="offcanvasExample"
        ref={ref}
        aria-labelledby="offcanvasExampleLabel"
        style={{ maxWidth: "220px", visibility: "visible" }}
      >
        <div className="offcanvas-header">
          {/* <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            Library
          </h5> */}
          <h4 className="title m-3">Libraries</h4>
          <button
            type="button"
            className="btn-close text-reset"
            // data-bs-dismiss="offcanvas"
            // aria-label="Close"
            onClick={() => {
              props.setIsSideBarOpen(false);
            }}
          ></button>
        </div>
        {sidebarData.map((content, index) => {
          return (
            <div
              className={`${
                !content.additionalStyle
                  ? content.active
                    ? "active_sidebar"
                    : "active_div_sidebar"
                  : ""
              } px-3 sidebar-button-wrapper`}
              key={index}
            >
              <ToolButton
                className={
                  content.additionalStyle
                    ? "fs-5 subTitle"
                    : `content ${content.active ? "active_sidebar" : ""}`
                }
                key="newPage"
                type="button"
                title={`${content.text}`}
                aria-label={content.text}
                icon={!content.additionalStyle && content.icon}
                onClick={() => {
                  handleActiveButton(`${content.text}`);
                  props.setIsSideBarOpen(false);
                }}
              >
                {content.text}
              </ToolButton>
              <br />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LibrarySidebar;
