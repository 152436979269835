import React from "react";
import clsx from "clsx";
import { t } from "../i18n";
import { AppState } from "../types";
import { capitalizeString } from "../utils";
import "./LibraryTemplateButton.scss";
import { useIsMobile } from "./App";

export const LibraryTemplateButton: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}> = ({ appState, setAppState }) => {
  const LIBRARY_ICON = (
    <svg
      width="512px"
      height="512px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t("toolBar.collections")}</title>
      <rect
        x="32"
        y="96"
        width="64"
        height="368"
        rx="16"
        ry="16"
        style={{
          fill: "none",
          stroke: appState.theme === "dark" ? "#fff" : "#000",
          strokeLinejoin: "round",
          strokeWidth: "32px",
        }}
      />
      <line
        x1="112"
        y1="224"
        x2="240"
        y2="224"
        style={{
          fill: "none",
          stroke: appState.theme === "dark" ? "#fff" : "#000",
          strokeLinejoin: "round",
          strokeWidth: "32px",
        }}
      />
      <line
        x1="112"
        y1="400"
        x2="240"
        y2="400"
        style={{
          fill: "none",
          stroke: appState.theme === "dark" ? "#fff" : "#000",
          strokeLinejoin: "round",
          strokeWidth: "32px",
        }}
      />
      <rect
        x="112"
        y="160"
        width="128"
        height="304"
        rx="16"
        ry="16"
        style={{
          fill: "none",
          stroke: appState.theme === "dark" ? "#fff" : "#000",
          strokeLinejoin: "round",
          strokeWidth: "32px",
        }}
      />
      <rect
        x="256"
        y="48"
        width="96"
        height="416"
        rx="16"
        ry="16"
        style={{
          fill: "none",
          stroke: appState.theme === "dark" ? "#fff" : "#000",
          strokeLinejoin: "round",
          strokeWidth: "32px",
        }}
      />
      <path
        d="M422.46,96.11l-40.4,4.25c-11.12,1.17-19.18,11.57-17.93,23.1l34.92,321.59c1.26,11.53,11.37,20,22.49,18.84l40.4-4.25c11.12-1.17,19.18-11.57,17.93-23.1L445,115C443.69,103.42,433.58,94.94,422.46,96.11Z"
        style={{
          fill: "none",
          stroke: appState.theme === "dark" ? "#fff" : "#000",
          strokeLinejoin: "round",
          strokeWidth: "32px",
        }}
      />
    </svg>
  );

  const isMobile = useIsMobile();

  return (
    <label
      className={clsx(
        "ToolIcon ToolIcon_type_floating ToolIcon__library zen-mode-visibility",
        `ToolIcon_size_medium`,
        `${isMobile ? "library_template_button" : ""}`,
        {
          "zen-mode-visibility--hidden": appState.zenModeEnabled,
        },
      )}
      title={`${capitalizeString(t("toolBar.library"))} — 9`}
      style={{ marginInlineStart: "var(--space-factor)" }}
    >
      <input
        className="ToolIcon_type_checkbox"
        type="checkbox"
        name="editor-library"
        onChange={(event) => {
          setAppState({ isShowLibraryTemplate: event.target.checked });
        }}
        checked={appState.isShowLibraryTemplate}
        aria-label={capitalizeString(t("toolBar.library"))}
        aria-keyshortcuts="9"
      />
      <div className="ToolIcon__icon">{LIBRARY_ICON}</div>
    </label>
  );
};
