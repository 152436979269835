import "./Loader.css";
const Loader = ({ className = "" }) => (
  <div
    className={`centered-loader ${className}`}
    style={{
      position: className === "style" && "absolute",
      height: className === "style" && "100%",
      width: className === "style" && "100%",
      top: className === "style" && "0%",
      zIndex: className === "style" && "1",
    }}
  >
    <div className="spinner-border" role="status"></div>
  </div>
);
export default Loader;
