import React from "react";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import { FlexibleGroupIcon } from "./icons";
import { t } from "../i18n";
import { useIsMobile } from "./App";
import clsx from "clsx";

const FlexibleGroupButton = ({
  onClick,
  className = "",
}: {
  onClick?: () => void;
  className?: string;
}) => {
  return (
    <ToolButton
      className={className}
      onClick={onClick}
      icon={<FlexibleGroupIcon />}
      type={ToolButtonEnum.BUTTON}
      title={t("labels.flexibleGroup")}
      // aria-label={t("labels.flexibleGroup")}
      aria-label={""}
      // showAriaLabel={useIsMobile()}
    >
      <div></div>
    </ToolButton>
  );
};

export default FlexibleGroupButton;
