import Library from "../../../../../../data/library";
import { NonDeleted, ExcalidrawElement } from "../../../../../../element/types";
import {
  AppState,
  BinaryFiles,
  LibraryData,
  LibraryItem,
  LibraryItems,
} from "../../../../../../types";
import { useIsMobile } from "../../../../../App";
import CardWithEditing from "../../../../components/card/CardwithEditing";
import { CardWithSvg } from "../../../../components/card/CardWithSvg";
import {
  CollectionSidebarItemsEnum,
  SidebarItemsEnum,
} from "../../../../components/constant/SidebarItems";

interface WorkInProgressLibraryTabProps {
  unpublishedItems: (
    | LibraryItem
    | {
        id: null;
        elements: readonly NonDeleted<ExcalidrawElement>[];
      }
  )[];
  files: BinaryFiles;
  setDeleteElement: (val: number[]) => void;
  deleteElelement: number[];
  addToCollection: number[];
  setAddToCollection: (value: number[]) => void;
  selectedTab: SidebarItemsEnum | CollectionSidebarItemsEnum;
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  onInsertShape: (elements: LibraryItem["elements"]) => void;
  selectedItems: LibraryItem["id"][];
  setPreviewDialog: (val: LibraryItem["elements"] | null) => void;
  appState: AppState;
  resetScene: (opts?: { resetLoadingState: boolean }) => void;
  setAppState: React.Component<any, AppState>["setState"];
  saveLib: boolean;
  getSelectedItems: (
    libraryItems: LibraryItems,
    selectedItems: LibraryItem["id"][],
  ) => void;
  onPublishLibSuccess: (data: LibraryData) => void;
  isTrusted: number;
  library: Library;
  libraryItems: LibraryItems;
  setSaveLib: (val: boolean) => void;
}

export const WorkInProgressLibraryTab = (
  props: WorkInProgressLibraryTabProps,
) => {
  const {
    unpublishedItems,
    files,
    setDeleteElement,
    deleteElelement,
    addToCollection,
    setAddToCollection,
    selectedTab,
    setSelectedItems,
    onInsertShape,
    selectedItems,
    setPreviewDialog,
    appState,
    resetScene,
    setAppState,
    saveLib,
    getSelectedItems,
    onPublishLibSuccess,
    isTrusted,
    library,
    libraryItems,
    setSaveLib,
  } = props;
  const isMobile = useIsMobile();
  return (
    <>
      <div
        className="row overflow-auto"
        style={{
          position: "relative",
          top: isMobile ? "7%" : "",
          height: "calc(100% - 23%)",
        }}
      >
        {appState.editingLibrary.isEditing && !saveLib && (
          <div
            className="col-lg-4 col-md-6 col-sm-6 col-xs-12 cursor-pointer"
            onClick={() => {
              setAppState({ isLibraryOpen: false });
              resetScene();
              setAppState({
                editingLibrary: {
                  ...appState.editingLibrary,
                  isEditing: true,
                },
              });
              localStorage.removeItem("selectedElement");
            }}
          >
            <div className={`card preview-card mb-3`}>
              <div className="card-img-top">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 12.75H6C5.59 12.75 5.25 12.41 5.25 12C5.25 11.59 5.59 11.25 6 11.25H18C18.41 11.25 18.75 11.59 18.75 12C18.75 12.41 18.41 12.75 18 12.75Z"
                    fill="#292D32"
                  />
                  <path
                    d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V18C12.75 18.41 12.41 18.75 12 18.75Z"
                    fill="#292D32"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
        {saveLib ? (
          <CardWithEditing
            libraryItems={getSelectedItems(libraryItems, selectedItems)}
            appState={appState}
            onSuccess={onPublishLibSuccess}
            onError={(error) => window.alert(error)}
            updateItemsInStorage={() => library.saveLibrary(libraryItems)}
            onRemove={(id: string) =>
              setSelectedItems(selectedItems.filter((_id) => _id !== id))
            }
            files={files}
            isTrusted={isTrusted}
            setSaveLib={setSaveLib}
            setDeleteElement={setDeleteElement}
            setAddToCollection={setAddToCollection}
            setSelectedItems={setSelectedItems}
          />
        ) : (
          unpublishedItems.map((item, index) => (
            <CardWithSvg
              key={index}
              item={item}
              index={index}
              addToCollection={addToCollection}
              setAddToCollection={setAddToCollection}
              files={files}
              setDeleteElement={setDeleteElement}
              deleteElelement={deleteElelement}
              selectedTab={selectedTab}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              setPreviewDialog={setPreviewDialog}
              onInsertShape={onInsertShape}
              appState={appState}
              resetScene={resetScene}
              setAppState={setAppState}
            />
          ))
        )}
      </div>
    </>
  );
};
