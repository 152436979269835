import { useEffect, useState } from "react";
import {
  LibraryTabsEnum,
  SidebarItemsProps,
} from "../../../LibraryItemsDialog";
import { CollectionSidebarItemsEnum } from "../../../components/constant/SidebarItems";
import {
  audioIcon,
  bookmarkIcon,
  collectionIcon,
  imageIcon,
  likeIcon,
  reviewIcon,
  stockIcon,
  videoIcon,
  whiteTemplateIcon,
} from "../../../../icons";
import { t } from "../../../../../i18n";
import { useIsMobile } from "../../../../App";

interface CollectionSidebarProps {
  navToggle: boolean;
  setNavToggle: (val: boolean) => void;
  selectedTab: CollectionSidebarItemsEnum;
  setSelectedTab: (val: CollectionSidebarItemsEnum) => void;
  selectedTopTab: LibraryTabsEnum;
  isSmallDevice?: boolean;
}

export const CollectionSidebar = (props: CollectionSidebarProps) => {
  const {
    navToggle,
    setNavToggle,
    selectedTab,
    setSelectedTab,
    selectedTopTab,
    isSmallDevice,
  } = props;

  const CollectionSidebarItems = [
    {
      id: CollectionSidebarItemsEnum.Template,
      icon: whiteTemplateIcon,
      value: CollectionSidebarItemsEnum.Template,
      title: t("collection.community.template.title"),
    },
    {
      id: CollectionSidebarItemsEnum.StockImage,
      icon: stockIcon,
      value: CollectionSidebarItemsEnum.StockImage,
      title: t("collection.community.stokeImage.title"),
    },
    {
      id: CollectionSidebarItemsEnum.MyCollections,
      icon: collectionIcon,
      value: CollectionSidebarItemsEnum.MyCollections,
      title: t("collection.personal.myCollections.title"),
    },
    {
      id: CollectionSidebarItemsEnum.Images,
      icon: imageIcon,
      value: CollectionSidebarItemsEnum.Images,
      title: t("collection.personal.images.title"),
    },
    {
      id: CollectionSidebarItemsEnum.Videos,
      icon: videoIcon,
      value: CollectionSidebarItemsEnum.Videos,
      title: t("collection.personal.videos.title"),
    },
    {
      id: CollectionSidebarItemsEnum.Audios,
      icon: audioIcon,
      value: CollectionSidebarItemsEnum.Audios,
      title: t("collection.personal.audios.title"),
    },
    {
      id: CollectionSidebarItemsEnum.UnderReview,
      icon: reviewIcon,
      value: CollectionSidebarItemsEnum.UnderReview,
      title: t("collection.personal.underReview.title"),
    },
    {
      id: CollectionSidebarItemsEnum.Liked,
      icon: likeIcon,
      value: CollectionSidebarItemsEnum.Liked,
      title: t("collection.personal.liked.title"),
    },
    {
      id: CollectionSidebarItemsEnum.Bookmark,
      icon: bookmarkIcon,
      value: CollectionSidebarItemsEnum.Bookmark,
      title: t("collection.personal.bookmark.title"),
    },
  ];

  const [categories, setCategories] = useState<SidebarItemsProps[]>(
    CollectionSidebarItems,
  );

  useEffect(() => {
    setCategories(CollectionSidebarItems);
    setSelectedTab(
      CollectionSidebarItems[0].value as CollectionSidebarItemsEnum,
    );
  }, []);

  const handleItemClick = (value: CollectionSidebarItemsEnum) => {
    setSelectedTab(value);
  };

  const isMobile = useIsMobile();

  return (
    <>
      <div
        id="nav-bar"
        className={
          isSmallDevice && !navToggle ? "position-absolute bg-white" : ""
        }
        style={{
          width: !navToggle && isMobile ? "100%" : "",
          ...(isSmallDevice && !navToggle && { zIndex: 2, minWidth: 81 }),
        }}
      >
        <input
          id="nav-toggle"
          type="checkbox"
          checked={navToggle}
          onClick={() => setNavToggle(!navToggle)}
        />
        <div
          id="nav-header"
          style={{
            width: !navToggle && isMobile ? "90%" : "",
          }}
        >
          <a id="nav-title">{t("toolBar.collections")}</a>
          <label htmlFor="nav-toggle">
            <span id="nav-toggle-burger"></span>
          </label>
        </div>
        <div id="nav-content" style={{ width: isMobile ? "auto" : "" }}>
          {categories.map((item, index) => {
            return (
              <>
                <div
                  className={`nav-button ${
                    (item?.value ?? item.title) === selectedTab
                      ? "activeItem"
                      : ""
                  }`}
                  key={index}
                  onClick={() =>
                    handleItemClick(
                      (item?.value ?? item.title) as CollectionSidebarItemsEnum,
                    )
                  }
                >
                  <i
                    className="fas"
                    data-bs-toggle="tooltip"
                    title={item.title}
                  >
                    {item.icon}
                  </i>
                  <span>{item.title}</span>
                </div>
                <div id="nav-content-highlight" className="d-none"></div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
