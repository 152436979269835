import clsx from "clsx";
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import axios from "axios";

import MathView, { MathFieldChangeEvent, MathViewRef } from "react-math-view";
import ReactTooltip from "react-tooltip";
import { actionDeleteSelected } from "../actions";
import { ActionManager } from "../actions/manager";
import { SignOutButton } from "../App/msal-auth/SignOutButton";
import spin from "../assests/spin.jpg";
import { StartEditingProps, useIsMobile } from "../components/App";
import { EXPORT_IMAGE_TYPES } from "../constants";
import { exportCanvas } from "../data";
import { isImageFileHandle } from "../data/blob";
import Library from "../data/library";
import { isTextElement, showSelectedShapeActions } from "../element";
import {
  ExcalidrawElement,
  ExcalidrawTextWithStyleElement,
  NonDeleted,
  NonDeletedExcalidrawElement,
} from "../element/types";
import { getStudents } from "../excalidraw-app/api";
import {
  ACV_API_URL,
  studentAccessRevokeMessage,
  studentSessionEndMessage,
} from "../excalidraw-app/api/constant";
import {
  checkUserRole,
  getLessonInfo,
  getLessonWisePdfImportedData,
  getSettingData,
  pdfImportDocumentCounter,
} from "../excalidraw-app/api/userAPI";
import { Language, t } from "../i18n";
import { calculateScrollCenter, getSelectedElements } from "../scene";
import {
  AppProps,
  AppState,
  BinaryFiles,
  CollocationType,
  ExcalidrawProps,
  LibraryData,
  LibraryItem,
  LibraryItems,
  Subscription,
} from "../types";
import { muteFSAbortError, sceneCoordsToViewportCoords } from "../utils";
import { midUuid } from "../utils/uuid";
import { SelectedShapeActions, ShapesSwitcher, ZoomActions } from "./Actions";
import AlertDialog from "./AlertDialog";
import AudioRecorder from "./AudioRecorder";
import { Avatar } from "./Avatar";
import { BackgroundPickerAndDarkModeToggle } from "./BackgroundPickerAndDarkModeToggle";
import CollabButton from "./CollabButton";
import { NotesTitleContext } from "./contexts/NotesTitle.context";
import { StudentMessageContext } from "./contexts/StudentMessage.context";
import { Dialog } from "./Dialog";
import { ErrorDialog } from "./ErrorDialog";
import { FixedSideContainer } from "./FixedSideContainer";
import FlexibleGroupButton from "./flexibleGroupButton";
import { GPTDialog } from "./GPTDialog";
import { HelpDialog } from "./HelpDialog";
import { HintViewer } from "./HintViewer";
import {
  audioIcon,
  close,
  pin,
  pinFill,
  recording,
  settingOutlineIcon,
  users,
  videoIcon,
} from "./icons";
import { ImageExportDialog } from "./ImageExportDialog";
import { Island } from "./Island";
import { JSONExportDialog } from "./JSONExportDialog";
import "./LayerUI.scss";
import { LibraryButton } from "./LibraryButton";
import { LibraryDialog } from "./LibraryDialog";
import { LibraryMenu } from "./LibraryMenu";
import { LibraryTemplateButton } from "./LibraryTemplateButton";
import { LoadingMessage } from "./LoadingMessage";
import { LockButton } from "./LockButton";
import { MobileMenu } from "./MobileMenu";
import PdfPageSelectionDialog from "./PdfSelectionDialog";
import PublishLibrary from "./PublishLibrary";
import { Section } from "./Section";
import { Settings } from "./settings";
import SideBar from "./SideBar";
import SpinnerDialog from "./spinner/index";
import { SegmentType, timerDuration } from "./spinner/types";
import Stack from "./Stack";
import { TiptapEditor } from "./tiptapEditor/TipTapEditor";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import VideoRecorder from "./VideoRecorder";
import Portall from "../excalidraw-app/collab/Portall";
import { EditMermaidDiagramDialog } from "./EditMermaidDiagramDialog";
interface LayerUIProps {
  actionManager: ActionManager;
  appState: AppState;
  files: BinaryFiles;
  onPageDelete?: (pageId: string) => void;
  canvas: HTMLCanvasElement | null;
  setAppState: React.Component<any, AppState>["setState"];
  elements: readonly NonDeletedExcalidrawElement[];
  onCollabButtonClick?: () => void;
  onFlexibleGroupButtonClick?: () => void;
  onLockToggle: () => void;
  onInsertElements: (elements: readonly NonDeletedExcalidrawElement[]) => void;
  importCustomCategory: (elements: LibraryItem["elements"]) => void;
  importLibraryFromUrl: (urls: string[]) => void;
  zenModeEnabled: boolean;
  sidebarWrapper: boolean;
  showExitZenModeBtn: boolean;
  showThemeBtn: boolean;
  toggleZenMode: () => void;
  langCode: Language["code"];
  isCollaborating: boolean;
  renderTopRightUI?: (
    isMobile: boolean,
    appState: AppState,
  ) => JSX.Element | null;
  renderCustomFooter?: (isMobile: boolean, appState: AppState) => JSX.Element;
  viewModeEnabled: boolean;
  libraryReturnUrl: ExcalidrawProps["libraryReturnUrl"];
  UIOptions: AppProps["UIOptions"];
  focusContainer: () => void;
  library: Library;
  id: string;
  onPageChange?: any;
  onImageAction: (data: { insertOnCanvasDirectly: boolean }) => void;
  onImageActionFromLibrary: (imageFile: File) => void;
  mathfieldRef: React.RefObject<MathViewRef>;
  onMathFormulaAction: (
    mathfieldRef: React.RefObject<MathViewRef>,
    formulaValue?: string,
  ) => void;
  togglekeyboardShortcut: (isAdding: boolean) => void;
  toggleZoomInZoomOut: (isZoom: boolean) => void;
  mathFormulaValueRef: { current: string | null | undefined };
  executeFinalizeAction: () => void;

  onVideoActionFromLibrary: (
    file: File,
    filename?: string | null,
    color?: string | null,
  ) => void;
  onAudioActionFromLibrary: (
    file: File,
    filename?: string | null,
    color?: string | null,
  ) => void;
  startTextEditing: (data: StartEditingProps) => void;
  onTextWithStylesAction: ({
    textString,
    show,
    handleCloseDialogue,
    isAIGenerated,
    width,
  }: {
    textString: string;
    show?: boolean;
    handleCloseDialogue?: VoidFunction;
    isAIGenerated?: boolean;
    width?: number;
  }) => void;
  handlePdfFile: (
    selectedPages: Array<{ index: number; page: string }>,
    pdfId: string,
  ) => void;
  resetScene: (opts?: { resetLoadingState: boolean }) => void;
}

const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  cb: (event: MouseEvent) => void,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current) {
        return;
      }

      if (
        event.target instanceof Element &&
        (ref.current.contains(event.target) ||
          !document.body.contains(event.target))
      ) {
        return;
      }

      cb(event);
    };
    document.addEventListener("pointerdown", listener, false);

    return () => {
      document.removeEventListener("pointerdown", listener);
    };
  }, [ref, cb]);
};

export let libraryTemplateMenu2: any,
  videoRecorder: any,
  audioRecorder: any,
  notes: any,
  settings: any,
  settingsPopover: any;

const LayerUI = ({
  actionManager,
  appState,
  files,
  setAppState,
  canvas,
  elements,
  onCollabButtonClick,
  onFlexibleGroupButtonClick,
  onLockToggle,
  onInsertElements,
  zenModeEnabled,
  sidebarWrapper,
  showExitZenModeBtn,
  showThemeBtn,
  toggleZenMode,
  isCollaborating,
  renderTopRightUI,
  renderCustomFooter,
  viewModeEnabled,
  libraryReturnUrl,
  UIOptions,
  focusContainer,
  library,
  id,
  onPageChange,
  onPageDelete,
  onImageAction,
  mathfieldRef,
  onImageActionFromLibrary,
  onVideoActionFromLibrary,
  onAudioActionFromLibrary,
  onMathFormulaAction,
  togglekeyboardShortcut,
  mathFormulaValueRef,
  executeFinalizeAction,
  startTextEditing,
  onTextWithStylesAction,
  handlePdfFile,
  toggleZoomInZoomOut,
  importCustomCategory,
  importLibraryFromUrl,
  resetScene,
}: LayerUIProps) => {
  const isMobile = useIsMobile();
  const [isStudent, setIsStudent] = useState<boolean>(appState.S);
  const [isMobileView, setIsMobileView] = React.useState(
    window.innerHeight <= 768,
  );
  const [lesson, setLesson] = useState({
    ClassName: null,
    LessonDate: "",
  });

  // notes btn ref
  const notesRef = React.useRef<HTMLDivElement>(null);

  //setting btn ref
  const settingRef = React.useRef<HTMLDivElement>(null);

  const { setStudentMessage } = useContext(StudentMessageContext);

  const [formulaInnerValue, setFormulaInnerValue] = useState(
    appState.formulaValue,
  );
  const [
    isCollaboratingWithFlexibleGroups,
    setIsCollaboratingWithFlexibleGroups,
  ] = useState(false);
  const [isCollaborating_, setIsCollaborating_] = useState(false);

  const [canUploadMedia, setCanUploadMedia] = useState({
    video: false,
    audio: false,
  });

  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [openGPTDialog, setOpenGPTDialog] = useState<boolean>(false);
  const [openSpinnerDialog, setOpenSpinnerDialog] = useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const [gptCredit, setGptCredit] = useState(0);
  const [showPublishLibraryDialog, setShowPublishLibraryDialog] = useState(
    false,
  );
  const [publishLibSuccess, setPublishLibSuccess] = useState<null | {
    url: string;
    authorName: string;
  }>(null);
  const [libraryItems, setLibraryItems] = useState<LibraryItems>([]);
  const [subscription, setSubscription] = useState<Subscription>();
  const [limitExceedModal, setLimitExceedModal] = useState(false);
  const [allowedDocumentsPerLesson, setAllowedDocumentsPerLesson] = useState(
    null,
  );
  const [open, setOpen] = useState(false);
  const [segments, setSegments] = useState<{
    isTimer: boolean;
    timerDuration: {
      [timerDuration.MINUTES]: number;
      [timerDuration.SECONDS]: number;
    };
    segments: SegmentType[];
  }>({
    isTimer: false,
    timerDuration: { [timerDuration.MINUTES]: 1, [timerDuration.SECONDS]: 0 },
    segments: [],
  });
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [pinSidebar, setPinSidebar] = useState<boolean>(false);
  const [openSettingPopover, setOpenSettingPopover] = useState<boolean>(false);
  const [deleteElelement, setDeleteElement] = useState<number[]>([]);
  const [addToCollection, setAddToCollection] = useState<number[]>([]);
  // console.log("libraryItems", libraryItems);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerHeight <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getBackgroundColorBtn = document.querySelector(
      '[data-testid="background-color"]',
    );

    if (getBackgroundColorBtn) {
      const backgroundColorBtnPopover = document.getElementById(
        "background-color-popover",
      );
      if (backgroundColorBtnPopover) {
        backgroundColorBtnPopover.style.top = `${
          getBackgroundColorBtn.getBoundingClientRect().top +
          getBackgroundColorBtn.getBoundingClientRect().height / 2
        }px`;
        backgroundColorBtnPopover.style.left = `10px`;
      }
    }
  }, [appState.openPopup === "canvasColorPicker"]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isStudent && viewModeEnabled && event.altKey && event.key === "r") {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const removeOldLibraryElements = () => {
    const currentPageElements = JSON.parse(localStorage.getItem("acv") || "[]");
    const currentPageOldElements =
      appState.editingLibrary.currentPageOldElements;
    let elementsIdsForDelete: { [id: string]: boolean } = {};

    if (currentPageElements?.length) {
      if (currentPageOldElements?.length) {
        for (let index = 0; index < currentPageElements.length; index++) {
          const element = currentPageElements[index];
          // Check that element is available on page before starting editing library.
          const isOldElementOfPage = currentPageOldElements.some(
            (oldElement) => oldElement.id === element.id,
          );
          if (!isOldElementOfPage) {
            elementsIdsForDelete[element.id] = true;
          }
        }
      } else {
        for (let index = 0; index < currentPageElements.length; index++) {
          const element = currentPageElements[index];
          elementsIdsForDelete[element.id] = true;
        }
      }
    }

    if (elementsIdsForDelete) {
      setAppState({
        selectedElementIds: elementsIdsForDelete,
      });
      setTimeout(() => {
        actionManager.executeAction(actionDeleteSelected);
      }, 5);
    }
    setAppState({
      editingLibrary: {
        ...appState.editingLibrary,
        libraryId: "",
        isEditing: false,
        libraryInfo: {} as CollocationType,
        oldElements: [],
        currentPageOldElements: [],
      },
    });
  };

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const profile = window.localStorage.getItem("profile-blob");
  // const profileImg = window.localStorage.getItem("profile-image");
  const SAS = window.localStorage.getItem("SAS");
  const displayName = user?.displayName;
  const isCollaborationStart = (val: string) => {
    switch (val) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return false; // Default case to handle unexpected values
    }
  };
  const isJoinCollaboration = isCollaborationStart(
    localStorage.getItem("isCollaborating") || "false",
  );

  const words = displayName?.split(" ").filter((word: string) => !!word.trim());
  let name = "";

  if (words?.length > 1) {
    name = words[0]?.substring(0, 1) + words[words.length - 1]?.substring(0, 1);
  } else {
    name = displayName?.substring(0, 1);
  }

  const role = localStorage.getItem("Role");
  useEffect(() => {
    const params = new URLSearchParams(window.location.search).get("element");
    if (params) {
      axios
        .get(params)
        .then((res) => {
          const elements: NonDeleted<ExcalidrawElement>[] = [];
          const files: BinaryFiles[] = [];

          if (res.data?.library) {
            res.data.library.map((lib: any) => {
              lib.map((data: NonDeleted<ExcalidrawElement>) => {
                elements.push(data);
              });
            });
          }

          if (res.data?.libraryItems) {
            res.data.libraryItems.map((lib: any) => {
              lib.elements.map((data: NonDeleted<ExcalidrawElement>) => {
                elements.push(data);
              });
            });
          }

          onInsertElements(elements);
        })
        .catch((error) => console.log(error));
    }
    const fetchData = async () => {
      const slug = new URLSearchParams(window.location.search).get("slug");
      const validSlug = slug ?? "";
      const res = await checkUserRole(validSlug);
      if (res) setGptCredit(res.credit ? res.credit : 0);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setIsCollaboratingWithFlexibleGroups(
      JSON.parse(
        localStorage.getItem("isCollaboratingWithFlexibleGroups") || "false",
      ),
    );
  }, [localStorage.getItem("isCollaboratingWithFlexibleGroups")]);
  useEffect(() => {
    setIsCollaborating_(
      JSON.parse(localStorage.getItem("isCollaborating") || "false"),
    );
  }, [localStorage.getItem("isCollaborating")]);

  useEffect(() => {
    const lesson = JSON.parse(localStorage.getItem("lesson") || "{}");
    if (lesson?.ClassName) {
      setLesson(lesson);
    } else {
      const lessonId =
        new URLSearchParams(window.location.search)
          .get("lessonId")
          ?.replace(/\//g, "") || "";
      const slug =
        new URLSearchParams(window.location.search)
          .get("slug")
          ?.replace(/\//g, "") || "";
      getLessonInfo(lessonId, slug).then((res) =>
        setLesson({ ClassName: res?.result[0]?.ClassName }),
      );
    }

    setIsStudent(JSON.parse(window.localStorage.getItem("S") || "{}"));
    if (["SuperAdmin", "Admin", "Teacher"].includes(user.role)) {
      setIsStudent(false);
    }
  }, [appState.S]);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const slug = new URLSearchParams(window.location.search).get("slug");
    const validSlug = slug ?? "";
    axios
      .post(
        `${process.env.REACT_APP_ACV_BACKEND_API}/api/startup/get-DB-data?slug=${validSlug}`,
        {
          tenantId: "12d105e3-e51a-4c7f-843b-50046ad42224",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      )
      .then((res) => {
        setSubscription(res.data.subscription);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    setFormulaInnerValue(appState.formulaValue);
    setFontSizeByDivision(appState.formulaValue);
    mathfieldRef.current?.focus();
  }, [appState.formulaValue]);

  useEffect(() => {
    if (mathfieldRef.current) {
      // Open virtual keyboard in iframe tag

      mathfieldRef.current.mathVirtualKeyboardPolicy = "sandboxed";
      const mathField = document.querySelector(
        ".math-formula-wrapper math-field",
      );
      if (mathField) {
        const shadowRoot = mathField.shadowRoot;
        if (shadowRoot) {
          const firstSpan = shadowRoot.querySelector("span");

          if (firstSpan) {
            firstSpan.style.pointerEvents = "auto";
          }
        }
      }
    }
  }, [mathfieldRef.current]);

  useEffect(() => {
    const closeOnOutsideClick = (event: MouseEvent) => {
      if (showPopover && event.target) {
        setShowPopover(false);
      }

      const gptDialog = document.getElementById("gpt-model");
      const gptButton = document.getElementById("gpt-button");

      if (gptButton && gptDialog) {
        if (
          !gptDialog.contains(event.target as Node) ||
          !gptButton.contains(event.target as Node)
        ) {
          setOpenGPTDialog(false);
        }
      }
    };

    window.addEventListener("click", closeOnOutsideClick);

    return () => {
      window.removeEventListener("click", closeOnOutsideClick);
    };
  }, [showPopover, openGPTDialog]);

  useEffect(() => {
    setAppState({
      ...calculateScrollCenter(elements, appState, canvas),
    });
  }, [appState.isLoading]);

  useEffect(() => {
    appState?.editingLibrary?.libraryItems?.length &&
      appState?.editingLibrary?.isEditing &&
      handleEditingLibrary();
  }, [localStorage.getItem("acv")]);

  const updateLibraryItemElements = (
    newElements: ExcalidrawElement[],
    i: number,
  ) => {
    let clonedLibraryItems: LibraryItems = [
      ...appState.editingLibrary.libraryItems,
    ];

    if (i > 0) clonedLibraryItems[i]["elements"] = newElements;
    return clonedLibraryItems;
  };

  const handleEditingLibrary = () => {
    const currentPageElements = JSON.parse(localStorage.getItem("acv") || "[]");
    const libraryItems = appState.editingLibrary.libraryItems;
    const editingLibIndex = libraryItems.findIndex(
      (lib) => lib.id === appState.editingLibrary.libraryId,
    );
    if (editingLibIndex !== -1) {
      setAppState({
        editingLibrary: {
          ...appState.editingLibrary,
          libraryItems: updateLibraryItemElements(
            currentPageElements as ExcalidrawElement[],
            editingLibIndex,
          ),
        },
      });
    }
  };

  const handleSpinnerDialog = (data: {
    isTimer: boolean;
    timerDuration: {
      [timerDuration.MINUTES]: number;
      [timerDuration.SECONDS]: number;
    };
    segments: SegmentType[];
  }) => {
    // closeLibrary();
    setAppState({ isLibraryOpen: false });
    setOpenSpinnerDialog(!openSpinnerDialog);
    setSegments(data);
  };

  const closeMathInput = () => {
    setAppState(
      {
        formulaValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}",
        updatingFormulaElementId: "",
        showMathInputAndKeyboard: false,
      },
      () => {
        executeFinalizeAction();
      },
    );
    togglekeyboardShortcut(true);
    // @ts-ignore
    const divElement = document.querySelector(
      ".ML__keyboard.is-math-mode.can-paste.is-visible",
    );
    if (divElement) {
      // @ts-ignore
      mathfieldRef.current?.executeCommand("toggleVirtualKeyboard");
    }
  };

  const renderJSONExportDialog = () => {
    if (!UIOptions.canvasActions.export) {
      return null;
    }

    const exportOpts = isStudent
      ? { saveFileToDisk: false }
      : UIOptions.canvasActions.export;

    return (
      <JSONExportDialog
        elements={elements}
        appState={appState}
        actionManager={actionManager}
        exportOpts={exportOpts}
        canvas={canvas}
        small={isStudent ? true : false}
        setAppState={setAppState}
      />
    );
  };

  const createExporter = async (
    type: keyof typeof EXPORT_IMAGE_TYPES,
    exportedElements: readonly NonDeletedExcalidrawElement[],
  ) => {
    const fileHandle = await exportCanvas(
      type,
      exportedElements,
      appState,
      files,
      {
        exportBackground: appState.exportBackground,
        name: appState.name,
        viewBackgroundColor: appState.viewBackgroundColor,
      },
    )
      .catch(muteFSAbortError)
      .catch((error) => {
        console.error(error);
        setAppState({ errorMessage: error.message });
      });

    if (
      appState.exportEmbedScene &&
      fileHandle &&
      isImageFileHandle(fileHandle)
    ) {
      setAppState({ fileHandle });
    }
  };

  const renderImageExportDialog = () => {
    if (!UIOptions.canvasActions.saveAsImage) {
      return null;
    }

    return (
      <ImageExportDialog
        elements={elements}
        appState={appState}
        files={files}
        actionManager={actionManager}
        onExportToPng={createExporter}
        onExportToSvg={createExporter}
        onExportToClipboard={createExporter}
        selectedPages={selectedPages}
        setSelectedPages={setSelectedPages}
      />
    );
  };

  const renderSpinWheelDialog = useMemo(() => {
    return (
      <>
        <ToolButton
          icon={<img src={spin} height="23px" width="23px" />}
          type="button"
          aria-label={t("buttons.spinner")}
          showAriaLabel={isMobile}
          title={t("buttons.spinner")}
          id="spinner-button"
          onClick={() => {
            setOpenSpinnerDialog(!openSpinnerDialog);
            setSegments({
              isTimer: false,
              timerDuration: {
                [timerDuration.MINUTES]: 1,
                [timerDuration.SECONDS]: 0,
              },
              segments: [],
            });
          }}
        />
        {openSpinnerDialog && (
          <SpinnerDialog
            open={openSpinnerDialog}
            setOpen={setOpenSpinnerDialog}
            onClose={() => setOpenSpinnerDialog(false)}
            insertImageOnCanvas={onImageActionFromLibrary}
            onTextWithStylesAction={onTextWithStylesAction}
            segmentsData={segments}
          />
        )}
      </>
    );
  }, [
    openSpinnerDialog,
    segments,
    isMobile,
    setSegments,
    setOpenSpinnerDialog,
    onImageActionFromLibrary,
    onTextWithStylesAction,
  ]);

  const renderSettingPopover = useMemo(() => {
    return (
      openSettingPopover && (
        <Settings
          actionManager={actionManager}
          setOpenSettingPopover={setOpenSettingPopover}
          appState={appState}
          left={isMobile ? 0 : 10}
          top={
            settingRef.current
              ? `${settingRef.current?.getBoundingClientRect().top - 10}px`
              : "50%"
          }
        />
      )
    );
  }, [openSettingPopover, settingRef, isMobile]);

  const renderGPTDialog = () => {
    return (
      <GPTDialog
        appState={appState}
        startTextEditing={startTextEditing}
        openGPTDialog={openGPTDialog}
        setOpenGPTDialog={setOpenGPTDialog}
        setAppState={setAppState}
        onTextWithStylesAction={onTextWithStylesAction}
        gptCredit={gptCredit}
        setGptCredit={setGptCredit}
      />
    );
  };

  const renderEditMermaidDiagramDialog = useMemo(() => {
    return appState.EditMermaidDiagramDialog.open ? (
      <>
        <EditMermaidDiagramDialog
          editMermaidDiagramDialog={appState.EditMermaidDiagramDialog}
          appState={appState}
          setAppState={setAppState}
        />
      </>
    ) : (
      <></>
    );
  }, [appState.EditMermaidDiagramDialog]);

  settings = (
    <div
      style={{ position: isMobile ? "absolute" : "relative" }}
      className={`${isMobile ? "settings-icon" : ""}`}
      ref={settingRef}
    >
      <ToolButton
        data-testid="setting-button"
        icon={settingOutlineIcon}
        type="button"
        aria-label={t("buttons.setting")}
        title={t("buttons.setting")}
        id="setting-button"
        onClick={() => setOpenSettingPopover(!openSettingPopover)}
      />
      {isMobile && renderSettingPopover}
    </div>
  );

  const Separator = () => {
    return <div style={{ width: ".625em" }} />;
  };

  const handleButtonClick = () => {
    const perPageAudioLimit = subscription ? subscription?.audioLimit : 0;
    const perPageVideoLimit = subscription ? subscription.videoLimit : 0;
    const nonDeletedVideo = elements.filter(
      (data) => data.type === "video" && data.isDeleted === false,
    );
    const nonDeletedAudio = elements.filter(
      (data) => data.type === "audio" && data.isDeleted === false,
    );
    setCanUploadMedia({
      video: nonDeletedVideo.length < Number(perPageVideoLimit) ? true : false,
      audio: nonDeletedAudio.length < Number(perPageAudioLimit) ? true : false,
    });
    setShowPopover(!showPopover);
  };

  const popoverStyle = {
    display: showPopover ? "block" : "none",
    position: "absolute",
    backgroundColor: "#f9f9f9",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    zIndex: 1,
    top: "calc(100% + 4px)",
    left: "50%",
    transform: "translateX(-50%)",
  };

  const renderViewModeCanvasActions = () => {
    return (
      <Section
        heading="canvasActions"
        className={clsx("zen-mode-transition", {
          "transition-left": zenModeEnabled,
        })}
      >
        {/* the zIndex ensures this menu has higher stacking order,
         see https://github.com/excalidraw/excalidraw/pull/1445 */}
        <Island
          padding={isStudent ? 0 : 2}
          style={{ zIndex: 1 }}
          className="vScroll"
        >
          <Stack.Col gap={4}>
            <Stack.Row gap={1}>
              {!isStudent && (
                <span
                  title={user?.mail}
                  className="d-flex justify-content-center"
                >
                  <Avatar
                    onClick={() => {
                      // const msg =
                      //   user?.displayName +
                      //   " clicked profile!\nYour Email is : " +
                      //   user?.mail;
                      // alert(msg);
                    }}
                    color={"#4c6ef5"}
                    border={"#4c6ef5"}
                  >
                    {user?.UrlPhoto ? (
                      <img src={user?.UrlPhoto} alt={name} className="Avatar" />
                    ) : profile ? (
                      <img src={profile} alt={name} className="Avatar" />
                    ) : (
                      name
                    )}
                  </Avatar>
                </span>
              )}
              {renderJSONExportDialog()}
              {isStudent ? (
                <div className="d-none">{renderImageExportDialog()}</div>
              ) : (
                renderImageExportDialog()
              )}
            </Stack.Row>
          </Stack.Col>
        </Island>
      </Section>
    );
  };

  const renderPDFDialog = () => {
    return (
      <PdfPageSelectionDialog
        open={appState.pdfPageSelectionDialog}
        setAppState={setAppState}
        appState={appState}
        pdfFile={appState.pdfFile || null}
        onConfirmPageSelection={async (selectedPages) => {
          const slug =
            new URLSearchParams(window.location.search).get("slug") || "";
          const lessonId =
            new URLSearchParams(window.location.search)
              .get("lessonId")
              ?.replace(/\//g, "") || "";
          const getSettingsData = await getSettingData(slug);
          const allowedDocumentsPerLesson =
            getSettingsData?.data[0]?.allowedDocumentsPerLesson;
          setAllowedDocumentsPerLesson(allowedDocumentsPerLesson);
          const res = await getLessonWisePdfImportedData(lessonId);
          const pdfCount = res?.result.length ? res.result[0]?.pdfIds : [];
          const uuid = midUuid();

          if (allowedDocumentsPerLesson !== pdfCount?.length) {
            if (selectedPages.length > 0) {
              handlePdfFile(selectedPages, uuid);
              const body = {
                userId: user?.mail,
                slug: slug,
                lessonId: lessonId,
                pdfId: uuid,
              };
              pdfImportDocumentCounter(body);
            } else {
              setOpen(true);
            }
          } else {
            setLimitExceedModal(true);
          }
        }}
      />
    );
  };

  const renderCanvasActions = () => (
    <Section
      heading="canvasActions"
      className={clsx(`zen-mode-transition`, {
        "transition-left": zenModeEnabled,
      })}
    >
      {/* the zIndex ensures this menu has higher stacking order,
         see https://github.com/excalidraw/excalidraw/pull/1445 */}

      {renderNotesPopover}
      {renderSettingPopover}
      <div id="background-color"></div>

      {!zenModeEnabled && (
        <Island padding={2} style={{ zIndex: 1 }} className="vScroll">
          <Stack.Col gap={4}>
            <Stack.Row gap={3} justifyContent="space-between">
              <span
                title={user?.mail}
                className="d-flex justify-content-center"
              >
                <Avatar
                  onClick={() => {
                    // const msg =
                    //   user?.displayName +
                    //   " clicked profile!\nYour Email is : " +
                    //   user?.mail;
                    // alert(msg);
                  }}
                  color={"#4c6ef5"}
                  border={"#4c6ef5"}
                >
                  {user?.UrlPhoto ? (
                    <img src={user?.UrlPhoto} alt={name} className="Avatar" />
                  ) : profile ? (
                    <img src={profile} alt={name} className="Avatar" />
                  ) : (
                    name
                  )}
                </Avatar>
              </span>
              <LibraryButton appState={appState} setAppState={setAppState} />
              {/* <LibraryTemplateButton
              appState={appState}
              setAppState={setAppState}
            /> */}

              <BackgroundPickerAndDarkModeToggle
                actionManager={actionManager}
                appState={appState}
                setAppState={setAppState}
                showThemeBtn={showThemeBtn}
                containerId="background-color"
              />
              {/* Uncomment when the video/audio collaboration is working perfect */}
              {notes}

              {/* <Separator /> */}
              {renderJSONExportDialog()}
              {actionManager.renderAction("loadScene")}
              {renderImageExportDialog()}
              {actionManager.renderAction("clearCanvas")}
              {renderGPTDialog()}
              {/* {renderSpinWheelDialog} */}
              {renderEditMermaidDiagramDialog}
              <div className="position-relative d-flex justify-content-center">
                {settings}
              </div>
              {/* <span
                className="ms-2 mt-2 cursor-pointer position-relative"
                onClick={() => setOpenSettingPopover(!openSettingPopover)}
              > */}
              {/* </span> */}
            </Stack.Row>
            {appState.fileHandle && (
              <>{actionManager.renderAction("saveToActiveFile")}</>
            )}
            {appState.pdfPageSelectionDialog && renderPDFDialog()}
            {open && (
              <AlertDialog
                title="Warning"
                onClose={() => {
                  setOpen(false);
                }}
                small={true}
              >
                <p>
                  You need to select at least one page to import the PDF onto
                  the canvas.
                </p>
              </AlertDialog>
            )}
            {limitExceedModal && (
              <AlertDialog
                title="Warning"
                onClose={() => {
                  setLimitExceedModal(false);
                }}
                small={true}
              >
                <p>{`Per lesson, you are only allowed to use ${allowedDocumentsPerLesson} document. Your limit has been exceeded`}</p>
                <span>
                  (Note: If you wish to import the PDF, please delete any
                  previously imported PDF from the canvas.)
                </span>
              </AlertDialog>
            )}
          </Stack.Col>
        </Island>
      )}
    </Section>
  );

  const renderSelectedShapeActions = () => (
    <Section
      heading="selectedShapeActions"
      className={clsx("zen-mode-transition", {
        "transition-left": zenModeEnabled,
      })}
    >
      <Island
        className={"App-menu__left shdw ml-6px"}
        padding={2}
        style={{
          // we want to make sure this doesn't overflow so substracting 200
          // which is approximately height of zoom footer and top left menu items with some buffer
          // if active file name is displayed, subtracting 248 to account for its height
          maxHeight: `${appState.height - (appState.fileHandle ? 248 : 200)}px`,
          left: appState.sidebarWrapper ? "15rem" : 0,
        }}
      >
        <SelectedShapeActions
          appState={appState}
          elements={elements}
          renderAction={actionManager.renderAction}
          elementType={appState.elementType}
        />
      </Island>
    </Section>
  );
  const renderTextEditor = useCallback(() => {
    const { open, sceneX, sceneY, value } = appState.textEditor;

    let top;
    let left;

    if (sceneX || sceneY) {
      const { x: viewportX, y: viewportY } = sceneCoordsToViewportCoords(
        {
          sceneX,
          sceneY,
        },
        appState,
      );

      top = viewportY;
      left = viewportX;
    }
    return (
      open && (
        <div
          style={{
            top: `${top ? top + "px" : "50%"}`,
            left: `${left + "px" || "50%"}`,
            position: "absolute",
          }}
        >
          <TiptapEditor
            startTextEditing={startTextEditing}
            appState={appState}
            onTextWithStylesAction={onTextWithStylesAction}
            setAppState={setAppState}
            top={top || 0}
            left={left || 0}
          />
        </div>
      )
    );
  }, [
    appState.textEditor,
    appState.updatingTextElement,
    onTextWithStylesAction,
    setAppState,
    startTextEditing,
  ]);

  const setFontSizeByDivision = (
    mathFormulaValue: string | null | undefined,
  ) => {
    if (mathfieldRef.current) {
      if (!mathFormulaValue) return;
      const count = (mathFormulaValue.match(/\\frac/g) || []).length;
      mathfieldRef.current.setAttribute(
        "style",
        `font-size: ${16 + count * 1.7 + "px"}`,
      );
    }
  };

  const onChangeFormulaString = (
    e: React.SyntheticEvent<HTMLInputElement, MathFieldChangeEvent>,
  ) => {
    const mathFormulaValue = e.currentTarget.value;
    setFormulaInnerValue(mathFormulaValue);
    setFontSizeByDivision(mathFormulaValue);
    setAppState({
      selectedElementIds: {
        [Object.keys(appState.selectedElementIds)[0]]: true,
      },
    });
    mathFormulaValueRef.current = e.currentTarget.value;
    // mathFormulaValueRef.current = formulaInnerValue;
  };

  const renderFormulaTextBox = () => (
    <Section
      key="react-math-input-container"
      heading="mathFormulaInput"
      className="math-formula-container d-flex flex-column"
    >
      <div
        className="math-modal-layer"
        onClick={() => {
          closeMathInput();
        }}
      ></div>
      <div className="math-modal-wrapper">
        <div>
          <h5 className="modal-header p-0 border-0 text-center">
            {t("formula.title")}
          </h5>
        </div>
        {/* {!appState.updatingFormulaElementId ? ( */}
        <ToolButton
          key="newPage"
          type={ToolButtonEnum.BUTTON}
          aria-label="close"
          className={`position-absolute closeIcon`}
          icon={close}
          size="medium"
          onClick={() => {
            closeMathInput();
          }}
        ></ToolButton>

        <div className="math-formula-wrapper">
          <MathView
            key={"react-math-input"}
            className="react-math-field mx-auto d-block"
            value={appState.formulaValue}
            onChange={onChangeFormulaString}
            ref={mathfieldRef}
            onLoad={() => console.log("math field input loaded")}
          />
        </div>
        {/* {!appState.updatingFormulaElementId ? ( */}
        <ToolButton
          className="bg-brand text-white mt-3 w-fit-content save-formula-btn mx-auto"
          key="save formula"
          type={ToolButtonEnum.BUTTON}
          title="Save"
          aria-label="Save"
          onClick={async () => {
            onMathFormulaAction(mathfieldRef, formulaInnerValue);
            const divElement = document.querySelector(
              ".ML__keyboard.is-math-mode.can-paste.is-visible",
            );
            if (divElement) {
              // @ts-ignore
              mathfieldRef.current?.executeCommand("toggleVirtualKeyboard");
            }
          }}
        >
          <span>{t("formula.button")}</span>
        </ToolButton>
        {/* ) : (
          <div className="pt-5"></div>
        )} */}
      </div>
    </Section>
  );

  const getWhiteboardLink = () => {
    const slug =
      new URLSearchParams(window.location.search)
        .get("slug")
        ?.replace(/\//g, "") || "";
    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";

    const isCollaborating = localStorage.getItem("isCollaborating");
    const roomData = JSON.parse(localStorage.getItem("roomLinkData") || "{}");

    const whiteBoardLink =
      isCollaborating && roomData
        ? `${process.env.REACT_APP_URI}?slug=${slug}&lessonId=${lessonId}&W&F/#room=${roomData.roomId},${roomData.roomKey}`
        : `${process.env.REACT_APP_URI}?slug=${slug}&lessonId=${lessonId}&W&F`;

    return whiteBoardLink;
  };

  const renderWhiteboardModel = () => (
    <Section
      key="react-math-input-container"
      heading="mathFormulaInput"
      className="math-formula-container d-flex flex-column"
    >
      <div
        className="math-modal-layer"
        onClick={() => {
          setAppState({ whiteboardModel: false });
        }}
      ></div>
      <div className="math-modal-wrapper">
        <div>
          <h5 className="modal-header p-0 border-0 text-center ">
            {t("whiteBoardModel.title")}
          </h5>
        </div>
        <p className="mb-0 my-2">{t("whiteBoardModel.message")}</p>
        <ToolButton
          key="close"
          type={ToolButtonEnum.BUTTON}
          aria-label="close"
          className={`position-absolute closeIcon`}
          icon={close}
          size="medium"
          onClick={() => {
            setAppState({ whiteboardModel: false, elementType: "selection" });
          }}
        ></ToolButton>
        <div className="d-flex justify-content-end">
          <ToolButton
            className="cancel-btn bg-danger text-white mt-3 w-fit-content save-formula-btn m-0 me-2"
            key={t("whiteBoardModel.cancelBtn")}
            type={ToolButtonEnum.BUTTON}
            title={t("whiteBoardModel.cancelBtn")}
            aria-label={t("whiteBoardModel.cancelBtn")}
            onClick={() => {
              setAppState({ whiteboardModel: false, elementType: "selection" });
            }}
          >
            <span>{t("whiteBoardModel.cancelBtn")}</span>
          </ToolButton>
          <ToolButton
            className="bg-brand text-white mt-3 w-fit-content save-formula-btn m-0 "
            key={t("whiteBoardModel.okBtn")}
            type={ToolButtonEnum.BUTTON}
            title={t("whiteBoardModel.okBtn")}
            aria-label={t("whiteBoardModel.okBtn")}
            onClick={() => {
              window.open(getWhiteboardLink(), "_blank");
              localStorage.setItem("openFormula", "true");
              localStorage.setItem("aOpenFormula", "true");
              setAppState({ whiteboardModel: false, elementType: "selection" });
            }}
          >
            <span>{t("whiteBoardModel.okBtn")}</span>
          </ToolButton>
        </div>
      </div>
    </Section>
  );

  const onPublishLibSuccess = useCallback(
    (data: LibraryData, libraryItems: LibraryItems) => {
      setShowPublishLibraryDialog(false);
      setPublishLibSuccess({ url: data.url, authorName: data.authorName });
      const nextLibItems = [...libraryItems];
      nextLibItems.forEach((libItem) => {
        libItem.status = "unpublished";
      });
      library.saveLibrary(nextLibItems);
      setLibraryItems(nextLibItems);
      setAppState({ isLibraryOpen: true });
    },
    [
      setShowPublishLibraryDialog,
      setPublishLibSuccess,
      libraryItems,
      // selectedItems,
      library,
    ],
  );

  const editCollection = useCallback(
    (libraryItems: LibraryItems) => {
      const nextLibItems = [...libraryItems];
      nextLibItems.forEach((libItem) => {
        libItem.status = "unpublished";
      });
      library.saveLibrary(nextLibItems);
      setLibraryItems(nextLibItems);
      setAppState({ isLibraryOpen: true });
    },
    [
      libraryItems,
      // selectedItems,
      library,
    ],
  );

  const closeLibrary = useCallback(() => {
    const isDialogOpen = !!document.querySelector(".Dialog");

    // Prevent closing if any dialog is open
    if (isDialogOpen) {
      return;
    }
    setAppState({ isLibraryOpen: false });
  }, [setAppState]);

  const closeVideoRecorder = useCallback(() => {
    setAppState({ isOpenVideoRecorder: false, isOpenAudioRecorder: false });
  }, [setAppState]);

  const deselectItems = useCallback(() => {
    setAppState({
      selectedElementIds: {},
      selectedGroupIds: {},
    });
  }, [setAppState]);

  const renderNotesPopover = useMemo(() => {
    return (
      showPopover && (
        <div
          style={
            showPopover && !isMobile
              ? {
                  // transition: "all 0.3s",
                  display: "block",
                  left: isMobile ? 0 : 10,
                  top: `${
                    notesRef.current
                      ? `${
                          notesRef.current?.getBoundingClientRect().top +
                          notesRef.current?.getBoundingClientRect().height / 2
                        }px`
                      : "50%"
                  }`,
                }
              : isMobile && showPopover
              ? { display: "block" }
              : { display: "none" }
          }
          className={isMobile ? "popover-mobile" : "popover"}
        >
          <div
            className={`text-nowrap d-flex justify-content-center align-items-center rounded notes ${
              !canUploadMedia.video ? "disabled" : ""
            }`}
            onClick={() => {
              if (canUploadMedia.video) {
                setAppState({
                  isOpenVideoRecorder: true,
                  isOpenAudioRecorder: false,
                });
                setShowPopover(false);
              }
            }}
            data-tip="React-tooltip"
            data-for="video-tooltip"
          >
            <span className="ms-2">{videoIcon}</span>
            <span className="fs-6 px-3 py-2">{t("notes.video.title")}</span>
            {!canUploadMedia.video && (
              <ReactTooltip
                place="bottom"
                effect="solid"
                multiline={true}
                id="video-tooltip"
              >
                You've exceeded the limit. <br />
                You're allowed to upload a maximum of{" "}
                {subscription && subscription.videoLimit.toString()} videos per
              </ReactTooltip>
            )}
          </div>
          <div
            className={`text-nowrap d-flex justify-content-center align-items-center rounded mt-2 notes ${
              !canUploadMedia.audio ? "disabled" : ""
            }`}
            data-tip="React-tooltip"
            data-for="audio-tooltip"
            onClick={() => {
              if (canUploadMedia.audio) {
                setAppState({
                  isOpenAudioRecorder: true,
                  isOpenVideoRecorder: false,
                });
                setShowPopover(false);
              }
            }}
          >
            <span className="ms-2">{audioIcon}</span>
            <span className="fs-6 px-3 py-2">{t("notes.audio.title")}</span>
            {!canUploadMedia.audio && (
              // @ts-ignore
              <ReactTooltip
                place="bottom"
                effect="solid"
                multiline={true}
                id="audio-tooltip"
              >
                You've exceeded the limit. <br />
                You're allowed to upload a maximum of{" "}
                {subscription && subscription.audioLimit.toString()} audios per
                page.
              </ReactTooltip>
            )}
          </div>
        </div>
      )
    );
  }, [showPopover, notesRef, isMobile]);

  const libraryMenu = appState.isLibraryOpen ? (
    <LibraryMenu
      pendingElements={getSelectedElements(elements, appState)}
      onClose={closeLibrary}
      onInsertShape={onInsertElements}
      onAddToLibrary={deselectItems}
      setAppState={setAppState}
      libraryReturnUrl={libraryReturnUrl}
      focusContainer={focusContainer}
      library={library}
      theme={appState.theme}
      files={files}
      id={id}
      appState={appState}
      insertImageOnCanvas={onImageActionFromLibrary}
      insertVideoOnCanvas={onVideoActionFromLibrary}
      insertAudioOnCanvas={onAudioActionFromLibrary}
      setShowPublishLibraryDialog={setShowPublishLibraryDialog}
      showPublishLibraryDialog={showPublishLibraryDialog}
      setPublishLibSuccess={setPublishLibSuccess}
      publishLibSuccess={publishLibSuccess}
      setLibraryItems={setLibraryItems}
      libraryItems={libraryItems}
      togglekeyboardShortcut={togglekeyboardShortcut}
      handleSpinnerDialog={handleSpinnerDialog}
      subscription={subscription}
      elements={elements}
      importCustomCategory={importCustomCategory}
      editCollection={editCollection}
      importLibraryFromUrl={importLibraryFromUrl}
      addToCollection={addToCollection}
      setAddToCollection={setAddToCollection}
      deleteElelement={deleteElelement}
      setDeleteElement={setDeleteElement}
      resetScene={resetScene}
    />
  ) : null;

  videoRecorder = appState.isOpenVideoRecorder ? (
    <VideoRecorder
      onClose={closeVideoRecorder}
      insertVideoOnCanvas={onVideoActionFromLibrary}
      subscription={subscription}
    />
  ) : null;

  audioRecorder = appState.isOpenAudioRecorder ? (
    <AudioRecorder
      onClose={closeVideoRecorder}
      insertAudioOnCanvas={onAudioActionFromLibrary}
      subscription={subscription}
    />
  ) : null;

  notes = (
    <div
      style={{ position: isMobile ? "absolute" : "relative" }}
      className={`${isMobile ? "notes-icon" : ""}`}
      ref={notesRef}
    >
      <button type="button" className="tool-button" onClick={handleButtonClick}>
        <ToolButton
          type={ToolButtonEnum.ICON}
          icon={recording}
          title={t("toolBar.video-audio-notes")}
          aria-label={t("toolBar.video-audio-notes")}
        />
      </button>
      {isMobile && renderNotesPopover}
    </div>
  );

  const libraryTemplateMenu = appState.isLibraryOpen ? (
    <LibraryMenu
      pendingElements={getSelectedElements(elements, appState)}
      onClose={closeLibrary}
      onInsertShape={onInsertElements}
      onAddToLibrary={deselectItems}
      setAppState={setAppState}
      libraryReturnUrl={libraryReturnUrl}
      focusContainer={focusContainer}
      library={library}
      theme={appState.theme}
      files={files}
      id={id}
      appState={appState}
      insertImageOnCanvas={onImageActionFromLibrary}
      insertVideoOnCanvas={onVideoActionFromLibrary}
      insertAudioOnCanvas={onAudioActionFromLibrary}
      setShowPublishLibraryDialog={setShowPublishLibraryDialog}
      showPublishLibraryDialog={showPublishLibraryDialog}
      setPublishLibSuccess={setPublishLibSuccess}
      publishLibSuccess={publishLibSuccess}
      setLibraryItems={setLibraryItems}
      libraryItems={libraryItems}
      togglekeyboardShortcut={togglekeyboardShortcut}
      handleSpinnerDialog={handleSpinnerDialog}
      subscription={subscription}
      elements={elements}
      importCustomCategory={importCustomCategory}
      editCollection={editCollection}
      importLibraryFromUrl={importLibraryFromUrl}
      addToCollection={addToCollection}
      setAddToCollection={setAddToCollection}
      deleteElelement={deleteElelement}
      setDeleteElement={setDeleteElement}
      resetScene={resetScene}
    />
  ) : null;

  libraryTemplateMenu2 = appState.isShowLibraryTemplate && (
    <>
      {togglekeyboardShortcut(false)}
      <LibraryDialog
        onInsertShape={onInsertElements}
        insertImageOnCanvas={onImageActionFromLibrary}
        insertVideoOnCanvas={onVideoActionFromLibrary}
        insertAudioOnCanvas={onAudioActionFromLibrary}
        setAppState={setAppState}
        appState={appState}
        onClose={() => {
          setAppState({ isShowLibraryTemplate: false });
        }}
        editCollection={editCollection}
        elements={elements}
        subscription={subscription}
        setLibraryItems={function (val: LibraryItems): void {
          throw new Error("Function not implemented.");
        }}
        libraryItems={[]}
        importLibraryFromUrl={importLibraryFromUrl}
      />
    </>
  );

  const renderFixedSideContainer = () => {
    const shouldRenderSelectedShapeActions = showSelectedShapeActions(
      appState,
      elements,
    );
    const dateString = lesson.LessonDate;
    const indexOfT = dateString.indexOf("T");
    const dateWithoutTime = dateString.substring(0, indexOfT);

    return (
      <NotesTitleContext.Provider
        value={{
          fileName: appState.notesTitle,
          color: appState.notesColor,
          onColorChange: (color) => {
            setAppState({
              ...appState,
              notesColor: color,
            });
          },
          onFileNameChange: (title) => {
            setAppState({
              ...appState,
              notesTitle: title,
            });
          },
        }}
      >
        <FixedSideContainer side="top">
          <div className="App-menu App-menu_top">
            <Stack.Col
              gap={4}
              className={clsx({ "disable-pointerEvents": zenModeEnabled })}
            >
              {/* {!isStudent && (
                <> */}
              <button
                className="bg-brand px-3 py-2 text-white pageBtn"
                onClick={() => {
                  setAppState({ sidebarWrapper: true });
                }}
              >
                {t("multipage.pageNumber")}
                {" " + appState.currentPage}
              </button>

              {/* </> */}
              {/* )} */}
              <SideBar
                appState={appState}
                onPageChange={onPageChange}
                onPageDelete={onPageDelete}
                setAppState={(
                  appState:
                    | AppState
                    | ((
                        prevState: Readonly<AppState>,
                        props: Readonly<any>,
                      ) => AppState | Pick<AppState, keyof AppState> | null)
                    | Pick<AppState, keyof AppState>
                    | null,
                ) => setAppState(appState)}
                isStudent={isStudent}
              />

              {/* {viewModeEnabled
              ? renderViewModeCanvasActions()
              : renderCanvasActions()} */}
              {shouldRenderSelectedShapeActions && renderSelectedShapeActions()}
            </Stack.Col>

            {((!viewModeEnabled && !isStudent) || isJoinCollaboration) && (
              <>
                <Section heading="shapes" className="top-menu-section">
                  {(heading: string) => (
                    <Stack.Col
                      gap={5}
                      align="start"
                      className="d-flex align-items-center"
                    >
                      <Stack.Row gap={1} className="shdw fit-content">
                        <LockButton
                          zenModeEnabled={zenModeEnabled}
                          checked={appState.elementLocked}
                          onChange={onLockToggle}
                          title={t("toolBar.lock")}
                        />
                        <Island
                          padding={1}
                          className={clsx({ "zen-mode": zenModeEnabled })}
                        >
                          <HintViewer
                            appState={appState}
                            elements={elements}
                            isMobile={isMobile}
                            library={library}
                            setLibraryItems={setLibraryItems}
                            focusContainer={focusContainer}
                            setAddToCollection={setAddToCollection}
                            setDeleteElement={setDeleteElement}
                            setAppState={setAppState}
                            resetScene={resetScene}
                          />
                          {heading}
                          <Stack.Row gap={1}>
                            <ShapesSwitcher
                              canvas={canvas}
                              elementType={appState.elementType}
                              setAppState={setAppState}
                              onImageAction={({ pointerType }) => {
                                onImageAction({
                                  insertOnCanvasDirectly:
                                    pointerType !== "mouse",
                                });
                              }}
                              appState={appState}
                              togglekeyboardShortcut={togglekeyboardShortcut}
                              toggleZoomInZoomOut={toggleZoomInZoomOut}
                              isStudent={isStudent}
                              isJoinCollaboration={isJoinCollaboration}
                            />
                          </Stack.Row>
                        </Island>

                        {onCollabButtonClick && (
                          <>
                            {!isStudent && (
                              <>
                                <Island
                                  padding={1}
                                  className={`${
                                    !isCollaborating_ ? "pl-0 px-3" : ""
                                  } ${clsx({
                                    "zen-mode collabBtnIsland ":
                                      zenModeEnabled + " collabBtnIsland",
                                  })}`}
                                >
                                  <CollabButton
                                    isCollaborating={isCollaborating_}
                                    collaboratorCount={
                                      appState.collaborators.size
                                    }
                                    onClick={onCollabButtonClick}
                                  />
                                </Island>
                              </>
                            )}
                            {!isStudent && isCollaboratingWithFlexibleGroups && (
                              <>
                                <Island
                                  padding={1}
                                  className={clsx({
                                    "zen-mode collabBtnIsland":
                                      zenModeEnabled + " collabBtnIsland",
                                  })}
                                >
                                  <FlexibleGroupButton
                                    onClick={onFlexibleGroupButtonClick}
                                  />
                                </Island>
                              </>
                            )}

                            {!isStudent && isCollaborating_ && (
                              <Island
                                padding={1}
                                className={clsx({
                                  "zen-mode collabBtnIsland p-1":
                                    zenModeEnabled + " collabBtnIsland",
                                })}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary d-none"
                                  id="collaborationBtn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#collaboration"
                                >
                                  Collaboration
                                </button>
                                <ToolButton
                                  type={ToolButtonEnum.BUTTON}
                                  icon={users}
                                  onClick={() => {
                                    const lessonId =
                                      new URLSearchParams(
                                        window.location.search,
                                      )
                                        .get("lessonId")
                                        ?.replace(/\//g, "") || "";
                                    const slug =
                                      new URLSearchParams(
                                        window.location.search,
                                      )
                                        .get("slug")
                                        ?.replace(/\//g, "") || "";
                                    getStudents(
                                      `${ACV_API_URL}/api/record/get-student-record-for-whiteBaord/${lessonId}?slug=${slug}`,
                                    ).then(async (res) => {
                                      const studList = res?.result;
                                      const studs = localStorage.getItem(
                                        "students",
                                      )
                                        ? JSON.parse(
                                            localStorage.getItem("students") ||
                                              "",
                                          )
                                        : [];
                                      const students = await studList.map(
                                        (stud: any) => {
                                          const student =
                                            studs.length > 0 &&
                                            studs.find(
                                              (data: { studEmail: string }) =>
                                                data.studEmail ===
                                                stud.StudentEmailId,
                                            );
                                          console.log(
                                            "student ",
                                            student.isWhiteboard,
                                          );
                                          if (student.isWhiteboard === 2) {
                                            setStudentMessage({
                                              ...studentSessionEndMessage,
                                            });
                                          } else if (
                                            student.isWhiteboard === false
                                          ) {
                                            setStudentMessage({
                                              ...studentAccessRevokeMessage,
                                            });
                                          }

                                          return {
                                            studEmail: stud.StudentEmailId,
                                            studName: stud.StudentName,
                                            photo: `${stud.StudentPhoto.Foto}?${res?.SASToken}`,
                                            isWhiteboard: student
                                              ? student.isWhiteboard !== 2
                                                ? student.isWhiteboard
                                                : true
                                              : true,
                                            idle: false,
                                          };
                                        },
                                      );
                                      window.localStorage.setItem(
                                        "students",
                                        JSON.stringify(students),
                                      );
                                    });
                                    document
                                      .getElementById("collaborationBtn")
                                      ?.click();
                                  }}
                                  title={`Collaborators`}
                                  aria-label={`Collaborators`}
                                />
                              </Island>
                            )}
                          </>
                        )}
                      </Stack.Row>
                      {/* {libraryMenu} */}

                      {libraryTemplateMenu2}
                      {videoRecorder}
                      {audioRecorder}
                      {showPublishLibraryDialog && (
                        <PublishLibrary
                          onClose={() => setShowPublishLibraryDialog(false)}
                          libraryItems={appState.editingLibrary.libraryItems}
                          appState={appState}
                          onSuccess={(data: LibraryData) => {
                            onPublishLibSuccess(
                              data,
                              appState.editingLibrary.libraryItems,
                            );
                            removeOldLibraryElements();
                            setShowPublishLibraryDialog(false);
                          }}
                          onError={(error) => window.alert(error)}
                          updateItemsInStorage={() =>
                            library.saveLibrary(libraryItems)
                          }
                          onRemove={(id: string) => {
                            // setAppState({
                            //   editingLibrary: {
                            //     ...appState.editingLibrary,
                            //     libraryItems: appState.editingLibrary.libraryItems.filter(
                            //       (libraryItem) => libraryItem.id !== id,
                            //     ),
                            //   },
                            // })
                          }}
                          files={files}
                        />
                      )}
                      {/* {appState.editingLibrary.isEditing && (
                        <div>
                          <button
                            type="button"
                            className="border border-danger px-3 py-2 me-2 text-danger"
                            onClick={removeOldLibraryElements}
                          >
                            Cancel
                          </button>
                          <button
                            className="bg-brand px-3 py-2 text-white"
                            onClick={() => {
                              setShowPublishLibraryDialog(true);
                            }}
                          >
                            Save
                          </button>
                        </div>
                      )} */}
                    </Stack.Col>
                  )}
                </Section>
                {renderTextEditor()}
                {/* {appState.openTextEditor &&
                  appState.editingElement &&
                  isTextElement(appState.editingElement) &&
                  renderTextEditor()} */}
                {appState.showMathInputAndKeyboard && renderFormulaTextBox()}
              </>
            )}

            {!zenModeEnabled && (
              <div>
                <div className={`sidebar ${openSidebar ? "open" : ""}`}>
                  <Section heading="canvasActions" className="fixedBtmLsn">
                    <div
                      className="pin d-flex"
                      onClick={() => setPinSidebar(!pinSidebar)}
                    >
                      {pinSidebar ? pinFill : pin}
                    </div>
                    <Island padding={1}>
                      {(role ? role + " : " : "Student : ") + displayName}
                    </Island>
                    <Island padding={1}>
                      {lesson.ClassName
                        ? "Class: " + lesson?.ClassName
                        : "Collaboration mode"}
                    </Island>
                    <Island padding={1}>
                      {lesson?.LessonDate
                        ? "LessonDate: " + dateWithoutTime
                        : "Collaboration mode"}
                    </Island>
                  </Section>
                </div>

                <button
                  className="arrow-button"
                  onClick={() => setOpenSidebar(!pinSidebar && !openSidebar)}
                >
                  <span className={`arrow-icon ${openSidebar ? "open" : ""}`}>
                    ➤
                  </span>
                </button>
              </div>
            )}

            {isStudent && (
              <>
                <div className="fixedTopProfile" title={user.mail}>
                  <Avatar
                    onClick={() => {}}
                    color={"#4c6ef5"}
                    border={"#4c6ef5"}
                  >
                    {user?.UrlPhoto ? (
                      <img src={user?.UrlPhoto} alt={name} className="Avatar" />
                    ) : profile ? (
                      <img src={profile} alt={name} className="Avatar" />
                    ) : (
                      name
                    )}
                  </Avatar>
                  {isJoinCollaboration && (
                    <>
                      <LibraryButton
                        appState={appState}
                        setAppState={setAppState}
                      />
                      <LibraryTemplateButton
                        appState={appState}
                        setAppState={setAppState}
                      />
                    </>
                  )}

                  {renderViewModeCanvasActions()}
                  {/* <ToolButton
                      className="Card-button"
                      icon={exportImage}
                      type="button"
                      aria-label={t(
                        "whiteBoard.actionBar.export.saveAsImage.title",
                      )}
                      title={t("buttons.exportImage")}
                      showAriaLabel={false}
                      onClick={() => {
                        document.getElementById("exportAsImage")?.click();
                      }}
                    /> */}
                  {isJoinCollaboration === false && (
                    <ShapesSwitcher
                      canvas={canvas}
                      elementType={appState.elementType}
                      setAppState={setAppState}
                      onImageAction={({ pointerType }) => {
                        onImageAction({
                          insertOnCanvasDirectly: pointerType !== "mouse",
                        });
                      }}
                      appState={appState}
                      togglekeyboardShortcut={togglekeyboardShortcut}
                      toggleZoomInZoomOut={toggleZoomInZoomOut}
                      isStudent={isStudent}
                      isJoinCollaboration={isJoinCollaboration}
                    />
                  )}
                </div>
                <div className="d-flex h-100">{libraryMenu}</div>
              </>
            )}

            {isStudent ? (
              <div className="students">
                <Section heading="canvasActions" className="fixedBtm">
                  <Island padding={2}>
                    <Portall containerId="zoom_btn">
                      <ZoomActions
                        renderAction={actionManager.renderAction}
                        zoom={appState.zoom}
                      />
                    </Portall>
                  </Island>
                </Section>
              </div>
            ) : (
              <div
                className="d-flex right-side-panel h-100"
                style={{ position: "absolute" }}
              >
                {libraryTemplateMenu}

                <div
                  className={clsx(
                    "layer-ui__wrapper__top-right zen-mode-transition w80px justifycenter",
                    {
                      "transition-right": zenModeEnabled,
                    },
                  )}
                >
                  <Stack.Col
                    gap={10}
                    style={{ margin: "15vh" }}
                    className={clsx({
                      "disable-pointerEvents m15vh": zenModeEnabled,
                    })}
                  >
                    {viewModeEnabled
                      ? renderViewModeCanvasActions()
                      : renderCanvasActions()}
                    <Section heading="canvasActions" className="fixedBtm">
                      <Island padding={2}>
                        <Portall containerId="zoom_btn">
                          <ZoomActions
                            renderAction={actionManager.renderAction}
                            zoom={appState.zoom}
                          />
                        </Portall>
                      </Island>
                    </Section>
                  </Stack.Col>
                </div>
              </div>
            )}
          </div>
        </FixedSideContainer>
      </NotesTitleContext.Provider>
    );
  };

  const renderBottomAppMenu = () => {
    return (
      <footer
        role="contentinfo"
        className="layer-ui__wrapper__footer App-menu App-menu_bottom"
      >
        <div
          className={clsx(
            "layer-ui__wrapper__footer-left zen-mode-transition",
            {
              "layer-ui__wrapper__footer-left--transition-left": zenModeEnabled,
            },
          )}
        >
          <Stack.Col gap={2}>
            <Section heading="canvasActions">
              {/* <Island padding={1}>
                <ZoomActions
                  renderAction={actionManager.renderAction}
                  zoom={appState.zoom}
                />
              </Island> */}
              {!viewModeEnabled && (
                <>
                  <div
                    className={clsx(
                      "undo-redo-buttons zen-mode-transition pb-2",
                      {
                        "layer-ui__wrapper__footer-left--transition-bottom": zenModeEnabled,
                      },
                    )}
                  >
                    {!isCollaborating_ &&
                      actionManager.renderAction("undo", { size: "small" })}
                    {!isCollaborating_ &&
                      actionManager.renderAction("redo", { size: "small" })}
                    {/* <ToolButton
                    className="bg-brand text-white pr-2"
                    key="moreTools"
                    type="button"
                    title={"More Tools"}
                    aria-label={"More Tools"}
                    icon={moreTools}
                    onClick={() => {
                      alert("Fuctionality will be added soon!");
                    }}
                  >
                    <span>More Tools</span>
                  </ToolButton> */}
                    {!user.role && <SignOutButton />}
                  </div>
                  {isMobileView && <div id="zoom_btn"></div>}
                </>
              )}
            </Section>
          </Stack.Col>
        </div>
        {/* <div
          className={clsx(
            "layer-ui__wrapper__footer-center zen-mode-transition",
            {
              "layer-ui__wrapper__footer-left--transition-bottom": zenModeEnabled,
            },
          )}
        >
          {renderCustomFooter?.(false, appState)}
        </div> */}
        {/* <div
          className={clsx(
            "layer-ui__wrapper__footer-right zen-mode-transition",
            {
              "transition-right disable-pointerEvents": zenModeEnabled,
            },
          )}
        >
          {actionManager.renderAction("toggleShortcuts")}
        </div> */}
        <button
          className={clsx("disable-zen-mode", {
            "disable-zen-mode--visible": showExitZenModeBtn,
          })}
          onClick={toggleZenMode}
        >
          {t("buttons.exitZenMode")}
        </button>
      </footer>
    );
  };

  const dialogs = (
    <>
      {appState.isLoading && <LoadingMessage />}
      {appState.errorMessage && (
        <ErrorDialog
          message={appState.errorMessage}
          onClose={() => setAppState({ errorMessage: null })}
        />
      )}
      {appState.showHelpDialog && (
        <HelpDialog
          onClose={() => {
            setAppState({ showHelpDialog: false });
          }}
        />
      )}
      {/* {appState.pasteDialog.shown && (
        <PasteChartDialog
          setAppState={setAppState}
          appState={appState}
          onInsertChart={onInsertElements}
          onClose={() =>
            setAppState({
              pasteDialog: { shown: false, data: null },
            })
          }
        />
      )} */}
    </>
  );

  const renderPublishSuccess = useCallback(() => {
    return (
      <Dialog
        onCloseRequest={() => setPublishLibSuccess(null)}
        title={t("publishSuccessDialog.title")}
        className="publish-library-success"
        small={true}
        children={
          <>
            <p>
              {t("publishSuccessDialog.content", {
                authorName: publishLibSuccess!.authorName,
              })}{" "}
            </p>
            <ToolButton
              type={ToolButtonEnum.BUTTON}
              title={t("buttons.close")}
              aria-label={t("buttons.close")}
              label={t("buttons.close")}
              onClick={() => setPublishLibSuccess(null)}
              data-testid="publish-library-success-close"
              className="publish-library-success-close"
            />
          </>
        }
        closeOnClickOutside={false}
        open={false}
        setOpen={() => false}
      />
    );
  }, [setPublishLibSuccess, publishLibSuccess]);

  return isMobile ? (
    <div className="mobile-menu">
      {appState.showMathInputAndKeyboard && renderFormulaTextBox()}
      {renderTextEditor()}
      {appState.whiteboardModel && renderWhiteboardModel()}
      {appState.pdfPageSelectionDialog && renderPDFDialog()}

      {dialogs}
      <MobileMenu
        appState={appState}
        elements={elements}
        actionManager={actionManager}
        libraryMenu={libraryMenu}
        renderJSONExportDialog={renderJSONExportDialog}
        renderImageExportDialog={renderImageExportDialog}
        renderGPTDialog={renderGPTDialog}
        renderEditMermaidDiagramDialog={renderEditMermaidDiagramDialog}
        setAppState={setAppState}
        onCollabButtonClick={onCollabButtonClick}
        onLockToggle={onLockToggle}
        canvas={canvas}
        isCollaborating={isCollaborating_}
        renderCustomFooter={renderCustomFooter}
        viewModeEnabled={viewModeEnabled}
        showThemeBtn={showThemeBtn}
        renderTopRightUI={renderTopRightUI}
        isStudent={isStudent}
        onImageAction={onImageAction}
        togglekeyboardShortcut={togglekeyboardShortcut}
        onFlexibleGroupButtonClick={onFlexibleGroupButtonClick}
        isCollaboratingWithFlexibleGroups={isCollaboratingWithFlexibleGroups}
        zenModeEnabled={zenModeEnabled}
        sidebarWrapper={sidebarWrapper}
        onPageChange={onPageChange}
        onPageDelete={onPageDelete}
        toggleZoomInZoomOut={toggleZoomInZoomOut}
        renderSpinWheelDialog={renderSpinWheelDialog}
        library={library}
        setLibraryItems={setLibraryItems}
        focusContainer={focusContainer}
        setAddToCollection={setAddToCollection}
        setDeleteElement={setDeleteElement}
        resetScene={resetScene}
      />
    </div>
  ) : (
    <div
      className={clsx("layer-ui__wrapper", {
        "disable-pointerEvents":
          appState.draggingElement ||
          appState.resizingElement ||
          (appState.editingElement && !isTextElement(appState.editingElement)),
      })}
    >
      {dialogs}
      {isStudent && (
        <div className="m-3" style={{ position: "absolute", left: 0, top: 0 }}>
          {renderViewModeCanvasActions()}
        </div>
      )}
      {renderFixedSideContainer()}
      {renderBottomAppMenu()}
      {publishLibSuccess && renderPublishSuccess()}
      {appState.scrolledOutside && (
        <button
          className="scroll-back-to-content"
          onClick={() => {
            setAppState({
              ...calculateScrollCenter(elements, appState, canvas),
            });
          }}
        >
          {t("buttons.scrollBackToContent")}
        </button>
      )}
    </div>
  );
};

const areEqual = (prev: LayerUIProps, next: LayerUIProps) => {
  const getNecessaryObj = (appState: AppState): Partial<AppState> => {
    const {
      suggestedBindings,
      startBoundElement: boundElement,
      ...ret
    } = appState;
    return ret;
  };
  const prevAppState = getNecessaryObj(prev.appState);
  const nextAppState = getNecessaryObj(next.appState);

  const keys = Object.keys(prevAppState) as (keyof Partial<AppState>)[];
  return (
    prev.renderCustomFooter === next.renderCustomFooter &&
    prev.langCode === next.langCode &&
    prev.elements === next.elements &&
    prev.files === next.files &&
    keys.every((key) => prevAppState[key] === nextAppState[key])
  );
};

export default React.memo(LayerUI, areEqual);
