import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  attachmentIcon,
  reverseIcon,
  rightArrowIcon,
  upArrowIcon,
} from "../../../icons";
import "./styles.scss";
import { PreDefinesThemes } from "./components/preDefinesThemes";
import axios from "axios";
import { SpinnerContext } from "../../contexts";
import { useIsMobile } from "../../../App";
import { AttachementModal } from "./components/attachementModal";
import Portall from "../../../../excalidraw-app/collab/Portall";

interface Props {
  results: string[];
  onTextWithStylesAction: ({
    textString,
    show,
    handleCloseDialogue,
    isAIGenerated,
    width,
  }: {
    textString: string;
    show?: boolean;
    handleCloseDialogue?: VoidFunction;
    isAIGenerated?: boolean;
    width?: number;
  }) => void;
  onClose: () => void;
  insertImageOnCanvas: (file: File) => void;
  wheelImage: File;
  setMagicChat: (data: string) => void;
  magicChat: string;
  language: string;
  suggestionsPrompt: string[];
  setSuggestionsPrompt: React.Dispatch<React.SetStateAction<string[]>>;
}

const preDefinesData = [
  "Tell me something fun to discuss at class about ",
  "Give me 3 reflecting questions about",
  "Write 3 fin riddles about ",
  "Let´s talk about facts related to ",
];

export const MagicOutput = ({
  results,
  onTextWithStylesAction,
  onClose,
  insertImageOnCanvas,
  wheelImage,
  setMagicChat,
  magicChat,
  language,
  suggestionsPrompt,
  setSuggestionsPrompt,
}: Props) => {
  const { segments, timeDuration, isTimer } = useContext(SpinnerContext);
  const [selectedResult, setSelectedResult] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingMoreSuggestion, setIsMoreSuggestion] = useState<boolean>(
    false,
  );
  const [preDefinesThemes, setPreDefinesThemes] = useState<string[]>([
    "Tell me something fun to discuss at class about ",
    "Give me 3 reflecting questions about",
    "Write 3 fin riddles about ",
    "Let´s talk about facts related to ",
  ]);
  const [responsePrompt, setResponsePrompt] = useState<string>("");
  const [userPrompt, setUserPrompt] = useState<string>("");
  const [userChat, setUserChat] = useState<string>("");

  const [isInclude, setIsInclude] = useState<boolean>(false);
  const responsePromptRef = useRef<HTMLDivElement>(null);
  const inputPromptRef = useRef<HTMLTextAreaElement>(null);
  const [promptHeight, setPromptHeight] = useState<number>(50);
  const [isChat, setIsChat] = useState<boolean>(false);
  const [morePrompt, setMorePrompt] = useState<string>("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownVisibleChat, setDropdownVisibleChat] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [mentions, setMentions] = useState([...results]);
  const [isAKey, setIsAKey] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const dropdownRefUserChat = useRef<HTMLUListElement>(null);
  const [selectedMentionIndex, setSelectedMentionIndex] = useState(-1);
  const [openAttachementModal, setOpenAttachementModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [extractedText, setExtractedText] = useState<string>("");
  const isMobile = useIsMobile();

  const getSuggestionParams = useCallback(
    (data: string) => {
      return {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "user",
            content: `Give me 4 suggestions or relatable questions regarding this ${
              selectedFiles
                ? `Based on the provided text, ${extractedText}`
                : ""
            }${data}. Please provide suggestion in a JSON array format. I want suggestion like this: {"suggestions":["suggestion 1","suggestion 2","suggestion 3","suggestion 4"]}, Can you please share content in only ${language} language.`,
          },
        ],
        max_tokens: 2000,
        temperature: 0.7,
        n: 1,
      };
    },
    [extractedText, language, selectedFiles],
  );

  const getGptPrompt = async (data: string) => {
    if (responsePromptRef.current) {
      responsePromptRef.current.innerHTML += `
        <div class="my-3 text-end">
          <p class="user-prompt">${data}</p>
        </div>
      `;
    }
    const params = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "user",
          content: `${
            selectedFiles ? "Based on the provided text," : ""
          }${data}. Please provide your answer in HTML format and refer to this as a previous chat: ${
            responsePromptRef?.current && selectedFiles.length === 0
              ? responsePromptRef?.current?.innerHTML
              : ""
          }.
          Provided text:
          ${extractedText}
          
          Can you please share the response in HTML format, using only the ${language} language? Please avoid repeating any questions or suggestions.
          `,
        },
      ],
      max_tokens: 2000,
      temperature: 0.7,
      n: 1,
    };

    //This params is used after getting the response  from predefine themes
    const suggestionsParams = getSuggestionParams(data);
    setIsLoading(true);
    const res = await axios.post(
      `${process.env.REACT_APP_EP_URL_WHITEBOARD}/gpt`,
      params,
    );
    const resSggestions = await axios.post(
      `${process.env.REACT_APP_EP_URL_WHITEBOARD}/gpt`,
      suggestionsParams,
    );
    if (resSggestions?.data?.status === "Success") {
      // Define a regular expression to match text between curly braces
      const regex = /{([^}]+)}/g;

      // Use the regex to extract the data
      const matches = resSggestions?.data?.result?.choices[0]?.message?.content.match(
        regex,
      );
      if (matches && matches?.length > 0) {
        setSuggestionsPrompt(JSON.parse(matches[0])?.suggestions);
      }
    }
    if (res?.data?.status === "Success") {
      const response = res?.data?.result?.choices[0]?.message?.content;

      // Define a regular expression to match text between triple backticks
      // const regex = /```([\s\S]+?)```/g;
      // Define a regular expression to match text between triple backticks

      const regex = /```(?:html\s*)?([\s\S]+?)```/g;

      // Use the regex to extract the data
      let matches = response.match(regex);

      if (matches && matches?.length > 0) {
        // Find the indices of <body> and </body> tags
        const bodyStartIndex = matches[0].indexOf("<body>");
        const bodyEndIndex = matches[0].indexOf("</body>");

        // Extract the content between <body> and </body> tags
        const bodyContent = matches[0].substring(
          bodyStartIndex + "<body>".length,
          bodyEndIndex,
        );
      }
      if (responsePromptRef.current) {
        responsePromptRef.current.innerHTML += response;
        setMagicChat(JSON.stringify(responsePromptRef.current.innerHTML));
      }
      setResponsePrompt(response);
    }
    setMorePrompt(data);
    setIsLoading(false);
    setSelectedFiles([]);
    setExtractedText("");
  };

  const getMoreSuggestions = async (data: string) => {
    const suggestionsParams = getSuggestionParams(data);
    setIsMoreSuggestion(true);
    const resSggestions = await axios.post(
      `${process.env.REACT_APP_EP_URL_WHITEBOARD}/gpt`,
      suggestionsParams,
    );
    if (resSggestions?.data?.status === "Success") {
      // Define a regular expression to match text between curly braces
      const regex = /{([^}]+)}/g;

      // Use the regex to extract the data
      const matches = resSggestions?.data?.result?.choices[0]?.message?.content.match(
        regex,
      );
      if (matches && matches?.length > 0) {
        setSuggestionsPrompt(JSON.parse(matches[0])?.suggestions);
      }
      setIsMoreSuggestion(false);
    }
  };

  useEffect(() => {
    const data_ = preDefinesData?.map((data) => {
      return data + " " + selectedResult?.map((result) => result)?.join(", ");
    });
    setPreDefinesThemes(data_);
  }, [selectedResult]);

  useEffect(() => {
    if (responsePromptRef.current && magicChat) {
      responsePromptRef.current.innerHTML += JSON.parse(magicChat);
    }
  }, []);

  const insertMention = (data: string) => {
    const textBeforeAt = userPrompt.substring(0, userPrompt.lastIndexOf("@"));
    const textAfterAt = userPrompt.substring(userPrompt.lastIndexOf("@") + 1);
    const newText = `${textBeforeAt}@${data} ${textAfterAt}`;
    setUserPrompt(newText);
    setDropdownVisible(false);
    setSelectedMentionIndex(-1);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.selectionStart = inputRef.current.selectionEnd =
          newText.length;
        inputRef.current.focus();
      }
    }, 0);
  };

  const insertMentionForChat = (data: string) => {
    const textBeforeAt = userChat.substring(0, userChat.lastIndexOf("@"));
    const newText = `${textBeforeAt}@${data} `;
    setUserChat(newText);
    setDropdownVisibleChat(false);
    setTimeout(() => {
      if (inputPromptRef.current) {
        inputPromptRef.current.selectionStart = inputPromptRef.current.selectionEnd =
          newText.length;
        inputPromptRef.current.focus();
      }
    }, 0);
  };

  const handleMentionClick = (data: string) => {
    insertMention(data);
  };

  const scrollIntoView = (index: number) => {
    const dropdown = dropdownRef.current;

    if (dropdown) {
      const item = dropdown.children[index];
      // @ts-ignore
      const itemTop = item?.offsetTop;
      // @ts-ignore
      const itemBottom = itemTop + item?.offsetHeight;

      if (itemTop < dropdown?.scrollTop) {
        dropdown.scrollTop = itemTop;
      } else if (itemBottom > dropdown.scrollTop + dropdown.clientHeight) {
        dropdown.scrollTop = itemBottom - dropdown.clientHeight;
      }
    }
  };

  const handleKeyDown = (event: any, fun: any) => {
    // if (dropdownVisible || dropdownVisibleChat) {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedMentionIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % mentions.length;
        scrollIntoView(newIndex);
        return newIndex;
      });
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedMentionIndex((prevIndex) => {
        const newIndex = (prevIndex - 1 + mentions.length) % mentions.length;
        scrollIntoView(newIndex);
        return newIndex;
      });
    } else if (event.key === "Enter") {
      event.preventDefault();
      if (mentions.length > 0 && selectedMentionIndex !== -1) {
        fun(mentions[selectedMentionIndex]);
      }
    }
    // }
  };

  const onChange = (e: any, userChat: boolean) => {
    const value = e.target.value;
    const caretPosition = e.target.selectionStart;
    setCursorPosition(caretPosition);
    setMentions([...results]);
    const lastChar = value[caretPosition - 1];
    if (lastChar === "@") {
      setIsAKey(true);
      setSelectedMentionIndex(0);
      if (userChat) {
        setDropdownVisibleChat(true);
        setDropdownVisible(false);
      } else {
        setDropdownVisible(true);
        setDropdownVisibleChat(false);
      }
    } else {
      const wordStart = value.lastIndexOf("@", caretPosition - 1);
      if (wordStart !== -1) {
        const searchTerm = value.substring(wordStart + 1, caretPosition);
        const filteredResults = results.filter((data) =>
          data.toLowerCase().startsWith(searchTerm.toLowerCase()),
        );
        setMentions(filteredResults);
        if (userChat) {
          setDropdownVisibleChat(filteredResults.length > 0);
          setDropdownVisible(false);
        } else {
          setDropdownVisible(filteredResults.length > 0);
          setDropdownVisibleChat(false);
        }
      } else {
        userChat ? setDropdownVisibleChat(false) : setDropdownVisible(false);
        setIsAKey(false);
      }
    }
  };

  var divElements = document
    .getElementsByClassName("spinner-dialog")[0]
    .getElementsByClassName("Modal__content");
  var height = 764;
  // Iterate through each element in the collection
  for (var i = 0; i < divElements.length; i++) {
    // Get the current element
    var divElement = divElements[i];

    // Get the height of the current element
    height = divElement.clientHeight;
  }
  return (
    <>
      <div className="magic-output w-100">
        <div className={`${!isMobile && "d-flex"} justify-content-between`}>
          <div className="d-flex">
            <h5 className="p-0 m-0">Magic ACV Wheel ✨</h5>
          </div>
          <div
            className="output-section d-flex gap-3"
            style={{ marginTop: isMobile ? "10px" : 0 }}
          >
            {results?.length > 0 &&
              results?.map((result, index) => {
                const isSelected = selectedResult?.find(
                  (data) => data === result,
                );
                return (
                  <div
                    key={index}
                    className={`result-item ${
                      isSelected ? "selected-item" : ""
                    }`}
                    onClick={() => {
                      if (isSelected) {
                        setSelectedResult(
                          selectedResult.filter((data) => data !== result),
                        );
                      } else {
                        setSelectedResult([...selectedResult, result]);
                      }
                    }}
                  >
                    <span>{result}</span>
                  </div>
                );
              })}
          </div>
        </div>
        <PreDefinesThemes
          preDefinesThemes={preDefinesThemes}
          getGptPrompt={getGptPrompt}
          setIsChat={setIsChat}
          setUserPrompt={setUserPrompt}
        />
        {!isChat ? (
          <div className="w-100">
            <form action="" className="promptForm">
              <textarea
                className="w-100 p-3 promptInput"
                placeholder="Please enter your prompt"
                rows={1}
                ref={inputRef}
                onChange={(e) => {
                  setUserPrompt(e.target.value);
                  onChange(e, false);
                }}
                onKeyDown={async (e) => {
                  if (dropdownVisible) {
                    handleKeyDown(e, insertMention);
                  } else {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      setUserPrompt("");
                      await getGptPrompt(userPrompt);
                      setIsChat(true);
                    }
                  }
                }}
                value={userPrompt}
              >
                {userPrompt}
              </textarea>
              {dropdownVisible && userPrompt && (
                <ul
                  ref={dropdownRef}
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    backgroundColor: "white",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    listStyle: "none",
                    padding: 0,
                    margin: 0,
                    maxHeight: "150px",
                    overflowY: "auto",
                    zIndex: 99,
                  }}
                >
                  {mentions.map((user, index) => (
                    <li
                      key={index}
                      onClick={() => handleMentionClick(user)}
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        backgroundColor:
                          index === selectedMentionIndex
                            ? "#e6f7ff"
                            : "transparent",
                      }}
                      className="mention-list-item"
                    >
                      {user}
                    </li>
                  ))}
                </ul>
              )}
              <button
                className="btn-generate-prompt"
                type="submit"
                disabled={!userPrompt}
                onClick={async (e) => {
                  e.preventDefault();
                  await getGptPrompt(userPrompt);
                  setUserPrompt("");
                }}
                style={{
                  backgroundColor: userPrompt ? "#181818" : "#E9E9E9",
                  color: "white",
                }}
              >
                {upArrowIcon("19", "24")}
              </button>
              <Portall containerId="external_source">
                <button
                  className="btn-attachment"
                  type="submit"
                  onClick={async (e) => {
                    e.preventDefault();
                    setOpenAttachementModal(true);
                  }}
                  style={{
                    backgroundColor: "#494b83",
                    color: "white",
                  }}
                >
                  {attachmentIcon("19", "24")} Add External Source
                </button>
                {selectedFiles.length ? (
                  <button className="file-badge position-absolute">
                    {selectedFiles.length}
                  </button>
                ) : (
                  ""
                )}
              </Portall>
              {openAttachementModal && (
                <AttachementModal
                  setOpen={setOpenAttachementModal}
                  open={openAttachementModal}
                  selectedFiles={selectedFiles}
                  onAttachFiles={(selectedFiles, extractedText) => {
                    setSelectedFiles(selectedFiles);
                    setExtractedText(extractedText);
                  }}
                />
              )}
            </form>
          </div>
        ) : (
          <button
            className="btn"
            onClick={() => {
              setIsChat(false);
              setUserPrompt("");
              setUserChat("");
              if (responsePromptRef.current) {
                responsePromptRef.current.innerHTML = "";
              }
              console.log(":::::: 544");
              setSuggestionsPrompt([]);
            }}
          >
            + New Chat
          </button>
        )}
        <div className={`${isMobile ? "d-flow" : "d-flex"} gap-3`}>
          <div
            className={`${
              isMobile ? "w-100" : "w-75"
            } gpt-box-main position-relative`}
          >
            {/* {isLoading ? ( */}

            {/* ) : ( */}
            <div
              className="gpt-box"
              // style={{ height: isChat ? "51vh" : "48vh" }}
              style={{ height: height - 300, transition: "height 0.3s ease" }}
            >
              <div className={`responsePromptBox`}>
                <div id="responsePrompt" ref={responsePromptRef}>
                  {/* {responsePrompt} */}
                </div>
                <section
                  className={`dots-container ${
                    isLoading || isLoadingMoreSuggestion ? "" : "d-none"
                  }`}
                >
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </section>
                {!isLoadingMoreSuggestion && (
                  <div className="mt-4 suggestion-boxes">
                    {suggestionsPrompt?.length > 0 &&
                      suggestionsPrompt?.map((data) => {
                        return (
                          <div
                            className="suggestion-prompt-box"
                            onClick={() => {
                              getGptPrompt(data);
                            }}
                          >
                            <h5>{data}</h5>
                          </div>
                        );
                      })}
                  </div>
                )}
                {suggestionsPrompt?.length > 0 && (
                  <div className="text-center">
                    <button
                      className="btn text-center mt-3 text-success mx-auto more-ideas-btn"
                      onClick={() => {
                        getMoreSuggestions(morePrompt);
                      }}
                    >
                      {reverseIcon("20px", "20px", "#198754")}
                      <span>More Ideas</span>
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="px-3">
              <div className="w-100 promptChatForm">
                <form action="" className="promptForm">
                  {dropdownVisibleChat && userChat && (
                    <ul
                      ref={dropdownRef}
                      style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        bottom: "60px",
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        listStyle: "none",
                        padding: 0,
                        margin: 0,
                        maxHeight: "150px",
                        overflowY: "auto",
                        zIndex: 99,
                      }}
                    >
                      {mentions.map((user, index) => (
                        <li
                          key={index}
                          onClick={() => insertMentionForChat(user)}
                          style={{
                            padding: "10px",
                            cursor: "pointer",
                            backgroundColor:
                              index === selectedMentionIndex
                                ? "#e6f7ff"
                                : "transparent",
                          }}
                          className="mention-list-item"
                        >
                          {user}
                        </li>
                      ))}
                    </ul>
                  )}
                  <textarea
                    ref={inputPromptRef}
                    className="w-100 promptInput promptChatInput"
                    placeholder="Please enter your prompt"
                    // rows={1}
                    onChange={(e) => {
                      setUserChat(e.target.value);
                      onChange(e, true);
                    }}
                    onKeyDown={async (e) => {
                      if (dropdownVisibleChat) {
                        handleKeyDown(e, insertMentionForChat);
                      } else {
                        const textarea = inputPromptRef.current;
                        if (e.key === "Enter" && e.shiftKey) {
                          if (promptHeight <= 100) {
                            setPromptHeight(promptHeight + 30);
                          }
                        }
                        // Backspace to decrease height if at the beginning of a line
                        if (e.key === "Backspace" && textarea) {
                          const cursorPosition = textarea.selectionStart;
                          const textBeforeCursor = textarea.value.substring(
                            0,
                            cursorPosition,
                          );
                          const isAtLineStart =
                            textBeforeCursor.endsWith("\n") ||
                            cursorPosition === 0;

                          if (isAtLineStart && promptHeight > 50) {
                            // Ensure the height doesn't go below the initial height
                            setPromptHeight((prevHeight) => prevHeight - 30);
                          }
                        }

                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          setUserChat("");
                          setIsChat(true);
                          await getGptPrompt(userChat);
                        }
                      }
                    }}
                    value={userChat}
                    style={{ height: isMobile ? "70px" : promptHeight + "px" }}
                  >
                    {userChat}
                  </textarea>

                  <button
                    className="btn-generate-prompt"
                    type="submit"
                    disabled={!userChat}
                    onClick={async (e) => {
                      e.preventDefault();
                      setUserChat("");
                      setIsChat(true);
                      await getGptPrompt(userChat);
                    }}
                    style={{
                      backgroundColor: userChat ? "#181818" : "#E9E9E9",
                      color: "white",
                    }}
                  >
                    {upArrowIcon("19", "24")}
                  </button>
                </form>
              </div>
            </div>

            {/* )} */}
          </div>
          <div className="insert-buttons-div d-flex flex-column justify-content-between p-2">
            <div
              className={`${
                isMobile && "d-flex justify-content-between flex-wrap"
              }`}
            >
              <button className="btn-insert btn-draft mt-3 d-flex gap-1">
                <span>Draft Event</span>
                <div className="icon">{rightArrowIcon}</div>
              </button>
              <button className="btn-insert btn-draft mt-3 d-flex gap-1">
                <span>Draft Feedback</span>
                <div className="icon">{rightArrowIcon}</div>
              </button>
            </div>
            <div
              className={`${
                isMobile &&
                "d-flex justify-content-between align-items-baseline flex-wrap"
              }`}
              style={{ gap: isMobile ? "10px" : "0" }}
            >
              <button
                className={`btn-insert mt-3 d-flex gap-1 ${
                  magicChat ? "" : "disabled-btn"
                }`}
                onClick={() => {
                  if (responsePromptRef.current) {
                    sessionStorage.setItem(
                      "magicChat",
                      JSON.stringify(responsePromptRef.current.innerHTML),
                    );
                    sessionStorage.setItem(
                      "spinWheel",
                      JSON.stringify({
                        segments,
                        isTimer,
                        timeDuration,
                        results,
                      }),
                    );
                    onTextWithStylesAction({
                      textString: responsePromptRef.current.innerHTML,
                      show: true,
                      handleCloseDialogue: onClose,
                      isAIGenerated: true,
                      width: 690,
                    });
                  }
                  isInclude && insertImageOnCanvas(wheelImage);
                }}
                disabled={!magicChat}
              >
                <span>Insert it</span>
                <div className="icon">{rightArrowIcon}</div>
              </button>
              <label className="checkbox-container d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  checked={isInclude}
                  onChange={(e) => setIsInclude(e.target.checked)}
                />
                <span>{"Include wheel image"}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
