/* eslint-disable prettier/prettier */
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../App/shareComponent/Loader";
import { ExcalidrawElement, NonDeleted } from "../element/types";
import { fetchAllCollection } from "../excalidraw-app/api/collection";
import { t } from "../i18n";
import { CollocationType, Filter, LibraryItem } from "../types";
import { useIsMobile } from "./App";
import { Avatar } from "./Avatar";
import { LibraryCarousel } from "./LibraryCarousel";
import { filteredValueType } from "./LibraryDialog";
import MultiSelectDropdown from "./MultiSelectDropdown";
import "./TemplateContent.scss";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import { CollectionImagePreview } from "./collectionImagePreview";
import {
  bookmarkIcon,
  close,
  fillBookmarkIcon,
  fillLikeIcon,
  filterIcon,
  menu,
  whiteLikeIcon,
} from "./icons";

type ChildProps = {
  collections: CollocationType[];
  selectDetailCollectionHandler: (data: CollocationType) => void;
  changeTab: (val: string) => void;
  handleClose: () => void;
  onInsertShape: (elements: LibraryItem["elements"]) => void;
  likeUnlikeCollection: (id: string) => void;
  bookmarkCollection: (id: string) => void;
  filterOption: Filter;
  filterDataWithKeys: filteredValueType;
  setFilterDataWithKeys: (data: filteredValueType) => void;
  fetchFilteredData: (data: filteredValueType) => void;
  setFilterData: (val: string[]) => void;
  filterData: string[];
  isLoading: boolean;
  setIsLoading: (val: boolean) => void;
  isSideBarOpen: boolean;
  setIsSideBarOpen: (val: boolean) => void;
  importLibraryFromUrl: (urls: string[]) => void;
};

const TemplateContent = ({
  changeTab,
  collections,
  handleClose,
  selectDetailCollectionHandler,
  likeUnlikeCollection,
  bookmarkCollection,
  filterOption,
  filterDataWithKeys,
  setFilterDataWithKeys,
  fetchFilteredData,
  filterData,
  setFilterData,
  isLoading,
  setIsLoading,
  isSideBarOpen,
  setIsSideBarOpen,
  importLibraryFromUrl,
}: ChildProps) => {
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [removeVal, setRemoveVal] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [filteredCollections, setFilteredCollections] = useState<
    CollocationType[]
  >([]);
  const [isFilterApply, setFilterApply] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    setFilteredCollections(collections);
  }, [collections]);

  const sortByContet = [
    t("collection.community.template.sortBy.title"),
    t("collection.community.template.sortBy.types.newest"),
    t("collection.community.template.sortBy.types.mostLiked"),
    t("collection.community.template.sortBy.types.title"),
    t("collection.community.template.sortBy.types.author"),
  ];

  const handleMoreInfo = (data: CollocationType) => {
    selectDetailCollectionHandler(data);
    changeTab("info");
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue: any = event.target.value;
    setSelectedValue(newValue);
    onSortCollection(newValue);
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]); // Update the type of selectedCheckboxes

  const handleAddToCollection = (index: number) => {
    // Update state to toggle the checkbox at the specified index
    setSelectedCheckboxes((prevState) => {
      if (prevState.includes(index)) {
        return prevState.filter((item) => item !== index); // Remove index if already selected
      } else {
        return [...prevState, index]; // Add index if not selected
      }
    });
  };

  const selectArray = [
    {
      title: t("collection.community.template.filter.age"),
      label: [
        t("collection.community.template.filter.options.7day"),
        t("collection.community.template.filter.options.1month"),
        t("collection.community.template.filter.options.3month"),
        t("collection.community.template.filter.options.6month"),
      ],

      id: "age",
      search: false,
    },
    {
      title: t("collection.community.template.filter.author"),
      label: filterOption.authors,
      id: "author",
      search: true,
    },
    {
      title: t("collection.community.template.filter.tags"),
      label: filterOption.tags,
      id: "tags",
      search: true,
    },
  ];

  const selectedFilterValue = (val: string[]) => {
    setFilterData(val);
  };

  const clearFilter = (val: string) => {
    let clonedFilterDataWithKeys = { ...filterDataWithKeys };
    const updatedCheckedCategories = filterData.filter((cat) => cat !== val);
    if (
      val === t("collection.community.template.filter.options.7day") ||
      val === t("collection.community.template.filter.options.1month") ||
      val === t("collection.community.template.filter.options.3month") ||
      val === t("collection.community.template.filter.options.6month")
    ) {
      clonedFilterDataWithKeys.age = "";
    } else {
      clonedFilterDataWithKeys.author = clonedFilterDataWithKeys.author.filter(
        (author) => author !== val,
      );
      clonedFilterDataWithKeys.tags = clonedFilterDataWithKeys.tags.filter(
        (tag) => tag !== val,
      );
    }
    setFilterDataWithKeys(clonedFilterDataWithKeys);
    setFilterData(updatedCheckedCategories);
    setRemoveVal(val);
    fetchFilteredData(clonedFilterDataWithKeys);
  };

  const removeFilters = async () => {
    try {
      setFilterData([]);
      setIsLoading(true);
      const { result } = await fetchAllCollection({});
      setFilteredCollections(result.resources);
      setFilterDataWithKeys({
        ...filterDataWithKeys,
        age: "",
        author: [],
        tags: [],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const [selectedSources, setSelectedSources] = useState<string[]>([]);

  const handleButtonClick = (collectionSource: string) => {
    if (selectedSources.includes(collectionSource)) {
      // If the source is already selected, remove it from the array
      setSelectedSources(
        selectedSources.filter((source: string) => source !== collectionSource),
      );
    } else {
      setSelectedSources([...selectedSources, collectionSource]);
    }
  };

  // laod element from DB - azure blob storage link
  const loadElementsFromDB = async (urls: string[]) => {
    const elements: NonDeleted<ExcalidrawElement>[] = [];

    try {
      await Promise.all(
        urls.map(async (url) => {
          const res = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });

          if (res.data?.library) {
            res.data.library.forEach((lib: any) => {
              lib.forEach((data: NonDeleted<ExcalidrawElement>) => {
                elements.push(data);
              });
            });
          }
          if (res.data?.libraryItems) {
            res.data.libraryItems.forEach((lib: any) => {
              lib.elements.forEach((data: NonDeleted<ExcalidrawElement>) => {
                elements.push(data);
              });
            });
          }
        }),
      );

      const slug = new URLSearchParams(window.location.search).get("slug");
      const room = new URLSearchParams(window.location.search).get("room");
      const lessonId = new URLSearchParams(window.location.search).get(
        "lessonId",
      );

      let finalURL = `/?slug=${slug}&lessonId=${lessonId}/#addLibrary=${urls}`;
      if (room) finalURL += `/#room=${room}`;

      const libraryId = finalURL.split("#addLibrary=")[1];
      const urlArray = libraryId ? libraryId.split(",") : [];
      importLibraryFromUrl(urlArray);
      localStorage.setItem("SET-ACV-LIBRARY", "true");
      handleClose();
      // onInsertShape(elements);
    } catch (error) {
      console.error("Error loading elements from DB:", error);
    }
  };

  const searchAPI = async () => {
    try {
      setIsLoading(true);
      if (searchVal) {
        const { result } = await fetchAllCollection({ search: searchVal });
        setFilteredCollections(result.resources);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onSortCollection = async (index: number) => {
    setIsLoading(true);
    try {
      setSearchVal(null);
      // used index because it's tricky to handle when languages are change
      //index 1: newest, 2: most like, 3: title(a-z), 4: author(a-z)
      const { result } = await fetchAllCollection({ sort: index });
      setFilteredCollections(result.resources);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="template_content h-100">
      <div className="d-lg-flex justify-content-between pb-3">
        <div className="d-flex align-items-center">
          <button
            className={`btn me-3 ${useIsMobile() ? "d-none" : ""}`}
            // data-bs-toggle="offcanvas"
            // data-bs-target="#offcanvasExample"
            // aria-controls="offcanvasExample"
            style={{ width: "28px" }}
            onClick={() => {
              setIsSideBarOpen(!isSideBarOpen);
            }}
          >
            {menu}
          </button>
          <div className="position-relative">
            <input
              type="text"
              id="search"
              name="search"
              placeholder={t("collection.community.template.search")}
              className="m-0 w-100 mt-lg-0 mt-3"
              value={searchVal || ""}
              onChange={(e) => {
                const value = e.target.value === "" ? null : e.target.value;
                setSearchVal(value);
                if (value === null) {
                  setFilteredCollections(collections);
                }
              }}
              onKeyUp={() => {
                searchAPI();
              }}
            />
            <ToolButton
              key="newPage"
              type={ToolButtonEnum.BUTTON}
              aria-label="close"
              className={`position-absolute closeIcon ${
                !searchVal && "d-none"
              }`}
              icon={close}
              onClick={() => {
                setSearchVal(null);
                setFilteredCollections(collections);
              }}
            ></ToolButton>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          {useIsMobile() ? (
            <div className="d-flex align-items-center">
              <select
                className="custom-select"
                value={selectedValue}
                onChange={handleSelectChange}
              >
                {sortByContet.map((sortText, index) => {
                  return (
                    <option selected={index === 1} value={index}>
                      {sortText}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : (
            <div className="d-flex align-items-center pt-lg-0  ms-auto me-lg-4 me-2">
              {sortByContet.map((sortText, index) => {
                return (
                  <p
                    className="ps-3 text-nowrap mb-0"
                    style={{ cursor: "pointer" }}
                    onClick={() => onSortCollection(index)}
                  >
                    {sortText}
                  </p>
                );
              })}
            </div>
          )}
          <div
            className={`${
              useIsMobile() ? "" : "d-flex justify-content-end pt-lg-0"
            }`}
          >
            <span
              className="filter"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <ToolButton
                key="newPage"
                type={ToolButtonEnum.BUTTON}
                type={ToolButtonEnum.BUTTON}
                title={t("collection.community.template.filter.title")}
                aria-label={t("collection.community.template.filter.title")}
                icon={filterIcon}
                className="filterIcon"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <span className="">
                  {t("collection.community.template.filter.title")}
                </span>
              </ToolButton>
            </span>
          </div>
        </div>
      </div>

      {selectedSources.length > 0 && (
        <div className="d-flex justify-content-end mb-2">
          <div className="d-flex">
            <h6 className="d-flex align-items-center me-2 mb-0">
              Import: {selectedSources.length}
            </h6>
            <ToolButton
              key="import"
              type={ToolButtonEnum.BUTTON}
              aria-label="import"
              className="importBtn"
              onClick={async () => {
                // await Promise.all(
                //   selectedSources.map((source) => loadElementsFromDB(source)),
                // );
                loadElementsFromDB(selectedSources);
              }}
            >
              {t("collection.community.template.importBtn")}
            </ToolButton>
            <ToolButton
              key="cancel"
              type={ToolButtonEnum.BUTTON}
              aria-label="cancel"
              className="cancelBtn"
              onClick={() => {
                setSelectedSources([]);
              }}
            >
              {t("collection.community.template.cancelBtn")}
            </ToolButton>
          </div>
        </div>
      )}
      {isFilterApply && (
        <div className=" d-flex justify-content-between">
          <div className="d-flex">
            {filterData?.map((data) => (
              <p className="filter_val mb-0 mt-3 ms-2">
                <span className="align-text-bottom">{data}</span>
                <ToolButton
                  key="newPage"
                  type={ToolButtonEnum.BUTTON}
                  aria-label=""
                  icon={close}
                  onClick={() => clearFilter(data)}
                ></ToolButton>
              </p>
            ))}
          </div>
          {filterData.length > 0 && (
            <p className="clear_filter mb-0 mt-3" onClick={removeFilters}>
              {t("collection.community.template.filter.clear")}
            </p>
          )}
        </div>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-fluid template-cards pb-3">
          <div className="row">
            {filteredCollections?.length > 0 ? (
              filteredCollections.map((collection, index: number) => {
                return (
                  <div className="col-sm-6 col-md-4 col-xs-12">
                    <div className="card item-card card-block mt-4">
                      <h4 className="card-title d-flex justify-content-between">
                        {collection.name}
                        <div className="likeSection">
                          <ToolButton
                            key="newPage"
                            type={ToolButtonEnum.BUTTON}
                            title={t("collection.personal.bookmark.title")}
                            aria-label={t("collection.personal.bookmark.title")}
                            icon={
                              collection?.isBookmark
                                ? fillBookmarkIcon
                                : bookmarkIcon
                            }
                            className="likeIcon"
                            onClick={() => {
                              bookmarkCollection(collection.id);
                            }}
                          ></ToolButton>
                          <ToolButton
                            key="newPage"
                            type={ToolButtonEnum.BUTTON}
                            title={t("collection.personal.liked.title")}
                            aria-label={t("collection.personal.liked.title")}
                            icon={
                              collection.isLiked ? fillLikeIcon : whiteLikeIcon
                            }
                            className="likeIcon"
                            onClick={() => {
                              likeUnlikeCollection(collection.id);
                            }}
                          >
                            <span className="likeFont">
                              {collection.likeCount}
                            </span>
                          </ToolButton>
                        </div>
                      </h4>
                      <div className="mx-auto">
                        {Array.isArray(collection.preview) ? (
                          collection.preview.length > 0 &&
                          collection.preview.length > 1 ? (
                            <LibraryCarousel
                              images={
                                (collection.preview as unknown) as string[]
                              }
                              index={index}
                              name={collection.name}
                              className="card-img-top"
                            />
                          ) : (
                            <CollectionImagePreview
                              previewURL={collection.preview[0]}
                              index={index}
                              className="card-img-top"
                            />
                          )
                        ) : (
                          <CollectionImagePreview
                            previewURL={collection.preview}
                            index={index}
                            className="card-img-top"
                          />
                        )}
                      </div>
                      <div className="item-card-title mt-3 mb-3 d-flex justify-content-between">
                        <div className="d-flex">
                          <Avatar
                            onClick={() => {
                              // const msg =
                              //   user?.displayName +
                              //   " clicked profile!\nYour Email is : " +
                              //   user?.mail;
                              // alert(msg);
                            }}
                            color={"#4c6ef5"}
                            border={"#4c6ef5"}
                          >
                            {collection.authors[0].url &&
                            collection.authors[0].url !== "null" ? (
                              <img
                                key={index}
                                src={collection.authors[0].url}
                                alt=""
                                className="Avatar"
                              />
                            ) : (
                              collection.authors[0].name.substring(0, 2)
                            )}
                          </Avatar>
                          <span className="ms-2 mt-2">
                            {collection.authors[0].name}
                          </span>
                        </div>
                        <p className="date">
                          {moment
                            .utc(collection.created)
                            .local()
                            .format("DD MMM YYYY, h:mm A")}
                        </p>
                      </div>
                      <div className="d-flex h-100 flex-column justify-content-end">
                        <p className="card-text d-flex justify-content-end ">
                          <ToolButton
                            className="bg-brand text-white pr-2 more-info-btn template-card-infoBtn infoBtn"
                            key="moreInfo"
                            type={ToolButtonEnum.BUTTON}
                            title={t(
                              "collection.community.template.moreInfoBtn",
                            )}
                            aria-label={t(
                              "collection.community.template.moreInfoBtn",
                            )}
                          >
                            <span onClick={() => handleMoreInfo(collection)}>
                              {t("collection.community.template.moreInfoBtn")}
                            </span>
                          </ToolButton>

                          <ToolButton
                            className={`pr-2 template-card-infoBtn infoBtn${
                              selectedSources.includes(collection.source)
                                ? " selected"
                                : ""
                            }`}
                            key="moreInfo"
                            type={ToolButtonEnum.BUTTON}
                            title={t(
                              "collection.personal.bookmark.addToCollectionBtn",
                            )}
                            aria-label={t(
                              "collection.personal.bookmark.addToCollectionBtn",
                            )}
                            // onClick={() => {
                            //   localStorage.setItem("SET-ACV-LIBRARY", "true");
                            //   loadElementsFromDB(collection.source);
                            //   handleClose();
                            // }}
                            onClick={() => handleButtonClick(collection.source)}
                          >
                            <span>
                              {/* {t("collection.personal.bookmark.addToCollectionBtn")} */}
                              {"Add"}
                            </span>
                          </ToolButton>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="empty_img text-center m-auto">
                <img
                  src="https://acvstorageprod.blob.core.windows.net/react-images/Empty-pana.svg"
                  alt=""
                  height={292}
                  width={292}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className="offcanvas offcanvas-end"
        // tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        data-bs-scroll={false}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel" className="title mt-4">
            {t("collection.community.template.filter.title")}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setOpen(false);
              removeFilters();
              setFilterApply(false);
            }}
          ></button>
        </div>
        <div className="offcanvas-body overflow-hidden d-flex flex-column h-100">
          <MultiSelectDropdown
            selectArray={selectArray}
            isOpen={open}
            removeFilters={removeFilters}
            filterApply={isFilterApply}
            removeVal={removeVal}
            setFilterDataWithKeys={setFilterDataWithKeys}
            filterDataWithKeys={filterDataWithKeys}
            filterData={filterData}
            setFilterData={setFilterData}
          />
        </div>
        <div className="footer">
          <button
            type="button"
            className="bg-brand text-white pr-2 infoBtn"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setOpen(true);
              setFilterApply(true);
              fetchFilteredData(filterDataWithKeys);
            }}
          >
            {t("collection.community.template.filter.apply")}
          </button>
          <button
            type="button"
            className="infoBtn text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setOpen(false);
              removeFilters();
              setFilterApply(false);
            }}
          >
            {t("collection.community.template.filter.cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemplateContent;
