import OpenColor from "open-color";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MIME_TYPES } from "../../../../../../constants";
import {
  ExcalidrawElement,
  FileId,
  NonDeleted,
} from "../../../../../../element/types";
import {
  bookmarkCollectionAPI,
  getCategoryWiseLibraryTemplate,
  likeUnlikeCollectionAPI,
  removeCategoryFromLibraryTemplate,
} from "../../../../../../excalidraw-app/api/collection";
import { exportToSvg } from "../../../../../../scene/export";
import {
  AppState,
  BinaryFileData,
  BinaryFiles,
  CustomCategies,
  CollocationType,
  LibraryFileType,
  LibraryItem,
} from "../../../../../../types";
import { useIsMobile } from "../../../../../App";
import { LibraryType } from "../../../../../TemplateInfo";
import { Toast, ToastType } from "../../../../../Toast";
import { CardWithImage } from "../../../../components/card/CardWithImage";
import { SidebarItemsEnum } from "../../../../components/constant/SidebarItems";

interface CustomCategoryProps {
  categoryId: string;
  setAppState: React.Component<any, AppState>["setState"];
  appState: AppState;
  editCollection: (val: LibraryType[]) => void;
  setSelectedSideLibraryTab: (val: SidebarItemsEnum) => void;
  setPreviewTemplateData: (data: CollocationType) => void;
  loadElementsFromDB: (source: string) => Promise<LibraryItem["elements"]>;
  setFullScreenCollectionPreview: () => void;
  search: string;
  setSearch: (val: string) => void;
  setSelectedCatagoryForExportCanvas?: Dispatch<
    SetStateAction<CustomCategies[]>
  >;
  selectedCatagoryForExportCanvas?: CustomCategies[];
}

export const CustomCategory = (props: CustomCategoryProps) => {
  const {
    categoryId,
    setAppState,
    appState,
    editCollection,
    setSelectedSideLibraryTab,
    search,
    setSelectedCatagoryForExportCanvas,
    selectedCatagoryForExportCanvas,
  } = props;
  const [customCategoryData, setCustomCategoryData] = useState<
    CollocationType[]
  >([]);

  const [filteredCustomCategoryData, setFilteredCustomCategoryData] = useState<
    CollocationType[]
  >([]);
  const [toastMessage, setToastMessage] = useState<{
    message: string;
    type: ToastType;
  } | null>(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const slug = new URLSearchParams(window.location.search).get("slug");

    const fetchData = async () => {
      const response = await getCategoryWiseLibraryTemplate(
        slug ?? "",
        user?.username,
        categoryId,
      );

      setCustomCategoryData(response?.result ?? []);
    };

    fetchData();
  }, [categoryId]);

  useEffect(() => {
    if (search) {
      setFilteredCustomCategoryData(
        customCategoryData.filter((data) =>
          data.name.toLowerCase().includes(search.toLowerCase()),
        ),
      );
    } else {
      setFilteredCustomCategoryData(customCategoryData);
    }
  }, [search, customCategoryData]);

  const isMobile = useIsMobile();

  const getFilesByLibraryElements = (
    elements: ExcalidrawElement[],
    templateInfo: CollocationType,
  ) => {
    let files: BinaryFiles = {};
    for (let index = 0; index < elements.length; index++) {
      const element: ExcalidrawElement = elements[index];
      if (
        element.type === "image" ||
        element.type === "formula" ||
        element.type === "mermaidDiagram" ||
        element.type === "video" ||
        element.type === "audio" ||
        element.type === "textWithStyles"
      ) {
        if (element?.fileId !== null) {
          if (appState.files.hasOwnProperty(element.fileId)) {
            files[element.fileId] = appState.files[element.fileId];
          }
          if (templateInfo && templateInfo.files) {
            const elementFileIndex = templateInfo.files.findIndex(
              (file: { id: FileId }) => file.id == element.fileId,
            );

            if (elementFileIndex !== -1) {
              const elementFile = templateInfo.files[
                elementFileIndex
              ] as LibraryFileType;
              files[elementFile.id as string] = {
                mimeType:
                  elementFile.mimeType ||
                  (MIME_TYPES.binary as BinaryFileData["mimeType"]),
                id: elementFile.id as BinaryFileData["id"],
                dataURL: elementFile.url as BinaryFileData["dataURL"],
                created: elementFile.created,
                isPublished: true,
              };
            }
          }
        }
      }
    }
    return files ? files : null;
  };

  const generateSvgForLibrary = async (
    libraryItems: LibraryType[],
    item: CollocationType,
  ) => {
    const librariesWithSvg: LibraryType[] = await Promise.all(
      libraryItems.map(async (libraryItem: LibraryType) => {
        const files = getFilesByLibraryElements(libraryItem.elements, item);
        const svg = libraryItem?.elements?.length
          ? await exportToSvg(
              libraryItem.elements,
              {
                exportBackground: false,
                viewBackgroundColor: OpenColor.white,
              },
              files,
            )
          : null;
        return {
          ...libraryItem,
          svg,
        };
      }),
    );
  };

  const onEdit = async (item: CollocationType) => {
    const response = await fetch(item.source);
    const result = await response.json();

    setAppState({
      editingLibrary: {
        ...appState.editingLibrary,
        libraryId: appState.editingLibrary.libraryInfo.id,
        isEditing: true,
        libraryInfo: item,
        libraryItems: result?.libraryItems[0]?.elements,
        oldElements: [],
      },
    });
    const modifiedLibraryItems = result?.libraryItems?.length
      ? result?.libraryItems.map((libraryItem: LibraryType) => {
          return { ...libraryItem, isSelected: false };
        })
      : [];
    editCollection(modifiedLibraryItems);
    generateSvgForLibrary(modifiedLibraryItems, item);
    setSelectedSideLibraryTab(SidebarItemsEnum.WorkInProgress);
  };

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const slug = new URLSearchParams(window.location.search).get("slug");

  return (
    <>
      <div
        className="row overflow-auto"
        style={{
          position: "relative",
          top: isMobile ? "7%" : "",
          height: "calc(100% - 23%)",
        }}
      >
        {filteredCustomCategoryData.map((template, index) => (
          <RenderCard
            {...props}
            index={index}
            template={template}
            setSelectedCatagoryForExportCanvas={
              setSelectedCatagoryForExportCanvas
            }
            selectedCatagoryForExportCanvas={selectedCatagoryForExportCanvas}
            categoryId={categoryId}
            onAfterMovedToCategory={() => {
              setToastMessage({
                message: "Collection successfully imported.",
                type: ToastType.SUCCESS,
              });
            }}
            onEdit={() => {
              template.userId === user?.mail && onEdit(template);
            }}
            onDelete={() => {
              removeCategoryFromLibraryTemplate(
                template.id,
                user.username,
                slug ?? "",
                categoryId,
              );
              setFilteredCustomCategoryData(
                filteredCustomCategoryData.filter(
                  (data) => data.id !== template.id,
                ),
              );
              setToastMessage({
                message: "Successfully removed collection.",
                type: ToastType.ERROR,
              });
            }}
            onAfterDeleteCategory={(id) => {
              if (template.id !== id) {
                const updatedCategory = template.category?.filter(
                  (category) => category !== id,
                );
                const index = filteredCustomCategoryData.findIndex(
                  (data) => data.id === template.id,
                );
                if (updatedCategory) {
                  const data = [...filteredCustomCategoryData];

                  data[index].category = [...updatedCategory];

                  setFilteredCustomCategoryData([...data]);
                }
              }
              if (categoryId === id) {
                setFilteredCustomCategoryData(
                  filteredCustomCategoryData.filter(
                    (data) => data.id !== template.id,
                  ),
                );
              }
            }}
          />
        ))}
        {toastMessage !== null && (
          <Toast
            type={toastMessage.type}
            message={toastMessage.message}
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
      </div>
    </>
  );
};

interface RenderCardProps extends CustomCategoryProps {
  template: CollocationType;
  index: number;
  onAfterMovedToCategory: () => void;
  onEdit: VoidFunction;
  onDelete: VoidFunction;
  onAfterDeleteCategory: (id: string) => void;
  setSelectedCatagoryForExportCanvas?: Dispatch<
    SetStateAction<CustomCategies[]>
  >;
  selectedCatagoryForExportCanvas?: CustomCategies[];
}

const RenderCard = ({
  template,
  index,
  loadElementsFromDB,
  setSelectedCatagoryForExportCanvas,
  selectedCatagoryForExportCanvas,
  categoryId,
  ...rest
}: RenderCardProps) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const isUserTemplate = template.userId === user?.mail;

  const [elements, setElements] = useState<LibraryItem["elements"]>([]);

  useEffect(() => {
    const func = async () => {
      const ele = await loadElementsFromDB(template.source);
      setElements(ele);
    };
    func();
  }, [template]);

  return (
    <CardWithImage
      {...rest}
      _key={index}
      images={template.preview}
      buttonText=""
      isDelete={true}
      userProfile={template.authors[0]?.url}
      userName={template.authors[0]?.name}
      created={template.created}
      name={template.name}
      likes={template.likeCount}
      isLikefill={template.isLiked}
      onLike={async () => {
        const res = await likeUnlikeCollectionAPI(template.id);
        return res.result.likeCount;
      }}
      isBookMarkFill={template.isBookmark}
      onBookmark={async () => {
        await bookmarkCollectionAPI(template.id);
      }}
      isMove={true}
      id={template.id}
      isEdit={isUserTemplate}
      elements={elements}
      onPreview={() => {
        rest.setPreviewTemplateData(template);
      }}
      templateCategory={template.category}
      onAfterDeleteCategory={rest.onAfterDeleteCategory}
      isCustomCatagory={true}
      setSelectedCatagoryForExportCanvas={setSelectedCatagoryForExportCanvas}
      selectedCatagoryForExportCanvas={selectedCatagoryForExportCanvas}
      template={template}
      categoryId={categoryId}
    />
  );
};
