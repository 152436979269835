import React from "react";
import { t } from "../i18n";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import { close, filterIcon, menu } from "./icons";
import { useIsMobile } from "./App";
import { CollocationType } from "../types";

type LibraryFilterMenu = {
  searchVal: string;
  setSearchVal: (val: string) => void;
  setFilteredCollections: (val: CollocationType[]) => void;
  collections: CollocationType[];
  searchAPI: VoidFunction;
  selectedValue: number;
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onSortCollection: (index: number) => void;
  setOpen: (val: boolean) => void;

  isSideBarOpen: boolean;
  setIsSideBarOpen: (val: boolean) => void;
};

const LibraryFilterMenu = ({
  searchVal,
  setSearchVal,
  setFilteredCollections,
  collections,
  searchAPI,
  selectedValue,
  handleSelectChange,
  onSortCollection,
  setOpen,
  isSideBarOpen,
  setIsSideBarOpen,
}: LibraryFilterMenu) => {
  const sortByContet = [
    t("collection.community.template.sortBy.title"),
    t("collection.community.template.sortBy.types.newest"),
    t("collection.community.template.sortBy.types.mostLiked"),
    t("collection.community.template.sortBy.types.title"),
    t("collection.community.template.sortBy.types.author"),
  ];

  return (
    <div className="d-lg-flex justify-content-between pb-3">
      <div className="d-flex align-items-center">
        <button
          className={`btn me-3 ${useIsMobile() ? "d-none" : ""}`}
            style={{ width: "28px" }}
          onClick={() => {
            setIsSideBarOpen(!isSideBarOpen);
          }}
        >
          {menu}
        </button>
        <input
          type="text"
          id="search"
          name="search"
          placeholder={t("collection.community.template.search")}
          className="m-0 w-100 mt-lg-0 mt-3 d-block"
          value={searchVal || ""}
          onChange={(e) => {
            const value = e.target.value === "" ? "" : e.target.value;
            setSearchVal(value);
            if (value === null) {
              setFilteredCollections(collections);
            }
          }}
          onKeyUp={() => {
            searchAPI();
          }}
        />
      </div>
      <div className="d-flex justify-content-between">
        {useIsMobile() ? (
          <div className="d-flex align-items-center">
            <select
              className="custom-select"
              value={selectedValue}
              onChange={handleSelectChange}
            >
              {sortByContet.map((sortText, index) => {
                return (
                  <option selected={index === 1} value={index}>
                    {sortText}
                  </option>
                );
              })}
            </select>
          </div>
        ) : (
          <div className="d-flex align-items-center pt-lg-0 pt-3 ms-auto me-4">
            {sortByContet.map((sortText, index) => {
              return (
                <p
                  className="ps-3 text-nowrap mb-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => onSortCollection(index)}
                >
                  {sortText}
                </p>
              );
            })}
          </div>
        )}
        <div
          className={`${
            useIsMobile()
              ? ""
              : "d-flex justify-content-end pt-lg-0 align-items-center"
          }`}
        >
          <span
            className="filter"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <ToolButton
              key="newPage"
              type={ToolButtonEnum.BUTTON}
              title={t("collection.community.template.filter.title")}
              aria-label={t("collection.community.template.filter.title")}
              icon={filterIcon}
              className="filterIcon"
              onClick={() => {
                setOpen(true);
              }}
            >
              <span className="">
                {t("collection.community.template.filter.title")}
              </span>
            </ToolButton>
          </span>
        </div>
      </div>
    </div>
  );
};

export default LibraryFilterMenu;
