import { sidebarText } from "../../../../excalidraw-app/api/constant";
import { t } from "../../../../i18n";
import {
  audioIcon,
  bookmarkIcon,
  brain,
  chart,
  cloud,
  collectionIcon,
  cup,
  imageIcon,
  lampOn,
  likeIcon,
  note,
  puzzle,
  reviewIcon,
  searchIcon,
  setting,
  sparkle,
  stockIcon,
  videoIcon,
  whiteTemplateIcon,
  workInProgress,
} from "../../../icons";

export enum SidebarItemsEnum {
  WorkInProgress = "Work in progress",
  AddedFromCollections = "Added from collections",
  PublicSpinWheel = "Public Spin Wheel",
  // Recomanded = "Recomanded",
  // Brainstroming = "Brainstroming",
  // ProblemSolving = "Problem solving",
  // DesignAndResearch = "Design and Research",
  // Strategy = "Strategy",
  // ProjectPlaning = "Project Planing",
  // Retrospective = "Retrospective",
  // Games = "Games",
  // Workshops = "Workshops",
  // Learning = "Learning",
}

export enum CollectionSidebarItemsEnum {
  Template = "Template",
  StockImage = "Stock Image",
  MyCollections = "My Collections",
  Images = "Images",
  Videos = "Videos",
  Audios = "Audios",
  UnderReview = "Under Review",
  Liked = "Liked",
  Bookmark = "Bookmark",
}
