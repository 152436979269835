import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  fetchFilteredCollectionData,
  fetchUnderReviewCollectionsAPI,
  withdrawCollectionApprovalRequestAPI,
} from "../../../../../../excalidraw-app/api/collection";
import { getUserInfo } from "../../../../../../excalidraw-app/api/getuserInfo";
import {
  AppState,
  BinaryFileData,
  BinaryFiles,
  CollocationType,
  LibraryFileType,
  LibraryItem,
  LibraryItems,
} from "../../../../../../types";
import { CardWithImage } from "../../../../components/card";
import Loader from "../../../../../../App/shareComponent/Loader";
import { LibraryType } from "../../../../../TemplateInfo";
import { exportToSvg } from "../../../../../../scene/export";
import OpenColor from "open-color";
import { ExcalidrawElement, FileId } from "../../../../../../element/types";
import { MIME_TYPES } from "../../../../../../constants";
import { LibraryTabsEnum } from "../../../../LibraryItemsDialog";
import { Toast, ToastType } from "../../../../../Toast";
import { FilterRef, FilteredValueType } from "../../CollectionsTab";
import { t } from "../../../../../../i18n";
import { Dialog } from "../../../../../Dialog";

interface UnderReviewCollectionProps {
  searchVal: string;
  setAppState: React.Component<any, AppState>["setState"];
  appState: AppState;
  editCollection: (val: LibraryItems) => void;
  setSelectedsection: (val: LibraryTabsEnum) => void;
  selectedSortBy: number | null;
}

export const UnderReviewCollection = forwardRef<
  FilterRef,
  UnderReviewCollectionProps
>(
  (
    {
      searchVal,
      setAppState,
      appState,
      editCollection,
      setSelectedsection,
      selectedSortBy,
    },
    ref,
  ) => {
    const [underReviewData, setUnderReviewData] = useState<CollocationType[]>(
      [],
    );
    const [filteredUnderReviewData, setFilteredUnderReviewData] = useState<
      CollocationType[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState("");
    const fetchUnderReviewCollections = async () => {
      try {
        setIsLoading(true);
        const user = await getUserInfo();

        const apiBody = {
          userId: user.mail,
        };
        const { result } = await fetchUnderReviewCollectionsAPI(apiBody);
        setUnderReviewData(result);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };

    useEffect(() => {
      fetchUnderReviewCollections();
    }, []);

    useEffect(() => {
      const onSortCollection = async (index: number) => {
        setIsLoading(true);
        try {
          const user = await getUserInfo();
          //index 1: newest, 2: most like, 3: title(a-z), 4: author(a-z)
          const apiBody = {
            userId: user?.mail,
            sort: index,
          };
          const { result } = await fetchUnderReviewCollectionsAPI(apiBody);
          setFilteredUnderReviewData(result);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.log(error);
        }
      };

      onSortCollection(selectedSortBy ? selectedSortBy : 0);
    }, [selectedSortBy]);

    useEffect(() => {
      if (searchVal) {
        const searchResult = underReviewData.filter((template) =>
          template.name.toLowerCase().includes(searchVal.toLowerCase()),
        );
        return setFilteredUnderReviewData(searchResult);
      }
      setFilteredUnderReviewData(underReviewData);
    }, [searchVal, underReviewData]);

    const withdrawCollectionApprovalRequest = async (id: string) => {
      try {
        const apiBody = {
          id,
        };
        await withdrawCollectionApprovalRequestAPI(apiBody);
        setFilteredUnderReviewData(
          filteredUnderReviewData.filter((item) => item.id !== id),
        );
        setToastMessage("Successfully template unpublished.");
      } catch (err) {
        console.log(err);
      }
    };

    const fetchFilteredData = async (filterDataWithKeys: FilteredValueType) => {
      try {
        setIsLoading(true);
        const user = await getUserInfo();

        const { result } = await fetchFilteredCollectionData({
          ...filterDataWithKeys,
          userId: user.mail,
        });

        setFilteredUnderReviewData(result.resources);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("error-in-fetchFilteredData", error);
      }
    };

    const removeFilters = async () => {
      try {
        setIsLoading(true);
        const user = await getUserInfo();

        const apiBody = {
          userId: user.mail,
        };
        const { result } = await fetchUnderReviewCollectionsAPI(apiBody);

        setFilteredUnderReviewData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    useImperativeHandle(ref, () => ({
      onApply: fetchFilteredData,
      onClear: removeFilters,
    }));

    const onEdit = async (item: CollocationType) => {
      const response = await fetch(item.source);
      const result = await response.json();

      setAppState({
        editingLibrary: {
          ...appState.editingLibrary,
          libraryId: appState.editingLibrary.libraryInfo.id,
          isEditing: true,
          libraryInfo: item,
          libraryItems: result?.libraryItems[0]?.elements,
          oldElements: [],
        },
      });
      const modifiedLibraryItems = result?.libraryItems?.length
        ? result?.libraryItems.map((libraryItem: LibraryType) => {
            return { ...libraryItem, isSelected: false };
          })
        : [];
      editCollection(modifiedLibraryItems);
      generateSvgForLibrary(modifiedLibraryItems, item);
      setSelectedsection(LibraryTabsEnum.MyLibrary);
    };

    const getFilesByLibraryElements = (
      elements: ExcalidrawElement[],
      templateInfo: CollocationType,
    ) => {
      let files: BinaryFiles = {};
      for (let index = 0; index < elements?.length; index++) {
        const element: ExcalidrawElement = elements[index];
        if (
          element.type === "image" ||
          element.type === "formula" ||
          element.type === "mermaidDiagram" ||
          element.type === "video" ||
          element.type === "audio" ||
          element.type === "textWithStyles"
        ) {
          if (element?.fileId !== null) {
            if (appState.files.hasOwnProperty(element.fileId)) {
              files[element.fileId] = appState.files[element.fileId];
            }
            if (templateInfo && templateInfo.files) {
              const elementFileIndex = templateInfo.files.findIndex(
                (file: { id: FileId }) => file.id == element.fileId,
              );

              if (elementFileIndex !== -1) {
                const elementFile = templateInfo.files[
                  elementFileIndex
                ] as LibraryFileType;
                files[elementFile.id as string] = {
                  mimeType:
                    elementFile.mimeType ||
                    (MIME_TYPES.binary as BinaryFileData["mimeType"]),
                  id: elementFile.id as BinaryFileData["id"],
                  dataURL: elementFile.url as BinaryFileData["dataURL"],
                  created: elementFile.created,
                  isPublished: true,
                };
              }
            }
          }
        }
      }
      return files ? files : null;
    };

    const generateSvgForLibrary = async (
      libraryItems: LibraryType[],
      item: CollocationType,
    ) => {
      const librariesWithSvg: LibraryType[] = await Promise.all(
        libraryItems.map(async (libraryItem: LibraryType) => {
          const files = getFilesByLibraryElements(libraryItem.elements, item);
          const svg = libraryItem?.elements?.length
            ? await exportToSvg(
                libraryItem.elements,
                {
                  exportBackground: false,
                  viewBackgroundColor: OpenColor.white,
                },
                files,
              )
            : null;
          return {
            ...libraryItem,
            svg,
          };
        }),
      );
    };

    return (
      <div className="row overflow-auto" style={{ height: "calc(100% - 28%)" }}>
        {isLoading ? (
          <Loader className="h-100" />
        ) : (
          filteredUnderReviewData.map((item, index) => (
            <CardWithImage
              key={index}
              images={item.preview}
              userProfile={""}
              userName={item.status || item.authors?.[0]?.name}
              created={item.created}
              name={item.name}
              likeBookmarkIcon={false}
              buttonText={t("collection.personal.underReview.withdraw")}
              onAddtoCollectionBtnClick={() => {
                setOpen(true);
                setId(item.id);
              }}
              isHoverPreviewBtns={false}
              isHoverUseThisTemplateBtn={false}
              isEdit={true}
              onEdit={() => onEdit(item)}
            />
          ))
        )}
        {open && (
          <Dialog
            children={
              <div style={{ padding: "6px" }}>
                <p>
                  If you withdraw this template this will delete permanently.
                </p>
                <div className="d-flex justify-content-end gap-2">
                  <button
                    style={{
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      background: "#494B83",
                      color: "white",
                    }}
                    onClick={() => {
                      withdrawCollectionApprovalRequest(id);
                      setOpen(false);
                    }}
                  >
                    Confirm
                  </button>
                  <button
                    style={{
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      color: "white",
                      background: "#FA5252",
                    }}
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            }
            title={"Are you sure you want to withdraw this template?"}
            onCloseRequest={() => setOpen(false)}
            closeOnClickOutside={false}
            open={open}
            setOpen={setOpen}
          />
        )}
        {toastMessage !== null && (
          <Toast
            type={ToastType.ERROR}
            message={
              toastMessage ? toastMessage : "Added to library successfully"
            }
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
      </div>
    );
  },
);
