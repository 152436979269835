import { Dispatch, SetStateAction, createContext } from "react";
import { SegmentType } from "../types";

export enum timerDuration {
  MINUTES = "minutes",
  SECONDS = "seconds",
}

type SpinnerContextType = {
  segments: SegmentType[];
  setSegments: Dispatch<SetStateAction<SegmentType[]>>;
  selectedWheelNumber: number;
  setSelectedWheelNumber: Dispatch<SetStateAction<number>>;
  setMinimize: Dispatch<SetStateAction<boolean>>;
  setMinimizeAllSpinWheel: Dispatch<SetStateAction<boolean>>;
  minimizeAllSpinWheel: boolean;
  setOpenSaveDialog: Dispatch<SetStateAction<boolean>>;
  openSaveDialog: boolean;
  timeDuration: {
    [timerDuration.MINUTES]: number;
    [timerDuration.SECONDS]: number;
  };
  setTimeDuration: Dispatch<
    SetStateAction<{
      [timerDuration.MINUTES]: number;
      [timerDuration.SECONDS]: number;
    }>
  >;
  isTimer: boolean;
  setIsTimer: Dispatch<SetStateAction<boolean>>;
  topic: string;
  setTopic: Dispatch<SetStateAction<string>>;
  topicGenerationLimit: number;
  setTopicGenerationLimit: Dispatch<SetStateAction<number>>;
};

const SpinnerContextDefaultValue: SpinnerContextType = {
  segments: [],
  setSegments: () => {},
  selectedWheelNumber: 1,
  setSelectedWheelNumber: () => {},
  setMinimize: () => {},
  setMinimizeAllSpinWheel: () => {},
  minimizeAllSpinWheel: false,
  setOpenSaveDialog: () => {},
  openSaveDialog: false,
  timeDuration: {
    minutes: 1,
    seconds: 0,
  },
  setTimeDuration: () => {},
  isTimer: false,
  setIsTimer: () => {},
  topic: "",
  setTopic: () => {},
  topicGenerationLimit: 10,
  setTopicGenerationLimit: () => {},
};

export const SpinnerContext = createContext<SpinnerContextType>(
  SpinnerContextDefaultValue,
);
