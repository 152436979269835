import { isSomeElementSelected } from "../scene";
import { KEYS } from "../keys";
import { ToolButton, ToolButtonEnum } from "../components/ToolButton";
import { trash } from "../components/icons";
import { t } from "../i18n";
import { register } from "./register";
import { getNonDeletedElements } from "../element";
import { ExcalidrawElement } from "../element/types";
import { AppState, BinaryFiles } from "../types";
import { newElementWith } from "../element/mutateElement";
import { getElementsInGroup } from "../groups";
import { LinearElementEditor } from "../element/linearElementEditor";
import { fixBindingsAfterDeletion } from "../element/binding";
import { deleteFiles } from "../excalidraw-app/api/collection";
import {
  onSaveElementInDB,
  pdfImportDocumentCounter,
} from "../excalidraw-app/api/userAPI";

const deleteSelectedElements = (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
) => {
  return {
    elements: elements.map((el) => {
      if (appState.selectedElementIds[el.id]) {
        if (
          (el.type === "image" ||
            el.type === "video" ||
            el.type === "audio" ||
            el.type === "formula" ||
            el.type === "mermaidDiagram" ||
            el.type === "textWithStyles") &&
          el.fileId
        ) {
          //below code is for if user delete the pdf then check delete only one page or not
          // if delete all pages then delete the pdf from the collection
          if (el.pdfId) {
            const currentPageElements = JSON.parse(
              localStorage.getItem("acv") || "[]",
            );

            const element = currentPageElements.filter(
              (element: any) =>
                element.pdfId === el.pdfId && element.id !== el.id,
            );

            if (element.length === 0) {
              const slug =
                new URLSearchParams(window.location.search).get("slug") || "";
              const user = JSON.parse(localStorage.getItem("user") || "{}");
              const body = {
                userId: user?.mail,
                slug: slug,
                lessonId: el.lessonId,
                pdfId: el.pdfId || "",
              };
              pdfImportDocumentCounter(body);
            }
          }

          deleteFiles(el.fileId, el.type, el.lessonId);
        }
        return newElementWith(el, { isDeleted: true });
      }
      return el;
    }),
    appState: {
      ...appState,
      selectedElementIds: {},
    },
  };
};

const handleGroupEditingState = (
  appState: AppState,
  elements: readonly ExcalidrawElement[],
): AppState => {
  if (appState.editingGroupId) {
    const siblingElements = getElementsInGroup(
      getNonDeletedElements(elements),
      appState.editingGroupId!,
    );
    if (siblingElements.length) {
      return {
        ...appState,
        selectedElementIds: { [siblingElements[0].id]: true },
      };
    }
  }
  return appState;
};

export const actionDeleteSelected = register({
  name: "deleteSelectedElements",
  perform: (elements, appState, formData, app) => {
    if (appState.editingLinearElement) {
      const {
        elementId,
        activePointIndex,
        startBindingElement,
        endBindingElement,
      } = appState.editingLinearElement;
      const elementsMap = app.scene.getNonDeletedElementsMap();
      const element = LinearElementEditor.getElement(elementId, elementsMap);
      if (!element) {
        return false;
      }
      if (
        // case: no point selected → delete whole element
        activePointIndex == null ||
        activePointIndex === -1 ||
        // case: deleting last remaining point
        element.points.length < 2
      ) {
        const nextElements = elements.filter((el) => el.id !== element.id);
        const nextAppState = handleGroupEditingState(appState, nextElements);
        return {
          elements: nextElements,
          appState: {
            ...nextAppState,
            editingLinearElement: null,
          },
          commitToHistory: false,
        };
      }

      // We cannot do this inside `movePoint` because it is also called
      // when deleting the uncommitted point (which hasn't caused any binding)
      const binding = {
        startBindingElement:
          activePointIndex === 0 ? null : startBindingElement,
        endBindingElement:
          activePointIndex === element.points.length - 1
            ? null
            : endBindingElement,
      };

      LinearElementEditor.movePoint(element, activePointIndex, "delete");

      return {
        elements,
        appState: {
          ...appState,
          editingLinearElement: {
            ...appState.editingLinearElement,
            ...binding,
            activePointIndex: activePointIndex > 0 ? activePointIndex - 1 : 0,
          },
        },
        commitToHistory: true,
      };
    }

    let files = appState.files;
    let videoCache = appState.videoCache;
    let audioCache = appState.audioCache;
    const selectedElements = elements.filter(
      ({ id }) => appState.selectedElementIds[id],
    );
    let {
      elements: nextElements,
      appState: nextAppState,
    } = deleteSelectedElements(elements, appState);
    fixBindingsAfterDeletion(nextElements, selectedElements);

    // you know why function body is not updating  and
    //Nope i think this function called only this one

    // appState.removeVideoFromVideoCatch();
    files = Object.keys(files).reduce((result: BinaryFiles, key) => {
      if (!selectedElements.some((element: any) => element?.fileId === key)) {
        result[key] = files[key];
      } else {
        clearInterval(appState.videoInterval);
        clearInterval(appState.audioInterval);
      }
      return result;
    }, {});

    if (appState.videoCache) {
      videoCache = Array.from(videoCache).filter((data) =>
        Object.keys(appState.files).includes(data[0]),
      ) as any;
      if (Array.from(videoCache).length > 0) {
        const videoPromise = Array.from(videoCache)[0][1].video;

        if (videoPromise instanceof Promise) {
          videoPromise
            .then((videoElement) => {
              videoElement.pause();
              videoElement.currentTime = videoElement.duration;
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          videoPromise.pause();
          // videoPromise.currentTime = videoPromise.duration;
        }
      }
    }
    if (appState.audioCache) {
      audioCache = Array.from(audioCache).filter((data) =>
        Object.keys(appState.files).includes(data[0]),
      ) as any;

      if (Array.from(audioCache).length > 0) {
        const audioPromise = Array.from(audioCache)[0][1].audio;

        if (audioPromise instanceof Promise) {
          audioPromise
            .then((audioElement) => {
              audioElement.pause();
              audioElement.currentTime = audioElement.duration;
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          audioPromise.pause();
          // audioPromise.currentTime = audioPromise.duration;
        }
      }
    }

    nextAppState = handleGroupEditingState(
      { ...nextAppState, files },
      nextElements,
    );
    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";

    // Wait for update value in localStorage.
    window.parent.postMessage(
      { type: "STORE_ELEMENTS", isLoading: true },
      `${process.env.REACT_APP_PARENT_APP}`,
    );
    setTimeout(async () => {
      await onSaveElementInDB(
        appState.currentPage,
        lessonId,
        appState.DBElements,
        appState.viewBackgroundColor,
      );
      window.parent.postMessage(
        { type: "STORE_ELEMENTS", isLoading: false },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
    }, 500);

    return {
      elements: nextElements,
      appState: {
        ...nextAppState,
        elementType: "selection",
        multiElement: null,
      },
      commitToHistory: isSomeElementSelected(
        getNonDeletedElements(elements),
        appState,
      ),
    };
  },
  contextItemLabel: "labels.delete",
  keyTest: (event) => event.key === KEYS.BACKSPACE || event.key === KEYS.DELETE,
  PanelComponent: ({ elements, appState, updateData }) => (
    <ToolButton
      type={ToolButtonEnum.BUTTON}
      icon={trash}
      title={t("labels.delete")}
      aria-label={t("labels.delete")}
      onClick={() => updateData(null)}
      visible={isSomeElementSelected(getNonDeletedElements(elements), appState)}
    />
  ),
});
