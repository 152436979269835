import { apiPost } from ".";
import { BinaryFileData, BinaryFiles } from "../../types";
import { API_URL } from "./constant";

export const saveDataToCosmosDB = (body: any) =>
  apiPost(`${API_URL}/save-data-to-cosmosdb`, body);

export const loadDataToCosmosDB = (body: any) =>
  apiPost(`${API_URL}/load-data-to-cosmosdb`, body);

export const uploadImageBlob = (body: FormData, lessonId: string) =>
  apiPost(`${API_URL}/upload-whiteboard-image/${lessonId}`, body);

export const uploadVideoBlob = (body: FormData, lessonId: string) =>
  apiPost(`${API_URL}/upload-whiteboard-video/${lessonId}`, body);

export const uploadAudioBlob = (body: FormData, lessonId: string) =>
  apiPost(`${API_URL}/upload-whiteboard-audio/${lessonId}`, body);

export const updateWhiteboardMedia = (body: {
  type: string;
  fileId: string;
  lessonId: string;
  data: JSON;
}) => {
  apiPost(`${API_URL}/update-whiteboard-media`, body);
};
