export const ERDiagramSyntax = `

Mermaid syntax for ER diagrams is compatible with PlantUML, with an extension to label the relationship. Each statement consists of the following parts:

    <first-entity> [<relationship> <second-entity> : <relationship-label>]

Where:

first-entity is the name of an entity. Names must begin with an alphabetic character or an underscore (from v10.5.0+), and may also contain digits and hyphens.
relationship describes the way that both entities inter-relate. See below.
second-entity is the name of the other entity.
relationship-label describes the relationship from the perspective of the first entity.
For example:

    PROPERTY ||--|{ ROOM : contains

This statement can be read as a property contains one or more rooms, and a room is part of one and only one property. You can see that the label here is from the first entity's perspective: a property contains a room, but a room does not contain a property. When considered from the perspective of the second entity, the equivalent label is usually very easy to infer. (Some ER diagrams label relationships from both perspectives, but this is not supported here, and is usually superfluous).

Only the first-entity part of a statement is mandatory. This makes it possible to show an entity with no relationships, which can be useful during iterative construction of diagrams. If any other parts of a statement are specified, then all parts are mandatory.

Relationship Syntax
The relationship part of each statement can be broken down into three sub-components:

the cardinality of the first entity with respect to the second
whether the relationship confers identity on a 'child' entity
the cardinality of the second entity with respect to the first
Cardinality is a property that describes how many elements of another entity can be related to the entity in question. In the above example a PROPERTY can have one or more ROOM instances associated to it, whereas a ROOM can only be associated with one PROPERTY. In each cardinality marker there are two characters. The outermost character represents a maximum value, and the innermost character represents a minimum value. The table below summarises possible cardinalities.

Value (left)	Value (right)	Meaning
|o	            o|	            Zero or one
||	            ||	            Exactly one
}o	            o{	            Zero or more (no upper limit)
}|	            |{	            One or more (no upper limit)

Aliases

Value (left)	Value (right)	Alias for
one or zero	    one or zero	    Zero or one
zero or one	    zero or one	    Zero or one
one or more	    one or more	    One or more
one or many	    one or many	    One or more
many(1)	        many(1)	        One or more
1+	            1+	            One or more
zero or more	zero or more	Zero or more
zero or many	zero or many	Zero or more
many(0)	        many(0)	        Zero or more
0+	            0+	            Zero or more
only one	    only one	    Exactly one
1	            1	            Exactly one

Identification
Relationships may be classified as either identifying or non-identifying and these are rendered with either solid or dashed lines respectively. This is relevant when one of the entities in question can not have independent existence without the other. For example a firm that insures people to drive cars might need to store data on NAMED-DRIVERs. In modelling this we might start out by observing that a CAR can be driven by many PERSON instances, and a PERSON can drive many CARs - both entities can exist without the other, so this is a non-identifying relationship that we might specify in Mermaid as: PERSON }|..|{ CAR : "driver". Note the two dots in the middle of the relationship that will result in a dashed line being drawn between the two entities. But when this many-to-many relationship is resolved into two one-to-many relationships, we observe that a NAMED-DRIVER cannot exist without both a PERSON and a CAR - the relationships become identifying and would be specified using hyphens, which translate to a solid line:

Aliases

Value	        Alias for
to	            identifying
optionally to	non-identifying

code:

erDiagram
    CAR ||--o{ NAMED-DRIVER : allows
    PERSON ||--o{ NAMED-DRIVER : is

Attributes
Attributes can be defined for entities by specifying the entity name followed by a block containing multiple type name pairs, where a block is delimited by an opening { and a closing }. The attributes are rendered inside the entity boxes. For example:

Code:

erDiagram
    CAR ||--o{ NAMED-DRIVER : allows
    CAR {
        string registrationNumber
        string make
        string model
    }
    PERSON ||--o{ NAMED-DRIVER : is
    PERSON {
        string firstName
        string lastName
        int age
    }


The type values must begin with an alphabetic character and may contain digits, hyphens, underscores, parentheses and square brackets. The name values follow a similar format to type, but may start with an asterisk as another option to indicate an attribute is a primary key. Other than that, there are no restrictions, and there is no implicit set of valid data types.

Entity Name Aliases (v10.5.0+)
An alias can be added to an entity using square brackets. If provided, the alias will be showed in the diagram instead of the entity name.

Code:

erDiagram
    p[Person] {
        string firstName
        string lastName
    }
    a["Customer Account"] {
        string email
    }
    p ||--o| a : has


Attribute Keys and Comments
Attributes may also have a key or comment defined. Keys can be PK, FK or UK, for Primary Key, Foreign Key or Unique Key. To specify multiple key constraints on a single attribute, separate them with a comma (e.g., PK, FK). A comment is defined by double quotes at the end of an attribute. Comments themselves cannot have double-quote characters in them.

Code:

erDiagram
    CAR ||--o{ NAMED-DRIVER : allows
    CAR {
        string registrationNumber PK
        string make
        string model
        string[] parts
    }
    PERSON ||--o{ NAMED-DRIVER : is
    PERSON {
        string driversLicense PK "The license #"
        string(99) firstName "Only 99 characters are allowed"
        string lastName
        string phone UK
        int age
    }
    NAMED-DRIVER {
        string carRegistrationNumber PK, FK
        string driverLicence PK, FK
    }
    MANUFACTURER only one to zero or more CAR : makes

Other Things
If you want the relationship label to be more than one word, you must use double quotes around the phrase
If you don't want a label at all on a relationship, you must use an empty double-quoted string

Styling
Config options
For simple color customization:

Name	Used as
fill	Background color of an entity or attribute
stroke	Border color of an entity or attribute, line color of a relationship

Classes used
The following CSS class selectors are available for richer styling:

Selector	                Description
.er.attributeBoxEven	    The box containing attributes on even-numbered rows
.er.attributeBoxOdd	        The box containing attributes on odd-numbered rows
.er.entityBox	            The box representing an entity
.er.entityLabel	            The label for an entity
.er.relationshipLabel	    The label for a relationship
.er.relationshipLabelBox    The box surrounding a relationship label
.er.relationshipLine	    The line representing a relationship between entities

`;
