import "./ToolIcon.scss";

import { t } from "../i18n";
import { ToolButton } from "./ToolButton";
import { THEME } from "../constants";
import { Theme } from "../element/types";
import { useIsMobile } from "./App";

// We chose to use only explicit toggle and not a third option for system value,
// but this could be added in the future.
export const DarkModeToggle = (props: {
  value: Theme;
  onChange: (value: Theme) => void;
  title?: string;
}) => {
  const title =
    props.title ||
    (props.value === "dark" ? t("buttons.lightMode") : t("buttons.darkMode"));

  return (
    <ToolButton
      type="icon"
      icon={props.value === THEME.LIGHT ? ICONS.MOON : ICONS.SUN}
      title={title}
      aria-label={title}
      onClick={() =>
        props.onChange(props.value === THEME.DARK ? THEME.LIGHT : THEME.DARK)
      }
      className="ml-1 w-100"
      data-testid="toggle-dark-mode"
      showAriaLabel={useIsMobile()}
    />
  );
};

const ICONS = {
  SUN: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18.3333C5.40835 18.3333 1.66669 14.5917 1.66669 9.99999C1.66669 5.40832 5.40835 1.66666 10 1.66666C14.5917 1.66666 18.3334 5.03332 18.3334 9.16666C18.3334 11.925 16.0917 14.1667 13.3334 14.1667H11.8584C11.625 14.1667 11.4417 14.35 11.4417 14.5833C11.4417 14.6833 11.4834 14.775 11.55 14.8583C11.8917 15.25 12.0834 15.7417 12.0834 16.25C12.0834 17.4 11.15 18.3333 10 18.3333ZM10 3.33332C6.32502 3.33332 3.33335 6.32499 3.33335 9.99999C3.33335 13.675 6.32502 16.6667 10 16.6667C10.2334 16.6667 10.4167 16.4833 10.4167 16.25C10.4167 16.1167 10.35 16.0167 10.3 15.9583C9.95835 15.575 9.77502 15.0833 9.77502 14.5833C9.77502 13.4333 10.7084 12.5 11.8584 12.5H13.3334C15.175 12.5 16.6667 11.0083 16.6667 9.16666C16.6667 5.94999 13.675 3.33332 10 3.33332Z"
        fill="white"
      />
      <path
        d="M5.41669 10.8333C6.10704 10.8333 6.66669 10.2737 6.66669 9.58334C6.66669 8.89299 6.10704 8.33334 5.41669 8.33334C4.72633 8.33334 4.16669 8.89299 4.16669 9.58334C4.16669 10.2737 4.72633 10.8333 5.41669 10.8333Z"
        fill="white"
      />
      <path
        d="M7.91669 7.5C8.60704 7.5 9.16669 6.94036 9.16669 6.25C9.16669 5.55964 8.60704 5 7.91669 5C7.22633 5 6.66669 5.55964 6.66669 6.25C6.66669 6.94036 7.22633 7.5 7.91669 7.5Z"
        fill="white"
      />
      <path
        d="M12.0833 7.5C12.7737 7.5 13.3333 6.94036 13.3333 6.25C13.3333 5.55964 12.7737 5 12.0833 5C11.393 5 10.8333 5.55964 10.8333 6.25C10.8333 6.94036 11.393 7.5 12.0833 7.5Z"
        fill="white"
      />
      <path
        d="M14.5833 10.8333C15.2737 10.8333 15.8333 10.2737 15.8333 9.58334C15.8333 8.89299 15.2737 8.33334 14.5833 8.33334C13.893 8.33334 13.3333 8.89299 13.3333 9.58334C13.3333 10.2737 13.893 10.8333 14.5833 10.8333Z"
        fill="white"
      />
    </svg>
  ),
  MOON: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="var(--icon-fill-color)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 18.3333C5.40835 18.3333 1.66669 14.5917 1.66669 9.99999C1.66669 5.40832 5.40835 1.66666 10 1.66666C14.5917 1.66666 18.3334 5.03332 18.3334 9.16666C18.3334 11.925 16.0917 14.1667 13.3334 14.1667H11.8584C11.625 14.1667 11.4417 14.35 11.4417 14.5833C11.4417 14.6833 11.4834 14.775 11.55 14.8583C11.8917 15.25 12.0834 15.7417 12.0834 16.25C12.0834 17.4 11.15 18.3333 10 18.3333V18.3333ZM10 3.33332C6.32502 3.33332 3.33335 6.32499 3.33335 9.99999C3.33335 13.675 6.32502 16.6667 10 16.6667C10.2334 16.6667 10.4167 16.4833 10.4167 16.25C10.4167 16.1167 10.35 16.0167 10.3 15.9583C9.95835 15.575 9.77502 15.0833 9.77502 14.5833C9.77502 13.4333 10.7084 12.5 11.8584 12.5H13.3334C15.175 12.5 16.6667 11.0083 16.6667 9.16666C16.6667 5.94999 13.675 3.33332 10 3.33332Z"></path>
      <path d="M5.41669 10.8333C6.10704 10.8333 6.66669 10.2737 6.66669 9.58334C6.66669 8.89299 6.10704 8.33334 5.41669 8.33334C4.72633 8.33334 4.16669 8.89299 4.16669 9.58334C4.16669 10.2737 4.72633 10.8333 5.41669 10.8333Z"></path>
      <path d="M7.91669 7.5C8.60704 7.5 9.16669 6.94036 9.16669 6.25C9.16669 5.55964 8.60704 5 7.91669 5C7.22633 5 6.66669 5.55964 6.66669 6.25C6.66669 6.94036 7.22633 7.5 7.91669 7.5Z"></path>
      <path d="M12.0833 7.5C12.7737 7.5 13.3333 6.94036 13.3333 6.25C13.3333 5.55964 12.7737 5 12.0833 5C11.393 5 10.8333 5.55964 10.8333 6.25C10.8333 6.94036 11.393 7.5 12.0833 7.5Z"></path>
      <path d="M14.5833 10.8333C15.2737 10.8333 15.8333 10.2737 15.8333 9.58334C15.8333 8.89299 15.2737 8.33334 14.5833 8.33334C13.893 8.33334 13.3333 8.89299 13.3333 9.58334C13.3333 10.2737 13.893 10.8333 14.5833 10.8333Z"></path>
    </svg>
  ),
};
