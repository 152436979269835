/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { CollocationType, Filter } from "../types";
import { Avatar } from "./Avatar";
import { bookmarkIcon, close, fillBookmarkIcon, fillLikeIcon } from "./icons";
import "./Like.scss";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import { t } from "../i18n";
import moment from "moment";
import Loader from "../App/shareComponent/Loader";
import LibraryFilterMenu from "./LibraryFilterMenu";
import {
  fetchAllCollection,
  fetchFilteredCollectionData,
} from "../excalidraw-app/api/collection";
import { getUserInfo } from "../excalidraw-app/api/getuserInfo";
import { filteredValueType } from "./LibraryDialog";
import MultiSelectDropdown from "./MultiSelectDropdown";
import { ReactSVG } from "react-svg";
import { LibraryCarousel } from "./LibraryCarousel";
import { CollectionImagePreview } from "./collectionImagePreview";
import { ExcalidrawElement, NonDeleted } from "../element/types";
import axios from "axios";
import { useNavigate } from "react-router-dom";

type LikeProps = {
  // like: boolean;
  // setLike: (val: boolean) => void;
  likeData: CollocationType[];
  changeTab: (val: string) => void;
  selectDetailCollectionHandler: (data: CollocationType) => void;
  likeUnlikeCollection: (id: string) => void;
  bookmarkCollection: (id: string) => void;
  isLoading: boolean;
  setIsLoading: (val: boolean) => void;
  filterDataWithKeys: filteredValueType;
  setFilterData: (val: string[]) => void;
  setFilterDataWithKeys: (data: filteredValueType) => void;
  filterOption: Filter;
  filterData: string[];
  fetchFilteredData: (data: filteredValueType) => void;
  isSideBarOpen: boolean;
  setIsSideBarOpen: (val: boolean) => void;
  handleClose: () => void;
  importLibraryFromUrl: (urls: string[]) => void;
};

const Like = ({
  likeData,
  selectDetailCollectionHandler,
  changeTab,
  likeUnlikeCollection,
  bookmarkCollection,
  isLoading,
  setIsLoading,
  filterDataWithKeys,
  setFilterData,
  setFilterDataWithKeys,
  filterOption,
  filterData,
  fetchFilteredData,
  isSideBarOpen,
  setIsSideBarOpen,
  handleClose,
  importLibraryFromUrl,
}: LikeProps) => {
  const [check, setCheck] = useState<Number>(1);
  const [LikeData, setLikeData] = useState<CollocationType[]>([]);
  const [filteredLikeData, setFilteredLikeData] = useState<CollocationType[]>(
    [],
  );
  const [isFilterApply, setFilterApply] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState(0);
  const [removeVal, setRemoveVal] = useState<string>("");
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLikeData(likeData);
  }, []);

  useEffect(() => {
    handleSearch();
  }, [search]);

  const handleCheck = (tabValue: Number) => {
    setCheck(tabValue);
  };

  const handleMoreInfo = (data: CollocationType) => {
    selectDetailCollectionHandler(data);
    changeTab("info");
  };

  const loadElementsFromDB = async (urls: string[]) => {
    const elements: NonDeleted<ExcalidrawElement>[] = [];
    try {
      await Promise.all(
        urls.map(async (url) => {
          const res = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });

          if (res.data?.library) {
            res.data.library.forEach((lib: any) => {
              lib.forEach((data: NonDeleted<ExcalidrawElement>) => {
                elements.push(data);
              });
            });
          }
          if (res.data?.libraryItems) {
            res.data.libraryItems.forEach((lib: any) => {
              lib.elements.forEach((data: NonDeleted<ExcalidrawElement>) => {
                elements.push(data);
              });
            });
          }
        }),
      );

      const slug = new URLSearchParams(window.location.search).get("slug");
      const room = new URLSearchParams(window.location.search).get("room");
      const lessonId = new URLSearchParams(window.location.search).get(
        "lessonId",
      );

      let finalURL = `/?slug=${slug}&lessonId=${lessonId}/#addLibrary=${urls}`;
      if (room) finalURL += `/#room=${room}`;

      const libraryId = finalURL.split("#addLibrary=")[1];
      const urlArray = libraryId ? libraryId.split(",") : [];
      importLibraryFromUrl(urlArray);
      localStorage.setItem("SET-ACV-LIBRARY", "true");
      handleClose();
      // onInsertShape(elements);
    } catch (error) {
      console.error("Error loading elements from DB:", error);
    }
  };

  const selectArray = [
    {
      title: t("collection.community.template.filter.age"),
      label: [
        t("collection.community.template.filter.options.7day"),
        t("collection.community.template.filter.options.1month"),
        t("collection.community.template.filter.options.3month"),
        t("collection.community.template.filter.options.6month"),
      ],

      id: "age",
      search: false,
    },
    {
      title: t("collection.community.template.filter.author"),
      label: filterOption.authors,
      id: "author",
      search: true,
    },
    {
      title: t("collection.community.template.filter.tags"),
      label: filterOption.tags,
      id: "tags",
      search: true,
    },
  ];

  const handleSearch = () => {
    if (!!search.trim()) {
      const searchedCollections = LikeData.filter((collection) =>
        collection.name.toLowerCase().includes(search.toLowerCase()),
      );
      setFilteredLikeData(searchedCollections);
    } else {
      setFilteredLikeData([]);
    }
  };

  const onSortCollection = async (index: number) => {
    setIsLoading(true);
    try {
      setSearch("");
      // used index because it's tricky to handle when languages are change
      //index 1: newest, 2: most like, 3: title(a-z), 4: author(a-z)
      const { result } = await fetchAllCollection({ sort: index });
      setFilteredLikeData(result.resources);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleButtonClick = (collectionSource: string) => {
    if (selectedSources.includes(collectionSource)) {
      // If the source is already selected, remove it from the array
      setSelectedSources(
        selectedSources.filter((source: string) => source !== collectionSource),
      );
    } else {
      setSelectedSources([...selectedSources, collectionSource]);
    }
  };

  const searchAPI = async () => {
    try {
      setIsLoading(true);
      if (search) {
        const lessonId = new URLSearchParams(window.location.search)
          .get("lessonId")
          ?.replace(/\//g, "") as string;
        const user = await getUserInfo();
        const apiBody: {
          lessonId: string;
          userId: string;
          search: string;
        } = {
          lessonId: lessonId as string,
          userId: user.mail,
          search,
        };
        const { result } = await fetchFilteredCollectionData({
          ...filterDataWithKeys,
          search,
        });
        const likedCollections = result?.resources.length
          ? result?.resources.filter(
              (collection: CollocationType) => collection.isLiked,
            )
          : [];

        setFilteredLikeData(likedCollections);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const removeFilters = async () => {
    try {
      setFilterData([]);
      setIsLoading(true);
      const { result } = await fetchAllCollection({});
      setFilteredLikeData(result.resources);
      setFilterDataWithKeys({
        ...filterDataWithKeys,
        age: "",
        author: [],
        tags: [],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue: any = event.target.value;
    setSelectedValue(newValue);
    onSortCollection(newValue);
  };

  const clearFilter = (val: string) => {
    let clonedFilterDataWithKeys = { ...filterDataWithKeys };
    const updatedCheckedCategories = filterData.filter((cat) => cat !== val);
    if (
      val === t("collection.community.template.filter.options.7day") ||
      val === t("collection.community.template.filter.options.1month") ||
      val === t("collection.community.template.filter.options.3month") ||
      val === t("collection.community.template.filter.options.6month")
    ) {
      clonedFilterDataWithKeys.age = "";
    } else {
      clonedFilterDataWithKeys.author = clonedFilterDataWithKeys.author.filter(
        (author) => author !== val,
      );
      clonedFilterDataWithKeys.tags = clonedFilterDataWithKeys.tags.filter(
        (tag) => tag !== val,
      );
    }
    setFilterDataWithKeys(clonedFilterDataWithKeys);
    setFilterData(updatedCheckedCategories);
    setRemoveVal(val);
    fetchFilteredData(clonedFilterDataWithKeys);
  };

  const myCollections_ = !!search.trim() ? filteredLikeData : LikeData;

  return (
    <div className="like_container h-100">
      <h4 className="fw-bold">Liked Template </h4>

      <LibraryFilterMenu
        collections={LikeData}
        handleSelectChange={handleSelectChange}
        onSortCollection={onSortCollection}
        searchAPI={searchAPI}
        searchVal={search}
        selectedValue={selectedValue}
        setFilteredCollections={setFilteredLikeData}
        setOpen={setOpen}
        setSearchVal={setSearch}
        isSideBarOpen={isSideBarOpen}
        setIsSideBarOpen={setIsSideBarOpen}
      />

      {selectedSources.length > 0 && (
        <div className="d-flex justify-content-end mb-2">
          <div className="d-flex">
            <h6 className="d-flex align-items-center me-2 mb-0">
              Import: {selectedSources.length}
            </h6>
            <ToolButton
              key="import"
              type={ToolButtonEnum.BUTTON}
              aria-label="import"
              className="importBtn"
              onClick={async () => {
                // await Promise.all(
                //   selectedSources.map((source) => loadElementsFromDB(source)),
                // );
                loadElementsFromDB(selectedSources);
              }}
            >
              {t("collection.community.template.importBtn")}
            </ToolButton>
            <ToolButton
              key="cancel"
              type={ToolButtonEnum.BUTTON}
              aria-label="cancel"
              className="cancelBtn"
              onClick={() => {
                setSelectedSources([]);
              }}
            >
              {t("collection.community.template.cancelBtn")}
            </ToolButton>
          </div>
        </div>
      )}
      {isFilterApply && (
        <div className=" d-flex justify-content-between">
          <div className="d-flex">
            {filterData?.map((data) => (
              <p className="filter_val mb-0 mt-3 ms-2">
                <span className="align-text-bottom">{data}</span>
                <ToolButton
                  key="newPage"
                  type={ToolButtonEnum.BUTTON}
                  aria-label=""
                  icon={close}
                  onClick={() => clearFilter(data)}
                ></ToolButton>
              </p>
            ))}
          </div>
          {filterData.length > 0 && (
            <p className="clear_filter mb-0 mt-3" onClick={removeFilters}>
              {t("collection.community.template.filter.clear")}
            </p>
          )}
        </div>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid like-card pb-3">
            <div className="row">
              {myCollections_.length > 0 ? (
                myCollections_.map((collection, index) => {
                  return (
                    <div className="col-sm-6 col-md-4 col-xs-12">
                      <div className="card item-card card-block mt-4">
                        <h4 className="card-title d-flex justify-content-between">
                          {collection.name}
                          <div className="likeSection">
                            <ToolButton
                              key="newPage"
                              type={ToolButtonEnum.BUTTON}
                              title={t("collection.personal.bookmark.title")}
                              aria-label={t(
                                "collection.personal.bookmark.title",
                              )}
                              icon={
                                collection?.isBookmark
                                  ? fillBookmarkIcon
                                  : bookmarkIcon
                              }
                              className="likeIcon"
                              onClick={() => {
                                bookmarkCollection(collection.id);
                                const templateIndex = LikeData.findIndex(
                                  (t) => t.id === collection.id,
                                );
                                if (templateIndex > -1) {
                                  LikeData[
                                    templateIndex
                                  ].isBookmark = !collection.isBookmark;

                                  setLikeData([...LikeData]);
                                }
                              }}
                            ></ToolButton>
                            <ToolButton
                              key="newPage"
                              type={ToolButtonEnum.BUTTON}
                              title={t("collection.personal.liked.title")}
                              aria-label={t("collection.personal.liked.title")}
                              icon={fillLikeIcon}
                              className="likeIcon"
                              onClick={() => {
                                likeUnlikeCollection(collection.id);
                                const templateIndex = likeData.findIndex(
                                  (t) => t.id === collection.id,
                                );
                                if (templateIndex > -1) {
                                  myCollections_[
                                    templateIndex
                                  ].isLiked = !collection.isLiked;
                                  myCollections_[
                                    templateIndex
                                  ].likeCount = !collection.isLiked
                                    ? collection.likeCount - 1
                                    : collection.likeCount + 1;
                                  setLikeData([
                                    ...myCollections_.filter(
                                      (like) =>
                                        like.id !==
                                        myCollections_[templateIndex].id,
                                    ),
                                  ]);
                                }
                              }}
                            >
                              <span className="likeFont">
                                {collection.likeCount}
                              </span>
                            </ToolButton>
                          </div>
                        </h4>

                        {Array.isArray(collection.preview) ? (
                          collection.preview.length > 0 &&
                          collection.preview.length > 1 ? (
                            <LibraryCarousel
                              images={
                                (collection.preview as unknown) as string[]
                              }
                              index={index}
                              name={collection.name}
                            />
                          ) : (
                            <CollectionImagePreview
                              previewURL={collection.preview[0]}
                              index={index}
                            />
                          )
                        ) : (
                          <CollectionImagePreview
                            previewURL={collection.preview}
                            index={index}
                          />
                        )}
                        <div className="item-card-title mt-3 mb-3 d-flex justify-content-between">
                          <div className="d-flex">
                            <Avatar
                              onClick={() => {
                                // const msg =
                                //   user?.displayName +
                                //   " clicked profile!\nYour Email is : " +
                                //   user?.mail;
                                // alert(msg);
                              }}
                              color={"#4c6ef5"}
                              border={"#4c6ef5"}
                            >
                              {collection.authors[0].url &&
                              collection.authors[0].url !== "null" ? (
                                <img
                                  key={index}
                                  src={collection.authors[0].url}
                                  alt=""
                                  className="Avatar"
                                />
                              ) : (
                                collection.authors[0].name.substring(0, 2)
                              )}
                            </Avatar>
                            <span className="ms-2 mt-2">
                              {collection.authors[0].name}
                            </span>
                          </div>
                          <p className="date">
                            {moment
                              .utc(collection.created)
                              .local()
                              .format("YYYY-MM-DD")}
                          </p>
                        </div>
                        <p className="card-text d-flex justify-content-end">
                          <ToolButton
                            className="bg-brand text-white pr-2 infoBtn"
                            key="moreInfo"
                            type={ToolButtonEnum.BUTTON}
                            title={t(
                              "collection.community.template.moreInfoBtn",
                            )}
                            aria-label={t(
                              "collection.community.template.moreInfoBtn",
                            )}
                          >
                            <span
                              onClick={(e: any) => handleMoreInfo(collection)}
                            >
                              {t("collection.community.template.moreInfoBtn")}
                            </span>
                          </ToolButton>
                          <ToolButton
                            className=" pr-2 infoBtn"
                            key="moreInfo"
                            type={ToolButtonEnum.BUTTON}
                            title={t(
                              "collection.community.template.addToCollectionBtn",
                            )}
                            aria-label={t(
                              "collection.community.template.addToCollectionBtn",
                            )}
                            // onClick={() => {
                            //   loadElementsFromDB(collection.source);
                            //   handleClose();
                            // }}
                            onClick={() => handleButtonClick(collection.source)}
                          >
                            <span>
                              {t(
                                "collection.community.template.addToCollectionBtn",
                              )}
                            </span>
                          </ToolButton>
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="empty_img h-100 d-flex align-items-center justify-content-center">
                  <img
                    src="https://acvstorageprod.blob.core.windows.net/react-images/Personal%20files-pana.svg"
                    alt=""
                    height={292}
                    width={292}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <div
        className="offcanvas offcanvas-end"
        // tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        data-bs-scroll={false}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel" className="title mt-4">
            {t("collection.community.template.filter.title")}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setOpen(false);
              removeFilters();
              setFilterApply(false);
            }}
          ></button>
        </div>
        <div className="offcanvas-body overflow-hidden d-flex flex-column h-100">
          <MultiSelectDropdown
            selectArray={selectArray}
            isOpen={open}
            removeFilters={removeFilters}
            filterApply={isFilterApply}
            removeVal={removeVal}
            setFilterDataWithKeys={setFilterDataWithKeys}
            filterDataWithKeys={filterDataWithKeys}
            filterData={filterData}
            setFilterData={setFilterData}
          />
        </div>
        <div className="footer">
          <button
            type="button"
            className="bg-brand text-white pr-2 infoBtn"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setOpen(true);
              setFilterApply(true);
              fetchFilteredData(filterDataWithKeys);
            }}
          >
            {t("collection.community.template.filter.apply")}
          </button>
          <button
            type="button"
            className="infoBtn text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setOpen(false);
              removeFilters();
              setFilterApply(false);
            }}
          >
            {t("collection.community.template.filter.cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Like;
