/* eslint-disable prettier/prettier */
import axios from "axios";
import moment from "moment";
import OpenColor from "open-color";
import React, { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MIME_TYPES } from "../constants";
import { ExcalidrawElement, FileId, NonDeleted } from "../element/types";
import {
  createComments,
  deleteComments,
  editComments,
  getAuthorWiseTemplate,
  getComments,
  getTagWiseTemplate,
} from "../excalidraw-app/api/storeElementData";
import { exportToSvg } from "../scene/export";
import {
  AppState,
  BinaryFileData,
  BinaryFiles,
  CollocationType,
  CommentType,
  LibraryFileType,
  LibraryItem,
  LibraryItems,
} from "../types";
import { Avatar } from "./Avatar";
import { Dialog } from "./Dialog";
import ImageCard from "./ImageCard";
import { LibraryCarousel } from "./LibraryCarousel";
import "./TemplateInfo.scss";
import { TipTapEditorWithCommentBox } from "./TipTapEditorWithCommentBox";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import { CollectionImagePreview } from "./collectionImagePreview";
import {
  bookmarkIcon,
  edit,
  fillBookmarkIcon,
  fillLikeIcon,
  whiteLikeIcon,
} from "./icons";
import { t } from "../i18n";
type TemplateInfoProps = {
  templateInfo: CollocationType;
  handleClose: () => void;
  onInsertShape: (elements: LibraryItem["elements"]) => void;
  likeUnlikeCollection: (id: string) => void;
  selectDetailCollectionHandler: (data: CollocationType) => void;
  changeTab: (val: string) => void;
  bookmarkCollection: (id: string) => void;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  setLibraryItems_: (val: LibraryItems) => void;
  libraryItems_: LibraryItems;
  editCollection: (libraryItems: LibraryItems) => void;
  importLibraryFromUrl: (urls: string[]) => void;
};

export type LibraryType = LibraryItem & {
  elements: ExcalidrawElement[];
  svg?: SVGSVGElement | null;
  isSelected: boolean;
};

const TemplateInfo = ({
  templateInfo,
  handleClose,
  onInsertShape,
  likeUnlikeCollection,
  selectDetailCollectionHandler,
  changeTab,
  bookmarkCollection,
  appState,
  setAppState,
  editCollection,
  importLibraryFromUrl,
}: TemplateInfoProps) => {
  const myRef = createRef<HTMLDivElement>();
  const navigate = useNavigate();

  const [check, setCheck] = useState<Number>(1);
  const [relatedData, setRelatedData] = useState<CollocationType[]>([]);
  const [commentData, setCommentData] = useState<CommentType[]>([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [showOption, setShowOption] = useState<string>("");
  const [isEditComment, setIsEditComment] = useState<string>("");

  const [commentDetail, setCommentDetail] = useState<{
    html: string;
    text: string;
  }>({
    html: "",
    text: "",
  });

  const [editCommentDetail, setEditCommentDetail] = useState<{
    html: string;
    text: string;
  }>({
    html: "",
    text: "",
  });

  const [libraryItems, setLibraryItems] = useState<LibraryType[]>([]);
  const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;
  const [
    previewCollectionImageDialog,
    setPreviewCollectionImageDialog,
  ] = useState(false);
  const [previewCollectionImage, setPreviewCollectionImage] = useState("");

  useEffect(() => {
    myRef.current?.scrollIntoView({ behavior: "smooth" });
    getAuthorWiseData();
    getComment();
    convertUrlToJson(templateInfo.source);
  }, [templateInfo]);

  const convertUrlToJson = async (url: string) => {
    const response = await fetch(url);
    const result = await response.json();
    const modifiedLibraryItems = result?.libraryItems?.length
      ? result?.libraryItems.map((libraryItem: LibraryType) => {
          return { ...libraryItem, isSelected: false };
        })
      : [];
    setLibraryItems(modifiedLibraryItems);
    generateSvgForLibrary(modifiedLibraryItems);
  };

  const getFilesByLibraryElements = (elements: ExcalidrawElement[]) => {
    let files: BinaryFiles = {};
    for (let index = 0; index < elements.length; index++) {
      const element: ExcalidrawElement = elements[index];
      if (
        element.type === "image" ||
        element.type === "formula" ||
        element.type === "mermaidDiagram" ||
        element.type === "video" ||
        element.type === "audio" ||
        element.type === "textWithStyles"
      ) {
        if (element?.fileId !== null) {
          if (appState.files.hasOwnProperty(element.fileId)) {
            files[element.fileId] = appState.files[element.fileId];
          }
          if (templateInfo && templateInfo.files) {
            const elementFileIndex = templateInfo.files.findIndex(
              (file: { id: FileId }) => file.id == element.fileId,
            );

            if (elementFileIndex !== -1) {
              const elementFile = templateInfo.files[
                elementFileIndex
              ] as LibraryFileType;
              files[elementFile.id as string] = {
                mimeType:
                  elementFile.mimeType ||
                  (MIME_TYPES.binary as BinaryFileData["mimeType"]),
                id: elementFile.id as BinaryFileData["id"],
                dataURL: elementFile.url as BinaryFileData["dataURL"],
                created: elementFile.created,
                isPublished: true,
              };
            }
          }
        }
      }
    }
    return files ? files : null;
  };

  const generateSvgForLibrary = async (libraryItems: LibraryType[]) => {
    const librariesWithSvg: LibraryType[] = await Promise.all(
      libraryItems.map(async (libraryItem: LibraryType) => {
        const files = getFilesByLibraryElements(libraryItem.elements);
        const svg = libraryItem?.elements?.length
          ? await exportToSvg(
              libraryItem.elements,
              {
                exportBackground: false,
                viewBackgroundColor: OpenColor.white,
              },
              files,
            )
          : null;
        return {
          ...libraryItem,
          svg,
        };
      }),
    );
    librariesWithSvg && setLibraryItems([...librariesWithSvg]);
  };

  const getAuthorWiseData = async () => {
    const response = await getAuthorWiseTemplate(templateInfo?.userId);
    setRelatedData(response?.results);
  };

  const getComment = async () => {
    const response = await getComments(templateInfo?.id);
    setCommentData(response?.results);
  };

  useEffect(() => {
    getAuthorWiseData();
    getComment();
  }, []);

  const handleCheck = (tabValue: Number) => {
    setCheck(tabValue);
  };

  const handleMoreInfo = (data: CollocationType) => {
    selectDetailCollectionHandler(data);
    changeTab("info");
  };

  const handleTag = async (data: string) => {
    const response = await getTagWiseTemplate(data);
    setRelatedData(response.results);
    handleCheck(2);
    setSelectedTag(data);
    // console.log(response);
  };

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const profile = localStorage.getItem("profileBase64");
  const storage = process.env.REACT_APP_STORAGE || "";

  const createComment = async (data: string) => {
    const body: CommentType = {
      name: user.displayName,
      comment: data,
      libraryId: templateInfo.id,
      userId: user.userId ? user.userId : user.mail,
      image: user.UrlPhoto
        ? `https://${storage}.blob.core.windows.net/profiles/${
            user.UrlPhoto.split("/").pop().split("?")[0]
          }`
        : profile,
    };
    const response = await createComments(body);
    const cmtArray = commentData;
    cmtArray.unshift(response?.result);
    setCommentData([...cmtArray]);
    setCommentDetail({ html: "", text: "" });
  };

  const deleteComment = async (id: string) => {
    const response = await deleteComments(id);
    setCommentData([...commentData.filter((data) => data.id !== id)]);
  };
  const editCmt = async (id: string) => {
    const body: CommentType = {
      id: id,
      comment: editCommentDetail.html,
    };
    const response = await editComments(body);
    const index = commentData.findIndex((data) => data.id === id);
    if (index > -1) {
      commentData[index].comment = response?.result.comment;
    }
    setCommentData([...commentData]);
    setEditCommentDetail({ html: "", text: "" });
  };

  // laod element from DB - azure blob storage link
  const loadElementsFromDB = async (url: string) => {
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const elements: NonDeleted<ExcalidrawElement>[] = [];

        if (res.data?.library) {
          res.data.library.map((lib: any) => {
            lib.map((data: NonDeleted<ExcalidrawElement>) => {
              elements.push(data);
            });
          });
        }
        if (res.data?.libraryItems) {
          res.data.libraryItems.map((lib: any) => {
            lib.elements.map((data: NonDeleted<ExcalidrawElement>) => {
              elements.push(data);
            });
          });
        }

        const slug = new URLSearchParams(window.location.search).get("slug");
        const room = new URLSearchParams(window.location.search).get("room");
        const lessonId = new URLSearchParams(window.location.search).get(
          "lessonId",
        );

        if (room) {
          const finalURL = `/?slug=${slug}&lessonId=${lessonId}/#room=${room}/#addLibrary=${url}`;
          const libraryId = finalURL.split("#addLibrary=")[1];
          const urlArray = libraryId ? libraryId.split(",") : [];
          importLibraryFromUrl(urlArray);
        } else {
          const finalURL = `/?slug=${slug}&lessonId=${lessonId}/#addLibrary=${url}`;
          const libraryId = finalURL.split("#addLibrary=")[1];
          const urlArray = libraryId ? libraryId.split(",") : [];
          importLibraryFromUrl(urlArray);
        }
        // onInsertShape(elements);

        return res.data;
      });
  };

  const toggleLibrarySelect = (i: number) => {
    let clonedLibraryItems = [...libraryItems];
    const isSelected = clonedLibraryItems[i].isSelected;
    if (isSelected) {
      clonedLibraryItems[i].isSelected = !isSelected;
    } else {
      clonedLibraryItems = clonedLibraryItems.map((library) => {
        return { ...library, isSelected: false };
      });
      clonedLibraryItems[i].isSelected = !clonedLibraryItems[i].isSelected;
    }
    setLibraryItems(clonedLibraryItems);
  };

  const closeModal = () => {
    setPreviewCollectionImageDialog(false);
  };

  return (
    <div className="template_info" ref={myRef}>
      {previewCollectionImageDialog && (
        <Dialog
          onCloseRequest={closeModal}
          title={t("confirmDialog.previewCollectionImage")}
          closeOnClickOutside={true}
          open={previewCollectionImageDialog}
          setOpen={() => true}
          children={
            Array.isArray(previewCollectionImage) ? (
              previewCollectionImage.length > 0 &&
              previewCollectionImage.length > 1 ? (
                <LibraryCarousel
                  images={previewCollectionImage}
                  index={1}
                  name="dialog"
                  className="preview"
                />
              ) : (
                <CollectionImagePreview
                  previewURL={previewCollectionImage[0]}
                  index={1}
                  className="preview"
                />
              )
            ) : (
              <CollectionImagePreview
                previewURL={previewCollectionImage}
                index={1}
                className="preview"
              />
            )
          }
          className="preview-dialog"
        />
      )}
      <div className="row">
        <div className="col-md-4">
          <h1 className="title">{templateInfo.name}</h1>
          <h4 className="subheading">
            Published:{" "}
            {moment.utc(templateInfo.created).local().format("YYYY-MM-DD")}
          </h4>
          <p className="description">{templateInfo.description}</p>

          <ToolButton
            className="bg-brand text-white pr-2 infoBtn"
            key="moreInfo"
            type={ToolButtonEnum.BUTTON}
            title="collection"
            aria-label="collection"
            onClick={() => {
              loadElementsFromDB(templateInfo.source);
              handleClose();
            }}
          >
            <span>{t("cards.addToCollection")}</span>
          </ToolButton>
        </div>
        <div
          className="col-md-8 py-3 pt-lg-0 d-flex flex-column align-items-center"
          // style={{ width: "fit-content" }}
        >
          <h6 className="fw-bold text-center my-3">{templateInfo?.title}</h6>
          <div
            className="img-div"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPreviewCollectionImageDialog(true);
              setPreviewCollectionImage(
                templateInfo.preview ? templateInfo.preview : "",
              );
            }}
          >
            {Array.isArray(templateInfo.preview) &&
            templateInfo.preview.length > 0 ? (
              templateInfo.preview.length > 1 ? (
                <div
                  className="card-library"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: 620,
                    maxHeight: 470,
                    overflow: "auto",
                    gap: 10,
                  }}
                >
                  {/* <div className="checkbox-inputs" onClick={(e) => {}}> */}
                  {libraryItems.map((library, i) => {
                    return (
                      library.svg && (
                        <div
                          className="d-flex flex-column justify-content-between"
                          key={`library-item-${i}`}
                        >
                          <b className="text-center mb-2">{library.name}</b>
                          <ImageCard
                            svg={library.svg}
                            selected={library.isSelected}
                            toggleLibrarySelect={(e: React.MouseEvent) => {
                              e.preventDefault();
                              toggleLibrarySelect(i);
                            }}
                          />
                        </div>
                      )
                    );
                  })}
                  {/* </div> */}
                </div>
              ) : (
                <CollectionImagePreview
                  previewURL={templateInfo.preview[0]}
                  index={1}
                />
              )
            ) : (
              <CollectionImagePreview
                previewURL={templateInfo.preview}
                index={1}
              />
            )}
          </div>
        </div>
        {templateInfo.userId === userMail && (
          <div className="text-end px-0 mt-2">
            <ToolButton
              className="bg-brand text-white pr-2 infoBtn "
              key="editLibrary"
              type={ToolButtonEnum.BUTTON}
              title="editLibrary"
              aria-label="editLibrary"
              onClick={() => {
                setAppState({
                  editingLibrary: {
                    ...appState.editingLibrary,
                    libraryId: appState.editingLibrary.libraryInfo.id,
                    isEditing: true,
                    libraryInfo: templateInfo,
                    libraryItems,
                    oldElements: [],
                  },
                });

                editCollection(libraryItems);
                handleClose();
              }}
            >
              <span>Edit collection</span>
            </ToolButton>
          </div>
        )}
      </div>
      <div className="row pt-5">
        <div className="col-md-6 col-sm-12">
          <h3 className="author_title">Author</h3>
          <div className="row">
            <div className="col-md-4 col-sm-12 p-0 d-flex">
              <Avatar onClick={() => {}} color={"#4c6ef5"} border={"#4c6ef5"}>
                {templateInfo.authors[0].url &&
                templateInfo.authors[0].url !== "null" ? (
                  <img
                    src={templateInfo.authors[0].url}
                    alt=""
                    className="Avatar"
                  />
                ) : (
                  templateInfo.authors[0].name.substring(0, 2)
                )}
              </Avatar>

              <span className="author_name">
                {templateInfo.authors[0].name}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <h3 className="author_title pt-3 pt-lg-0 ">
            {t("previewDialog.tags")}
          </h3>
          <div className="tags row ms-0">
            {templateInfo?.tag?.length ? (
              templateInfo.tag.map((data, index) => (
                <p
                  className={`tag_btn ${data === selectedTag ? "active" : ""}`}
                  onClick={() => {
                    handleTag(data);
                  }}
                >
                  {data}
                </p>
              ))
            ) : (
              <>
                <p className="text-center">
                  {t("previewDialog.noTagsAvalible")}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-md-6 col-sm-12 p-0 d-flex">
          <Avatar
            onClick={() => {
              // const msg =
              //   user?.displayName +
              //   " clicked profile!\nYour Email is : " +
              //   user?.mail;
              // alert(msg);
            }}
            color={"#4c6ef5"}
            border={"#4c6ef5"}
          >
            {user.UrlPhoto ? (
              <img src={user.UrlPhoto} className="Avatar" />
            ) : profile ? (
              <img src={profile} className="Avatar" />
            ) : (
              user.displayName.substring(0, 2)
            )}
          </Avatar>
          <div className="ms-2 flex-grow-1" style={{ width: "90%" }}>
            <TipTapEditorWithCommentBox
              createComment={createComment}
              setComment={setCommentDetail}
              comment={commentDetail.html}
              textCommentLength={commentDetail.text.length}
            />
            <div
              style={{
                flexDirection: "column",
                display: "flex",
              }}
            >
              <ToolButton
                className="bg-brand text-white pr-2 infoBtn mt-2 px-2"
                key=""
                type={ToolButtonEnum.BUTTON}
                title="comment"
                aria-label="comment"
                style={{
                  cursor: commentDetail.text.length > 400 && "not-allowed",
                  width: "15%",
                }}
              >
                <span
                  style={{ fontSize: 13 }}
                  onClick={() => {
                    isEditComment && setIsEditComment("");
                    createComment(commentDetail.html);
                  }}
                >
                  {t("previewDialog.submit")}
                </span>
              </ToolButton>
            </div>
          </div>
        </div>
      </div>
      {commentData.length
        ? commentData.map((data) => {
            const date = data._ts && new Date(data._ts * 1000).toDateString();

            return (
              <>
                <div className="row mt-3">
                  <div className="col-md-6 col-sm-12 p-0 d-flex">
                    <Avatar color={"#4c6ef5"} border={"#4c6ef5"}>
                      {data.image && data.image !== "null" ? (
                        <img src={data.image} alt="" className="Avatar" />
                      ) : data.name ? (
                        data.name ? (
                          data.name.substring(0, 2)
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </Avatar>

                    <div
                      className="ms-2 flex-grow-1"
                      onMouseOver={() => {
                        data.userId === user?.mail
                          ? setShowOption(data.id ? data.id : "")
                          : setShowOption("");
                      }}
                      onMouseLeave={() => setShowOption("")}
                      style={{ width: "90%" }}
                    >
                      <p className="mb-0 comment_text d-flex">
                        {data.name}
                        <span className="comment_time">&nbsp; {date}</span>
                        {showOption === data.id && (
                          <>
                            <p
                              style={{ color: "#d33228", fontSize: 12 }}
                              className="ms-auto mb-0 cursor-pointer"
                              onClick={() =>
                                deleteComment(data.id ? data.id : "")
                              }
                            >
                              {t("previewDialog.delete")}
                            </p>
                            <p
                              style={{ color: "#777", fontSize: 12 }}
                              className="ms-1 mb-0 cursor-pointer"
                              onClick={() => {
                                setIsEditComment(data.id ? data.id : "");
                                setEditCommentDetail({
                                  html: data.comment ? data.comment : "",
                                  text: "",
                                });
                              }}
                            >
                              {t("previewDialog.edit")}
                            </p>
                          </>
                        )}
                      </p>
                      {isEditComment === data.id ? (
                        <div className="ms-1 flex-grow-1">
                          <TipTapEditorWithCommentBox
                            createComment={editCmt}
                            setComment={setEditCommentDetail}
                            comment={editCommentDetail.html}
                            textCommentLength={editCommentDetail.text.length}
                          />
                          <div
                            style={{
                              flexDirection: "column",
                              display: "flex",
                            }}
                          >
                            <ToolButton
                              className="bg-brand text-white pr-2 infoBtn mt-2 px-2"
                              key=""
                              type={ToolButtonEnum.BUTTON}
                              title="comment"
                              aria-label="comment"
                              style={{
                                cursor:
                                  editCommentDetail.text.length > 400 &&
                                  "not-allowed",
                                width: "15%",
                              }}
                            >
                              <span
                                style={{ fontSize: 13 }}
                                onClick={() => {
                                  editCmt(data.id as string);
                                  setIsEditComment("");
                                }}
                              >
                                {t("previewDialog.submit")}
                              </span>
                            </ToolButton>
                          </div>
                        </div>
                      ) : (
                        // <p className="mb-0 comment_text">{data.comment}</p>
                        <p
                          className="mb-0 comment_text"
                          dangerouslySetInnerHTML={{ __html: data.comment }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })
        : ""}
      <div className="row pt-4">
        <div className="col-md-12 col-sm-12" id="tsum-tabs">
          <input
            id="tab1"
            type="radio"
            name="tabs"
            checked={check === 1}
            onClick={(e) => {
              handleCheck(1);
              getAuthorWiseData();
            }}
          />
          <label htmlFor="tab1">Templates from this Author</label>

          <input
            id="tab2"
            type="radio"
            name="tabs"
            checked={check === 2}
            onClick={(e) => {
              handleCheck(2);
              templateInfo?.tag?.length
                ? handleTag(templateInfo.tag[0])
                : setRelatedData([]);
            }}
          />
          <label htmlFor="tab2">Related templates</label>

          <section id={check === 1 ? "content1" : "content2"} className="row">
            {relatedData.length ? (
              relatedData.map((data, index) => {
                return (
                  templateInfo.id !== data.id && (
                    <div
                      className="col-12 col-lg-4 item d-grid"
                      key={`relatedData-${index + 1}`}
                    >
                      <div className="card item-card card-block mt-4">
                        <h4 className="card-title d-flex justify-content-between">
                          {data.name}
                          <div className="likeSection">
                            <ToolButton
                              key="newPage"
                              type={ToolButtonEnum.BUTTON}
                              title="Bookmark"
                              aria-label="Bookmark"
                              icon={
                                data?.isBookmark
                                  ? fillBookmarkIcon
                                  : bookmarkIcon
                              }
                              className="likeIcon"
                              onClick={() => {
                                bookmarkCollection(data.id);
                                const templateIndex = relatedData.findIndex(
                                  (t) => t.id === data.id,
                                );
                                if (templateIndex > -1) {
                                  relatedData[
                                    templateIndex
                                  ].isBookmark = !data.isBookmark;

                                  setRelatedData([...relatedData]);
                                }
                              }}
                            ></ToolButton>
                            <ToolButton
                              key="newPage"
                              type={ToolButtonEnum.BUTTON}
                              title="Like"
                              aria-label="Like"
                              icon={data.isLiked ? fillLikeIcon : whiteLikeIcon}
                              className="likeIcon"
                              onClick={() => {
                                likeUnlikeCollection(data.id);
                                const templateIndex = relatedData.findIndex(
                                  (t) => t.id === data.id,
                                );
                                if (templateIndex > -1) {
                                  relatedData[
                                    templateIndex
                                  ].isLiked = !data.isLiked;
                                  relatedData[
                                    templateIndex
                                  ].likeCount = !data.isLiked
                                    ? data.likeCount - 1
                                    : data.likeCount + 1;
                                  setRelatedData([...relatedData]);
                                }
                              }}
                            >
                              <span className="likeFont">{data.likeCount}</span>
                            </ToolButton>
                          </div>
                        </h4>
                        <div className="mx-auto">
                          {Array.isArray(data.preview) ? (
                            data.preview.length > 0 &&
                            (data.preview.length > 1 ? (
                              <LibraryCarousel
                                images={(data.preview as unknown) as string[]}
                                index={index}
                                name={data.name}
                              />
                            ) : (
                              <CollectionImagePreview
                                previewURL={data.preview[0]}
                                index={index}
                              />
                            ))
                          ) : (
                            <CollectionImagePreview
                              previewURL={data.preview}
                              index={index}
                            />
                          )}
                        </div>
                        {/* <img
                          key={index}
                          src={data.preview}
                          alt=""
                          className="collectionImage"
                        /> */}
                        <div className="item-card-title mt-3 mb-3 d-flex justify-content-between">
                          <div className="d-flex">
                            <Avatar
                              onClick={() => {
                                // const msg =
                                //   user?.displayName +
                                //   " clicked profile!\nYour Email is : " +
                                //   user?.mail;
                                // alert(msg);
                              }}
                              color={"#4c6ef5"}
                              border={"#4c6ef5"}
                            >
                              {data.authors[0].url &&
                              data.authors[0].url !== "null" ? (
                                <img
                                  key={index}
                                  src={data.authors[0].url}
                                  alt=""
                                  className="Avatar"
                                />
                              ) : (
                                data.authors[0].name.substring(0, 2)
                              )}
                            </Avatar>
                            <span className="ms-2 mt-2">
                              {data.authors[0].name}
                            </span>
                          </div>
                          <p className="date">
                            {moment
                              .utc(data.created)
                              .local()
                              .format("DD MMM YYYY, h:mm A")}
                          </p>
                        </div>
                        <div className="h-100 d-flex flex-column justify-content-end">
                          <p className="card-text d-flex justify-content-end">
                            <ToolButton
                              className="bg-brand text-white pr-2 infoBtn"
                              key="moreInfo"
                              type={ToolButtonEnum.BUTTON}
                              title="More info"
                              aria-label="More info"
                            >
                              <span
                                onClick={(e) => {
                                  handleMoreInfo(data);
                                }}
                              >
                                More info
                              </span>
                            </ToolButton>
                            <ToolButton
                              className=" pr-2 infoBtn"
                              key="moreInfo"
                              type={ToolButtonEnum.BUTTON}
                              title="collection"
                              aria-label="collection"
                              onClick={() => {
                                loadElementsFromDB(data.source);
                                handleClose();
                              }}
                            >
                              <span>{t("cards.addToCollection")}</span>
                            </ToolButton>
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                );
              })
            ) : (
              <span className="ml-3 text-center">
                There is no related templates.
              </span>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default TemplateInfo;
