import { useCallback, useEffect, useRef } from "react";
import { TOAST_TIMEOUT } from "../constants";
import "./Toast.scss";

export enum ToastType {
  DEFAULT = "default",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

const ToastBackColorMap = {
  [ToastType.DEFAULT]: "#D0D0CE",
  [ToastType.WARNING]: "#f1c40f",
  [ToastType.ERROR]: "#e74c3c",
  [ToastType.SUCCESS]: "#07bc0c",
};

export const Toast = ({
  type = ToastType.DEFAULT,
  message,
  clearToast,
  className,
}: {
  type?: ToastType;
  message: string;
  clearToast: () => void;
  className?: String;
}) => {
  const timerRef = useRef<number>(0);

  const scheduleTimeout = useCallback(
    () =>
      (timerRef.current = window.setTimeout(() => clearToast(), TOAST_TIMEOUT)),
    [clearToast],
  );

  useEffect(() => {
    scheduleTimeout();
    return () => clearTimeout(timerRef.current);
  }, [scheduleTimeout, message]);

  return (
    <div
      style={{
        backgroundColor: ToastBackColorMap[type],
        width: className && "fit-content",
        padding: className && "6px",
        left:
          (className === "styleChange" ? "40%" : "") ||
          (className === "style" ? "36%" : ""),
      }}
      className="Toast"
      onMouseEnter={() => clearTimeout(timerRef?.current)}
      onMouseLeave={scheduleTimeout}
    >
      <p className="Toast__message">{message}</p>
    </div>
  );
};
