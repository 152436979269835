import { ERDiagramSyntax } from "./DiagramSyntax/ERDiagramSyntax";
import { GitGraphDiagramSyntax } from "./DiagramSyntax/GitGraphDiagramSyntax";
import { MindMapDiagramSyntax } from "./DiagramSyntax/MindMapDiagramSyntax";
import { RequirementDiagramSyntax } from "./DiagramSyntax/RequirementDiagramSyntax";

export const diagramExample = (type: string, language: string) => {
  switch (type) {
    case "flowchart TD":
      switch (language) {
        case "English":
          return `flowchart TD
    A[Start] --> B{Is it?}
    B -->|Yes| C[OK]
    C --> D[Rethink]
    D --> B
    B ---->|No| E[End]`;
        case "Spanish":
          return `flowchart TD
    A[Inicio] --> B{¿Es así?}
    B -->|Sí| C[OK]
    C --> D[Reconsiderar]
    D --> B
    B ---->|No| E[Fin]`;
        case "Português":
          return `flowchart TD
    A[Início] --> B{É isso?}
    B -->|Sim| C[OK]
    C --> D[Reconsiderar]
    D --> B
    B ---->|Não| E[Fim]`;
        case "French":
          return `flowchart TD
    A[Début] --> B{Est-ce?}
    B -->|Oui| C[D'accord]
    C --> D[Repenser]
    D --> B
    B ---->|Non| E[Fin]`;
        case "German":
          return `flowchart TD
    A[Start] --> B{Ist es so?}
    B -->|Ja| C[OK]
    C --> D[Überdenken]
    D --> B
    B ---->|Nein| E[Ende]`;
        case "हिन्दी":
          return `flowchart TD
    A[शुरू] --> B{क्या यह सही है?}
    B -->|हां| C[ठीक है]
    C --> D[पुनर्विचार करें]
    D --> B
    B ---->|नहीं| E[समाप्त]`;
        default:
          return `flowchart TD
    A[Start] --> B{Is it?}
    B -->|Yes| C[OK]
    C --> D[Rethink]
    D --> B
    B ---->|No| E[End]`;
      }

    case "mindmap":
      switch (language) {
        case "English":
          return `mindmap
  root((mindmap))
    Origins
      Long History
      Popularization
        British author Tony Buzan
    Research
      On effectiveness<br/>and characteristics
      On automatic creation
        Uses
            Creative techniques
            Strategic planning
            Argument mapping
    Tools
      Paper and pencil
      Mermaid
`;
        case "Spanish":
          return `mindmap
  root((mindmap))
    Orígenes
      Historia larga
      Popularización
        Autor británico de psicología popular Tony Buzan
    Investigación
      Sobre efectividad<br/>y características
      Sobre creación automática
        Usos
            Técnicas creativas
            Planificación estratégica
            Mapeo de argumentos
    Herramientas
      Papel y lápiz
      Mermaid`;

        case "Português":
          return `mindmap
  root((mindmap))
    Origens
      Longa história
      Popularização
        Autor britânico de psicologia popular Tony Buzan
    Pesquisa
      Sobre eficácia<br/>e características
      Sobre criação automática
        Usos
            Técnicas criativas
            Planejamento estratégico
            Mapeamento de argumentos
    Ferramentas
      Papel e caneta
      Mermaid`;

        case "French":
          return `mindmap
  root((mindmap))
    Origines
      Longue histoire
      Popularisation
        Auteur britannique de psychologie populaire Tony Buzan
    Recherche
      Sur l'efficacité<br/>et les caractéristiques
      Sur la création automatique
        Utilisations
            Techniques créatives
            Planification stratégique
            Cartographie des arguments
    Outils
      Papier et stylo
      Mermaid`;

        case "German":
          return `mindmap
  root((mindmap))
    Ursprünge
      Lange Geschichte
      Popularisierung
        Britischer populärpsychologischer Autor Tony Buzan
    Forschung
      Zur Wirksamkeit<br/>und Eigenschaften
      Zur automatischen Erstellung
        Verwendungen
            Kreative Techniken
            Strategische Planung
            Argumentmapping
    Werkzeuge
      Papier und Stift
      Mermaid`;

        case "हिन्दी":
          return `mindmap
  root((mindmap))
    उत्पत्ति
      लंबा इतिहास
      ::icon(fa fa-book)
      लोकप्रियकरण
        ब्रिटिश लोकप्रिय मनोविज्ञान लेखक टोनी ब्यूज़न
    शोध
      प्रभावशीलता<br/>और विशेषताएँ
      स्वचालित निर्माण पर
        उपयोग
            रचनात्मक तकनीकें
            रणनीतिक योजना
            तर्क मानचित्रण
    उपकरण
      कागज और कलम
      Mermaid`;

        default:
          return `mindmap
                  RootNode
                    ChildNode1
                    ChildNode2
                      GrandchildNode1
                      GrandchildNode2
                  Indentation indicates hierarchy.`;
      }

    case "timeline":
      switch (language) {
        case "English":
          return `timeline
    title History of Social Media Platform
    2002 : LinkedIn
    2004 : Facebook
         : Google
    2005 : Youtube
    2006 : Twitter`;
        case "Spanish":
          return `timeline
    title Historia de la Plataforma de Redes Sociales
    2002 : LinkedIn
    2004 : Facebook
         : Google
    2005 : Youtube
    2006 : Twitter`;

        case "Português":
          return `timeline
    title História da Plataforma de Mídias Sociais
    2002 : LinkedIn
    2004 : Facebook
         : Google
    2005 : Youtube
    2006 : Twitter`;

        case "French":
          return `timeline
    title Histoire des Plateformes de Médias Sociaux
    2002 : LinkedIn
    2004 : Facebook
         : Google
    2005 : Youtube
    2006 : Twitter`;

        case "German":
          return `timeline
    title Geschichte der sozialen Medienplattformen
    2002 : LinkedIn
    2004 : Facebook
         : Google
    2005 : Youtube
    2006 : Twitter`;

        case "हिन्दी":
          return `timeline
    title सामाजिक मीडिया प्लेटफार्म का इतिहास
    2002 : LinkedIn
    2004 : Facebook
         : Google
    2005 : Youtube
    2006 : Twitter`;

        default:
          return `timeline
    title History of Social Media Platform
    2002 : LinkedIn
    2004 : Facebook
         : Google
    2005 : Youtube
    2006 : Twitter`;
      }

    case "pie":
      switch (language) {
        case "English":
          return `pie title Pets adopted by volunteers
          "Dogs" : 386
          "Cats" : 85
          "Rats" : 15`;
        case "Spanish":
          return `pie title Mascotas adoptadas por voluntarios
    "Perros" : 386
    "Gatos" : 85
    "Ratas" : 15`;

        case "Português":
          return `pie title Animais adotados por voluntários
    "Cães" : 386
    "Gatos" : 85
    "Ratos" : 15`;

        case "French":
          return `pie title Animaux adoptés par les bénévoles
    "Chiens" : 386
    "Chats" : 85
    "Rats" : 15`;

        case "German":
          return `pie title Haustiere adoptiert von Freiwilligen
    "Hunde" : 386
    "Katzen" : 85
    "Ratten" : 15`;

        case "हिन्दी":
          return `pie title स्वेच्छिकों द्वारा अपनाए गए पालतू जानवर
    "कुत्ते" : 386
    "बिल्लियाँ" : 85
    "चूहे" : 15`;

        default:
          return `pie title Pets adopted by volunteers
          "Dogs" : 386
          "Cats" : 85
          "Rats" : 15`;
      }

    case "Gantt Chart":
      switch (language) {
        case "English":
          return `gantt
          title A Gantt Diagram
          dateFormat YYYY-MM-DD
          section Section
              A task          :a1, 2014-01-01, 30d
              Another task    :after a1, 20d
          section Another
              Task in Another :2014-01-12, 12d
              another task    :24d`;
        case "Spanish":
          return `gantt
    title Un Diagrama de Gantt
    dateFormat YYYY-MM-DD
    section Sección
        Una tarea          :a1, 2014-01-01, 30d
        Otra tarea    :after a1, 20d
    section Otra
        Tarea en Otra :2014-01-12, 12d
        otra tarea    :24d`;

        case "Português":
          return `gantt
    title Um Diagrama de Gantt
    dateFormat YYYY-MM-DD
    section Seção
        Uma tarefa          :a1, 2014-01-01, 30d
        Outra tarefa    :after a1, 20d
    section Outra
        Tarefa em Outra :2014-01-12, 12d
        outra tarefa    :24d`;

        case "French":
          return `gantt
    title Un Diagramme de Gantt
    dateFormat YYYY-MM-DD
    section Section
        Une tâche          :a1, 2014-01-01, 30d
        Une autre tâche    :after a1, 20d
    section Une autre
        Tâche dans Une autre :2014-01-12, 12d
        autre tâche    :24d`;

        case "German":
          return `gantt
    title Ein Gantt-Diagramm
    dateFormat YYYY-MM-DD
    section Abschnitt
        Eine Aufgabe          :a1, 2014-01-01, 30d
        Eine weitere Aufgabe    :after a1, 20d
    section Eine andere
        Aufgabe in Eine andere :2014-01-12, 12d
        eine weitere Aufgabe    :24d`;

        case "हिन्दी":
          return `gantt
    title एक गेंट्ट डायग्राम
    dateFormat YYYY-MM-DD
    section अनुभाग
        एक कार्य          :a1, 2014-01-01, 30d
        दूसरा कार्य    :after a1, 20d
    section दूसरा
        दूसरे में कार्य :2014-01-12, 12d
        अन्य कार्य    :24d`;

        default:
          return `gantt
          title A Gantt Diagram
          dateFormat YYYY-MM-DD
          section Section
              A task          :a1, 2014-01-01, 30d
              Another task    :after a1, 20d
          section Another
              Task in Another :2014-01-12, 12d
              another task    :24d`;
      }

    case "xychart-beta":
      switch (language) {
        case "English":
          return `xychart-beta
          title "Sales Revenue"
          x-axis [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec]
          y-axis "Revenue (in $)" 4000 --> 11000
          bar [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]
          line [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]`;
        case "Spanish":
          return `xychart-beta
    title "Ingresos por ventas"
    x-axis [ene, feb, mar, abr, may, jun, jul, ago, sep, oct, nov, dic]
    y-axis "Ingresos (en $)" 4000 --> 11000
    bar [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]
    line [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]`;

        case "Português":
          return `xychart-beta
    title "Receita de Vendas"
    x-axis [jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov, dez]
    y-axis "Receita (em $)" 4000 --> 11000
    bar [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]
    line [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]`;

        case "French":
          return `xychart-beta
    title "Revenus des ventes"
    x-axis [janv, fév, mars, avr, mai, juin, juil, août, sept, oct, nov, déc]
    y-axis "Revenu (en $)" 4000 --> 11000
    bar [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]
    line [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]`;

        case "German":
          return `xychart-beta
    title "Verkaufserlöse"
    x-axis [Jan, Feb, Mär, Apr, Mai, Jun, Jul, Aug, Sep, Okt, Nov, Dez]
    y-axis "Erlös (in $)" 4000 --> 11000
    bar [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]
    line [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]`;

        case "हिन्दी":
          return `xychart-beta
    title "बिक्री राजस्व"
    x-axis [जन, फ़र, मार्च, अप्रैल, मई, जून, जुलाई, अगस्त, सितम्बर, अक्टूबर, नवम्बर, दिसम्बर]
    y-axis "राजस्व (डॉलर में)" 4000 --> 11000
    bar [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]
    line [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]`;

        default:
          return `xychart-beta
          title "Sales Revenue"
          x-axis [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec]
          y-axis "Revenue (in $)" 4000 --> 11000
          bar [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]
          line [5000, 6000, 7500, 8200, 9500, 10500, 11000, 10200, 9200, 8500, 7000, 6000]`;
      }

    case "State Diagram":
      switch (language) {
        case "English":
          return `---
title: Simple sample
---
stateDiagram-v2
    [*] --> Still
    Still --> [*]

    Still --> Moving
    Moving --> Still
    Moving --> Crash
    Crash --> [*]`;
        case "Spanish":
          return `---
title: Ejemplo simple
---
stateDiagram-v2
    [*] --> Estático
    Estático --> [*]

    Estático --> Movimiento
    Movimiento --> Estático
    Movimiento --> Choque
    Choque --> [*]`;

        case "Português":
          return `---
title: Exemplo simples
---
stateDiagram-v2
    [*] --> Estático
    Estático --> [*]

    Estático --> Movimento
    Movimento --> Estático
    Movimento --> Colisão
    Colisão --> [*]`;

        case "French":
          return `---
title: Exemple simple
---
stateDiagram-v2
    [*] --> Stable
    Stable --> [*]

    Stable --> Mouvement
    Mouvement --> Stable
    Mouvement --> Collision
    Collision --> [*]`;

        case "German":
          return `---
title: Einfaches Beispiel
---
stateDiagram-v2
    [*] --> Stabil
    Stabil --> [*]

    Stabil --> Bewegung
    Bewegung --> Stabil
    Bewegung --> Zusammenstoß
    Zusammenstoß --> [*]`;

        case "हिन्दी":
          return `---
title: साधारण उदाहरण
---
stateDiagram-v2
    [*] --> स्थिर
    स्थिर --> [*]

    स्थिर --> गति
    गति --> स्थिर
    गति --> टकराव
    टकराव --> [*]`;

        default:
          return `---
title: Simple sample
---
stateDiagram-v2
    [*] --> Still
    Still --> [*]

    Still --> Moving
    Moving --> Still
    Moving --> Crash
    Crash --> [*]`;
      }

    case "erDiagram":
      switch (language) {
        case "English":
          return `---
title: Order example
---
erDiagram
     CUSTOMER ||--o{ ORDER : places
     ORDER ||--|{ LINE-ITEM : contains
     CUSTOMER }|..|{ DELIVERY-ADDRESS : uses`;

        case "Spanish":
          return `---
title: Ejemplo de Pedido
---
erDiagram
    CLIENTE ||--o{ PEDIDO : realiza
    PEDIDO ||--|{ LINEA-ARTICULO : contiene
    CLIENTE }|..|{ DIRECCION-ENTREGA : utiliza`;

        case "Português":
          return `---
title: Exemplo de Pedido
---
erDiagram
    CLIENTE ||--o{ PEDIDO : faz
    PEDIDO ||--|{ ITEM-DO-PEDIDO : contém
    CLIENTE }|..|{ ENDEREÇO-DE-ENTREGA : usa`;

        case "French":
          return `---
title: Exemple de commande
---
erDiagram
    CLIENT ||--o{ COMMANDE : place
    COMMANDE ||--|{ ARTICLE : contient
    CLIENT }|..|{ ADRESSE-DE-LIVRAISON : utilise`;

        case "German":
          return `---
title: Bestellbeispiel
---
erDiagram
    KUNDE ||--o{ BESTELLUNG : tätigt
    BESTELLUNG ||--|{ LINIEN-ARTIKEL : enthält
    KUNDE }|..|{ LIEFER-ADRESSE : nutzt`;

        case "हिन्दी":
          return `---
title: आदेश का उदाहरण
---
erDiagram
    ग्राहक ||--o{ आदेश : रखता है
    आदेश ||--|{ लाइन-आइटम : Contains
    ग्राहक }|..|{ वितरण-पता : उपयोग करता है`;

        default:
          return `---
title: Order example
---
erDiagram
    CUSTOMER ||--o{ ORDER : places
    ORDER ||--|{ LINE-ITEM : contains
    CUSTOMER }|..|{ DELIVERY-ADDRESS : uses`;
      }

    case "quadrantChart":
      switch (language) {
        case "English":
          return `quadrantChart
          title Reach and engagement of campaigns
          x-axis Low Reach --> High Reach
          y-axis Low Engagement --> High Engagement
          quadrant-1 We should expand
          quadrant-2 Need to promote
          quadrant-3 Re-evaluate
          quadrant-4 May be improved
          Campaign A: [0.3, 0.6]
          Campaign B: [0.45, 0.23]
          Campaign C: [0.57, 0.69]
          Campaign D: [0.78, 0.34]
          Campaign E: [0.40, 0.34]
          Campaign F: [0.35, 0.78]`;

        case "Spanish":
          return `quadrantChart
          title Alcance y compromiso de las campañas
          x-axis Bajo Alcance --> Alto Alcance
          y-axis Bajo Compromiso --> Alto Compromiso
          quadrant-1 Deberíamos expandir
          quadrant-2 Necesita promover
          quadrant-3 Reevaluar
          quadrant-4 Puede ser mejorado
          Campaña A: [0.3, 0.6]
          Campaña B: [0.45, 0.23]
          Campaña C: [0.57, 0.69]
          Campaña D: [0.78, 0.34]
          Campaña E: [0.40, 0.34]
          Campaña F: [0.35, 0.78]`;

        case "Português":
          return `quadrantChart
          title Alcance e engajamento das campanhas
          x-axis Baixo Alcance --> Alto Alcance
          y-axis Baixo Engajamento --> Alto Engajamento
          quadrant-1 Devemos expandir
          quadrant-2 Precisa promover
          quadrant-3 Reavaliar
          quadrant-4 Pode ser melhorado
          Campanha A: [0.3, 0.6]
          Campanha B: [0.45, 0.23]
          Campanha C: [0.57, 0.69]
          Campanha D: [0.78, 0.34]
          Campanha E: [0.40, 0.34]
          Campanha F: [0.35, 0.78]`;

        case "French":
          return `quadrantChart
          title Portée et engagement des campagnes
          x-axis Faible Portée --> Haute Portée
          y-axis Faible Engagement --> Haut Engagement
          quadrant-1 Nous devrions étendre
          quadrant-2 Besoin de promouvoir
          quadrant-3 Réévaluer
          quadrant-4 Peut être amélioré
          Campagne A: [0.3, 0.6]
          Campagne B: [0.45, 0.23]
          Campagne C: [0.57, 0.69]
          Campagne D: [0.78, 0.34]
          Campagne E: [0.40, 0.34]
          Campagne F: [0.35, 0.78]`;

        case "German":
          return `quadrantChart
          title Reichweite und Engagement der Kampagnen
          x-axis Niedrige Reichweite --> Hohe Reichweite
          y-axis Niedriges Engagement --> Hohes Engagement
          quadrant-1 Wir sollten erweitern
          quadrant-2 Muss beworben werden
          quadrant-3 Neu bewerten
          quadrant-4 Kann verbessert werden
          Kampagne A: [0.3, 0.6]
          Kampagne B: [0.45, 0.23]
          Kampagne C: [0.57, 0.69]
          Kampagne D: [0.78, 0.34]
          Kampagne E: [0.40, 0.34]
          Kampagne F: [0.35, 0.78]`;

        case "हिन्दी":
          return `quadrantChart
          title अभियानों की पहुंच और संलग्नता
          x-axis कम पहुंच --> अधिक पहुंच
          y-axis कम संलग्नता --> अधिक संलग्नता
          quadrant-1 हमें विस्तारित करना चाहिए
          quadrant-2 प्रचार की आवश्यकता है
          quadrant-3 पुनर्मूल्यांकन करें
          quadrant-4 सुधार हो सकता है
          अभियान A: [0.3, 0.6]
          अभियान B: [0.45, 0.23]
          अभियान C: [0.57, 0.69]
          अभियान D: [0.78, 0.34]
          अभियान E: [0.40, 0.34]
          अभियान F: [0.35, 0.78]`;

        default:
          return `quadrantChart
          title Reach and engagement of campaigns
          x-axis Low Reach --> High Reach
          y-axis Low Engagement --> High Engagement
          quadrant-1 We should expand
          quadrant-2 Need to promote
          quadrant-3 Re-evaluate
          quadrant-4 May be improved
          Campaign A: [0.3, 0.6]
          Campaign B: [0.45, 0.23]
          Campaign C: [0.57, 0.69]
          Campaign D: [0.78, 0.34]
          Campaign E: [0.40, 0.34]
          Campaign F: [0.35, 0.78]`;
      }

    case "journey":
      switch (language) {
        case "English":
          return `journey
          title My working day
          section Go to work
            Make tea: 5: Me
            Go upstairs: 3: Me
            Do work: 1: Me, Cat
          section Go home
            Go downstairs: 5: Me
            Sit down: 5: Me`;

        case "Spanish":
          return `journey
    title Mapa de Viaje del Cliente
    section Descubrimiento
        Investiga tus opciones : 2024-05-01, 3d
        Lee reseñas online : 2024-05-04, 2d
    section Decisión
        Compara precios : 2024-05-06, 1d
        Compra en línea : 2024-05-07, 1d
    section Poscompra
        Recibe confirmación de envío : 2024-05-08, 1d
        Deja una reseña : 2024-05-09, 2d`;

        case "Português":
          return `journey
    title Mapa de Jornada do Cliente
    section Descoberta
        Pesquisa suas opções : 2024-05-01, 3d
        Lê resenhas online : 2024-05-04, 2d
    section Decisão
        Compara preços : 2024-05-06, 1d
        Compra online : 2024-05-07, 1d
    section Pós-compra
        Recebe confirmação de envio : 2024-05-08, 1d
        Deixa uma resenha : 2024-05-09, 2d`;

        case "French":
          return `journey
    title Carte du Parcours Client
    section Découverte
        Recherche vos options : 2024-05-01, 3d
        Lit des avis en ligne : 2024-05-04, 2d
    section Décision
        Compare les prix : 2024-05-06, 1d
        Achète en ligne : 2024-05-07, 1d
    section Post-achat
        Reçoit la confirmation d'expédition : 2024-05-08, 1d
        Laisse un avis : 2024-05-09, 2d`;

        case "German":
          return `journey
    title Kundenreise-Karte
    section Entdeckung
        Erforscht Ihre Optionen : 2024-05-01, 3d
        Liest Online-Bewertungen : 2024-05-04, 2d
    section Entscheidung
        Vergleicht Preise : 2024-05-06, 1d
        Kauft online : 2024-05-07, 1d
    section Nach dem Kauf
        Erhält Versandbestätigung : 2024-05-08, 1d
        Hinterlässt eine Bewertung : 2024-05-09, 2d`;

        case "हिन्दी":
          return `journey
    title ग्राहक यात्रा मानचित्र
    section खोज
        आपके विकल्पों की जांच करें : 2024-05-01, 3d
        ऑनलाइन समीक्षा पढ़ें : 2024-05-04, 2d
    section निर्णय
        मूल्य तुलना करें : 2024-05-06, 1d
        ऑनलाइन खरीदारी करें : 2024-05-07, 1d
    section पोस्ट-खरीदारी
        शिपिंग पुष्टि प्राप्त करें : 2024-05-08, 1d
        समीक्षा छोड़ें : 2024-05-09, 2d`;

        default:
          return `journey
          title My working day
          section Go to work
            Make tea: 5: Me
            Go upstairs: 3: Me
            Do work: 1: Me, Cat
          section Go home
            Go downstairs: 5: Me
            Sit down: 5: Me`;
      }
    case "requirementDiagram":
      switch (language) {
        case "English":
          return `    requirementDiagram

    requirement test_req {
    id: 1
    text: the test text.
    risk: high
    verifymethod: test
    }

    element test_entity {
    type: simulation
    }

    test_entity - satisfies -> test_req`;
        case "Spanish":
          return `    requirementDiagram
      
    requirement test_req {
    id: 1
    text: el texto de prueba.
    risk: alto
    verifymethod: prueba
    }
        
    element test_entity {
    type: simulación
    }
        
    test_entity - satisface -> test_req`;

        case "Português":
          return `    requirementDiagram
      
        requirement test_req {
        id: 1
        text: o texto do teste.
        risk: alto
        verifymethod: teste
        }
      
        element test_entity {
        type: simulação
        }
      
        test_entity - satisfaz -> test_req`;

        case "French":
          return `    requirementDiagram
      
        requirement test_req {
        id: 1
        text: le texte de test.
        risk: élevé
        verifymethod: test
        }
      
        element test_entity {
        type: simulation
        }
      
        test_entity - satisfait -> test_req`;

        case "German":
          return `    requirementDiagram
      
        requirement test_req {
        id: 1
        text: der Testtext.
        risk: hoch
        verifymethod: Test
        }
      
        element test_entity {
        type: Simulation
        }
      
        test_entity - erfüllt -> test_req`;

        case "हिन्दी":
          return `    requirementDiagram
      
        requirement test_req {
        id: 1
        text: परीक्षण पाठ।
        risk: उच्च
        verifymethod: परीक्षण
        }
      
        element test_entity {
        type: सिमुलेशन
        }
      
        test_entity - पूरा करता है -> test_req`;

        default:
          return `    requirementDiagram

          requirement test_req {
          id: 1
          text: the test text.
          risk: high
          verifymethod: test
          }
      
          element test_entity {
          type: simulation
          }
      
          test_entity - satisfies -> test_req`;
      }

    case "gitGraph":
      switch (language) {
        case "English":
          return `---
title: Example Git diagram
---
gitGraph
   commit
   commit
   branch develop
   checkout develop
   commit
   commit
   checkout main
   merge develop
   commit
   commit`;
        case "Spanish":
          return `---
title: Ejemplo de diagrama Git
---
    gitGraph
       commit
       commit
       branch develop
       checkout develop
       commit
       commit
       checkout main
       merge develop
       commit
       commit`;

        case "Português":
          return `---
title: Exemplo de diagrama Git
---
    gitGraph
       commit
       commit
       branch develop
       checkout develop
       commit
       commit
       checkout main
       merge develop
       commit
       commit`;

        case "French":
          return `---
title: Exemple de diagramme Git
---
    gitGraph
       commit
       commit
       branch develop
       checkout develop
       commit
       commit
       checkout main
       merge develop
       commit
       commit`;

        case "German":
          return `---
title: Beispiel Git-Diagramm
---
    gitGraph
       commit
       commit
       branch develop
       checkout develop
       commit
       commit
       checkout main
       merge develop
       commit
       commit`;

        case "हिन्दी":
          return `---
title: Git आरेख का उदाहरण
---
    gitGraph
       commit
       commit
       branch develop
       checkout develop
       commit
       commit
       checkout main
       merge develop
       commit
       commit`;

        default:
          return `---
title: Example Git diagram
---
          gitGraph
             commit
             commit
             branch develop
             checkout develop
             commit
             commit
             checkout main
             merge develop
             commit
             commit`;
      }

    case "sankey-beta":
      switch (language) {
        case "English":
          return `sankey-beta
     A,B,C
     A,B,10
     A,C,5
     B,C,3`;
        case "Spanish":
          return `sankey-beta
    A,B,C
    A,B,10
    A,C,5
    B,C,3`;

        case "Português":
          return `sankey-beta
    A,B,C
    A,B,10
    A,C,5
    B,C,3`;

        case "French":
          return `sankey-beta
    A,B,C
    A,B,10
    A,C,5
    B,C,3`;

        case "German":
          return `sankey-beta
    A,B,C
    A,B,10
    A,C,5
    B,C,3`;

        case "हिन्दी":
          return `sankey-beta
    A,B,C
    A,B,10
    A,C,5
    B,C,3`;

        default:
          return `sankey-beta
     A,B,C
     A,B,10
     A,C,5
     B,C,3`;
      }

    case "sequenceDiagram":
      switch (language) {
        case "English":
          return `sequenceDiagram
          Alice->>John: Hello John, how are you?
          John-->>Alice: Great!
          Alice-)John: See you later!`;
        case "Spanish":
          return `sequenceDiagram
    Alice->>John: Hola John, ¿cómo estás?
    John-->>Alice: ¡Genial!
    Alice-)John: ¡Nos vemos luego!`;

        case "Português":
          return `sequenceDiagram
    Alice->>John: Olá John, como você está?
    John-->>Alice: Ótimo!
    Alice-)John: Até mais!`;

        case "French":
          return `sequenceDiagram
    Alice->>John: Bonjour John, comment ça va ?
    John-->>Alice: Super !
    Alice-)John: À plus tard !`;

        case "German":
          return `sequenceDiagram
    Alice->>John: Hallo John, wie geht's dir?
    John-->>Alice: Super!
    Alice-)John: Bis später!`;

        case "हिन्दी":
          return `sequenceDiagram
    Alice->>John: हेलो जॉन, तुम कैसे हो?
    John-->>Alice: बहुत अच्छा!
    Alice-)John: फिर मिलते हैं!`;

        default:
          return `sequenceDiagram
    Alice->>John: Hello John, how are you?
    John-->>Alice: Great!
    Alice-)John: See you later!`;
      }

    case "classDiagram":
      switch (language) {
        case "English":
          return `---
title: Animal example
---
          classDiagram
              note "From Duck till Zebra"
              Animal <|-- Duck
              note for Duck "can fly
can swim
can dive
can help in debugging"
              Animal <|-- Fish
              Animal <|-- Zebra
              Animal : +int age
              Animal : +String gender
              Animal: +isMammal()
              Animal: +mate()
              class Duck{
                  +String beakColor
                  +swim()
                  +quack()
              }
              class Fish{
                  -int sizeInFeet
                  -canEat()
              }
              class Zebra{
                  +bool is_wild
                  +run()
              }`;
        case "Spanish":
          return `---
title: Ejemplo de Animal
---
    classDiagram
        note "Desde Pato hasta Cebra"
        Animal <|-- Pato
        note for Pato "puede volar\npuede nadar\npuede bucear\npuede ayudar en depuración"
        Animal <|-- Pez
        Animal <|-- Cebra
        Animal : +int edad
        Animal : +String género
        Animal: +esMamífero()
        Animal: +reproducirse()
        class Pato{
            +String colorPico
            +nadar()
            +cuac()
        }
        class Pez{
            -int tamañoEnPies
            -puedeComer()
        }
        class Cebra{
            +bool esSalvaje
            +correr()
        }`;

        case "Português":
          return `---
title: Exemplo de Animal
---
    classDiagram
        note "De Pato até Zebra"
        Animal <|-- Pato
        note for Pato "pode voar\npode nadar\npode mergulhar\npode ajudar na depuração"
        Animal <|-- Peixe
        Animal <|-- Zebra
        Animal : +int idade
        Animal : +String gênero
        Animal: +éMamífero()
        Animal: +reproduzir()
        class Pato{
            +String corBico
            +nadar()
            +quack()
        }
        class Peixe{
            -int tamanhoEmPés
            -podeComer()
        }
        class Zebra{
            +bool éSelvagem
            +correr()
        }`;

        case "French":
          return `---
title: Exemple d'Animal
---
    classDiagram
        note "Du Canard au Zèbre"
        Animal <|-- Canard
        note for Canard "peut voler\npeut nager\npeut plonger\npeut aider au débogage"
        Animal <|-- Poisson
        Animal <|-- Zèbre
        Animal : +int âge
        Animal : +String genre
        Animal: +estMammifère()
        Animal: +reproduire()
        class Canard{
            +String couleurDuBec
            +nager()
            +quack()
        }
        class Poisson{
            -int tailleEnPieds
            -peutManger()
        }
        class Zèbre{
            +bool estSauvage
            +courir()
        }`;

        case "German":
          return `---
title: Beispiel Tier
---
    classDiagram
        note "Von Ente bis Zebra"
        Animal <|-- Ente
        note for Ente "kann fliegen\nkann schwimmen\nkann tauchen\nkann beim Debuggen helfen"
        Animal <|-- Fisch
        Animal <|-- Zebra
        Animal : +int Alter
        Animal : +String Geschlecht
        Animal: +istSäugetier()
        Animal: +paaren()
        class Ente{
            +String Schnabelfarbe
            +schwimmen()
            +quaken()
        }
        class Fisch{
            -int GrößeInFuß
            -kannEssen()
        }
        class Zebra{
            +bool istWild
            +laufen()
        }`;

        case "हिन्दी":
          return `---
title: पशु उदाहरण
---
    classDiagram
        note "बतख से ज़ेब्रा तक"
        Animal <|-- बतख
        note for बतख "उड़ सकता है\nतैर सकता है\nगहराई में जा सकता है\nडीबगिंग में मदद कर सकता है"
        Animal <|-- मछली
        Animal <|-- ज़ेब्रा
        Animal : +int उम्र
        Animal : +String लिंग
        Animal: +मामल()
        Animal: +प्रजनन()
        class बतख{
            +String चोंच का रंग
            +तैरना()
            +क्वैक()
        }
        class मछली{
            -int आकारफुट
            -खा सकता है()
        }
        class ज़ेब्रा{
            +bool जंगली है
            +दौड़ना()
        }`;

        default:
          return `---
title: Animal example
---
          classDiagram
              note "From Duck till Zebra"
              Animal <|-- Duck
              note for Duck "can fly
can swim
can dive
can help in debugging"
              Animal <|-- Fish
              Animal <|-- Zebra
              Animal : +int age
              Animal : +String gender
              Animal: +isMammal()
              Animal: +mate()
              class Duck{
                  +String beakColor
                  +swim()
                  +quack()
              }
              class Fish{
                  -int sizeInFeet
                  -canEat()
              }
              class Zebra{
                  +bool is_wild
                  +run()
              }`;
      }
    default:
      return `flowchart TD
    A[Start] --> B{Is it?}
    B -->|Yes| C[OK]
    C --> D[Rethink]
    D --> B
    B ---->|No| E[End]`;
  }
};

export const diagramSyntax = (type: string, language: string) => {
  switch (type) {
    case "flowchart TD":
      return `flowchart TD
    A[Start] --> B{Is it?}
    B -->|Yes| C[OK]
    C --> D[Rethink]
    D --> B
    B ---->|No| E[End]`;

    case "mindmap":
      return MindMapDiagramSyntax;

    case "timeline":
      return `timeline
      title string
      string : string
             : string
      string : string
      string : string : string`;

    case "pie":
      return `pie 
       title string
          "string" : number
          "string" : number
          "string" : number          

          after pie word title word must be important and don't provide a percentage in value
          `;

    case "Gantt Chart":
      return `gantt
      title string
      dateFormat YYYY-MM-DD
      excludes string
      section string
          string :string, string, string
          string :string, string
          string :        string, string
      section string
          string :string, string
          string :string`;

    case "xychart-beta":
      return `xychart-beta
                title string
                x-axis [] 
                y-axis string
                bar string [] `;

    case "State Diagram":
      return `---
      title: string
      ---
        stateDiagram-v2
            [*] --> string
            string --> string
            string --> string
            string --> string
            string --> [*]`;

    case "erDiagram":
      return ERDiagramSyntax;

    case "quadrantChart":
      return `quadrantChart
          title string
          x-axis string --> string
          y-axis string --> string
          quadrant-1 string
          quadrant-2 string
          quadrant-3 string
          quadrant-4 string
          string: [0.0, 0.0]
          string: [0.0, 0.0]
          string: [0.0, 0.0]
          
          after quadrant-4 don't provide a percentage in value only accept the decimal value
          `;

    case "journey":
      return `journey
      title string
      section string
        string: number: string
      section string
        string: number: string`;

    case "requirementDiagram":
      return RequirementDiagramSyntax;

    case "gitGraph":
      return GitGraphDiagramSyntax;

    case "sankey-beta":
      return `sankey-beta
                A,B,C
                A,B,10
                A,C,5
                B,C,3`;

    case "sequenceDiagram":
      return `sequenceDiagram
      Alice->>John: Hello John, how are you?
      John-->>Alice: Great!
      Alice-)John: See you later!`;

    case "classDiagram":
      return `---
      title: Animal example
      ---
                classDiagram
                    note "From Duck till Zebra"
                    Animal <|-- Duck
                    note for Duck "can fly
      can swim
      can dive
      can help in debugging"
                    Animal <|-- Fish
                    Animal <|-- Zebra
                    Animal : +int age
                    Animal : +String gender
                    Animal: +isMammal()
                    Animal: +mate()
                    class Duck{
                        +String beakColor
                        +swim()
                        +quack()
                    }
                    class Fish{
                        -int sizeInFeet
                        -canEat()
                    }
                    class Zebra{
                        +bool is_wild
                        +run()
                    }`;

    default:
      return `flowchart TD
    A[Start] --> B{Is it?}
    B -->|Yes| C[OK]
    C --> D[Rethink]
    D --> B
    B ---->|No| E[End]`;
  }
};

export const codeBlockRegex_ = (type: string) => {
  switch (type) {
    case "flowchart TD":
      return /```flowchart TD([\s\S]*?)```/;
    case "sequenceDiagram":
      return /```Sequence Diagram([\s\S]*?)```/;
    case "classDiagram":
      return /```Class Diagram([\s\S]*?)```/;
    case "mindmap":
      return /```mindmap([\s\S]*?)```/i;
    case "timeline":
      return /```timeline([\s\S]*?)```/i;
    case "pie":
      return /```pie([\s\S]*?)```/i;
    case "Gantt Chart":
      return /```gantt([\s\S]*?)```/i;
    // case "XY Chart":
    //   return /```xy([\s\S]*?)```/i;
    case "State Diagram":
      return /```stateDiagram([\s\S]*?)```/;
    case "erDiagram":
      return /```erDiagram([\s\S]*?)```/i;
    case "quadrantChart":
      return /```quadrant([\s\S]*?)```/i;
    case "journey":
      return /```journey([\s\S]*?)```/i;
    case "requirementDiagram":
      return /```requirement([\s\S]*?)```/i;
    case "gitGraph":
      return /```gitGraph([\s\S]*?)```/i;
    // case "Sankey Diagram":
    //   return /```sankey([\s\S]*?)```/i;
    default:
      return /```flowchart TD([\s\S]*?)```/;
  }
};

// Define keywords for each type of diagram, also normalized
export const diagramKeywords: Record<string, string[]> = {
  "Flowchart Diagram": ["flowchart"],
  "Sequence Diagram": ["sequencediagram", "sequence"],
  "Class Diagram": ["classdiagram", "class"],
  Mindmap: ["mindmap"],
  Timeline: ["timeline"],
  "Pie Chart": ["piechart", "pie"],
  "Gantt Chart": ["ganttchart", "gantt"],
  // "XY Chart": ["xychart"],
  "State Diagram": ["statediagram", "state"],
  "Entity Relationship Diagram": ["entityrelationshipdiagram", "erd"],
  "Quadrant Chart Diagram": ["quadrantchart", "quadrant"],
  "Journey Diagram": ["journeydiagram", "journey"],
  "Requirement Diagram": ["requirementdiagram"],
  "Git Graph": ["gitgraph", "git"],
  // "Sankey Diagram": ["sankeydiagram", "sankey"],
};
