/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import {
  fetchWBLocalImages,
  uploadWBLocalImages,
} from "../excalidraw-app/api/collection";
import { deleteIcon, image, menu, searchIcon, uploadIcon } from "./icons";
import "./Images.scss";
import { t } from "../i18n";
import Loader from "../App/shareComponent/Loader";
import ConfirmDialog from "./ConfirmDialog";
import { deleteWbLocalMedia } from "../excalidraw-app/api/userAPI";
import { convertIsoToDateString } from "../utils/dates";
import { Avatar } from "./Avatar";
import { getClientColors, getClientInitials } from "../clients";
import { set } from "idb-keyval";
import { useIsMobile } from "./App";
import { Toast, ToastType } from "./Toast";

const Images = ({
  insertImageOnCanvas,
  onClose,
  isSideBarOpen,
  setIsSideBarOpen,
}: {
  insertImageOnCanvas: (file: File) => void;
  onClose: () => void;
  isSideBarOpen: boolean;
  setIsSideBarOpen: (val: boolean) => void;
}) => {
  const [record, setRecord] = useState<any>({});
  const [images, setImages] = useState<
    {
      url: string;
      name: string;
      isSelected: boolean;
      createdAt: string;
      isLoading: boolean;
    }[]
  >([]);
  const [filteredImages, setFilteredImages] = useState<
    {
      url: string;
      name: string;
      isSelected: boolean;
      createdAt: string;
      isLoading: boolean;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [isDeleteAll, setIsDeleteAll] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentBlobName, setCurrentBlobName] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const user = JSON.parse(localStorage.getItem("user") || "");
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  const fetchUpdatedImages = async () => {
    try {
      setIsLoading(true);

      const user = JSON.parse(localStorage.getItem("user") || "");

      const { results } = await fetchWBLocalImages(user.mail);

      if (results?.length > 0 && results[0]?.localImages) {
        // const images = [...results[0].localImages].map((image, i) => {
        //   return { ...image, isSelected: false, isLoading: true };
        // });

        const allImages = results
          .map((item: any) =>
            item.localImages.map((image: any) => {
              return { ...image, isSelected: false, isLoading: true };
            }),
          )
          .flat();

        setImages(allImages);
        setRecord(results[0]);
      } else {
        setImages([]);
        setRecord({});
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchUpdatedImages();
  }, []);

  useEffect(() => {
    images.length > 0 ? checkSelected() : setIsSelectedAll(false);
  }, [images]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  const getUrlExtension = (url: any) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  const insertImage = async (url: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch image (status ${response.status})`);
      }
      const data = await response.blob();

      const imgExt = getUrlExtension(url);
      const file = new File([data], `image.${imgExt}`, {
        type: data.type || "image/png",
      });

      if (file.size > 1024 * 1024 * 2) {
        setToastMessage(
          "The image you selected is larger than 2 MB. Please select an image that is under 2 MB.",
        );
      } else {
        insertImageOnCanvas(file);
        setIsLoading(false);
        onClose();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error("Error fetching image:", err);
    }
  };

  const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const lesson = JSON.parse(localStorage.getItem("lesson") || "");

    const user = JSON.parse(localStorage.getItem("user") || "");

    if (file && lesson && user.mail) {
      const formData = new FormData();

      formData.append("image", file);
      formData.append("classId", lesson?.ClassID);
      formData.append("mail", user.mail);
      // if (file.size > 2097152) {
      //   setToastMessage(
      //     "The image you selected is larger than 2 MB. Please select an image that is under 2 MB.",
      //   );
      // } else {
      setIsLoading(true);
      await uploadWBLocalImages(formData);
      await fetchUpdatedImages();
      // }

      setIsLoading(false);
    }
  };

  const handleSelectAll = (checked: boolean) => {
    let clonedImages = [...images];
    setIsSelectedAll(checked);
    clonedImages = clonedImages.map((image) => {
      return { ...image, isSelected: checked };
    });
    setImages(clonedImages);
  };

  const checkSelected = () => {
    const isAllSelected = images.every((image) => image.isSelected);
    if (isAllSelected && !isSelectedAll) {
      setIsSelectedAll(true);
    } else if (!isAllSelected && isSelectedAll) {
      setIsSelectedAll(false);
    }
  };

  const handleDelete = async () => {
    try {
      const selectedAudioNames: string[] = [];

      images.filter((image) => {
        if (image.isSelected) {
          selectedAudioNames.push(image.name);
        }
      });

      const body = {
        id: record.id,
        classId: record.classId,
        email: record.email,
        type: "image",
        blobNames: isSelectedAll
          ? selectedAudioNames
          : currentBlobName
          ? [currentBlobName]
          : [],
        typeOfDelete: isSelectedAll ? "all" : "single",
      };
      await deleteWbLocalMedia(body);
      await fetchUpdatedImages();
    } catch (error) {
      console.log("error in delete audio", error);
    }
  };

  const handleSearch = () => {
    if (!!search.trim()) {
      const searchedImages = images.filter((image) => {
        const nameWithoutExtension = image.name
          .split(".")
          .slice(0, -1)
          .join(".");
        return nameWithoutExtension
          .toLowerCase()
          .includes(search.toLowerCase());
      });

      setFilteredImages(searchedImages);
    } else {
      setFilteredImages([]);
    }
  };

  const images_ = !!search.trim() ? filteredImages : images;

  const handleImageLoading = (i: number) => {
    const clonedImages = [...images_];
    clonedImages[i].isLoading = false;
    if (!!search.trim()) {
      setFilteredImages(clonedImages);
    } else {
      setImages(clonedImages);
    }
  };

  return (
    <>
      {isOpen && (
        <ConfirmDialog
          onCancel={() => {
            setIsOpen(false);
            setIsDeleteAll(false);
          }}
          onConfirm={async () => {
            setIsOpen(false);
            setIsDeleteAll(false);
            await handleDelete();
            setCurrentBlobName("");
          }}
          onClose={() => {
            setIsOpen(false);
            setIsDeleteAll(false);
          }}
          confirmText="Delete"
          title={isDeleteAll ? "Delete images" : "Delete image"}
        >
          {isDeleteAll
            ? "Are you sure you want to delete all the images?"
            : "Are you sure you want to delete the image?"}
        </ConfirmDialog>
      )}
      {toastMessage !== null && (
        <Toast
          type={ToastType.ERROR}
          message={
            "The image you selected is larger than 2 MB. Please select an image that is under 2 MB."
          }
          clearToast={() => setToastMessage(null)}
          className="style"
        />
      )}
      <div className="image_container h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="fw-bold">{t("collection.personal.images.title")}</h4>
            <h4 className="text">{t("collection.personal.images.message")}</h4>
          </div>
          <div className="btn_div">
            <label className="upload_btn">
              <svg height={15} width={15} className="me-2">
                {uploadIcon}
              </svg>
              {t("collection.personal.images.uploadBtn")}
              <input type="file" onChange={onImageChange} accept="image/*" />
            </label>
          </div>
        </div>
        <div className="pb-2 col-md-12">
          <div className="col-md-5 col-sm-12 position-relative">
            <div className="d-flex align-items-center">
              <button
                className={`btn me-3 ${useIsMobile() ? "d-none" : ""}`}
                // data-bs-toggle="offcanvas"
                // data-bs-target="#offcanvasExample"
                // aria-controls="offcanvasExample"
                style={{ width: "28px" }}
                onClick={() => {
                  setIsSideBarOpen(!isSideBarOpen);
                }}
              >
                {menu}
              </button>
              <input
                type="text"
                id="search"
                name="search"
                placeholder="type your search here"
                className="m-0 w-100 mt-lg-0 mt-3 "
                defaultValue=""
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            {/* <button
              className="ToolIcon_type_button ToolIcon_size_medium  position-absolute search-icon-wrapper ToolIcon_type_button--show ToolIcon"
              aria-label="close"
              type="button"
              // style={{ left: "48%" }}
            >
              <div className="ToolIcon__icon d-flex" aria-hidden="true">
                {searchIcon}
              </div>
            </button> */}
          </div>
        </div>
        <div
          className="d-flex align-items-center pb-2"
          style={{ height: "25px" }}
        >
          Select all
          <input
            className="mx-2"
            checked={isSelectedAll}
            onChange={(e) => {
              handleSelectAll(e.target.checked);
            }}
            type="checkbox"
          />
          <div
            className={`delete-icon-wrapper  ${isSelectedAll ? "" : "pe-none"}`}
            onClick={() => {
              setIsOpen(true);
              setIsDeleteAll(true);
            }}
          >
            {deleteIcon}
          </div>
        </div>

        {isLoading ? (
          <Loader className="h-100" />
        ) : (
          <div className="row overflow-auto ps-2">
            {images_.length > 0 ? (
              images_.map(
                (
                  image: {
                    url: string;
                    name: string;
                    isSelected: boolean;
                    createdAt: string;
                    isLoading: boolean;
                  },
                  i: number,
                ) => {
                  const nameWithoutExtension = image.name
                    .split(".")
                    .slice(0, -1)
                    .join(".");
                  return (
                    <div className="col-md-4  col-sm-12 py-1 px-1">
                      <div
                        className={`card img_card px-2 placeholder-glow py-4 ${
                          isSelectedAll ? "red-border" : ""
                        }`}
                      >
                        <div className="d-flex align-items-center justify-content-between py-2">
                          <Avatar
                            color="grey"
                            className="flex-shrink-0 overflow-hidden"
                            border=""
                            onClick={() => {}}
                          >
                            {!!user?.UrlPhoto ? (
                              <img
                                key={i}
                                src={user.UrlPhoto}
                                className="w-100 h-auto "
                                alt="user-profile"
                              />
                            ) : (
                              user?.name[0].toUpperCase() || "A"
                            )}
                          </Avatar>
                          <div>
                            <p className="mb-0 text-nowrap image-name w-100 px-2">
                              {nameWithoutExtension}
                            </p>
                            <p className="mb-0 text-nowrap image-name  px-2">
                              {convertIsoToDateString(image.createdAt)}
                            </p>
                          </div>
                          <div
                            className="delete-icon-wrapper-1"
                            onClick={() => {
                              setIsOpen(true);
                              setCurrentBlobName(image.name);
                            }}
                          >
                            {deleteIcon}
                          </div>
                        </div>
                        <div
                          className={`${
                            image.isLoading ? "" : "d-none"
                          } h-100 placeholder`}
                        ></div>

                        <img
                          key={i}
                          src={`${image.url}`}
                          onClick={() => insertImage(image.url)}
                          alt=""
                          onLoad={() => {
                            handleImageLoading(i);
                          }}
                          className={`${
                            image.isLoading ? "d-none" : ""
                          } img-fluid py-2`}
                        />
                      </div>
                    </div>
                  );
                },
              )
            ) : (
              <>
                <div className="empty_img d-flex flex-column justify-content-center align-items-center">
                  <img
                    src="https://acvstorageprod.blob.core.windows.net/react-images/Empty-pana.svg"
                    alt=""
                    height={292}
                    width={292}
                  />
                  <p className="text-center">
                    {t("collection.personal.images.emptyMessage")}
                  </p>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Images;
