import { getDefaultAppState } from "../appState";
import { ColorPicker } from "../components/ColorPicker";
import { trash, zoomIn, zoomOut } from "../components/icons";
import { ToolButton, ToolButtonEnum } from "../components/ToolButton";
import { DarkModeToggle } from "../components/DarkModeToggle";
import { MAX_ZOOM, MIN_ZOOM, THEME, ZOOM_STEP } from "../constants";
import { getCommonBounds, getNonDeletedElements } from "../element";
import { newElementWith } from "../element/mutateElement";
import { ExcalidrawElement } from "../element/types";
import { t } from "../i18n";
import { useIsMobile } from "../components/App";
import { CODES, KEYS } from "../keys";
import { getNormalizedZoom, getSelectedElements } from "../scene";
import { centerScrollOn } from "../scene/scroll";
import { getNewZoom, getStateForZoom } from "../scene/zoom";
import { AppState, NormalizedZoomValue } from "../types";
import { getShortcutKey } from "../utils";
import { register } from "./register";
import { Tooltip } from "../components/Tooltip";
import { onSaveElementInDB } from "../excalidraw-app/api/userAPI";
import { STORAGE_KEYS as LOCAL_STORAGE_KEY } from "../excalidraw-app/data/localStorage";
import ConfirmDialog from "../components/ConfirmDialog";
import { useState } from "react";

export const actionChangeViewBackgroundColor = register({
  name: "changeViewBackgroundColor",
  perform: (_, appState, value) => {
    return {
      appState: { ...appState, ...value },
      commitToHistory: !!value.viewBackgroundColor,
    };
  },
  PanelComponent: ({ appState, updateData, containerId }) => {
    return (
      <div style={{ position: "relative" }}>
        <ColorPicker
          label={t("labels.canvasBackground")}
          type="canvasBackground"
          color={appState.viewBackgroundColor}
          onChange={(color) => updateData({ viewBackgroundColor: color })}
          isActive={appState.openPopup === "canvasColorPicker"}
          setActive={(active) =>
            updateData({ openPopup: active ? "canvasColorPicker" : null })
          }
          data-testid="canvas-background-picker"
          left={0}
          top={0}
          containerId={containerId}
        />
      </div>
    );
  },
});

// export const actionClearCanvas = register({
//   name: "clearCanvas",
//   perform: (elements, appState: AppState) => {
//     return {
//       elements: elements.map((element) =>
//         newElementWith(element, { isDeleted: true }),
//       ),
//       appState: {
//         ...getDefaultAppState(),
//         theme: appState.theme,
//         elementLocked: appState.elementLocked,
//         exportBackground: appState.exportBackground,
//         exportEmbedScene: appState.exportEmbedScene,
//         gridSize: appState.gridSize,
//         showStats: appState.showStats,
//         pasteDialog: appState.pasteDialog,
//       },
//       commitToHistory: true,
//     };
//   },
//   PanelComponent: ({ updateData }) => (
//     <ToolButton
//       type="button"
//       icon={trash}
//       title={t("buttons.clearReset")}
//       aria-label={t("buttons.clearReset")}
//       showAriaLabel={useIsMobile()}
//       onClick={async () => {
//         // localStorage.setItem("clearCanvasConfirmationModal", true);

//         if (window.confirm(t("alerts.clearReset"))) {
//           window.parent.postMessage(
//             { type: "STORE_ELEMENTS", isLoading: true },
//             `${`${process.env.REACT_APP_PARENT_APP}`}`,
//           );
//           localStorage.setItem(
//             LOCAL_STORAGE_KEY.LOCAL_STORAGE_ELEMENTS,
//             JSON.stringify([]),
//           );
//           updateData(null);
//           const appState = JSON.parse(
//             localStorage.getItem("acv-state") || "{}",
//           );
//           const lessonId =
//             new URLSearchParams(window.location.search)
//               .get("lessonId")
//               ?.replace(/\//g, "") || "";
//           onSaveElementInDB(
//             appState.currentPage,
//             lessonId,
//             appState.DBElements || [],
//             appState.viewBackgroundColor,
//           );
//           window.parent.postMessage(
//             { type: "STORE_ELEMENTS", isLoading: false },
//             `${process.env.REACT_APP_PARENT_APP}`,
//           );
//         }
//       }}
//       data-testid="clear-canvas-button"
//     />
//   ),
// });

export const actionClearCanvas = register({
  name: "clearCanvas",
  perform: (elements, appState: AppState) => {
    return {
      elements: elements.map((element) =>
        newElementWith(element, { isDeleted: true }),
      ),
      appState: {
        ...getDefaultAppState(),
        theme: appState.theme,
        elementLocked: appState.elementLocked,
        exportBackground: appState.exportBackground,
        exportEmbedScene: appState.exportEmbedScene,
        gridSize: appState.gridSize,
        showStats: appState.showStats,
        pasteDialog: appState.pasteDialog,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ updateData }) => {
    const [showModal, setShowModal] = useState(false);

    return (
      <>
        <ToolButton
          type={ToolButtonEnum.BUTTON}
          icon={trash}
          title={t("buttons.clearReset")}
          aria-label={t("buttons.clearReset")}
          showAriaLabel={useIsMobile()}
          onClick={() => setShowModal(true)}
          data-testid="clear-canvas-button"
        />
        {showModal && (
          <ConfirmDialog
            onConfirm={async () => {
              window.parent.postMessage(
                { type: "STORE_ELEMENTS", isLoading: true },
                `${`${process.env.REACT_APP_PARENT_APP}`}`,
              );
              localStorage.setItem(
                LOCAL_STORAGE_KEY.LOCAL_STORAGE_ELEMENTS,
                JSON.stringify([]),
              );
              updateData(null);
              const appState = JSON.parse(
                localStorage.getItem("acv-state") || "{}",
              );
              const lessonId =
                new URLSearchParams(window.location.search)
                  .get("lessonId")
                  ?.replace(/\//g, "") || "";
              onSaveElementInDB(
                appState.currentPage,
                lessonId,
                appState.DBElements || [],
                appState.viewBackgroundColor,
              );
              window.parent.postMessage(
                { type: "STORE_ELEMENTS", isLoading: false },
                `${process.env.REACT_APP_PARENT_APP}`,
              );

              setShowModal(false);
            }}
            onCancel={() => setShowModal(false)}
            title={"Are you sure?"}
            open={true}
            setOpen={() => setShowModal(false)}
            children={<p>{t("alerts.clearReset")}</p>}
            closeOnClickOutside={false}
          />
        )}
      </>
    );
  },
});

export const actionZoomIn = register({
  name: "zoomIn",
  perform: (_elements, appState) => {
    const newZoom = getStateForZoom(
      {
        viewportX: appState.width / 2 + appState.offsetLeft,
        viewportY: appState.height / 2 + appState.offsetTop,
        nextZoom: getNormalizedZoom(appState.zoom.value + ZOOM_STEP),
      },
      appState,
    );

    return {
      appState: {
        ...appState,
        scrollX: newZoom.scrollX,
        scrollY: newZoom.scrollY,
        zoom: {
          value: newZoom.zoom.value,
          translation: {
            x: newZoom.scrollX,
            y: newZoom.scrollY,
          },
        },
      },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ updateData, appState }) => (
    <ToolButton
      type={ToolButtonEnum.BUTTON}
      icon={zoomIn}
      title={`${t("buttons.zoomIn")} — ${getShortcutKey("CtrlOrCmd++")}`}
      aria-label={t("buttons.zoomIn")}
      disabled={appState.zoom.value >= MAX_ZOOM}
      onClick={() => {
        updateData(null);
      }}
      size="small"
    />
  ),
  keyTest: (event) =>
    (event.code === CODES.EQUAL || event.code === CODES.NUM_ADD) &&
    (event[KEYS.CTRL_OR_CMD] || event.shiftKey),
});

export const actionZoomOut = register({
  name: "zoomOut",
  perform: (_elements, appState) => {
    const newZoom = getStateForZoom(
      {
        viewportX: appState.width / 2 + appState.offsetLeft,
        viewportY: appState.height / 2 + appState.offsetTop,
        nextZoom: getNormalizedZoom(appState.zoom.value - ZOOM_STEP),
      },
      appState,
    );

    return {
      appState: {
        ...appState,
        scrollX: newZoom.scrollX,
        scrollY: newZoom.scrollY,
        zoom: {
          value: newZoom.zoom.value,
          translation: {
            x: newZoom.scrollX,
            y: newZoom.scrollY,
          },
        },
      },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ updateData, appState }) => (
    <ToolButton
      type={ToolButtonEnum.BUTTON}
      icon={zoomOut}
      title={`${t("buttons.zoomOut")} — ${getShortcutKey("CtrlOrCmd+-")}`}
      aria-label={t("buttons.zoomOut")}
      disabled={appState.zoom.value <= MIN_ZOOM}
      onClick={() => {
        updateData(null);
      }}
      size="small"
    />
  ),
  keyTest: (event) =>
    (event.code === CODES.MINUS || event.code === CODES.NUM_SUBTRACT) &&
    (event[KEYS.CTRL_OR_CMD] || event.shiftKey),
});

export const actionResetZoom = register({
  name: "resetZoom",
  perform: (_elements, appState) => {
    const newZoom = getStateForZoom(
      {
        viewportX: appState.width / 2 + appState.offsetLeft,
        viewportY: appState.height / 2 + appState.offsetTop,
        nextZoom: getNormalizedZoom(1),
      },
      appState,
    );
    return {
      appState: {
        ...appState,
        scrollX: newZoom.scrollX,
        scrollY: newZoom.scrollY,
        zoom: {
          value: newZoom.zoom.value,
          translation: {
            x: newZoom.scrollX,
            y: newZoom.scrollY,
          },
        },
      },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ updateData, appState }) => (
    <Tooltip label={t("buttons.resetZoom")}>
      <ToolButton
        type={ToolButtonEnum.BUTTON}
        className="reset-zoom-button"
        title={t("buttons.resetZoom")}
        aria-label={t("buttons.resetZoom")}
        onClick={() => {
          updateData(null);
        }}
        size="small"
      >
        {(appState.zoom.value * 100).toFixed(0)}%
      </ToolButton>
    </Tooltip>
  ),
  keyTest: (event) =>
    (event.code === CODES.ZERO || event.code === CODES.NUM_ZERO) &&
    (event[KEYS.CTRL_OR_CMD] || event.shiftKey),
});

const zoomValueToFitBoundsOnViewport = (
  bounds: [number, number, number, number],
  viewportDimensions: { width: number; height: number },
) => {
  const [x1, y1, x2, y2] = bounds;
  const commonBoundsWidth = x2 - x1;
  const zoomValueForWidth = viewportDimensions.width / commonBoundsWidth;
  const commonBoundsHeight = y2 - y1;
  const zoomValueForHeight = viewportDimensions.height / commonBoundsHeight;
  const smallestZoomValue = Math.min(zoomValueForWidth, zoomValueForHeight);
  const zoomAdjustedToSteps =
    Math.floor(smallestZoomValue / ZOOM_STEP) * ZOOM_STEP;
  const clampedZoomValueToFitElements = Math.min(
    Math.max(zoomAdjustedToSteps, ZOOM_STEP),
    1,
  );
  return clampedZoomValueToFitElements as NormalizedZoomValue;
};

const zoomToFitElements = (
  elements: readonly ExcalidrawElement[],
  appState: Readonly<AppState>,
  zoomToSelection: boolean,
) => {
  const nonDeletedElements = getNonDeletedElements(elements);
  const selectedElements = getSelectedElements(nonDeletedElements, appState);

  const commonBounds =
    zoomToSelection && selectedElements.length > 0
      ? getCommonBounds(selectedElements)
      : getCommonBounds(nonDeletedElements);

  const zoomValue = zoomValueToFitBoundsOnViewport(commonBounds as any, {
    width: appState.width,
    height: appState.height,
  });
  const newZoom = getNewZoom(zoomValue, appState.zoom, {
    left: appState.offsetLeft,
    top: appState.offsetTop,
  });

  const [x1, y1, x2, y2] = commonBounds;
  const centerX = (x1 + x2) / 2;
  const centerY = (y1 + y2) / 2;

  return {
    appState: {
      ...appState,
      ...centerScrollOn({
        scenePoint: { x: centerX, y: centerY },
        viewportDimensions: {
          width: appState.width,
          height: appState.height,
        },
        zoom: newZoom,
      }),
      zoom: newZoom,
      scrollX: appState.width / 2 / newZoom.value - centerX,
      scrollY: appState.height / 2 / newZoom.value - centerY,
    },
    commitToHistory: false,
  };
};

export const actionZoomToSelected = register({
  name: "zoomToSelection",
  perform: (elements, appState) => zoomToFitElements(elements, appState, true),
  keyTest: (event) =>
    event.code === CODES.TWO &&
    event.shiftKey &&
    !event.altKey &&
    !event[KEYS.CTRL_OR_CMD],
});

export const actionZoomToFit = register({
  name: "zoomToFit",
  perform: (elements, appState) => zoomToFitElements(elements, appState, false),
  keyTest: (event) =>
    event.code === CODES.ONE &&
    event.shiftKey &&
    !event.altKey &&
    !event[KEYS.CTRL_OR_CMD],
});

export const actionToggleTheme = register({
  name: "toggleTheme",
  perform: (_, appState, value) => {
    return {
      appState: {
        ...appState,
        theme:
          value || (appState.theme === THEME.LIGHT ? THEME.DARK : THEME.LIGHT),
      },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ appState, updateData }) => (
    <div className="darkmodeBtn">
      <DarkModeToggle
        value={appState.theme}
        onChange={(theme) => {
          updateData(theme);
        }}
      />
    </div>
  ),
  keyTest: (event) => event.altKey && event.shiftKey && event.code === CODES.D,
});
