import { useCallback, useState, useRef, useContext, useEffect } from "react";
import { Dialog } from "./Dialog";
import "./Videos.scss";
import RecordRTC from "recordrtc";
import {
  circleFill,
  closeIcon,
  errorIcon,
  exportIcon,
  pauseFillIcon,
  playFillIcon,
  tickCircle,
  warningIcon,
} from "../components/icons";
import TitleSelect from "./AudioVideoTitleSelect";
import { NotesTitleContext } from "./contexts/NotesTitle.context";
import { t } from "../i18n";
import { Subscription } from "../types";

const VideoRecorder = ({
  onClose,
  insertVideoOnCanvas,
  subscription,
}: {
  onClose: () => void;
  insertVideoOnCanvas: (
    file: File,
    filename?: string | null,
    color?: string,
  ) => void;
  subscription?: Subscription;
}) => {
  const recordLimit = (subscription && subscription.videoRecordLimit) || 40;
  const [recordRTC, setRecordRTC] = useState<RecordRTC>();
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const videoNotesRef = useRef<HTMLVideoElement>(null);
  const [file, setFile] = useState<File>();
  const stopBtnRef = useRef<HTMLButtonElement>(null);
  const [second, setSecond] = useState(recordLimit);
  const [minute, setMinute] = useState("00");
  const [counter, setCounter] = useState(0);
  const [stopRecord, setIsStopRecord] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [confirmation, setConfirmation] = useState(false);

  const { fileName, color, onColorChange, onFileNameChange } = useContext(
    NotesTitleContext,
  );

  useEffect(() => {
    onColorChange("#000000");
    onFileNameChange("");
  }, []);

  const onDialogClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        const recorder = new RecordRTC(stream, {
          type: "video",
          mimeType: "video/webm;codecs=vp8",
          timeSlice: 1000,
        });
        setRecordRTC(recorder);
        recorder.startRecording();
        setIsRecording(true);
        setIsStopRecord(false);
        setSecond(recordLimit);
        setMinute("00");
        setCounter(0);
        videoNotesRef.current!.srcObject = stream;
        videoNotesRef.current!.play();
        videoNotesRef.current!.muted = true;
        videoNotesRef.current!.controls = false;

        // Set a timer to stop recording after 30 seconds
        setTimeout(() => {
          if (stopBtnRef && stopBtnRef.current) stopBtnRef.current.click();
        }, (Number(recordLimit) + 1) * 1000); // 31 seconds in milliseconds

        setError(null);
      })
      .catch((error) => {
        setError(error.message);
        console.log(error);
      });
  };

  const stopRecording = () => {
    videoNotesRef.current!.srcObject = null;
    recordRTC?.stopRecording(() => {
      const blob = recordRTC?.getBlob();
      const url = URL.createObjectURL(blob);

      const file = new File([blob], fileName as string, { type: "video/webm" });
      setFile(file);
      setIsRecording(false);
      setSecond(recordLimit);
      setMinute("00");
      setIsStopRecord(true);
      videoNotesRef.current!.src = url;
      videoNotesRef.current!.play();
      videoNotesRef.current!.muted = false;
      videoNotesRef.current!.controls = true;
    });
  };

  const insertVideo = async () => {
    if (!file) return;
    insertVideoOnCanvas(file, fileName, color);
    onClose();
    setSecond(recordLimit);
    setMinute("00");
  };

  useEffect(() => {
    let intervalId: any;

    if (isRecording) {
      intervalId = setInterval(() => {
        if (counter <= Number(recordLimit)) {
          const remainingSeconds = Number(recordLimit) - counter - 1;
          const remainingSecondCounter = Math.max(remainingSeconds % 60, 0);
          const remainingMinuteCounter = Math.max(
            Math.floor(remainingSeconds / 60),
            0,
          );

          let computedRemainingSecond =
            String(remainingSecondCounter).length === 1
              ? `0${remainingSecondCounter}`
              : remainingSecondCounter;
          let computedRemainingMinute =
            String(remainingMinuteCounter).length === 1
              ? `0${remainingMinuteCounter}`
              : remainingMinuteCounter;

          setSecond(computedRemainingSecond as string);
          setMinute(computedRemainingMinute as string);

          setCounter((counter) => counter + 1);
        } else {
          clearInterval(intervalId);
          stopRecording();
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isRecording, counter, recordLimit, stopRecording]);

  return (
    <Dialog
      onCloseRequest={() =>
        fileName || recordRTC ? setConfirmation(true) : onDialogClose()
      }
      title={t("notes.video.title")}
      className="publish-library video-model-width"
      closeOnClickOutside={false}
      open={true}
      setOpen={() => false}
      children={
        <>
          <div
            className="notes_div"
            style={{ border: confirmation ? "1px solid #c92a2a" : "none" }}
          >
            {(fileName || recordRTC) && confirmation ? (
              <div className="d-flex">
                <span className="confirmation">{errorIcon}</span>
                <div className="d-flex w-100">
                  <span className="text-danger" style={{ width: "90%" }}>
                    Are you sure? Your video will not be saved.
                  </span>
                  <div className="d-flex gap-1">
                    <span
                      data-toggle="tooltip"
                      data-bootstrap-placement="bottom"
                      title="Confirm"
                      onClick={onDialogClose}
                    >
                      {tickCircle}
                    </span>
                    <span
                      data-toggle="tooltip"
                      data-bootstrap-placement="bottom"
                      title="Stay here"
                      onClick={() => setConfirmation(false)}
                    >
                      {closeIcon}
                    </span>
                  </div>
                </div>
              </div>
            ) : error ? (
              <>
                <span className="me-2">{errorIcon}</span>
                <span className="text-danger">{error}</span>
              </>
            ) : (
              <>
                <span className="me-2 bg-color"> {warningIcon}</span>You are
                able to capture a video for a minimum duration of {recordLimit}{" "}
                seconds.
              </>
            )}
          </div>
          <div className="form-group d-block">
            <label htmlFor="exampleFormControlInput1" className="mb-1">
              {t("notes.video.name")}
            </label>
            <TitleSelect />
          </div>
          <div
            className={`d-flex flex-wrap gap-3 ${
              stopRecord
                ? "justify-content-around"
                : isRecording
                ? "justify-content-around"
                : "justify-content-start"
            }`}
          >
            {isRecording ? (
              <div
                className="my-3 pe-2"
                style={{ alignItems: "center", display: "flex" }}
              >
                {circleFill}
                {t("notes.video.button.circleFill")}
                <span style={{ marginLeft: "5px" }}>
                  <span className="minute text-black">{minute}</span>
                  <span>:</span>
                  <span className="second text-black">{second}</span>
                </span>
              </div>
            ) : (
              <button
                type="button"
                className="btn text-white my-3 bg-brand pe-2"
                value="Start recording"
                onClick={startRecording}
              >
                {playFillIcon} {t("notes.video.button.start")}
              </button>
            )}
            {isRecording && (
              <button
                type="button"
                className="btn text-white my-3 bg-brand pe-2"
                value="Stop recording"
                onClick={stopRecording}
                ref={stopBtnRef}
              >
                {pauseFillIcon} {t("notes.video.button.stop")}
              </button>
            )}
            {stopRecord && (
              <button
                type="button"
                className="btn text-white my-3 bg-brand pe-2"
                value=" Export to canvas"
                onClick={insertVideo}
              >
                {exportIcon} {t("notes.video.button.export")}
              </button>
            )}
          </div>

          <video
            ref={videoNotesRef}
            className="m-auto my-3 d-block video"
          ></video>
        </>
      }
    />
  );
};

export default VideoRecorder;
