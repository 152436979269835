import { useEffect, useState } from "react";
import { t } from "../../../../../../i18n";
import MultiSelectDropdown from "../../../../../MultiSelectDropdown";
import "./filterStyle.scss";
import { Filter } from "../../../../../../types";
import { fetchTemplatesFilterOptions } from "../../../../../../excalidraw-app/api/collection";
import { FilteredValueType } from "../../CollectionsTab";
import { useIsMobile } from "../../../../../App";

interface FilterCollectionProps {
  setFilterDataWithKeys: (data: any) => void;
  filterDataWithKeys: any;
  onFilterClear: () => void;
  onFilterApply: (val: FilteredValueType) => void;
  selectedSortBy: number;
  setSelectedSortBy: React.Dispatch<React.SetStateAction<number | null>>;
  filterApply: boolean;
  setFilterApply: (val: boolean) => void;
}

export const FilterCollection = ({
  setFilterDataWithKeys,
  filterDataWithKeys,
  onFilterApply,
  onFilterClear,
  selectedSortBy,
  setSelectedSortBy,
  filterApply,
  setFilterApply,
}: FilterCollectionProps) => {
  const [filterOption, setFilterOption] = useState<Filter>({
    authors: [],
    tags: [],
  });
  const [open, setOpen] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<string[]>([]);

  useEffect(() => {
    const getFilterData = async () => {
      const { result: filter } = await fetchTemplatesFilterOptions();
      setFilterOption(filter);
    };
    getFilterData();
  }, []);

  const selectArray = [
    {
      title: t("collection.community.template.filter.age"),
      label: [
        t("collection.community.template.filter.options.7day"),
        t("collection.community.template.filter.options.1month"),
        t("collection.community.template.filter.options.3month"),
        t("collection.community.template.filter.options.6month"),
      ],

      id: "age",
      search: false,
    },
    {
      title: t("collection.community.template.filter.author"),
      label: filterOption.authors,
      id: "author",
      search: true,
    },
    {
      title: t("collection.community.template.filter.tags"),
      label: filterOption.tags,
      id: "tags",
      search: true,
    },
  ];

  const removeFilters = () => {
    setFilterData([]);
    onFilterClear();
    setFilterDataWithKeys({
      ...filterDataWithKeys,
      age: "",
      author: [],
      tags: [],
    });
  };

  const isMobile = useIsMobile();

  const sortByContet = [
    t("collection.community.template.sortBy.title"),
    t("collection.community.template.sortBy.types.newest"),
    t("collection.community.template.sortBy.types.mostLiked"),
    t("collection.community.template.sortBy.types.title"),
    t("collection.community.template.sortBy.types.author"),
  ];

  return (
    <>
      <div className="d-flex justify-content-between">
        {isMobile ? (
          <div className="d-flex align-items-center">
            <select
              className="custom-select"
              value={sortByContet[selectedSortBy ? selectedSortBy : 0]}
              onChange={(e) =>
                setSelectedSortBy((e.target.value as unknown) as number)
              }
            >
              {sortByContet.map((sortText, index) => {
                return (
                  <option selected={index === 1} value={index}>
                    {sortText}
                  </option>
                );
              })}
            </select>
          </div>
        ) : (
          <div className="d-flex align-items-center pt-lg-0 pt-3 ms-auto me-4">
            {sortByContet.map((sortText, index) => {
              return (
                <p
                  className="ps-3 text-nowrap mb-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedSortBy(index)}
                >
                  {sortText}
                </p>
              );
            })}
          </div>
        )}
      </div>
      <div
        className="offcanvas offcanvas-end filter-drawer"
        id="offcanvasRight"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel" className="offcanvas-title title mt-4">
            {t("collection.community.template.filter.title")}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setOpen(false);
              removeFilters();
              setFilterApply(false);
            }}
          ></button>
        </div>
        <div className="offcanvas-body overflow-hidden d-flex flex-column h-100">
          <MultiSelectDropdown
            selectArray={selectArray}
            isOpen={open}
            removeFilters={removeFilters}
            filterApply={filterApply}
            removeVal={""}
            setFilterDataWithKeys={setFilterDataWithKeys}
            filterDataWithKeys={filterDataWithKeys}
            filterData={filterData}
            setFilterData={setFilterData}
          />
        </div>
        <div className="footer">
          <button
            type="button"
            className="bg-brand text-white pr-2 infoBtn"
            data-bs-dismiss="offcanvas"
            aria-label="Apply"
            onClick={() => {
              setOpen(true);
              setFilterApply(true);
              onFilterApply(filterDataWithKeys);
            }}
          >
            {t("collection.community.template.filter.apply")}
          </button>
          <button
            type="button"
            className="infoBtn text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setOpen(false);
              removeFilters();
              setFilterApply(false);
            }}
          >
            {t("collection.community.template.filter.cancel")}
          </button>
        </div>
      </div>
    </>
  );
};
