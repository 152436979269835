interface Props {
  preDefinesThemes: string[];
  getGptPrompt: (data: string) => void;
  setIsChat: (value: boolean) => void;
  setUserPrompt: (data: string) => void;
}

export const PreDefinesThemes = ({
  preDefinesThemes,
  getGptPrompt,
  setIsChat,
  setUserPrompt,
}: Props) => {
  return (
    <div>
      {/* <div>
        <h6>Select from your pre-defines themes</h6>
      </div> */}
      <div className="dropdown">
        <button
          className="dropdown-toggle"
          type="button"
          id={"predefinesThemes"}
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Select from your pre-defines themes
        </button>
        <ul
          className="dropdown-menu dropdown-menu-minutes"
          aria-labelledby={"predefinesThemes"}
        >
          {preDefinesThemes?.map((data) => {
            return (
              <li
                onClick={() => {
                  // setIsChat(true);
                  // getGptPrompt(data);
                  setUserPrompt(data);
                }}
              >
                <span className="dropdown-item">{data}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
