export async function urlToDataUrl(
  url: string,
  mimeType: string,
): Promise<{ dataUrl: string; mimeType: string; format: string } | null> {
  try {
    const response = await fetch(url);
    if (response.ok) {
      const blob = await response.blob();
      const mimeType_ = blob.type;
      const format = getFormatFromURL(url);
      const dataUrl = await blobToDataURL(
        blob,
        mimeType_ || mimeType || "image/png",
      );
      return { dataUrl, mimeType, format };
    } else {
      throw new Error("Failed to fetch the URL.");
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function blobToDataURL(blob: Blob, mimeType: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    blob = new Blob([blob], { type: mimeType });
    reader.readAsDataURL(blob);
  });
}

export function getFormatFromURL(url: string): string {
  const parts = url?.split(".");
  if (parts?.length > 1) {
    return parts[parts.length - 1];
  }
  return "unknown";
}
