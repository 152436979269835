import { Action, ActionResult } from "./types";
import { undo, redo, zoomIn } from "../components/icons";
import { ToolButton, ToolButtonEnum } from "../components/ToolButton";
import { t } from "../i18n";
import History, { HistoryEntry } from "../history";
import { ExcalidrawElement, SceneElementsMap } from "../element/types";
import { AppState } from "../types";
import { isWindows, KEYS } from "../keys";
import { getElementMap } from "../element";
import { newElementWith } from "../element/mutateElement";
import { fixBindingsAfterDeletion } from "../element/binding";
import { arrayToMap } from "../utils";
import { Store } from "../store";

const writeData = (
  appState: Readonly<AppState>,
  updater: () => [SceneElementsMap, AppState] | void,
): ActionResult => {
  const commitToHistory = false;
  if (
    !appState.multiElement &&
    !appState.resizingElement &&
    !appState.editingElement &&
    !appState.draggingElement
  ) {
    const result = updater();

    if (!result) {
      return { commitToHistory };
    }

    const [nextElementsMap, nextAppState] = result;
    const nextElements = Array.from(nextElementsMap.values());

    return {
      appState: nextAppState,
      elements: nextElements,
      commitToHistory,
    };
  }
  return { commitToHistory };
};

type ActionCreator = (history: History, store: Store) => Action;

export const createUndoAction: ActionCreator = (history, store) => ({
  name: "undo",
  perform: (elements, appState, value, app) =>
    writeData(appState, () =>
      history.undo(
        arrayToMap(elements) as SceneElementsMap, // TODO: #7348 refactor action manager to already include `SceneElementsMap`
        appState,
        store.snapshot,
        app.scene,
      ),
    ),
  keyTest: (event) =>
    event[KEYS.CTRL_OR_CMD] &&
    event.key.toLowerCase() === KEYS.Z &&
    !event.shiftKey,
  PanelComponent: ({ updateData, data }) => (
    <ToolButton
      className="bg-brand"
      type={ToolButtonEnum.BUTTON}
      icon={undo}
      aria-label={t("buttons.undo")}
      onClick={updateData}
      size={data?.size || "medium"}
    />
  ),
  commitToHistory: () => false,
});

export const createRedoAction: ActionCreator = (history, store) => ({
  name: "redo",
  perform: (elements, appState, _, app) =>
    writeData(appState, () =>
      history.redo(
        arrayToMap(elements) as SceneElementsMap, // TODO: #7348 refactor action manager to already include `SceneElementsMap`
        appState,
        store.snapshot,
        app.scene,
      ),
    ),
  keyTest: (event) =>
    (event[KEYS.CTRL_OR_CMD] &&
      event.shiftKey &&
      event.key.toLowerCase() === KEYS.Z) ||
    (isWindows && event.ctrlKey && !event.shiftKey && event.key === KEYS.Y),
  PanelComponent: ({ updateData, data }) => (
    <ToolButton
      className="bg-brand"
      type={ToolButtonEnum.BUTTON}
      icon={redo}
      aria-label={t("buttons.redo")}
      onClick={updateData}
      size={data?.size || "medium"}
    />
  ),
  commitToHistory: () => false,
});

// export const createMoreAction: ActionCreator = (history) => ({
//   name: "moreTools",
//   perform: (elements, appState) =>
//     writeData(elements, appState, () => history.redoOnce()),
//   keyTest: (event) =>
//     (event[KEYS.CTRL_OR_CMD] &&
//       event.shiftKey &&
//       event.key.toLowerCase() === KEYS.Z) ||
//     (isWindows && event.ctrlKey && !event.shiftKey && event.key === KEYS.Y),
//   PanelComponent: ({ updateData, data }) => (
//     <ToolButton
//     className="bg-brand"
//       type="button"
//       icon={zoomIn}
//       aria-label={t("buttons.zoomIn")}
//       onClick={updateData}
//       size={data?.size || "medium"}
//     />
//   ),
//   commitToHistory: () => false,
// });
