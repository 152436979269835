import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import Library from "../../data/library";
import {
  AppState,
  BinaryFiles,
  CustomCategies,
  CollocationType,
  LibraryItem,
  LibraryItems,
  Subscription,
  LibraryData,
} from "../../types";
import { useIsMobile, useIsTablet } from "../App";
import { Dialog } from "../Dialog";
import { SegmentType, timerDuration } from "../spinner/types";
import {
  CollectionSidebarItemsEnum,
  SidebarItemsEnum,
} from "./components/constant/SidebarItems";
import "./style.scss";
import { CollectionSidebar } from "./tabs/collections/sidebar";
import { LibraryTab } from "./tabs/library";
import { LibrarySidebar } from "./tabs/library/sidebar";
import { NonDeletedExcalidrawElement } from "../../element/types";
import { CollectionTab } from "./tabs/collections/CollectionsTab";
import { t } from "../../i18n";
export interface LibraryItemsDialogProps {
  isOpen: boolean;
  togglekeyboardShortcut: (val: boolean) => void;
  pendingElements: LibraryItem["elements"];
  libraryItems: LibraryItems;
  files: BinaryFiles;
  onRemoveFromLibrary: () => void;
  resetLibrary: () => void;
  selectedItems: LibraryItem["id"][];
  onAddToLibrary: (elements: LibraryItem["elements"]) => void;
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  setAppState: React.Component<any, AppState>["setState"];
  library: Library;
  onPublish: () => void;
  onInsertShape: (
    elements: LibraryItem["elements"],
    isCloseModal?: boolean,
  ) => void;
  handleSpinnerDialog: (val: {
    isTimer: boolean;
    timerDuration: {
      [timerDuration.MINUTES]: number;
      [timerDuration.SECONDS]: number;
    };
    segments: SegmentType[];
  }) => void;
  insertImageOnCanvas: (file: File) => void;
  insertVideoOnCanvas: (file: File) => void;
  insertAudioOnCanvas: (file: File) => void;
  subscription: Subscription | undefined;
  elements: readonly NonDeletedExcalidrawElement[];
  appState: AppState;
  editCollection: (val: LibraryItems) => void;
  importCustomCategory: (elements: LibraryItem["elements"]) => void;
  importLibraryFromUrl: (urls: string[]) => void;
  addToCollection: number[];
  setAddToCollection: (val: number[]) => void;
  deleteElelement: number[];
  setDeleteElement: (val: number[]) => void;
  resetScene: (opts?: { resetLoadingState: boolean }) => void;
  getSelectedItems: (
    libraryItems: LibraryItems,
    selectedItems: LibraryItem["id"][],
  ) => void;
  onPublishLibSuccess: (data: LibraryData) => void;
  isTrusted: number;
}

export interface SidebarItemsProps {
  id: string;
  icon: JSX.Element | string | null;
  title: string;
  color?: string;
  isCustom?: boolean;
  value?: SidebarItemsEnum | CollectionSidebarItemsEnum;
}

export enum LibraryTabsEnum {
  MyLibrary = "My Library",
  Collections = "Collections",
}

export interface CategoryDataTypes {
  id: string;
  color: string;
  icon: string;
  isCustom: boolean;
  title: string;
}

export const LibraryItemsDialog = (props: LibraryItemsDialogProps) => {
  const { isOpen, togglekeyboardShortcut, setAppState } = props;

  const [
    selectedCatagoryForExportCanvas,
    setSelectedCatagoryForExportCanvas,
  ] = useState<CustomCategies[]>([]);

  useEffect(() => {
    togglekeyboardShortcut(false);
    return () => {
      togglekeyboardShortcut(true);
    };
  }, []);

  const isMobile = useIsMobile();

  const [navToggle, setNavToggle] = useState(false);
  const [categoryData, setCategoryData] = useState<CategoryDataTypes>({
    id: "",
    color: "#d9480f",
    icon: "FaRegLaugh",
    isCustom: false,
    title: "",
  });

  const [
    selectedSideLibraryTab,
    setSelectedSideLibraryTab,
  ] = useState<SidebarItemsEnum>(SidebarItemsEnum.WorkInProgress);

  const [
    selectedSideCollectionTab,
    setSelectedSideCollectionTab,
  ] = useState<CollectionSidebarItemsEnum>(CollectionSidebarItemsEnum.Template);

  const [selectedSection, setSelectedSection] = useState<LibraryTabsEnum>(
    LibraryTabsEnum.MyLibrary,
  );

  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");

  const [
    fullScreenCollectionPreview,
    setFullScreenCollectionPreview,
  ] = useState(false);

  useEffect(() => {
    if (props.appState.defaultLibraryTab) {
      setSelectedSideLibraryTab(
        props.appState.defaultLibraryTab as SidebarItemsEnum,
      );
    }
  }, [props.appState.defaultLibraryTab]);

  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth <= 1420);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallDevice(window.innerWidth <= 1420);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const TabButtons = [
    {
      title: t("toolBar.mylibrary"),
      value: LibraryTabsEnum.MyLibrary,
      id: "nav-collection-tab",
    },

    {
      title: t("toolBar.collections"),
      value: LibraryTabsEnum.Collections,
      id: "nav-my-library-tab",
    },
  ];

  const onClose = () => {
    setAppState({
      isLibraryOpen: false,
      defaultLibraryTab: SidebarItemsEnum.WorkInProgress,
    });
  };

  const renderSidebar = useMemo(() => {
    return selectedSection === LibraryTabsEnum.MyLibrary ? (
      <LibrarySidebar
        navToggle={navToggle}
        setNavToggle={setNavToggle}
        selectedTab={selectedSideLibraryTab}
        setSelectedTab={setSelectedSideLibraryTab}
        selectedTopTab={selectedSection}
        categoryData={categoryData}
        setCategoryData={setCategoryData}
        setSelectedCategoryId={setSelectedCategoryId}
        selectedCatagoryForExportCanvas={selectedCatagoryForExportCanvas}
        isSmallDevice={isSmallDevice}
        defaultLibraryTab={props.appState.defaultLibraryTab}
      />
    ) : (
      <>
        <CollectionSidebar
          navToggle={navToggle}
          setNavToggle={setNavToggle}
          selectedTab={selectedSideCollectionTab}
          setSelectedTab={setSelectedSideCollectionTab}
          selectedTopTab={selectedSection}
          isSmallDevice={isSmallDevice}
        />
      </>
    );
  }, [
    selectedSection,
    selectedSideLibraryTab,
    selectedSideCollectionTab,
    navToggle,
    categoryData,
    selectedCategoryId,
    selectedCatagoryForExportCanvas,
  ]);

  // const templateRef = useRef<TemplateCollectionRef>({
  //   previewTemplateData: null,
  // });

  const [
    previewTemplateData,
    setPreviewTemplateData,
  ] = useState<CollocationType | null>(null);

  const renderTabs = useMemo(() => {
    return selectedSection === LibraryTabsEnum.MyLibrary ? (
      <LibraryTab
        selectedSideLibraryTab={selectedSideLibraryTab}
        setSelectedSideLibraryTab={setSelectedSideLibraryTab}
        selectedCategoryId={selectedCategoryId}
        previewTemplateData={previewTemplateData}
        setPreviewTemplateData={setPreviewTemplateData}
        setFullScreenCollectionPreview={setFullScreenCollectionPreview}
        fullScreenCollectionPreview={fullScreenCollectionPreview}
        selectedCatagoryForExportCanvas={selectedCatagoryForExportCanvas}
        setSelectedCatagoryForExportCanvas={setSelectedCatagoryForExportCanvas}
        {...props}
      />
    ) : (
      <CollectionTab
        selectedSideCollectionTab={selectedSideCollectionTab}
        setFullScreenCollectionPreview={setFullScreenCollectionPreview}
        fullScreenCollectionPreview={fullScreenCollectionPreview}
        previewTemplateData={previewTemplateData}
        setPreviewTemplateData={setPreviewTemplateData}
        setSelectedSection={setSelectedSection}
        {...props}
        // ref={templateRef}
      />
    );
  }, [
    selectedSection,
    selectedSideLibraryTab,
    selectedSideCollectionTab,
    fullScreenCollectionPreview,
    setSelectedSection,
    setAppState,
    props,
    selectedCatagoryForExportCanvas,
  ]);

  return (
    <>
      <Dialog
        onCloseRequest={onClose}
        className="libraryDialog libraryItemDialog p-0"
        isShowSidebarMenu={false}
        closeOnClickOutside={true}
        open={isOpen}
        setOpen={() => false}
      >
        {fullScreenCollectionPreview ? (
          renderTabs
        ) : (
          <>
            <div className="d-flex gap-2 h-100 w-100 libraryItem_dialog">
              {renderSidebar}

              <div
                className="p-4 libraryItem_content "
                style={{
                  width: `${
                    navToggle
                      ? isMobile
                        ? "calc(93% - 60px)"
                        : "93%"
                      : isSmallDevice && !isMobile
                      ? "100%"
                      : "80%"
                  }`,
                  display: !navToggle && isMobile ? "none" : "block",
                  // flex: !isMobile && navToggle ? "2" : "",
                }}
              >
                <nav style={{ height: "6%" }}>
                  <div
                    className={
                      isMobile
                        ? "nav nav-tabs pb-2 flex-nowrap"
                        : "nav nav-tabs pb-2"
                    }
                    id="nav-tab"
                    role="tablist"
                  >
                    {TabButtons.map((item, index) => {
                      return (
                        <button
                          key={index}
                          className={`nav-link tab-button d-flex ${
                            selectedSection === item.value ? "active" : ""
                          }`}
                          id={item.id}
                          data-bs-toggle="tab"
                          onClick={() => setSelectedSection(item.value)}
                          type="button"
                          role="tab"
                          aria-controls={item.value}
                          aria-selected={selectedSection === item.value}
                        >
                          {item.title}
                        </button>
                      );
                    })}
                  </div>
                </nav>

                {renderTabs}
              </div>
            </div>
          </>
        )}
      </Dialog>
    </>
  );
};
