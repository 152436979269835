import { useEffect, useState } from "react";
import { IconPickerItem } from "react-fa-icon-picker";
import {
  getCategories,
  getUserTemplateCategories,
  removeCategoryFromLibraryTemplate,
  updateLibraryTemplateCategory,
} from "../../../../../../excalidraw-app/api/collection";
import { Popover } from "../../../../../Popover";
import { SidebarItemsProps } from "../../../../LibraryItemsDialog";
import "./style.scss";
import { del } from "../../../../../icons";
import { Toast, ToastType } from "../../../../../Toast";
import { t } from "../../../../../../i18n";

interface MoveToCategoryPopoverProps {
  setOpenCategoryPopover: (val: boolean) => void;
  templateId: string;
  onAfterMovedToCategory: (category: string[]) => void;
  templateCategory: string[];
  onAfterDeleteCategory: (id: string) => void;
}

export const MoveToCategoryPopover = ({
  setOpenCategoryPopover,
  templateId,
  onAfterMovedToCategory,
  templateCategory: template_category,
  onAfterDeleteCategory,
}: MoveToCategoryPopoverProps) => {
  const [categories, setCategories] = useState<SidebarItemsProps[]>([]);
  const [filterCategory, setFilterCategory] = useState<SidebarItemsProps[]>([]);
  const [search, setSearch] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<{
    message: string;
    type: ToastType;
  } | null>(null);

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const slug = new URLSearchParams(window.location.search).get("slug");

  const [templateCategory, setTemplateCategory] = useState<string[]>([]);

  useEffect(() => {
    setLoading(true);
    const fetchCategory = async () => {
      const templateCategory = await getUserTemplateCategories(
        user?.username,
        slug ?? "",
        templateId,
      );

      if (templateCategory?.result.length > 0)
        setTemplateCategory(templateCategory?.result[0].category);

      const categoryList = await getCategories(user?.username, slug ?? "");
      categoryList?.result && setCategories(categoryList.result);
      setLoading(false);
    };
    fetchCategory();
  }, []);

  useEffect(() => {
    if (search) {
      const filteredData = categories.filter((item) =>
        item.title.toLowerCase().includes(search.toLowerCase()),
      );
      return setFilterCategory(filteredData);
    }
    setFilterCategory(categories);
  }, [search, categories]);

  const handleCheckboxChange = (itemId: string) => {
    // Check if itemId is already in checkedItems
    if (selectedCategory.includes(itemId)) {
      // Remove itemId if already checked
      setSelectedCategory(selectedCategory.filter((id) => id !== itemId));
    } else {
      // Add itemId if not already checked
      setSelectedCategory([...selectedCategory, itemId]);
    }
  };

  const onSubmit = async () => {
    const payload = {
      categories: selectedCategory,
      id: templateId,
      userId: user?.username,
      slug: slug,
    };
    await updateLibraryTemplateCategory(payload);
    onAfterMovedToCategory(selectedCategory);
    setSelectedCategory([]);
    setOpenCategoryPopover(false);
  };

  return (
    <div className="category_popover">
      <Popover>
        <div className="h-25 d-flex">
          <input
            type="search"
            className="category_search mb-1"
            placeholder="Search"
            value={search ?? ""}
            onChange={(e) => setSearch(e.target.value)}
            autoFocus
          />
        </div>
        <div className="h-50 overflow-auto">
          {loading
            ? "Loading.."
            : filterCategory.map((item, index) => (
                <div className="d-flex gap-2 category_wrapper w-100 align-items-center">
                  <input
                    type="checkbox"
                    className="filter-checkbox"
                    id={`${item.title}-${index}`}
                    onChange={() => handleCheckboxChange(item.id)}
                    disabled={templateCategory.includes(item.id)}
                  />
                  {<IconPickerItem icon={item.icon} size={16} />}
                  <label
                    title={item.title}
                    className="category_name"
                    htmlFor={`${item.title}-${index}`}
                  >
                    {item.title}
                  </label>
                  {templateCategory.includes(item.id) && (
                    <div
                      className="btn-remove mb-2"
                      style={{ height: 12, width: 12 }}
                      onClick={() => {
                        removeCategoryFromLibraryTemplate(
                          templateId,
                          user.username,
                          slug ?? "",
                          item.id,
                        );
                        setToastMessage({
                          message: "Successfully removed collection.",
                          type: ToastType.ERROR,
                        });
                        onAfterDeleteCategory(item.id);
                       const updatedTemplateCategory = templateCategory.filter((category) => category !== item.id)
                       setTemplateCategory([...updatedTemplateCategory]);
                      }}
                    >
                      {del}
                    </div>
                  )}
                </div>
              ))}
        </div>
        <div className="d-flex gap-2 justify-content-end align-items-center h-25">
          <button
            type="button"
            className="filter-submit-btn"
            onClick={onSubmit}
          >
            {t("previewDialog.submit")}
          </button>
          <button
            type="button"
            className="filter-cancel-btn"
            onClick={() => {
              setSelectedCategory([]);
              setOpenCategoryPopover(false);
            }}
          >
            {t("previewDialog.cancel")}
          </button>
        </div>
      </Popover>
      {toastMessage !== null && (
        <Toast
          type={toastMessage.type}
          message={toastMessage.message}
          clearToast={() => setToastMessage(null)}
          className="style"
        />
      )}
    </div>
  );
};
