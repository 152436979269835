import { fileOpen, fileSave } from "./filesystem";
import { cleanAppStateForExport, clearAppStateForDatabase } from "../appState";
import {
  EXPORT_DATA_TYPES,
  EXPORT_SOURCE,
  MIME_TYPES,
  VERSIONS,
} from "../constants";
import { clearElementsForDatabase, clearElementsForExport } from "../element";
import { ExcalidrawElement } from "../element/types";
import { AppState, BinaryFiles, LibraryItem, LibraryItems } from "../types";
import { isImageFileHandle, loadFromBlob } from "./blob";

import {
  ExportedDataState,
  ImportedDataState,
  ExportedLibraryData,
} from "./types";
import Library from "./library";
import { t } from "../i18n";
import JSZip from "jszip";

/**
 * Strips out files which are only referenced by deleted elements
 */
const filterOutDeletedFiles = (
  elements: readonly ExcalidrawElement[],
  files: BinaryFiles,
) => {
  const nextFiles: BinaryFiles = {};
  for (const element of elements) {
    if (
      !element.isDeleted &&
      "fileId" in element &&
      element.fileId &&
      files[element.fileId]
    ) {
      nextFiles[element.fileId] = files[element.fileId];
    }
  }
  return nextFiles;
};

export const serializeAsJSON = (
  elements: readonly ExcalidrawElement[],
  appState: Partial<AppState>,
  files: BinaryFiles,
  type: "local" | "database",
): string => {
  const data: ExportedDataState = {
    type: EXPORT_DATA_TYPES.excalidraw,
    version: VERSIONS.excalidraw,
    source: EXPORT_SOURCE,
    elements:
      type === "local"
        ? clearElementsForExport(elements)
        : clearElementsForDatabase(elements),
    appState:
      type === "local"
        ? cleanAppStateForExport(appState)
        : clearAppStateForDatabase(appState),
    files:
      type === "local"
        ? filterOutDeletedFiles(elements, files)
        : // will be stripped from JSON
          undefined,
  };

  return JSON.stringify(data, null, 2);
};

export const saveAsJSON = async (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
  files: BinaryFiles,
) => {
  const serialized = serializeAsJSON(elements, appState, files, "local");
  const blob = new Blob([serialized], {
    type: MIME_TYPES.acv,
  });

  const fileHandle = await fileSave(blob, {
    name: appState.name,
    extension: "acv",
    description: "Acv file",
    fileHandle: isImageFileHandle(appState.fileHandle)
      ? null
      : appState.fileHandle,
  });
  return { fileHandle };
};

export const saveAsJSONZip = async (
  pages: readonly ExcalidrawElement[][],
  appState: AppState,
  files: BinaryFiles,
) => {
  try {
    if (pages.length === 0) {
      throw new Error(t("alerts.cannotExportEmptyCanvas"));
    }
    const allPagesPromise = pages.map((page: any, i) => {
      return new Promise(async (resolve, reject) => {
        try {
          const jsonData = await convertCanvasToJSON(
            page.data,
            appState,
            files,
          );
          resolve({
            data: jsonData,
            filename: `${
              page?.pageName ? page?.pageName : `Page - ${page.page}`
            }.acv`,
          });
        } catch (error) {
          reject(error);
          console.log("error", error);
        }
      });
    });
    const resolvedAllPagesPromise: any = await Promise.all(allPagesPromise);
    if (resolvedAllPagesPromise.length > 0) {
      const zip = new JSZip();

      // Add each resolved file to the zip
      resolvedAllPagesPromise.forEach(
        (pageData: { data: any; filename: string }, i: Number) => {
          zip.file(pageData.filename, pageData.data);
        },
      );

      // Generate the zip file
      const zipBlob = await zip.generateAsync({ type: "blob" });
      return await fileSave(zipBlob, {
        description: "Export to ZIP",
        name: `acv-data`,
        extension: "zip",
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const convertCanvasToJSON = async (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
  files: BinaryFiles,
) => {
  const serialized = serializeAsJSON(elements, appState, files, "local");
  const blob = new Blob([serialized], {
    type: MIME_TYPES.acv,
  });
  return blob;
};

export const loadFromJSON = async (
  localAppState: AppState,
  localElements: readonly ExcalidrawElement[] | null,
) => {
  const blob = await fileOpen({
    description: "Acv files",
    // ToDo: Be over-permissive until https://bugs.webkit.org/show_bug.cgi?id=34442
    // gets resolved. Else, iOS users cannot open `.excalidraw` files.
    extensions: ["acv"],
    /*
    mimeTypes: [
      MIME_TYPES.excalidraw,
      "application/json",
      "image/png",
      "image/svg+xml",
    ],
    */
  });
  return loadFromBlob(blob, localAppState, localElements);
};

export const isValidExcalidrawData = (data?: {
  type?: any;
  elements?: any;
  appState?: any;
}): data is ImportedDataState => {
  return (
    data?.type === EXPORT_DATA_TYPES.excalidraw &&
    (!data.elements ||
      (Array.isArray(data.elements) &&
        (!data.appState || typeof data.appState === "object")))
  );
};

export const isValidLibrary = (json: any) => {
  return (
    typeof json === "object" &&
    json &&
    json.type === EXPORT_DATA_TYPES.excalidrawLibrary &&
    json.version === 2
  );
};

export const saveLibraryAsJSON = async (libraryItems: LibraryItems) => {
  const data: ExportedLibraryData = {
    type: EXPORT_DATA_TYPES.excalidrawLibrary,
    version: VERSIONS.excalidrawLibrary,
    source: EXPORT_SOURCE,
    libraryItems,
  };
  const serialized = JSON.stringify(data, null, 2);
  await fileSave(
    new Blob([serialized], {
      type: MIME_TYPES.acvlib,
    }),
    {
      name: "library",
      extension: "acvlib",
      description: "Excalidraw library file",
    },
  );
};

export const importLibraryFromJSON = async (
  library: Library,
  status?: string,
) => {
  const blob = await fileOpen({
    description: "Excalidraw library files",
    // ToDo: Be over-permissive until https://bugs.webkit.org/show_bug.cgi?id=34442
    // gets resolved. Else, iOS users cannot open `.excalidraw` files.
    extensions: ["acvlib"],
    /*
     */
  });
  await library.importLibrary(
    blob,
    "unpublished",
    status,
  );
};

export const serializeLibraryAsJSON = (libraryItems: LibraryItems) => {
  const data: ExportedLibraryData = {
    type: EXPORT_DATA_TYPES.excalidrawLibrary,
    version: VERSIONS.excalidrawLibrary,
    source: EXPORT_SOURCE,
    libraryItems,
  };
  return JSON.stringify(data, null, 2);
};
