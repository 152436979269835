import { apiDelete, apiGet, apiPost, apiPut } from ".";
import {
  StockImagesInput,
  WithdrawRequestInput,
  filteredValueType,
} from "../../components/LibraryDialog";
import { UnderReviewInput } from "../../components/UnderReview";
import { FilteredValueType } from "../../components/libraryItemsDialog/tabs/collections/CollectionsTab";
import { FileId } from "../../element/types";
import { API_URL } from "./constant";
const slug = new URLSearchParams(window.location.search).get("slug");
const token = localStorage.getItem("access_token");

export const fetchAllCollection = ({
  search,
  sort,
  tab,
}: {
  search?: string;
  sort?: number;
  tab?: string;
}) =>
  apiGet(
    `${API_URL}/fetch-all-collocation${slug ? "?slug=" + slug : ""}${
      token ? "&token=" + token : ""
    }${search ? "&search=" + search : ""}${sort ? "&sort=" + sort : ""}${
      tab ? "&tab=" + tab : ""
    }`,
  );

export const fetchTemplatesFilterOptions = () =>
  apiGet(`${API_URL}/get-templates-filter-options`);

export const fetchFilteredCollectionData = (body: FilteredValueType) =>
  apiPost(`${API_URL}/get-filtered-templates-data`, body);

export const fetchUnderReviewCollectionsAPI = (body: UnderReviewInput) =>
  apiPost(`${API_URL}/get-underreview-collections`, body);

export const withdrawCollectionApprovalRequestAPI = (
  body: WithdrawRequestInput,
) => apiDelete(`${API_URL}/withdraw-underreview-collections-request`, body);

export const fetchStockImagesAPI = (body: StockImagesInput) =>
  apiPost(`${API_URL}/get-stock-images`, body);

export const fetchMyPublishedCollectionAPI = (body: {
  userId: string;
  search?: string;
}) => apiPost(`${API_URL}/get-mypublished-collocation`, body);

export const fetchWBLocalImages = (mail: string) =>
  apiGet(`${API_URL}/get-wb-collection-images?email=${mail}`);

export const fetchWBLocalVideos = (mail: string) =>
  apiGet(`${API_URL}/get-wb-collection-videos?email=${mail}`);
export const fetchWBLocalAudios = (mail: string) =>
  apiGet(`${API_URL}/get-wb-collection-audios?email=${mail}`);

export const uploadWBLocalImages = (body: any) =>
  apiPost(`${API_URL}/upload-whiteboard-image`, body);

export const uploadWBLocalVideos = (body: any) =>
  apiPost(`${API_URL}/upload-whiteboard-video`, body);

export const uploadWBLocalAudios = (body: any) =>
  apiPost(`${API_URL}/upload-whiteboard-audio`, body);

export const likeUnlikeCollectionAPI = (id: string) =>
  apiPut(`${API_URL}/like-unlike-collection?id=${id}`);

export const bookmarkCollectionAPI = (id: string) =>
  // apiPut(`localhost:8080/bookmark-collection?id=${id}`);
  apiPut(`${API_URL}/bookmark-collection?id=${id}`);

export const postLocalStateLibray = (body: any) =>
  apiPost(`${API_URL}/post-localState-library`, body);

export const getLocalStateLibray = (body: any) =>
  apiGet(
    `${API_URL}/get-localState-library?lessonId=${body.lessonId}&userId=${body.userId}`,
  );

export const getPublishedLibrariesFiles = (lessonId: string, fileId: FileId) =>
  apiGet(
    `${API_URL}/get-published-library-files?lessonId=${lessonId}&fileId=${fileId}`,
  );

export const deleteFiles = (fileId: FileId, type: string, lessonId: string) =>
  apiDelete(
    `${API_URL}/delete-files?fileId=${fileId}&type=${type}&lessonId=${lessonId}`,
  );

export const getSpinWheels = (body: any) =>
  apiPost(`${API_URL}/spin-wheel/get`, body);

export const deleteSpinWheel = (id: string) =>
  apiDelete(`${API_URL}/spin-wheel/remove/${id}`);

export const createSpinWheel = (body: any) =>
  apiPost(`${API_URL}/spin-wheel/create`, body);

export const getCategories = (userId: string, slug: string) =>
  apiGet(`${API_URL}/category?userId=${userId}&slug=${slug}`);

export const getUserTemplateCategories = (
  userId: string,
  slug: string,
  collectionId: string,
) =>
  apiGet(
    `${API_URL}/category/template/userCategory?userId=${userId}&slug=${slug}&collectionId=${collectionId}`,
  );

export const deleteCategory = (id: string) =>
  apiDelete(`${API_URL}/category/${id}`);

export const createCategory = (body: any) =>
  apiPost(`${API_URL}/category`, body);

export const editCategory = (body: any) =>
  apiPost(`${API_URL}/category/edit`, body);

export const updateLibraryTemplateCategory = (body: any) =>
  apiPost(`${API_URL}/category/template`, body);

export const getCategoryWiseLibraryTemplate = (
  slug: string,
  userId: string,
  categoryId: string,
) =>
  apiGet(
    `${API_URL}/category/template?slug=${slug}&userId=${userId}&categoryId=${categoryId}`,
  );

export const removeCategoryFromLibraryTemplate = (
  id: string,
  userId: string,
  slug: string,
  categoryId: string,
) =>
  apiDelete(
    `${API_URL}/category/template?slug=${slug}&id=${id}&userId=${userId}&categoryId=${categoryId}`,
  );
