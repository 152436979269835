import { useCallback, useState, useRef, useEffect, useContext } from "react";
import { Dialog } from "./Dialog";
import RecordRTC from "recordrtc";
import {
  circleFill,
  closeIcon,
  errorIcon,
  exportIcon,
  pauseFillIcon,
  playFillIcon,
  tickCircle,
  warningIcon,
} from "./icons";
import "./Audio.scss";
import TitleSelect from "./AudioVideoTitleSelect";
import { NotesTitleContext } from "./contexts/NotesTitle.context";
import { t } from "../i18n";
import { set } from "lodash";
import { start } from "repl";
import { Subscription } from "../types";

const AudioRecorder = ({
  onClose,
  insertAudioOnCanvas,
  subscription,
}: {
  onClose: () => void;
  insertAudioOnCanvas: (
    file: File,
    filename?: string | null,
    color?: string,
  ) => void;
  subscription?: Subscription;
}) => {
  const recordLimit = (subscription && subscription.audioRecordLimit) || 30;
  const [recordRTC, setRecordRTC] = useState<RecordRTC>();
  const [isStopRecord, setIsStopRecord] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [file, setFile] = useState<File>();
  const timerRef = useRef<NodeJS.Timeout>();
  const stopBtnRef = useRef<HTMLButtonElement>(null);
  const startBtnRef = useRef<HTMLButtonElement>(null);

  const [second, setSecond] = useState(recordLimit);
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const [timer, setTimer] = useState(4);
  const [startCountDown, setStartCountDown] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [confirmation, setConfirmation] = useState(false);

  const { fileName, color, onColorChange, onFileNameChange } = useContext(
    NotesTitleContext,
  );

  useEffect(() => {
    onColorChange("#000000");
    onFileNameChange("");
  }, []);

  useEffect(() => {
    let intervalId: any;

    if (isActive || startCountDown === false) {
      intervalId = setInterval(() => {
        if (counter <= Number(recordLimit)) {
          const remainingSeconds = Number(recordLimit) - counter - 1; // Countdown from 30 seconds
          const remainingSecondCounter = remainingSeconds % 60;
          const remainingMinuteCounter = Math.floor(remainingSeconds / 60);

          let computedRemainingSecond =
            String(remainingSecondCounter).length === 1
              ? `0${remainingSecondCounter}`
              : remainingSecondCounter;
          let computedRemainingMinute =
            String(remainingMinuteCounter).length === 1
              ? `0${remainingMinuteCounter}`
              : remainingMinuteCounter;

          setSecond(computedRemainingSecond as string);
          setMinute(computedRemainingMinute as string);

          setCounter((counter) => counter + 1);
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  const onDialogClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (!startCountDown || error) return;

    const intervalId = setInterval(() => {
      if (timer <= 4 && timer > 1) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        setStartCountDown(false);
        clearInterval(intervalId);
        setIsActive(true);
        startRecording();
      }
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts or when stopCountDown is true
    return () => clearInterval(intervalId);
  }, [timer, startCountDown]); // Add dependencies if needed

  const startRecording = async () => {
    try {
      setSecond(recordLimit);
      setMinute("00");
      setStartCountDown(false);
      setCounter(0);

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const recorder = new RecordRTC(stream, {
        type: "audio",
        mimeType: "audio/webm",
        timeSlice: 1000,
      });
      setRecordRTC(recorder);
      recorder.startRecording();
      setIsStopRecord(false);

      // Set a timer to stop recording after 30 seconds
      setTimeout(() => {
        if (stopBtnRef && stopBtnRef.current) stopBtnRef.current.click();
      }, (Number(recordLimit) + 1) * 1000); // 31 seconds in milliseconds
      setError(null);
    } catch (error: any) {
      // You can also display an error message to the user
      setError(error.message);
      setIsActive(false);
      setStartCountDown(false);
    }
  };

  const stopRecording = () => {
    setTimer(3);
    setStartCountDown(false);
    recordRTC?.stopRecording(() => {
      setIsStopRecord(true);
      const blob = recordRTC?.getBlob();
      const url = URL.createObjectURL(blob);

      setIsActive(false);
      setSecond(recordLimit);
      setMinute("00");
      const file = new File([blob], fileName as string, { type: "audio/webm" });
      setFile(file);
      // Clear the timer to stop updating the current time
      clearInterval(timerRef.current);
      if (!audioRef || !audioRef.current) return;
      audioRef.current!.src = url;
    });
  };

  const insertAudio = async () => {
    if (!file) return;
    insertAudioOnCanvas(file, fileName, color);
    onClose();
    setIsActive(false);
    setSecond(recordLimit);
    setMinute("00");
    setIsStopRecord(false);
  };

  return (
    <>
      <Dialog
        onCloseRequest={() =>
          fileName || recordRTC ? setConfirmation(true) : onDialogClose()
        }
        title="Audio notes"
        className={`publish-library ${
          isStopRecord ? "audio-stop-model-width" : "audio-model-width"
        }`}
        closeOnClickOutside={false}
        open={true}
        setOpen={() => false}
        children={
          <>
            <div
              className="notes_div"
              style={{ border: confirmation ? "1px solid #c92a2a" : "none" }}
            >
              {(fileName || recordRTC) && confirmation ? (
                <div className="d-flex">
                  <span className="confirmation">{errorIcon}</span>
                  <div className="d-flex w-100">
                    <span className="text-danger" style={{ width: "90%" }}>
                      Are you sure? Your audio will not be saved.
                    </span>
                    <div className="d-flex gap-1">
                      <span
                        data-toggle="tooltip"
                        data-bootstrap-placement="bottom"
                        title="Confirm"
                        onClick={onDialogClose}
                      >
                        {tickCircle}
                      </span>
                      <span
                        data-toggle="tooltip"
                        data-bootstrap-placement="bottom"
                        title="Stay here"
                        onClick={() => setConfirmation(false)}
                      >
                        {closeIcon}
                      </span>
                    </div>
                  </div>
                </div>
              ) : error ? (
                <>
                  <span className="me-2">{errorIcon}</span>
                  <span className="text-danger">{error}</span>
                </>
              ) : (
                <>
                  <span className="me-2 bg-color"> {warningIcon}</span>
                  You can capture audio for a minimum duration of {
                    recordLimit
                  }{" "}
                  seconds.
                </>
              )}
            </div>
            <div className="form-group d-block position-relative">
              <div className="d-flex ">
                <label htmlFor="exampleFormControlInput1" className="mb-1">
                  {t("notes.audio.name")}
                </label>
                {startCountDown && (
                  <div className="w-100 start-timer position-absolute">
                    <h1
                      className="d-flex justify-content-center rounded-circle m-auto timer-animation"
                      style={{ justifyContent: "center" }}
                    >
                      {timer}
                    </h1>
                  </div>
                )}
              </div>
              <TitleSelect />
            </div>
            <div
              className={`d-flex flex-wrap gap-3 ${
                isStopRecord
                  ? "justify-content-around"
                  : isActive
                  ? "justify-content-around"
                  : "justify-content-start"
              }`}
            >
              {isActive ? (
                <div
                  className="my-3 pe-2"
                  style={{ alignItems: "center", display: "flex" }}
                >
                  {circleFill}
                  {t("notes.video.button.circleFill")}
                  <span style={{ marginLeft: "5px" }}>
                    <span className="minute text-black">{minute}</span>
                    <span>:</span>
                    <span className="second text-black">{second}</span>
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn text-white mt-3 mb-2 bg-brand pe-2"
                  value="Start recording"
                  onClick={() => {
                    setTimer(3);
                    setStartCountDown(true);
                  }}
                  ref={startBtnRef}
                  disabled={startCountDown || isActive || error !== null}
                >
                  {playFillIcon} {t("notes.audio.button.start")}
                </button>
              )}
              {isActive && (
                <button
                  type="button"
                  className="btn text-white mt-3 mb-2 bg-brand pe-2"
                  value="Stop recording"
                  onClick={stopRecording}
                  ref={stopBtnRef}
                  disabled={startCountDown}
                >
                  {pauseFillIcon} {t("notes.audio.button.stop")}
                </button>
              )}

              {isStopRecord && (
                <button
                  type="button"
                  className="btn text-white mt-3 mb-2 bg-brand pe-2"
                  value=" Export to canvas"
                  onClick={insertAudio}
                  disabled={startCountDown}
                >
                  {exportIcon} {t("notes.audio.button.send")}
                </button>
              )}
            </div>

            {isStopRecord && (
              <div style={{ marginTop: "5px" }}>
                <audio controls id={`audio`} ref={audioRef}></audio>
              </div>
            )}
          </>
        }
      />
    </>
  );
};

export default AudioRecorder;
