import { AppState } from "./types";
import { ExcalidrawElement, SceneElementsMap } from "./element/types";
import { isLinearElement } from "./element/typeChecks";
import { deepCopyElement } from "./element/newElement";
import { Emitter } from "./emitter";
import { AppStateChange, ElementsChange } from "./change";
import { Snapshot } from "./store";
import Scene from "./scene/Scene";

// export interface HistoryEntry {
//   appState: ReturnType<typeof clearAppStatePropertiesForHistory>;
//   elements: ExcalidrawElement[];

// }
export class HistoryEntry {
  private constructor(
    public readonly appStateChange: AppStateChange,
    public readonly elementsChange: ElementsChange,
  ) {}

  public static create(
    appStateChange: AppStateChange,
    elementsChange: ElementsChange,
  ) {
    return new HistoryEntry(appStateChange, elementsChange);
  }

  public inverse(): HistoryEntry {
    return new HistoryEntry(
      this.appStateChange.inverse(),
      this.elementsChange.inverse(),
    );
  }

  public applyTo(
    elements: SceneElementsMap,
    appState: AppState,
    snapshot: Readonly<Snapshot>,
    scene: Scene,
  ): [SceneElementsMap, AppState, boolean] {
    const [
      nextElements,
      elementsContainVisibleChange,
    ] = this.elementsChange.applyTo(elements, snapshot.elements);

    const [
      nextAppState,
      appStateContainsVisibleChange,
    ] = this.appStateChange.applyTo(appState, nextElements);

    const appliedVisibleChanges =
      elementsContainVisibleChange || appStateContainsVisibleChange;

    return [nextElements, nextAppState, appliedVisibleChanges];
  }

  /**
   * Apply latest (remote) changes to the history entry, creates new instance of `HistoryEntry`.
   */
  public applyLatestChanges(elements: SceneElementsMap): HistoryEntry {
    const updatedElementsChange = this.elementsChange.applyLatestChanges(
      elements,
    );

    return HistoryEntry.create(this.appStateChange, updatedElementsChange);
  }

  public isEmpty(): boolean {
    return this.appStateChange.isEmpty() && this.elementsChange.isEmpty();
  }
}

interface DehydratedExcalidrawElement {
  id: string;
  versionNonce: number;
}

interface DehydratedHistoryEntry {
  appState: string;
  elements: DehydratedExcalidrawElement[];
}

const clearAppStatePropertiesForHistory = (appState: AppState) => {
  return {
    selectedElementIds: appState.selectedElementIds,
    selectedGroupIds: appState.selectedGroupIds,
    viewBackgroundColor: appState.viewBackgroundColor,
    editingLinearElement: appState.editingLinearElement,
    editingGroupId: appState.editingGroupId,
    name: appState.name,
  };
};
export class HistoryChangedEvent {
  constructor(
    public readonly isUndoStackEmpty: boolean = true,
    public readonly isRedoStackEmpty: boolean = true,
  ) {}
}

type HistoryStack = HistoryEntry[];
class History {
  private elementCache = new Map<string, Map<number, ExcalidrawElement>>();
  private lastEntry: HistoryEntry | null = null;
  private recording: boolean = true;

  // private hydrateHistoryEntry({
  //   appState,
  //   elements,
  // }: DehydratedHistoryEntry): HistoryEntry {
  //   return {
  //     appState: JSON.parse(appState),
  //     elements: elements.map((dehydratedExcalidrawElement) => {
  //       const element = this.elementCache
  //         .get(dehydratedExcalidrawElement.id)
  //         ?.get(dehydratedExcalidrawElement.versionNonce);
  //       if (!element) {
  //         throw new Error(
  //           `Element not found: ${dehydratedExcalidrawElement.id}:${dehydratedExcalidrawElement.versionNonce}`,
  //         );
  //       }
  //       return element;
  //     }),
  //   };
  // }

  // private dehydrateHistoryEntry({
  //   appState,
  //   elements,
  // }: HistoryEntry): DehydratedHistoryEntry {
  //   return {
  //     appState: JSON.stringify(appState),
  //     elements: elements.map((element: ExcalidrawElement) => {
  //       if (!this.elementCache.has(element.id)) {
  //         this.elementCache.set(element.id, new Map());
  //       }
  //       const versions = this.elementCache.get(element.id)!;
  //       if (!versions.has(element.versionNonce)) {
  //         versions.set(element.versionNonce, deepCopyElement(element));
  //       }
  //       return {
  //         id: element.id,
  //         versionNonce: element.versionNonce,
  //       };
  //     }),
  //   };
  // }

  // private generateEntry = (
  //   appState: AppState,
  //   elements: readonly ExcalidrawElement[],
  // ): DehydratedHistoryEntry =>
  //   this.dehydrateHistoryEntry({
  //     appState: clearAppStatePropertiesForHistory(appState),
  //     elements: elements.reduce((elements, element) => {
  //       if (
  //         isLinearElement(element) &&
  //         appState.multiElement &&
  //         appState.multiElement.id === element.id
  //       ) {
  //         // don't store multi-point arrow if still has only one point
  //         if (
  //           appState.multiElement &&
  //           appState.multiElement.id === element.id &&
  //           element.points.length < 2
  //         ) {
  //           return elements;
  //         }

  //         elements.push({
  //           ...element,
  //           // don't store last point if not committed
  //           points:
  //             element.lastCommittedPoint !==
  //             element.points[element.points.length - 1]
  //               ? element.points.slice(0, -1)
  //               : element.points,
  //         });
  //       } else {
  //         elements.push(element);
  //       }
  //       return elements;
  //     }, [] as Mutable<typeof elements>),
  //   });

  clearRedoStack() {
    this.redoStack.splice(0, this.redoStack.length);
  }

  /**
   * Updates history's `lastEntry` to latest app state. This is necessary
   *  when doing undo/redo which itself doesn't commit to history, but updates
   *  app state in a way that would break `shouldCreateEntry` which relies on
   *  `lastEntry` to reflect last comittable history state.
   * We can't update `lastEntry` from within history when calling undo/redo
   *  because the action potentially mutates appState/elements before storing
   *  it.
   */
  // setCurrentState(appState: AppState, elements: readonly ExcalidrawElement[]) {
  //   this.lastEntry = this.hydrateHistoryEntry(
  //     this.generateEntry(appState, elements),
  //   );
  // }

  // history
  public readonly onHistoryChangedEmitter = new Emitter<
    [HistoryChangedEvent]
  >();

  private readonly undoStack: HistoryStack = [];
  private readonly redoStack: HistoryStack = [];

  public get isUndoStackEmpty() {
    return this.undoStack.length === 0;
  }

  public get isRedoStackEmpty() {
    return this.redoStack.length === 0;
  }

  public clear() {
    this.undoStack.length = 0;
    this.redoStack.length = 0;
  }

  resumeRecording() {
    this.recording = true;
  }

  /**
   * Record a local change which will go into the history
   */
  public record(
    elementsChange: ElementsChange,
    appStateChange: AppStateChange,
  ) {
    const entry = HistoryEntry.create(appStateChange, elementsChange);
    if (!entry.isEmpty()) {
      // we have the latest changes, no need to `applyLatest`, which is done within `History.push`
      this.undoStack.push(entry.inverse());

      if (!entry.elementsChange.isEmpty()) {
        // don't reset redo stack on local appState changes,
        // as a simple click (unselect) could lead to losing all the redo entries
        // only reset on non empty elements changes!
        this.redoStack.length = 0;
      }

      this.onHistoryChangedEmitter.trigger(
        new HistoryChangedEvent(this.isUndoStackEmpty, this.isRedoStackEmpty),
      );
    }
  }

  public undo(
    elements: SceneElementsMap,
    appState: AppState,
    snapshot: Readonly<Snapshot>,
    scene: Scene,
  ) {
    return this.perform(
      elements,
      appState,
      snapshot,
      () => History.pop(this.undoStack),
      (entry: HistoryEntry) => History.push(this.redoStack, entry, elements),
      scene,
    );
  }

  public redo(
    elements: SceneElementsMap,
    appState: AppState,
    snapshot: Readonly<Snapshot>,
    scene: Scene,
  ) {
    return this.perform(
      elements,
      appState,
      snapshot,
      () => History.pop(this.redoStack),
      (entry: HistoryEntry) => History.push(this.undoStack, entry, elements),
      scene,
    );
  }

  private perform(
    elements: SceneElementsMap,
    appState: AppState,
    snapshot: Readonly<Snapshot>,
    pop: () => HistoryEntry | null,
    push: (entry: HistoryEntry) => void,
    scene: Scene,
  ): [SceneElementsMap, AppState] | void {
    try {
      let historyEntry = pop();

      if (historyEntry === null) {
        return;
      }

      let nextElements = elements;
      let nextAppState = appState;
      let containsVisibleChange = false;

      // iterate through the history entries in case they result in no visible changes
      while (historyEntry) {
        try {
          [
            nextElements,
            nextAppState,
            containsVisibleChange,
          ] = historyEntry.applyTo(nextElements, nextAppState, snapshot, scene);
        } finally {
          // make sure to always push / pop, even if the increment is corrupted
          push(historyEntry);
        }

        if (containsVisibleChange) {
          break;
        }

        historyEntry = pop();
      }

      return [nextElements, nextAppState];
    } finally {
      // trigger the history change event before returning completely
      // also trigger it just once, no need doing so on each entry
      this.onHistoryChangedEmitter.trigger(
        new HistoryChangedEvent(this.isUndoStackEmpty, this.isRedoStackEmpty),
      );
    }
  }

  private static pop(stack: HistoryStack): HistoryEntry | null {
    if (!stack.length) {
      return null;
    }

    const entry = stack.pop();

    if (entry !== undefined) {
      return entry;
    }

    return null;
  }

  private static push(
    stack: HistoryStack,
    entry: HistoryEntry,
    prevElements: SceneElementsMap,
  ) {
    const updatedEntry = entry.inverse().applyLatestChanges(prevElements);
    return stack.push(updatedEntry);
  }
}

export default History;
