import React, { useEffect, useState } from "react";

interface WheelProps {
  segments: string[];
  segColors: string[];
  winningSegment?: string;
  onFinished: (segment: string) => void;
  primaryColor?: string;
  primaryColoraround?: string;
  contrastColor?: string;
  buttonText?: string;
  isOnlyOnce?: boolean;
  size?: number;
  upDuration?: number;
  downDuration?: number;
  fontFamily?: string;
  width?: number;
  height?: number;
  id?: string;
  spinnerRound: {
    arcSize: number;
    fontSize: string;
    moveToX: number;
    moveToY: number;
    lineToX: number;
    lineToY: number;
    lineToY2: number;
    segmentFontSize: string;
  };
}

export const SpinnerWheel: React.FC<WheelProps> = ({
  segments,
  segColors,
  winningSegment,
  onFinished,
  primaryColor,
  primaryColoraround,
  contrastColor,
  buttonText,
  isOnlyOnce = true,
  size = 580,
  upDuration = 1500,
  downDuration = 100,
  fontFamily = "proxima-nova",
  id = "canvas",
  spinnerRound,
}) => {
  let currentSegment: string = "";
  let isStarted: boolean = false;
  let timerHandle: number = 0;
  const timerDelay: number =
    segments.length >= 500
      ? 8
      : segments.length >= 100
      ? 2
      : segments.length >= 50
      ? 5
      : segments.length;
  let angleCurrent: number = 0;
  let angleDelta: number = 0;
  let canvasContext: CanvasRenderingContext2D | null = null;
  let maxSpeed: number =
    Math.PI /
    (segments.length >= 500
      ? 8
      : segments.length >= 100
      ? 2
      : segments.length >= 50
      ? 5
      : segments.length);
  const upTime: number = upDuration;
  const downTime: number = downDuration;
  let spinStart: number = 0;
  let frames: number = 0;
  const centerX: number = size / 2;
  const centerY: number = size / 2;
  // Initialize wheel and canvas when segments or their length changes
  useEffect(() => {
    wheelInit();
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 0);
  }, [segments.length, segments]); // Include segments in the dependency array

  // Initialize canvas and draw wheel
  const wheelInit = () => {
    initCanvas();
    wheelDraw();
  };
  // Set up event listener for spinning the wheel
  useEffect(() => {
    // Get the canvas element by Id
    const canvas = document.getElementById(id) as HTMLCanvasElement;

    // Check if canvas exists and the spin process hasn't started yet
    if (canvas && !isStarted) {
      // Check if all segments are empty strings
      const containsOnlyEmptyStrings = segments.every(
        (element) => element.trim() === "", // Using trim() to account for possible whitespace
      );

      // If all segments are empty, exit early and do not add the click event listener
      if (containsOnlyEmptyStrings) return;

      // Add the click event listener for the spin function
      const handleSpin = () => {
        if (!containsOnlyEmptyStrings) {
          spin();
        }
      };

      canvas.addEventListener("click", handleSpin);

      // Return a clean-up function to remove the event listener
      return () => {
        canvas.removeEventListener("click", handleSpin);
      };
    }
  }, [id, isStarted, segments]);

  // Initialize the canvas
  const initCanvas = () => {
    const canvas = document.getElementById(id) as HTMLCanvasElement;
    canvas.width = size;
    canvas.height = size;

    // Get the context of the canvas
    canvasContext = canvas.getContext("2d");
  };

  // Function to start spinning the wheel
  const spin = () => {
    isStarted = true;
    if (timerHandle === 0) {
      spinStart = new Date().getTime();
      maxSpeed =
        Math.PI /
        (segments.length >= 500
          ? 8
          : segments.length >= 100
          ? 2
          : segments.length >= 50
          ? 5
          : segments.length);
      frames = 0;
      timerHandle = (setInterval(onTimerTick, timerDelay) as unknown) as number;
    }
  };

  // Function to handle the timer tick while spinning
  const onTimerTick = () => {
    frames++;
    draw();
    const duration = new Date().getTime() - spinStart;
    let progress = 0;
    let finished = false;

    if (duration < upTime) {
      progress = duration / upTime;
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2);
    } else {
      progress = duration / downTime;
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
      if (progress >= 1) {
        finished = true;
      }
    }

    angleCurrent += angleDelta;
    while (angleCurrent >= Math.PI * 2) {
      angleCurrent -= Math.PI * 2;
    }
    if (finished) {
      onFinished(currentSegment);
      clearInterval(timerHandle);
      timerHandle = 0;
      angleDelta = 0;
    }
  };

  // Draw the wheel and needle
  const wheelDraw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const draw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };
  const drawSegment = (key: number, lastAngle: number, angle: number) => {
    const ctx = canvasContext!;
    const value = segments[key];
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, size / 2, lastAngle, angle, false);
    ctx.lineTo(centerX, centerY);
    ctx.closePath();
    ctx.fillStyle = segColors[key];
    ctx.fill();
    ctx.stroke();
    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate((lastAngle + angle) / 2);
    ctx.fillStyle = contrastColor || "white";
    ctx.font = `bold ${
      spinnerRound?.segmentFontSize ? spinnerRound?.segmentFontSize : "1em"
    } ${fontFamily}`;
    const metrics = ctx.measureText(value);
    ctx.fillText(
      Math.floor(metrics.width - 5) > size / 4
        ? value.slice(0, Math.floor(metrics.fontBoundingBoxDescent)) + "..."
        : value,
      size / 4,
      0,
    );
    ctx.restore();
  };

  // Function to draw the wheel
  const drawWheel = () => {
    const ctx = canvasContext;
    let lastAngle = angleCurrent;
    const len = segments.length;
    const PI2 = Math.PI * 2;

    if (!ctx) return;

    ctx.lineWidth = 1;
    ctx.strokeStyle = primaryColor || "black";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.font = `1em ${fontFamily}`;

    for (let i = 1; i <= len; i++) {
      const angle = PI2 * (i / len) + angleCurrent;
      drawSegment(i - 1, lastAngle, angle);
      lastAngle = angle;
    }

    // Draw a center circle
    ctx.beginPath();
    ctx.arc(
      centerX,
      centerY,
      spinnerRound?.arcSize ? spinnerRound?.arcSize : 40,
      0,
      PI2,
      false,
    );
    ctx.closePath();
    ctx.fillStyle = primaryColor || "black";
    ctx.lineWidth = 5;
    ctx.strokeStyle = len >= 100 ? "#f53434" : contrastColor || "white";
    ctx.fill();
    ctx.font = `bold ${
      spinnerRound?.fontSize ? spinnerRound?.fontSize : "2em"
    } ${fontFamily}`;
    ctx.fillStyle = contrastColor || "white";
    ctx.textAlign = "center";
    ctx.fillText(buttonText || "Spin", centerX, centerY + 3);
    ctx.stroke();

    // Draw outer circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, size / 2, 0, PI2, false);
    ctx.closePath();
    ctx.lineWidth = 20;
    ctx.strokeStyle = primaryColoraround || "white";
    ctx.stroke();
  };

  // Function to draw the needle
  const drawNeedle = () => {
    const ctx = canvasContext;
    if (!ctx) return;

    ctx.lineWidth = 1;
    ctx.strokeStyle =
      segments.length >= 100 ? "#f53434" : contrastColor || "white";
    ctx.fillStyle =
      segments.length >= 100 ? "#f53434" : contrastColor || "white";
    ctx.beginPath();
    ctx.moveTo(
      centerX + (spinnerRound?.moveToX ? spinnerRound?.moveToX : 10),
      centerY - (spinnerRound?.moveToY ? spinnerRound?.moveToY : 40),
    );
    ctx.lineTo(
      centerX - (spinnerRound?.lineToX ? spinnerRound?.lineToX : 10),
      centerY - (spinnerRound?.lineToY ? spinnerRound?.lineToY : 40),
    );
    ctx.lineTo(
      centerX,
      centerY - (spinnerRound?.lineToY2 ? spinnerRound?.lineToY2 : 60),
    );
    ctx.closePath();
    ctx.fill();

    const change = angleCurrent + Math.PI / 2;
    const i =
      segments.length -
      Math.floor((change % (Math.PI * 2)) / ((Math.PI * 2) / segments.length)) -
      1;
    currentSegment = segments[i];
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = `bold 1.2em ${fontFamily}`;
    ctx.fillStyle = contrastColor || "white";

    // Add horizontal offset (e.g., 20 pixels) to the x-coordinate
    const horizontalOffset = 20;

    // Fill the text with the horizontal offset applied to centerX
    ctx.fillText(
      currentSegment,
      centerX + horizontalOffset,
      centerY + size + 50,
    );
  };

  // Function to clear the canvas
  const clear = () => {
    const ctx = canvasContext;
    if (!ctx) return;

    ctx.clearRect(0, 0, size, size);
  };

  return (
    <div className="roulette" style={{ width: size, height: size }}>
      <canvas id={id} width={size} height={size}></canvas>
    </div>
  );
};
