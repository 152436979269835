import { useContext, useEffect, useMemo, useState } from "react";
import "./styles.scss";
import { ToolButton, ToolButtonEnum } from "../../../ToolButton";
import {
  minusOutlineIcon,
  plusOutlineIcon,
  restartOutlineIcon,
  startOutlineIcon,
  stopOutlineIcon,
} from "../../../icons";
import { SpinnerContext } from "../../contexts";
import { timerDuration } from "../../types";
import { useIsMobile } from "../../../App";

interface Props {
  onlyTime: boolean;
  isDisplayCheckBox: boolean;
  showSmallButtons: boolean;
  openResultDialog: boolean;
  setSeconds: (value: number) => void;
  isRunning: boolean;
  startTimer: () => void;
  restartTimer: () => void;
  pauseTimer: () => void;
  handleMinutes: (value: number) => void;
  setSecondsSpinner: (id: number, seconds: number) => void;
}

export const SpinnerTimer = ({
  onlyTime = false,
  isDisplayCheckBox = false,
  openResultDialog = false,
  setSeconds,
  isRunning,
  startTimer,
  restartTimer,
  pauseTimer,
  handleMinutes,
  setSecondsSpinner,
  showSmallButtons,
}: Props): JSX.Element => {
  const {
    segments,
    selectedWheelNumber,
    setSegments,
    setTimeDuration: setSpinnerTimeDuration,
    timeDuration,
    isTimer,
    setIsTimer,
  } = useContext(SpinnerContext);
  const [isIndex, setIsindex] = useState<number>(
    segments.findIndex((wheel) => {
      return Number(selectedWheelNumber) === Number(wheel?.wheel);
    }),
  );
  const isMobile = useIsMobile();

  useEffect(() => {
    setIsindex(
      segments.findIndex((wheel) => {
        return Number(selectedWheelNumber) === Number(wheel?.wheel);
      }),
    );
  }, [selectedWheelNumber]);
  const min = segments[Number(isIndex)]?.timerDuration?.minutes;
  // Function to pad the number with leading zeros if needed
  const padNumber = (number: number): string => {
    return number.toString().padStart(2, "0");
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const spinerBtns = [
    {
      icon: startOutlineIcon,
      onclick: startTimer,
      title: "Start",
      className: `start-btn ${isRunning ? "disabled" : ""}`,
      ariaLabel: "Start Button",
      type: ToolButtonEnum.ICON,
      label: null,
    },
    {
      icon: stopOutlineIcon,
      onclick: pauseTimer,
      title: "Pause",
      className: `pause-btn ${!isRunning ? "disabled" : ""}`,
      ariaLabel: "Pause Button",
      type: ToolButtonEnum.ICON,
      label: null,
    },
    {
      icon: plusOutlineIcon,
      onclick: () => handleMinutes(min + 1),
      title: "Plus",
      className: `plus-btn ${isRunning ? "disabled" : ""}`,
      ariaLabel: "Plus Button",
      type: ToolButtonEnum.ICON,
      label: null,
    },
    {
      icon: minusOutlineIcon,
      onclick: () => handleMinutes(min > 0 ? min - 1 : 0),
      title: "Minus",
      className: `minus-btn ${isRunning ? "disabled" : ""}`,
      ariaLabel: "Minus Button",
      type: ToolButtonEnum.ICON,
      label: null,
    },
    {
      icon: restartOutlineIcon,
      // onclick: () => {
      //   const time = new Date();
      //   time.setSeconds(time.getSeconds() + 60 * minutes);
      //   restart(time);
      // },
      onclick: restartTimer,
      title: "Restart",
      className: "restart-btn",
      ariaLabel: "Restart Button",
      type: ToolButtonEnum.ICON,
      label: null,
    },
  ];

  const renderButtons = useMemo(() => {
    return spinerBtns.map((btn, index) => (
      <ToolButton
        type={btn.type as ToolButtonEnum.BUTTON | ToolButtonEnum.ICON} // Fix: Change the type to ToolButtonEnum.ICON
        title={btn.title}
        {...(btn.type === ToolButtonEnum.ICON && { icon: btn.icon })}
        onClick={btn.onclick}
        className={`btn btn-primary ${btn.className} ${
          showSmallButtons ? "btn-floating-timer" : ""
        }`}
        aria-label={btn.ariaLabel}
        label={btn.label ?? ""}
        style={{
          width: showSmallButtons ? "1.5rem" : "2rem",
          height: showSmallButtons ? "1.5rem" : "2rem",
          margin: isMobile ? "5px" : "0 5px",
        }}
      />
    ));
  }, [spinerBtns]);

  const setTimeDuration = (value: number, _key: timerDuration) => {
    timeDuration[_key] = value;
    setSpinnerTimeDuration(timeDuration);
    segments[isIndex].timerDuration[_key] = value;
    setSegments([...segments]);
    setSeconds(
      segments[isIndex]?.timerDuration.minutes * 60 +
        segments[isIndex]?.timerDuration.seconds,
    );
    setSecondsSpinner(
      isIndex,
      segments[isIndex]?.timerDuration.minutes * 60 +
        segments[isIndex]?.timerDuration.seconds,
    );
  };

  return (
    <div className="">
      <div className="d-flex align-items-center gap-2">
        {isDisplayCheckBox && (
          <div className={`${!isMobile && "pb-1"}`}>
            <label className="checkbox-container d-flex align-items-center gap-2">
              <input
                type="checkbox"
                // checked={segments[isIndex]?.timer}
                checked={isTimer}
                onChange={(e) => {
                  setIsTimer(e.target.checked);
                  segments[isIndex].timer = e.target.checked;
                }}
              />
              <span>{"Timer"}</span>
            </label>
            {/* <input
              type="checkbox"
              id={"cbx-46" + isIndex}
              className="inp-cbx"
              onChange={(e) => (segments[isIndex].timer = e.target.checked)}
              checked={segments[isIndex].timer}
            />
            <label htmlFor={"cbx-46" + isIndex} className="cbx">
              <span>
                <svg viewBox="0 0 12 10" height="10px" width="12px">
                  <polyline points="1.5 6 4.5 9 10.5 1" />
                </svg>
              </span>
              <span>Timer</span>
            </label> */}
          </div>
        )}
        {!onlyTime && (
          <>
            {" "}
            <div>
              {dropdown(
                showSmallButtons ? "Mins" : "Minutes",
                "dropdownMenuButtonMinutes",
                setTimeDuration,
                timerDuration.MINUTES,
              )}
            </div>
            <div>
              {dropdown(
                showSmallButtons ? "Secs" : "Seconds",
                "dropdownMenuButtonSeconds",
                setTimeDuration,
                timerDuration.SECONDS,
              )}
            </div>{" "}
          </>
        )}
      </div>

      <div className="">{/* <h3 className="py-3">{"winner"}</h3> */}</div>
      <div className={`${isMobile && "d-flex"}`}>
        <div className="timer">
          <span>
            {/* {padNumber(
            segments[isIndex]?.timerDuration?.minutes
              ? segments[isIndex]?.timerDuration?.minutes
              : 0,
          )} */}
            {padNumber(timeDuration?.minutes ? timeDuration?.minutes : 0)}
          </span>
          :
          <span>
            {padNumber(timeDuration?.seconds ? timeDuration?.seconds : 0)}
            {/* {padNumber(
            segments[isIndex]?.timerDuration?.seconds
              ? segments[isIndex]?.timerDuration?.seconds
              : 0,
          )} */}
          </span>
        </div>
        {!onlyTime && (
          <div className={`${isMobile && "d-flex flex-wrap"} pt-2`}>
            {renderButtons}
          </div>
        )}
      </div>
    </div>
  );
};

const dropdown = (
  title: string,
  id: string,
  setData: (value: number, key: timerDuration) => void,
  key: timerDuration,
) => {
  return (
    <div className="dropdown">
      <button
        className="dropdown-toggle"
        type="button"
        id={id}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {title}
      </button>
      <ul className="dropdown-menu dropdown-menu-minutes" aria-labelledby={id}>
        {Array.from({ length: 60 }, (_, index) => index).map((data) => {
          return (
            <li
              onClick={() => {
                setData(data, key);
              }}
            >
              <span className="dropdown-item">
                {data < 10 ? "0" + data : data}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
