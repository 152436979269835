import { Extension } from "@tiptap/core";

export const KeyboardExtension = Extension.create({
  name: "keyboard",
  priority: 1000,
  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.setHardBreak(),
    };
  },
});
