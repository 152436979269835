import { useEffect, useRef, useState } from "react";
import oc from "open-color";
import { exportToSvg } from "../../../../scene/export";
import { BinaryFiles, LibraryItem } from "../../../../types";
import "./style.scss";

export const ConvertElementsToSvg = ({
  elements,
  files,
  zoomLevel = 1,
}: {
  elements: LibraryItem["elements"];
  files: BinaryFiles;
  zoomLevel?: number;
}) => {
  const [svgContent, setSvgContent] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const svg = await exportToSvg(
        elements,
        {
          exportBackground: false,
          viewBackgroundColor: oc.white,
        },
        files,
      );
      setSvgContent(svg.outerHTML);
      const parser = new DOMParser();
      const doc = parser.parseFromString(svg.outerHTML, "image/svg+xml");
      const svgElement = doc.querySelector("svg");
      if (svgElement && zoomLevel !== 1) {
        svgElement.style.transform = `scale(${zoomLevel})`;
        svgElement.style.transformOrigin = "top";
        setSvgContent(svgElement.outerHTML);
      }
    })();

    return () => {
      setSvgContent(null);
    };
  }, [elements, files, zoomLevel]);

  if (!svgContent) {
    return null; // or a loading spinner
  }

  const file = elements.map((element) => {
    //@ts-ignore
    const keyToMatch = element.fileId;
    const matchedObject = files[keyToMatch];
    return matchedObject;
  });

  return elements[0].type === "video" && file ? (
    <div className="container-elements">
      <video
        controls
        src={`${file[0]?.dataURL}`}
        className="container-video"
      />
    </div>
  ) : elements[0].type === "audio" && file ? (
    <div className="container-elements">
      <audio
        controls
        controlsList="noplaybackrate nodownload"
        src={`${file[0]?.dataURL}`}
      ></audio>
    </div>
  ) : (
    <div
      className="container-elements"
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
};
