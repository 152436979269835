import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Loader from "../../../../../../App/shareComponent/Loader";
import {
  fetchWBLocalImages,
  uploadWBLocalImages,
} from "../../../../../../excalidraw-app/api/collection";
import { deleteWbLocalMedia } from "../../../../../../excalidraw-app/api/userAPI";
import { AppState } from "../../../../../../types";
import { Toast, ToastType } from "../../../../../Toast";
import { CardWithImage } from "../../../../components/card";
import { MediaHandlersRef } from "../../CollectionsTab";
import { t } from "../../../../../../i18n";

export interface MediaCollectionProps {
  insertOnCanvas: (file: File) => void;
  setAppState: React.Component<any, AppState>["setState"];
  setDeleteElement?: React.Dispatch<React.SetStateAction<string[]>>;
  deleteElement?: string[];
  searchVal?: string;
}

interface ImagesType {
  url: string;
  name: string;
  isSelected: boolean;
  createdAt: string;
  isLoading: boolean;
}

export const ImageCollection = forwardRef<
  MediaHandlersRef,
  MediaCollectionProps
>(
  (
    {
      insertOnCanvas,
      setAppState,
      setDeleteElement,
      deleteElement,
      searchVal,
    }: MediaCollectionProps,
    ref,
  ) => {
    const [images, setImages] = useState<ImagesType[]>([]);
    const [filteredImages, setFilteredImages] = useState<ImagesType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [record, setRecord] = useState<any>({});
    const [toastMessage, setToastMessage] = useState<string | null>(null);
    const [currentBlobName, setCurrentBlobName] = useState<string>("");

    useImperativeHandle(ref, () => ({
      onDelete: handleDelete,
      onUpload: onImageChange,
    }));

    const fetchImageCollection = async () => {
      try {
        setIsLoading(true);

        const user = JSON.parse(localStorage.getItem("user") || "");

        const { results } = await fetchWBLocalImages(user.mail);

        if (results?.length > 0 && results[0]?.localImages) {
          const allImages = results
            .map((item: any) =>
              item.localImages.map((image: any) => {
                return { ...image, isSelected: false, isLoading: true };
              }),
            )
            .flat();

          setImages(allImages);
          setRecord(results[0]);
          setIsLoading(false);
        } else {
          setImages([]);
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        setRecord({});
        console.log(err);
      }
    };
    useEffect(() => {
      fetchImageCollection();
    }, []);

    useEffect(() => {
      if (searchVal) {
        const searchResult = images.filter((image) =>
          image.name.toLowerCase().includes(searchVal.toLowerCase()),
        );
        return setFilteredImages(searchResult);
      }
      setFilteredImages(images);
    }, [searchVal, images]);

    const getUrlExtension = (url: any) => {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    };

    const insertImage = async (url: string) => {
      setIsLoading(true);
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch image (status ${response.status})`);
        }
        const data = await response.blob();

        const imgExt = getUrlExtension(url);
        const file = new File([data], `image.${imgExt}`, {
          type: data.type || "image/png",
        });

        if (file.size > 1024 * 1024 * 2) {
          setToastMessage(
            "The image you selected is larger than 2 MB. Please select an image that is under 2 MB.",
          );
        } else {
          insertOnCanvas(file);
          setIsLoading(false);
          setAppState({ isLibraryOpen: false });
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.error("Error fetching image:", err);
      }
    };

    const handleDelete = async () => {
      try {
        const body = {
          id: record.id,
          classId: record.classId,
          email: record.email,
          type: "image",
          blobNames: deleteElement
            ? deleteElement
            : currentBlobName
            ? [currentBlobName]
            : [],
          typeOfDelete: "single",
        };
        await deleteWbLocalMedia(body);
        await fetchImageCollection();
      } catch (error) {
        console.log("error in delete audio", error);
      }
    };

    const onImageChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const file = event.target.files?.[0];
      const lesson = JSON.parse(localStorage.getItem("lesson") || "");

      const user = JSON.parse(localStorage.getItem("user") || "");

      if (file && lesson && user.mail) {
        const formData = new FormData();

        formData.append("image", file);
        formData.append("classId", lesson?.ClassID);
        formData.append("mail", user.mail);

        setIsLoading(true);
        await uploadWBLocalImages(formData);

        let arr: ImagesType[] = [];
        const deleteVideoFromVideos = filteredImages.filter((video) => {
          if (video.name !== currentBlobName) {
            arr.push({ ...video });
          }
        });
        setFilteredImages(arr);
        await fetchImageCollection();
        setIsLoading(false);
      }
    };

    return (
      <div className="row overflow-auto" style={{ height: "calc(100% - 28%)" }}>
        {isLoading ? (
          <Loader className="h-100" />
        ) : (
          filteredImages.map((item: any, index: number) => {
            const nameWithoutExtension = item.name
              .split(".")
              .slice(0, -1)
              .join(".");
            return (
              <CardWithImage
                key={index}
                created={item.createdAt}
                images={item.url}
                buttonText={t("cards.importOnCanvas")}
                isHoverPreviewBtns={false}
                isHoverUseThisTemplateBtn={false}
                onAddtoCollectionBtnClick={() => insertImage(item.url)}
                isDelete={true}
                onDelete={() => {
                  if (deleteElement && deleteElement.includes(item.name)) {
                    setDeleteElement &&
                      setDeleteElement((prev) =>
                        prev.filter((el) => el !== item.name),
                      );
                    setCurrentBlobName("");
                  } else {
                    setDeleteElement &&
                      setDeleteElement((prev) => [...prev, item.name]);
                    setCurrentBlobName(item.name);
                  }
                }}
                name={nameWithoutExtension}
                likeBookmarkIcon={false}
              />
            );
          })
        )}

        {toastMessage !== null && (
          <Toast
            type={ToastType.ERROR}
            message={
              "The image you selected is larger than 2 MB. Please select an image that is under 2 MB."
            }
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
      </div>
    );
  },
);
