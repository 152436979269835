import React, { useRef, useEffect } from "react";
import { exportToCanvas } from "../scene/export";
import { canvasToBlob } from "../data/blob";
import { render, unmountComponentAtNode } from "react-dom";
import { ErrorCanvasPreview } from "./ImageExportDialog";
import { CanvasError } from "../errors";
import "./Carousel.scss";
// {
//     item: React.ReactNode;
//     allPages: any;
//     selectedPages: string[];
//   }
const Carousel = (props: any) => {
  const selectedPagesRef = useRef<HTMLDivElement[]>([]);

  const {
    exportedElements,
    appState,
    files,
    exportBackground,
    viewBackgroundColor,
    exportPadding,
    selectedPages,
    allPages,
    actionManager,
  } = props;

  const renderPreview = (
    content: HTMLCanvasElement | Error,
    previewNode: HTMLDivElement,
  ) => {
    unmountComponentAtNode(previewNode);
    previewNode.innerHTML = "";
    if (content instanceof HTMLCanvasElement) {
      previewNode.appendChild(content);
    } else {
      render(<ErrorCanvasPreview />, previewNode);
    }
  };

  useEffect(() => {
    if (selectedPagesRef.current.length > 0) {
      for (let index = 0; index < selectedPages.length; index++) {
        const selectedPage = selectedPages[index];
        const pageData = allPages.find(
          (page: { page: string }) => page.page === selectedPage,
        );
        exportToCanvas(pageData.data, appState, files, {
          exportBackground,
          viewBackgroundColor,
          exportPadding,
        })
          .then((canvas) => {
            // if converting to blob fails, there's some problem that will
            // likely prevent preview and export (e.g. canvas too big)

            return canvasToBlob(canvas).then(() => {
              renderPreview(canvas, selectedPagesRef.current[index]);
            });
          })
          .catch((error) => {
            console.error(error);
            renderPreview(new CanvasError(), selectedPagesRef.current[index]);
          });
      }
    }
  }, [
    appState,
    files,
    exportedElements,
    exportBackground,
    exportPadding,
    viewBackgroundColor,
    selectedPages,
  ]);

  return (
    <div
      id="carouselExampleIndicators"
      className="carousel"
      data-bs-pause="true"
    >
      <div className="carousel-inner">
        {props.selectedPages.length > 0 &&
          props.selectedPages.map((pageNo: string, i: number) => {
            return (
              <div
                className={`carousel-item ${i === 0 ? "active" : ""} `}
                data-bs-interval="false"
                key={`page-preview-${pageNo}`}
              >
                {actionManager.renderAction("exportWithDarkMode")}
                <div
                  className="ExportDialog__preview"
                  ref={(el) => {
                    if (el) {
                      selectedPagesRef.current[i] = el;
                    }
                  }}
                />
                <div className="carousel-caption d-none d-md-block mt-5">
                  <h5 className="text-dark fw-bold">{"Page " + pageNo}</h5>
                </div>
              </div>
            );
          })}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
