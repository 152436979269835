import React, { useEffect, useRef, useState } from "react";
import Editable from "../components/Editable";
import { apiGet } from "../excalidraw-app/api";
import { getLessonId, getUserInfo } from "../excalidraw-app/api/getuserInfo";
import {
  getElemetDataFromDatabase,
  updateActivePage,
  updatePageName,
} from "../excalidraw-app/api/storeElementData";
import {
  onSaveElementInDB,
  pdfImportDocumentCounter,
} from "../excalidraw-app/api/userAPI";
import CollabWrapper from "../excalidraw-app/collab/CollabWrapper";
import {
  createNewCanvas,
  duplicateCanvas,

  // getCollaborationLink(),
  getPages,
} from "../excalidraw-app/data";
import { t } from "../i18n";
import { useIsMobile } from "./App";
import { Dialog } from "./Dialog";
import "./Sidebar.scss";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import { chevronsLeft, copy, del, moreTools } from "./icons";
import { urlToDataUrl } from "../utils/convertToDataURL";
import { uploadImageToAzureBlob } from "../utils/uploadImageToAzureBlob";

export const component = new CollabWrapper();

const SideBar = ({
  appState,
  setAppState,
  onPageChange,
  onPageDelete,
  isStudent,
}) => {
  const lessonId =
    new URLSearchParams(window.location.search)
      .get("lessonId")
      ?.replace(/\//g, "") || "";

  const [pageDetails, setPageDetails] = useState([]);
  const [deletePageDetails, setDeletePageDetails] = useState(null);
  const inputRef = useRef();
  const [task, setTask] = useState({
    page: 1,
    text: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [
    isCollaboratingWithFlexibleGroups,
    setIsCollaboratingWithFlexibleGroups,
  ] = useState(false);
  const [user, setUser] = useState(null);
  const [group, setGroup] = useState(null);
  const [mount, setMount] = useState(false);
  const [teacherActivePage, setTeacherActivePage] = useState(0);

  useEffect(() => {
    setTeacherActivePage(localStorage.getItem("ACTIVE_PAGE"));
  }, [localStorage.getItem("ACTIVE_PAGE")]);
  useEffect(() => {
    setTeacherActivePage(localStorage.getItem("ACTIVE_PAGE"));
  }, [localStorage.getItem("ACTIVE_PAGE_URL")]);

  const collaborating = Boolean(localStorage.getItem("isCollaborating"));

  const groupCondition =
    isStudent && isCollaboratingWithFlexibleGroups ? Boolean(group) : true;

  const [pageURL, setPageURL] = useState(null);
  const newPageButtonRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);

  useState(() => {
    setCurrentPage(appState.currentPage);
  }, [appState.currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getUser();
      await getpageData();
    };
    fetchData();

    setMount(true);
  }, []);

  useEffect(() => {
    const condition = isCollaboratingWithFlexibleGroups ? !isStudent : true;

    const fetchData = async () => {
      await onPageChange(appState.currentPage);
    };

    if (condition) fetchData();
  }, [isCollaboratingWithFlexibleGroups]);

  useEffect(() => {
    onPageChange(appState.currentPage);
  }, [currentPage]);

  useEffect(() => {
    const lastPart = appState.updatedPageDataURL?.dataURL?.split("-").pop();
    if (lastPart === appState.currentPage.toString()) {
      setPageURL(appState.updatedPageDataURL);
    }
  }, [appState.updatedPageDataURL, appState.currentPage]);

  useEffect(() => {
    setIsCollaboratingWithFlexibleGroups(
      JSON.parse(
        localStorage.getItem("isCollaboratingWithFlexibleGroups") || "false",
      ),
    );
  }, [localStorage.getItem("isCollaboratingWithFlexibleGroups")]);

  useEffect(() => {
    const storeData = async () => {
      if (JSON.parse(localStorage.getItem("acv"))?.length) {
        window.parent.postMessage(
          { type: "STORE_ELEMENTS", isLoading: true },
          `${process.env.REACT_APP_PARENT_APP}`,
        );
        await onSaveElementInDB(
          appState.currentPage,
          lessonId || null,
          appState.DBElements,
          appState.viewBackgroundColor,
        );
        window.parent.postMessage(
          { type: "STORE_ELEMENTS", isLoading: false },
          `${process.env.REACT_APP_PARENT_APP}`,
        );
      }
    };
    if (mount) storeData();
  }, [localStorage.getItem("acv")]);

  useEffect(() => {
    isStudent && user && isCollaboratingWithFlexibleGroups && getGroup();
  }, [
    isStudent,
    isCollaboratingWithFlexibleGroups,
    user,
    localStorage.getItem("PAGE_UPDATE"),
  ]);

  const getGroup = async () => {
    const lessonId = await getLessonId();

    if (!!lessonId.trim() && !!user?.mail.trim()) {
      //&&
      const res = await apiGet(
        `${process.env.REACT_APP_EP_URL_WHITEBOARD}/flexible-group/get-group-by-student?lessonId=${lessonId}&mail=${user.mail}`,
      );
      if (res) {
        setGroup(res?.result);
        if (res?.result?.page?.isPage) {
          localStorage.setItem("isShowNoPagesModel", "false");
          setAppState({
            currentPage: res.result.page.no,
          });
          await onPageChange(res.result.page.no);
          await getpageData();
          localStorage.removeItem("PAGE_UPDATE");
        } else {
          localStorage.setItem("isShowNoPagesModel", "true");
          setAppState({ isShowWhiteboard: false });
        }
      }
    }
  };

  const getUser = async () => {
    try {
      const user = await getUserInfo();
      setUser(user);
    } catch (error) {
      console.log("error-from-getUser", error);
    }
  };

  const checkIsPageInGroup = (pageId) => {
    const isPageInGroup = group.page.id === pageId;
    return isPageInGroup;
  };

  const getpageData = async (page) => {
    const currentPage = page ? page : false;

    if (currentPage) {
      setAppState({
        currentPage: page,
      });
    }

    await getPages(lessonId, currentPage).then(async (res) => {
      if (res.result.length == 1 || res.result.length == 0) {
        setAppState({
          currentPage: 1,
        });
      }

      const pageData = [...res.result].sort((a, b) => a.id - b.id);

      // const pageData = res.result.sort((a, b) =>
      //   a.page > b.page ? 1 : b.page > a.page ? -1 : 0,
      // );

      if (pageData.length > 0) {
        const currentPage = pageData[0].page;
        setAppState({
          currentPage: currentPage,
          updatedPageDataURL: {
            lessonId: lessonId,
            page: currentPage,
            dataURL: pageData[0].dataURL,
          },
        });
      }

      if (pageData?.length === 0) {
        newPageButtonRef?.current?.click();
      }
      setPageDetails([...pageData]);
    });
  };

  const changePage = async (page) => {
    if (appState.currentPage === page) return;

    await onPageChange(page, setPageDetails);
    setAppState({
      currentPage: page,
      sidebarWrapper: false,
    });

    const student = JSON.parse(window.localStorage.getItem("S") || "{}");
    if (!student) {
      await updateActivePage({
        link: window.location.href,
        activePage: page,
      });
    }
  };

  const sideWrapperToggler = () => {
    setAppState({
      sidebarWrapper: false,
    });
  };

  const handleClose = React.useCallback(() => {
    if (isOpen) setIsOpen(false);
    else setIsOpen(true);
  }, [isOpen]);

  const onSavePageNameHandler = async (pageName, data) => {
    try {
      const apiBody = {
        pageName: pageName,
        data: data,
      };
      await updatePageName(apiBody);
      await getpageData();
    } catch (err) {
      console.log(err);
    }
  };

  const savePageNameOnEnter = async (e, data) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      const apiBody = {
        pageName: task.text,
        data: data,
      };
      await updatePageName(apiBody);
      await getpageData();
    }
  };

  const onPageDeleteHandler = async () => {
    setMount(false);

    if (deletePageDetails) {
      console.log("deletePageDetails", deletePageDetails);
      const pageData = await getElemetDataFromDatabase(
        lessonId,
        Number(deletePageDetails.pageNo),
      );

      await onPageDelete(deletePageDetails.id);
      //delete imported pdf counter when delete the page
      if (
        pageData.result &&
        pageData.result[0].data &&
        pageData.result[0].data.length
      ) {
        const pageDetails = pageData.result[0].data;
        for (let i = 0; i < pageDetails.length; i++) {
          if (pageDetails[i].pdfId) {
            const slug =
              new URLSearchParams(window.location.search).get("slug") || "";
            const body = {
              userId: user?.mail,
              slug: slug,
              lessonId: pageDetails[i].lessonId,
              pdfId: pageDetails[i].pdfId,
            };
            pdfImportDocumentCounter(body);
          }
        }
      }
      const filteredPages = pageDetails.filter(
        (page) => page.id !== deletePageDetails.id,
      );

      setPageDetails([...filteredPages]);
      handleClose();
      // await getpageData();
      if (filteredPages.length === 0) {
        newPageButtonRef.current.click();
        setPageURL({
          page: 1,
          dataURL: null,
          lessonId: lessonId,
        });
        setAppState({
          currentPage: 1,
        });
      }
    }
    setMount(true);
  };

  return (
    <>
      {isOpen && (
        <Dialog
          title={t("alerts.confirmDelete")}
          small={true}
          onCloseRequest={handleClose}
          className={"delete-page-dialog"}
          autofocus={true}
        >
          <p>{t("alerts.deleteMsg")}</p>
          <div className="RoomDialog-sessionStartButtonContainer">
            <ToolButton
              className="cancel-page-dialogBtn me-2 px-2 py-1"
              type={ToolButtonEnum.BUTTON}
              // icon={trash}
              title={t("buttons.cancel")}
              aria-label={t("buttons.cancel")}
              showAriaLabel={true}
              onClick={handleClose}
            />
            <ToolButton
              className="Remove-page-dialogBtn px-2 py-1"
              type={ToolButtonEnum.BUTTON}
              // icon={del}
              title={t("labels.delete")}
              aria-label={t("labels.delete")}
              showAriaLabel={true}
              onClick={() => onPageDeleteHandler()}
            />
          </div>
        </Dialog>
      )}
      <div
        className={`${
          // appState.sidebarWrapper ? "sidebarWrapper " : "sidebarWrapper"
          appState.sidebarWrapper ? "sidebarWrapper" : "sidebarWrapper wnone"
        }${
          useIsMobile()
            ? " align-self-start  d-flex flex-column justify-content-between"
            : ""
        }`}
      >
        <div className="page-block">
          <ToolButton
            className="btn btn-light shadow-sm btn-compact"
            key="newPage"
            type={ToolButtonEnum.BUTTON}
            title={t("labels.compact")}
            aria-label={t("labels.compact")}
            icon={chevronsLeft}
            onClick={sideWrapperToggler}
            {...(useIsMobile() && { style: { maxHeight: "70px", flex: "1" } })}
          ></ToolButton>
        </div>
        <div
          {...(useIsMobile() && {
            style: { overflowY: "auto", overflowX: "hidden", flex: 2 },
          })}
        >
          {groupCondition &&
            pageDetails?.map((page, index) => {
              // index === 1 &&
              //   console.log(
              //     "object",
              //     isStudent && isCollaboratingWithFlexibleGroups,
              //   );
              const condition =
                isStudent && isCollaboratingWithFlexibleGroups
                  ? checkIsPageInGroup(page.id)
                  : true;
              const url = pageURL
                ? pageURL.lessonId === lessonId && pageURL.page == page.page
                  ? pageURL.dataURL
                  : page.dataURL
                : page && page?.dataURL
                ? page.dataURL
                : "";

              // const url = false;

              return (
                condition && (
                  <div className="page flex-2" key={`page-${index}`}>
                    <>
                      {isStudent ? (
                        <div
                          className={`rounded py-2 px-3 text-gray-700 leading-tight whitespace-pre-wrap hover:shadow-outline`}
                        >
                          <span
                            className={`${
                              page.pageName ? "text-black" : "text-gray-500"
                            }`}
                          >
                            {page.pageName ||
                              t("multipage.renamePlaceHolder") ||
                              "Editable content"}
                          </span>
                        </div>
                      ) : (
                        <Editable
                          text={page.pageName}
                          placeholder={t("multipage.renamePlaceHolder")}
                          childRef={inputRef}
                          type="input"
                        >
                          <input
                            ref={inputRef}
                            type="text"
                            name={task.page}
                            style={{ height: "30px" }}
                            className="shadow appearance-none border rounded w-100 mb-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-300"
                            placeholder={t("multipage.renamePlaceHolder")}
                            // value={task.text}
                            defaultValue={page.pageName}
                            onChange={(e) => {
                              if (!isStudent) {
                                setTask({
                                  page: page.page,
                                  text: e.target.value,
                                });
                                setAppState({
                                  pageName: e.target.value,
                                });
                              }
                            }}
                            onBlur={(e) => {
                              if (!isStudent) {
                                setTask({
                                  page: page.page,
                                  text: e.target.value,
                                });
                                onSavePageNameHandler(e.target.value, page);
                              }
                            }}
                            // onBlur={() => onSavePageNameHandler(page)}
                            onKeyDown={(e) =>
                              !isStudent && savePageNameOnEnter(e, page)
                            }
                          />
                        </Editable>
                      )}
                      <div
                        className="pagePreview"
                        style={{
                          outline: collaborating
                            ? Number(teacherActivePage) === Number(page.page) &&
                              "2px solid #607d3b"
                            : "",
                        }}
                        onClick={async () => {
                          window.parent.postMessage(
                            { type: "STORE_ELEMENTS", isLoading: true },
                            `${process.env.REACT_APP_PARENT_APP}`,
                          );
                          setAppState({
                            ...appState,
                            isLoading: true,
                          });
                          const lessonId =
                            new URLSearchParams(window.location.search)
                              .get("lessonId")
                              ?.replace(/\//g, "") || "";

                          await onSaveElementInDB(
                            appState.currentPage,
                            lessonId || null,
                            appState.DBElements,
                            appState.viewBackgroundColor,
                          );
                          window.parent.postMessage(
                            { type: "STORE_ELEMENTS", isLoading: false },
                            `${process.env.REACT_APP_PARENT_APP}`,
                          );
                          setAppState({
                            ...appState,
                            isLoading: false,
                          });
                          await changePage(page.page);
                        }}
                      >
                        {url ? (
                          <img
                            key={index}
                            src={url + "?t=" + Date.now()}
                            alt={index + 1}
                            width="186px"
                            height="80px"
                          />
                        ) : null}
                      </div>
                      <div className="nameIcon">
                        <div
                          className="name"
                          onClick={async () => await changePage(page.page)}
                        >
                          <p className="text">
                            {t("multipage.pageNumber")} {page.page}
                          </p>
                        </div>
                        {!isStudent && (
                          <div className="icons">
                            <ToolButton
                              key="copy"
                              type={ToolButtonEnum.BUTTON}
                              title={t("labels.copy")}
                              aria-label={t("labels.copy")}
                              icon={copy}
                              onClick={async () => {
                                const pageNo =
                                  Math.max.apply(
                                    Math,
                                    pageDetails.length
                                      ? pageDetails.map(function (o) {
                                          return o.page ? o.page : 0;
                                        })
                                      : [0],
                                  ) + 1;
                                if (collaborating) {
                                  await component.initializeSocketClient(null);
                                }
                                const pageData = await getElemetDataFromDatabase(
                                  lessonId,
                                  Number(page.page),
                                );
                                let url = null;
                                if (pageData.result[0].dataURL) {
                                  const { dataUrl } = await urlToDataUrl(
                                    pageData.result[0].dataURL,
                                  );
                                  const blobName = `${lessonId}-${pageNo}`;
                                  url = await uploadImageToAzureBlob(
                                    dataUrl,
                                    blobName,
                                  );
                                }

                                const res = await duplicateCanvas(
                                  lessonId,
                                  pageNo,
                                  [
                                    ...pageData.result[0].data,
                                    { dataURL: url },
                                  ],
                                  appState.viewBackgroundColor,
                                );

                                setPageDetails([
                                  ...pageDetails,
                                  {
                                    id: res?.result?.id,
                                    page: pageNo,
                                    dataURL: url,
                                  },
                                ]);
                              }}
                              className="icon-small"
                            />
                            <ToolButton
                              key="delete"
                              type={ToolButtonEnum.BUTTON}
                              title={t("labels.delete")}
                              aria-label={t("labels.delete")}
                              icon={del}
                              onClick={async () => {
                                handleClose();
                                console.log("page", page);
                                setDeletePageDetails({
                                  id: page.id,
                                  pageNo: page.page,
                                });
                              }}
                              data-toggle="tooltip"
                              className="icon-small"
                            />
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                )
              );
            })}
        </div>
        <div
          className={
            useIsMobile() ? "text-center align-items-center mt-2" : "page-block"
          }
          {...(useIsMobile() && {
            style: { flex: "1", maxHeight: "120px" }, // combine the height of app-toolbar-content(mobile view) + version-info + newPageBtn
          })}
        >
          {!isStudent && (
            <ToolButton
              className="bg-brand text-white pr-2 btnNewPage"
              key="newPage"
              type={ToolButtonEnum.BUTTON}
              title={t("multipage.newPageBtn")}
              aria-label={t("multipage.newPageBtn")}
              icon={moreTools}
              ref={newPageButtonRef}
              onClick={async () => {
                const pageNo = pageDetails
                  ? Math.max.apply(
                      Math,
                      pageDetails.length
                        ? pageDetails.map(function (o) {
                            return o.page ? o.page : 0;
                          })
                        : [0],
                    ) + 1
                  : 1;
                if (collaborating) {
                  await component.initializeSocketClient(null);
                }

                const res = await createNewCanvas(
                  lessonId,
                  pageNo,
                  appState.viewBackgroundColor,
                );

                if (!pageDetails) {
                  setPageDetails([
                    {
                      id: res?.result?.id,
                      page: pageNo,
                      dataURL: "",
                    },
                  ]);
                } else {
                  setPageDetails([
                    ...pageDetails,
                    {
                      id: res?.result?.id,
                      page: pageNo,
                      dataURL: "",
                    },
                  ]);
                }
              }}
            >
              <span>{t("multipage.newPageBtn")}</span>
            </ToolButton>
          )}
        </div>
      </div>
    </>
  );
};

export default SideBar;
