/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import "./MyCollection.scss";
import {
  bookmarkIcon,
  collectionIcon,
  fillBookmarkIcon,
  fillLikeIcon,
  whiteLikeIcon,
} from "./icons";
import { CollocationType, Filter } from "../types";
import { t } from "../i18n";
import moment from "moment";
import { Avatar } from "./Avatar";
import Loader from "../App/shareComponent/Loader";
import MultiSelectDropdown from "./MultiSelectDropdown";
import {
  fetchAllCollection,
  fetchMyPublishedCollectionAPI,
} from "../excalidraw-app/api/collection";
import { filteredValueType } from "./LibraryDialog";
import LibraryFilterMenu from "./LibraryFilterMenu";
import { getUserInfo } from "../excalidraw-app/api/getuserInfo";
import { ReactSVG } from "react-svg";
import { LibraryCarousel } from "./LibraryCarousel";
import { CollectionImagePreview } from "./collectionImagePreview";

export type MyCollectionProps = {
  myCollections: CollocationType[];
  unpublishApprovalCollectionHandler: (id: string) => void;
  likeUnlikeCollection: (id: string) => void;
  bookmarkCollection: (id: string) => void;
  isLoading: boolean;
  setFilterData: (val: string[]) => void;
  setFilterDataWithKeys: (data: filteredValueType) => void;
  setIsLoading: (val: boolean) => void;
  filterDataWithKeys: filteredValueType;
  filterOption: Filter;
  filterData: string[];
  fetchFilteredData: (data: filteredValueType) => void;

  isSideBarOpen: boolean;
  setIsSideBarOpen: (val: boolean) => void;
};

const MyCollection = ({
  myCollections,
  unpublishApprovalCollectionHandler,
  likeUnlikeCollection,
  bookmarkCollection,
  isLoading,
  setFilterData,
  setIsLoading,
  setFilterDataWithKeys,
  filterDataWithKeys,
  filterOption,
  filterData,
  fetchFilteredData,
  isSideBarOpen,
  setIsSideBarOpen,
}: MyCollectionProps) => {
  const [check, setCheck] = useState<Number>(1);
  const [search, setSearch] = useState<string>("");
  const [filteredCollections, setFilteredCollections] = useState<
    CollocationType[]
  >([]);
  const [open, setOpen] = useState<boolean>(false);
  const [isFilterApply, setFilterApply] = useState<boolean>(false);
  const [removeVal, setRemoveVal] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState(0);

  useEffect(() => {
    handleSearch();
  }, [search]);

  const handleCheck = (tabValue: Number) => {
    setCheck(tabValue);
  };

  const selectArray = [
    {
      title: t("collection.community.template.filter.age"),
      label: [
        t("collection.community.template.filter.options.7day"),
        t("collection.community.template.filter.options.1month"),
        t("collection.community.template.filter.options.3month"),
        t("collection.community.template.filter.options.6month"),
      ],

      id: "age",
      search: false,
    },
    {
      title: t("collection.community.template.filter.author"),
      label: filterOption.authors,
      id: "author",
      search: true,
    },
    {
      title: t("collection.community.template.filter.tags"),
      label: filterOption.tags,
      id: "tags",
      search: true,
    },
  ];

  const removeFilters = async () => {
    try {
      setFilterData([]);
      setIsLoading(true);
      const { result } = await fetchAllCollection({});
      setFilteredCollections(result.resources);
      setFilterDataWithKeys({
        ...filterDataWithKeys,
        age: "",
        author: [],
        tags: [],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleSearch = () => {
    if (!!search.trim()) {
      const searchedCollections = myCollections.filter((collection) =>
        collection.name.toLowerCase().includes(search.toLowerCase()),
      );
      setFilteredCollections(searchedCollections);
    } else {
      setFilteredCollections([]);
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue: any = event.target.value;
    setSelectedValue(newValue);
    onSortCollection(newValue);
  };

  const onSortCollection = async (index: number) => {
    setIsLoading(true);
    try {
      setSearch("");
      // used index because it's tricky to handle when languages are change
      //index 1: newest, 2: most like, 3: title(a-z), 4: author(a-z)
      const { result } = await fetchAllCollection({ sort: index });
      setFilteredCollections(result.resources);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const searchAPI = async () => {
    try {
      setIsLoading(true);
      if (search) {
        const lessonId = new URLSearchParams(window.location.search)
          .get("lessonId")
          ?.replace(/\//g, "") as string;
        const user = await getUserInfo();
        const apiBody: {
          userId: string;
          search: string;
        } = {
          userId: user.mail,
          search,
        };
        const { result } = await fetchMyPublishedCollectionAPI(apiBody);
        setFilteredCollections(result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const myCollections_ = !!search.trim() ? filteredCollections : myCollections;

  return (
    <div className="my_collection h-100">
      <h4 className="fw-bold">
        {t("collection.personal.myCollections.published")}
      </h4>
      <LibraryFilterMenu
        collections={myCollections}
        handleSelectChange={handleSelectChange}
        onSortCollection={onSortCollection}
        searchAPI={searchAPI}
        searchVal={search}
        selectedValue={selectedValue}
        setFilteredCollections={setFilteredCollections}
        setOpen={setOpen}
        setSearchVal={setSearch}
        isSideBarOpen={isSideBarOpen}
        setIsSideBarOpen={setIsSideBarOpen}
      />

      {!isLoading && myCollections_.length === 0 && (
        <section id="content1" className="row">
          <div className="empty_img">
            <img
              src="https://acvstorageprod.blob.core.windows.net/react-images/Empty-pana.svg"
              alt=""
              height={292}
              width={292}
            />
          </div>
          <p className="community_text">
            {t("collection.personal.myCollections.emptyMessage")}
          </p>
        </section>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-fluid myCollection-card">
          <div className="row">
            {myCollections_.length > 0 &&
              myCollections_.map((myCollection, index) => {
                return (
                  <div className="col-sm-6 col-md-4 col-xs-12">
                    <div className="card item-card card-block mt-4">
                      <h4 className="card-title d-flex justify-content-between">
                        {myCollection.name}
                        <div className="likeSection">
                          <ToolButton
                            key="newPage"
                            type={ToolButtonEnum.BUTTON}
                            title={t("collection.personal.bookmark.title")}
                            aria-label={t("collection.personal.bookmark.title")}
                            icon={
                              myCollection?.isBookmark
                                ? fillBookmarkIcon
                                : bookmarkIcon
                            }
                            className="likeIcon"
                            onClick={() => {
                              bookmarkCollection(myCollection.id);
                            }}
                          ></ToolButton>
                          <ToolButton
                            key="newPage"
                            type={ToolButtonEnum.BUTTON}
                            title={t("collection.personal.liked.title")}
                            aria-label={t("collection.personal.liked.title")}
                            icon={
                              myCollection.isLiked
                                ? fillLikeIcon
                                : whiteLikeIcon
                            }
                            className="likeIcon"
                            onClick={() =>
                              likeUnlikeCollection(myCollection.id)
                            }
                          >
                            <span className="likeFont">
                              {myCollection.likeCount}
                            </span>
                          </ToolButton>
                        </div>
                      </h4>
                      {Array.isArray(myCollection.preview) ? (
                        myCollection.preview.length > 0 &&
                        myCollection.preview.length > 1 ? (
                          <LibraryCarousel
                            images={
                              (myCollection.preview as unknown) as string[]
                            }
                            index={index}
                            name={myCollection.name}
                          />
                        ) : (
                          <CollectionImagePreview
                            previewURL={myCollection.preview[0]}
                            index={index}
                          />
                        )
                      ) : (
                        <CollectionImagePreview
                          previewURL={myCollection.preview}
                          index={index}
                        />
                      )}
                      <div
                        className="item-card-title mt-3 mb-3 d-flex"
                        style={{
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <div className="d-flex">
                          <Avatar
                            onClick={() => {
                              // const msg =
                              //   user?.displayName +
                              //   " clicked profile!\nYour Email is : " +
                              //   user?.mail;
                              // alert(msg);
                            }}
                            color={"#4c6ef5"}
                            border={"#4c6ef5"}
                          >
                            {myCollection.authors[0].url &&
                            myCollection.authors[0].url !== "null" ? (
                              <img
                                key={index}
                                src={myCollection.authors[0].url}
                                alt=""
                                className="Avatar"
                              />
                            ) : (
                              myCollection.authors[0].name.substring(0, 2)
                            )}
                          </Avatar>
                          <span className="ms-2 mt-2">
                            {myCollection.authors[0].name}
                          </span>
                        </div>
                        <p className="date">
                          {moment
                            .utc(myCollection.created)
                            .local()
                            .format("YYYY-MM-DD")}
                        </p>
                      </div>
                      <p className="card-text d-flex justify-content-end">
                        <ToolButton
                          className="pr-2 unpublish_btn"
                          type={ToolButtonEnum.BUTTON}
                          title="Unpublish"
                          aria-label="Unpublish"
                          onClick={() =>
                            unpublishApprovalCollectionHandler(myCollection.id)
                          }
                        >
                          <span>
                            {t("collection.personal.myCollections.unPublish")}
                          </span>
                        </ToolButton>
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      <div
        className="offcanvas offcanvas-end"
        // tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        data-bs-scroll={false}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel" className="title mt-4">
            {t("collection.community.template.filter.title")}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setOpen(false);
              removeFilters();
              setFilterApply(false);
            }}
          ></button>
        </div>
        <div className="offcanvas-body overflow-hidden d-flex flex-column h-100">
          <MultiSelectDropdown
            selectArray={selectArray}
            isOpen={open}
            removeFilters={removeFilters}
            filterApply={isFilterApply}
            removeVal={removeVal}
            setFilterDataWithKeys={setFilterDataWithKeys}
            filterDataWithKeys={filterDataWithKeys}
            filterData={filterData}
            setFilterData={setFilterData}
          />
        </div>
        <div className="footer">
          <button
            type="button"
            className="bg-brand text-white pr-2 infoBtn"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setOpen(true);
              setFilterApply(true);
              fetchFilteredData(filterDataWithKeys);
            }}
          >
            {t("collection.community.template.filter.apply")}
          </button>
          <button
            type="button"
            className="infoBtn text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setOpen(false);
              removeFilters();
              setFilterApply(false);
            }}
          >
            {t("collection.community.template.filter.cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyCollection;
