export const getUserInfo = async () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  return user;
};

export const getLessonId = async () => {
  const lesson = JSON.parse(localStorage.getItem("lesson") || "{}");
  // const lessonId = new URLSearchParams(window.location.search)
  //   .get("lessonId")
  //   ?.replace(/\//g, "");
  const lessonId = lesson.Title ? lesson.Title : lesson?.LessonId;
  return lessonId;
};
