import { useEffect, useState } from "react";
import { IconPickerItem } from "react-fa-icon-picker";
import {
  createCategory,
  editCategory,
  getCategories,
  deleteCategory as onDeleteCategory,
} from "../../../../../excalidraw-app/api/collection";
import { longUuid } from "../../../../../utils/uuid";
import ConfirmDialog from "../../../../ConfirmDialog";
import { Popover as CustomPopover } from "../../../../Popover";
import {
  PlusIcon,
  collectionIcon,
  del,
  edit,
  lampOn,
  moreIcon,
  workInProgress,
} from "../../../../icons";
import {
  CategoryDataTypes,
  LibraryTabsEnum,
  SidebarItemsProps,
} from "../../../LibraryItemsDialog";
import { SidebarItemsEnum } from "../../../components/constant/SidebarItems";
import { Popover } from "../../../components/popover";
import { CustomCategies } from "../../../../../types";
import "./LibrarySidebar.scss";
import { t } from "../../../../../i18n";
import { opacity } from "html2canvas/dist/types/css/property-descriptors/opacity";
import { zIndex } from "html2canvas/dist/types/css/property-descriptors/z-index";
import { useIsMobile } from "../../../../App";

interface LibrarySidebarProps {
  navToggle: boolean;
  setNavToggle: (val: boolean) => void;
  selectedTab: SidebarItemsEnum;
  setSelectedTab: (val: SidebarItemsEnum) => void;
  selectedTopTab: LibraryTabsEnum;
  categoryData: CategoryDataTypes;
  setCategoryData: (val: CategoryDataTypes) => void;
  setSelectedCategoryId: (val: string) => void;
  selectedCatagoryForExportCanvas?: CustomCategies[];
  isSmallDevice: boolean;
  defaultLibraryTab?: string;
}

export const LibrarySidebar = (props: LibrarySidebarProps) => {
  const {
    navToggle,
    setNavToggle,
    selectedTab,
    setSelectedTab,
    selectedTopTab,
    categoryData,
    setCategoryData,
    setSelectedCategoryId,
    selectedCatagoryForExportCanvas,
    isSmallDevice,
    defaultLibraryTab,
  } = props;

  const SidebarItems = [
    {
      id: "1",
      icon: workInProgress,
      value: SidebarItemsEnum.WorkInProgress,
      isCustom: false,
      color: "",
      title: t("myLibrary.WorkInProgress"),
    },
    {
      id: "2",
      icon: collectionIcon,
      value: SidebarItemsEnum.AddedFromCollections,
      isCustom: false,
      color: "",
      title: t("myLibrary.AddedFromCollections"),
    },
    {
      id: "3",
      icon: lampOn,
      value: SidebarItemsEnum.PublicSpinWheel,
      isCustom: false,
      color: "",
      title: t("myLibrary.PublicSpinWheel"),
    },
  ];

  const [categories, setCategories] = useState<SidebarItemsProps[]>(
    SidebarItems,
  );

  const [hoveredCategory, setHoveredCategory] = useState<string>("");
  const [popoverOpen, setPopoverOpen] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [deleteCategory, setDeleteCategory] = useState<string>("");

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const slug = new URLSearchParams(window.location.search).get("slug");

  useEffect(() => {
    const fetchCategory = async () => {
      const categoryList = await getCategories(user?.username, slug ?? "");
      categoryList?.result &&
        setCategories((prev) => [...prev, ...categoryList.result]);
    };
    fetchCategory();
    setCategories(SidebarItems);
  }, []);

  const handleItemClick = (title: SidebarItemsEnum) => {
    setSelectedTab(title);
  };

  const [isOpenCategoryPopover, setIsOpenCategoryPopover] = useState(false);

  const onAddNewCategory = (categoryData: CategoryDataTypes) => {
    const id = longUuid();
    const newCategoryData = {
      id,
      userId: user.username,
      slug: slug,
      title: categoryData.title,
      icon: categoryData.icon,
      color: categoryData.color,
      isCustom: true,
    };

    setCategories([
      ...categories,
      {
        id,
        title: categoryData.title,
        icon: categoryData.icon,
        color: categoryData.color,
        isCustom: true,
      },
    ]);
    createCategory(newCategoryData);
    setIsOpenCategoryPopover(false);
    setCategoryData({
      id: "",
      color: "#d9480f",
      icon: "FaRegLaugh",
      isCustom: false,
      title: "",
    });
  };

  const onEditCategory = async (categoryData: CategoryDataTypes) => {
    await editCategory({
      userId: user.username,
      slug: slug,
      title: categoryData.title,
      icon: categoryData.icon,
      color: categoryData.color,
      isCustom: true,
      id: categoryData.id,
    });
    setCategories((prev) => {
      const index = prev.findIndex((item) => item.id === categoryData.id);
      prev[index] = {
        id: categoryData.id,
        title: categoryData.title,
        icon: categoryData.icon,
        color: categoryData.color,
        isCustom: true,
      };
      return [...prev];
    });

    setIsEdit(false);
    setIsOpenCategoryPopover(false);
    setCategoryData({
      id: "",
      color: "#d9480f",
      icon: "FaRegLaugh",
      isCustom: false,
      title: "",
    });
  };
  const isMobile = useIsMobile();
  return (
    <>
      <div
        id="nav-bar"
        className={
          isSmallDevice && !navToggle ? "position-absolute bg-white" : ""
        }
        style={{
          width: !navToggle && isMobile ? "100%" : "",
          ...(isSmallDevice && !navToggle && { zIndex: 2, minWidth: 81 }),
        }}
      >
        <input
          id="nav-toggle"
          type="checkbox"
          checked={navToggle}
          onClick={() => setNavToggle(!navToggle)}
        />
        <div
          id="nav-header"
          style={{ width: !navToggle && isMobile ? "90%" : "" }}
        >
          <a id="nav-title">{t("toolBar.mylibrary")}</a>
          <label htmlFor="nav-toggle">
            <span id="nav-toggle-burger"></span>
          </label>
        </div>
        <div id="nav-content" style={{ width: isMobile ? "auto" : "" }}>
          {categories.map((item, index) => {
            return (
              <>
                <div
                  className={`nav-button ${
                    (item?.value ?? item.title) === selectedTab
                      ? "activeItem position-relative"
                      : "position-relative"
                  }`}
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleItemClick(
                      (item?.value ?? item.title) as SidebarItemsEnum,
                    );
                    setSelectedCategoryId(item.id);
                  }}
                  onMouseEnter={() =>
                    setHoveredCategory(item?.value ?? item.title)
                  }
                >
                  {typeof item.icon === "string" ? (
                    <IconPickerItem icon={item.icon} size={18} />
                  ) : (
                    <i
                      className="fas"
                      data-bs-toggle="tooltip"
                      title={item.title}
                    >
                      {item.icon}
                    </i>
                  )}
                  <button
                    className="me-1"
                    style={{
                      backgroundColor: `${item.color}`,
                      opacity: "0.8",
                    }}
                  ></button>
                  <span className="itemtitle" style={{ width: "100%" }}>
                    {" "}
                    {item.title}{" "}
                    {selectedCatagoryForExportCanvas?.find(
                      (cat) => cat?.categoryId === item.id,
                    )?.templateIds.length !== 0 &&
                      selectedCatagoryForExportCanvas?.some(
                        (cat) => cat?.categoryId === item.id,
                      ) && (
                        <span
                          className="p-2 border border-light rounded-circle selectedCatagory"
                          style={{ backgroundColor: `${item.color}` }}
                        >
                          {
                            selectedCatagoryForExportCanvas.find(
                              (cat) => cat?.categoryId === item.id,
                            )?.templateIds.length
                          }
                        </span>
                      )}
                  </span>

                  {(item?.value ?? item.title) === hoveredCategory &&
                    item.isCustom && (
                      <div className="position-relative w-100 library-item-dialog">
                        <div className="d-flex flex-end justify-content-center gap-2 w-100 me-2 ">
                          <span
                            style={{ marginLeft: "30%" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setPopoverOpen((prev) =>
                                prev === item.title ? "" : item.title,
                              );
                            }}
                          >
                            {moreIcon}
                          </span>
                        </div>
                        {popoverOpen === hoveredCategory && (
                          <CustomPopover>
                            <p
                              className="mx-3 my-2 cursor-pointer d-flex gap-2"
                              style={{
                                fontWeight: 600,
                              }}
                              onClick={async (e) => {
                                e.stopPropagation();
                                setCategoryData({
                                  id: item.id,
                                  color: item.color || "",
                                  icon: item.icon as string,
                                  isCustom: true,
                                  title: item.title,
                                });
                                setIsEdit(true);
                                setIsOpenCategoryPopover(
                                  !isOpenCategoryPopover,
                                );
                                setPopoverOpen("");
                              }}
                            >
                              <span
                                style={{
                                  height: 15,
                                  width: 15,
                                }}
                              >
                                {edit}
                              </span>
                              {t("previewDialog.edit")}
                            </p>
                            <p
                              className="mx-3 my-2 cursor-pointer d-flex gap-2"
                              style={{
                                color: "#d33228",
                                fontWeight: 600,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowAlert(true);
                                setDeleteCategory(item.id);
                              }}
                            >
                              <span
                                style={{
                                  height: 15,
                                  width: 15,
                                }}
                              >
                                {del}
                              </span>
                              {t("previewDialog.delete")}
                            </p>
                          </CustomPopover>
                        )}
                      </div>
                    )}
                </div>
                <div id="nav-content-highlight" className="d-none"></div>
              </>
            );
          })}
        </div>

        <div id="nav-footer">
          <hr />
          <div
            id="nav-footer-heading"
            className="nav-button activeItem"
            onClick={() => setIsOpenCategoryPopover(!isOpenCategoryPopover)}
          >
            <i className="fas">{<PlusIcon color="#8392a5" />}</i>{" "}
            <span>{t("myLibrary.addCategory")}</span>
          </div>
        </div>
        <Popover
          onAddNewCategory={onAddNewCategory}
          togglePopover={() => {
            setIsOpenCategoryPopover(false);
          }}
          categoryData={categoryData}
          setCategoryData={setCategoryData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          onEditCategory={onEditCategory}
          open={isOpenCategoryPopover}
        />
        {showAlert && (
          <ConfirmDialog
            onConfirm={async () => {
              setShowAlert(false);
              await onDeleteCategory(deleteCategory);
              setCategories((prev) =>
                prev.filter((item) => item.id !== deleteCategory),
              );
            }}
            onCancel={() => setShowAlert(false)}
            title={"Are you sure?"}
            children={<p>{"Are you sure you want to delete this category?"}</p>}
            closeOnClickOutside={false}
            open={showAlert}
            setOpen={setShowAlert}
          />
        )}
      </div>
    </>
  );
};
