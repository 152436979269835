import React, { useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";

const Portall = (props: { containerId: string; children: JSX.Element }) => {
  const container = document.getElementById(props.containerId);

  if (!container) {
    return props.children || null;
  }

  return ReactDOM.createPortal(props.children, container);
};

export default Portall;
