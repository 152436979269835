import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  bookmarkCollectionAPI,
  fetchAllCollection,
  fetchFilteredCollectionData,
  likeUnlikeCollectionAPI,
} from "../../../../../../excalidraw-app/api/collection";
import { CollocationType } from "../../../../../../types";
import { CardWithImage } from "../../../../components/card";
import Loader from "../../../../../../App/shareComponent/Loader";
import { TemplateCollectionProps } from "../template";
import { FilterRef, FilteredValueType } from "../../CollectionsTab";
import { Toast, ToastType } from "../../../../../Toast";

interface LikedCollectionPropsTypes {
  setSelectedTemplate: (val: string[]) => void;
  selectedTemplate: string[];
}

export const LikedCollection = forwardRef<
  FilterRef,
  TemplateCollectionProps & LikedCollectionPropsTypes
>(
  (
    {
      searchVal,
      setFullScreenCollectionPreview,
      setPreviewTemplateData,
      loadElementsFromDB,
      selectedTemplate,
      setSelectedTemplate,
      selectedSortBy,
    },
    ref,
  ) => {
    const [likeData, setLikeData] = useState<CollocationType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredLikeData, setFilteredLikeData] = useState<CollocationType[]>(
      [],
    );
    const [toastMessage, setToastMessage] = useState<{
      message: string;
      type: ToastType;
    } | null>(null);

    const fetchLikedCollection = async () => {
      try {
        setIsLoading(true);
        const { result } = await fetchAllCollection({
          tab: "liked",
        });

        const likeData = result.resources.filter(
          (item: CollocationType) => item.isLiked,
        );
        setLikeData(likeData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    useEffect(() => {
      fetchLikedCollection();
    }, []);

    useEffect(() => {
      const onSortCollection = async (index: number) => {
        setIsLoading(true);
        try {
          //index 1: newest, 2: most like, 3: title(a-z), 4: author(a-z)
          const { result } = await fetchAllCollection({
            sort: index,
            tab: "liked",
          });
          const likeData = result.resources.filter(
            (item: CollocationType) => item.isLiked,
          );
          setFilteredLikeData(likeData);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.log(error);
        }
      };

      onSortCollection(selectedSortBy ? selectedSortBy : 0);
    }, [selectedSortBy]);

    useEffect(() => {
      if (searchVal) {
        const searchResult = likeData.filter((template) =>
          template.name.toLowerCase().includes(searchVal.toLowerCase()),
        );
        return setFilteredLikeData(searchResult);
      }
      setFilteredLikeData(likeData);
    }, [searchVal, likeData]);

    const fetchFilteredData = async (filterDataWithKeys: FilteredValueType) => {
      try {
        setIsLoading(true);
        const { result } = await fetchFilteredCollectionData(
          filterDataWithKeys,
        );

        const likedCollections = result?.resources.length
          ? result?.resources.filter(
              (collection: CollocationType) => collection.isLiked,
            )
          : [];

        setFilteredLikeData(likedCollections);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("error-in-fetchFilteredData", error);
      }
    };

    const removeFilters = async () => {
      try {
        setIsLoading(true);
        const { result } = await fetchAllCollection({
          tab: "liked",
        });
        const likedCollections = result?.resources.length
          ? result?.resources.filter(
              (collection: CollocationType) => collection.isLiked,
            )
          : [];
        setFilteredLikeData(result.resources);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    useImperativeHandle(ref, () => ({
      onApply: fetchFilteredData,
      onClear: removeFilters,
    }));

    const onAddtoCollectionBtnClick = (collectionSource: string) => {
      if (selectedTemplate.includes(collectionSource)) {
        // If the source is already selected, remove it from the array
        setSelectedTemplate(
          selectedTemplate.filter(
            (source: string) => source !== collectionSource,
          ),
        );
      } else {
        setSelectedTemplate([...selectedTemplate, collectionSource]);
      }
    };

    return (
      <div className="row overflow-auto" style={{ height: "calc(100% - 28%)" }}>
        {isLoading ? (
          <Loader className="h-100" />
        ) : (
          filteredLikeData.map((item, index) => {
            return (
              <CardWithImage
                key={index}
                images={item.preview}
                userProfile={item.authors[0]?.url}
                userName={item.authors[0]?.name}
                created={item.created}
                name={item.name}
                likes={item.likeCount}
                isLikefill={item.isLiked}
                onLike={async () => {
                  if (item.isLiked) {
                    setFilteredLikeData(
                      filteredLikeData.filter((data) => data.id !== item.id),
                    );
                  }
                  const res = await likeUnlikeCollectionAPI(item.id);
                  return res.result.likeCount;
                }}
                isBookMarkFill={item.isBookmark}
                onBookmark={async () => {
                  await bookmarkCollectionAPI(item.id);
                }}
                setFullScreenCollectionPreview={setFullScreenCollectionPreview}
                onPreview={() => {
                  setPreviewTemplateData(item);
                }}
                onUseThisTemplate={() => loadElementsFromDB(item.source)}
                isCustomCatagory={false}
                source={item.source}
                onAddtoCollectionBtnClick={() =>
                  onAddtoCollectionBtnClick(item.source)
                }
                selectedTemplate={selectedTemplate}
                isMove={true}
                id={item.id}
                onAfterMovedToCategory={(category: string[]) => {
                  if (category.length) {
                    const addCategory = category?.filter(
                      (category: string) => category !== item.id,
                    );
                    const index = filteredLikeData.findIndex(
                      (data) => data.id === item.id,
                    );
                    if (addCategory) {
                      const data = [...filteredLikeData];

                      data[index].category = [...addCategory];

                      setFilteredLikeData([...data]);
                    }
                  }
                  setToastMessage({
                    message: "Collection successfully imported.",
                    type: ToastType.SUCCESS,
                  });
                }}
                onAfterDeleteCategory={(id) => {
                  if (item.id !== id) {
                    const updatedCategory = item.category?.filter(
                      (category) => category !== id,
                    );
                    const index = filteredLikeData.findIndex(
                      (data) => data.id === item.id,
                    );
                    if (updatedCategory) {
                      const data = [...filteredLikeData];

                      data[index].category = [...updatedCategory];

                      setFilteredLikeData([...data]);
                    }
                  }
                }}
                templateCategory={item.category}
              />
            );
          })
        )}
        {toastMessage !== null && (
          <Toast
            type={toastMessage.type}
            message={toastMessage.message}
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
      </div>
    );
  },
);
