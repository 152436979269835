import axios from "axios";
import { ExcalidrawElement, NonDeleted } from "../../../../../../element/types";
import { t } from "../../../../../../i18n";
import { LibraryItem } from "../../../../../../types";
import { ToolButton, ToolButtonEnum } from "../../../../../ToolButton";
import { useNavigate } from "react-router-dom";
import { CollectionSidebarItemsEnum } from "../../../../components/constant/SidebarItems";
import { importSVG, trash } from "../../../../../icons";
import { useIsMobile } from "../../../../../App";
import { useRef, useState } from "react";

interface ActionButtonsProps {
  selectedItems: LibraryItem["id"][];
  setSelectedItems: (val: []) => void;
  selectedSideCollectionTab: CollectionSidebarItemsEnum;
  setShowRemoveLibAlert: (val: boolean) => void;
  deleteElelement: string[];
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  importLibraryFromUrl: (urls: string[]) => void;
}

export const ActionButtons = (props: ActionButtonsProps) => {
  const {
    selectedItems,
    setSelectedItems,
    selectedSideCollectionTab,
    setShowRemoveLibAlert,
    deleteElelement,
    onUpload,
    importLibraryFromUrl,
  } = props;
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputKey, setInputKey] = useState(Date.now() + Math.random());

  // laod element from DB - azure blob storage link
  const loadElementsFromDB = async (urls: string[]) => {
    const elements: NonDeleted<ExcalidrawElement>[] = [];

    try {
      await Promise.all(
        urls.map(async (url) => {
          const res = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });

          if (res.data?.library) {
            res.data.library.forEach((lib: any) => {
              lib.forEach((data: NonDeleted<ExcalidrawElement>) => {
                elements.push(data);
              });
            });
          }
          if (res.data?.libraryItems) {
            res.data.libraryItems.forEach((lib: any) => {
              lib.elements.forEach((data: NonDeleted<ExcalidrawElement>) => {
                elements.push(data);
              });
            });
          }
        }),
      );

      const slug = new URLSearchParams(window.location.search).get("slug");
      const room = new URLSearchParams(window.location.search).get("room");
      const lessonId = new URLSearchParams(window.location.search).get(
        "lessonId",
      );

      let finalURL = `/?slug=${slug}&lessonId=${lessonId}/#addLibrary=${urls}`;
      if (room) finalURL += `/#room=${room}`;
      const libraryId = finalURL.split("#addLibrary=")[1];
      const urlArray = libraryId ? libraryId.split(",") : [];
      importLibraryFromUrl(urlArray);
      localStorage.setItem("SET-ACV-LIBRARY", "true");
    } catch (error) {
      console.error("Error loading elements from DB:", error);
    }
  };
  const isMobile = useIsMobile();

  return (
    <div className="library-actions">
      <div className="d-flex justify-content-end mb-2">
        <div className="d-flex gap-2">
          {(selectedSideCollectionTab === CollectionSidebarItemsEnum.Template ||
            selectedSideCollectionTab === CollectionSidebarItemsEnum.Liked ||
            selectedSideCollectionTab ===
              CollectionSidebarItemsEnum.Bookmark) &&
            selectedItems.length > 0 && (
              <>
                <h6 className="d-flex align-items-center me-2 mb-0">
                  Import: {selectedItems.length}
                </h6>
                <ToolButton
                  key="import"
                  type={ToolButtonEnum.BUTTON}
                  aria-label="import"
                  className="library-actions text-white px-3 py-1"
                  onClick={async () => {
                    loadElementsFromDB(selectedItems);
                  }}
                  style={{ backgroundColor: "#494B83" }}
                >
                  {t("collection.community.template.importBtn")}
                </ToolButton>
                <ToolButton
                  key="reset"
                  type={ToolButtonEnum.BUTTON}
                  aria-label="cancel"
                  className="library-actions-remove text-white px-3 py-1"
                  onClick={() => {
                    setSelectedItems([]);
                  }}
                >
                  {t("collection.community.template.cancelBtn")}
                </ToolButton>
              </>
            )}
          {(selectedSideCollectionTab === CollectionSidebarItemsEnum.Images ||
            selectedSideCollectionTab === CollectionSidebarItemsEnum.Videos ||
            selectedSideCollectionTab === CollectionSidebarItemsEnum.Audios) &&
            selectedItems.length > 0 && (
              <ToolButton
                key="Upload"
                type={ToolButtonEnum.BUTTON}
                title={"Remove"}
                aria-label={"Remove"}
                icon={trash}
                className="library-actions--removed text-white pe-3"
                onClick={() => setShowRemoveLibAlert(true)}
              >
                {!isMobile && <label>Delete</label>}
                {deleteElelement.length > 0 && (
                  <span className="library-actions-counter">
                    {deleteElelement.length}
                  </span>
                )}
              </ToolButton>
            )}
          {(selectedSideCollectionTab === CollectionSidebarItemsEnum.Images ||
            selectedSideCollectionTab === CollectionSidebarItemsEnum.Videos ||
            selectedSideCollectionTab ===
              CollectionSidebarItemsEnum.Audios) && (
            <>
              <input
                key={inputKey}
                type="file"
                id="file"
                ref={inputRef}
                hidden
                onChange={(e) => {
                  onUpload(e);
                  setInputKey(Date.now() + Math.random());
                }}
              />
              <ToolButton
                key="reset"
                type={ToolButtonEnum.BUTTON}
                title={t("buttons.load")}
                aria-label={t("buttons.load")}
                icon={importSVG}
                className="library-actions--upload text-white pe-3"
                onClick={() => {
                  inputRef.current?.click();
                }}
              >
                {!isMobile && <label>Upload</label>}
              </ToolButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
