/* eslint-disable prettier/prettier */

import { StockImagesType } from "../types";
import { menu, searchIcon } from "./icons";
import "./StockImages.scss";
import { ToolButton } from "./ToolButton";
import { t } from "../i18n";
import Loader from "../App/shareComponent/Loader";
import { useIsMobile } from "./App";

type StockImagesProps = {
  images: StockImagesType[];
  searchVal: string;
  changeHandler: (query: string) => void;
  insertImageOnCanvas: (file: File) => void;
  onClose: () => void;
  isLoading: boolean;
  isSideBarOpen: boolean;
  setIsSideBarOpen: (val: boolean) => void;
};
const StockImages = ({
  images,
  changeHandler,
  searchVal,
  insertImageOnCanvas,
  onClose,
  isLoading,
  isSideBarOpen,
  setIsSideBarOpen,
}: StockImagesProps) => {
  const loadImageOnCanvas = (src: string, alt: string) => {
    const name = alt.replace(/ /g, "_");
    SrcToFile(src, name, "image/jpg").then(function (file) {
      insertImageOnCanvas(file);
      console.log(file);
    });
  };

  function SrcToFile(src: string, fileName: string, mimeType: string) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }

  const getUrlExtension = (url: any) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };
  const insertImage = async (url: string) => {
    const imgExt = getUrlExtension(url);

    const response = await fetch(url);
    const blob = await response.blob();
    console.log(blob);

    const file = new File([blob], `image.${imgExt}`, {
      type: blob.type,
    });
    insertImageOnCanvas(file);
    onClose();
  };

  const lesson = JSON.parse(localStorage.getItem("lesson") || "");
  return (
    <div className="stock_container h-100 d-flex flex-column">
      <h4 className="text">{t("collection.community.stokeImage.message")}</h4>
      <div className="pb-2 col-md-12">
        <div className="d-flex align-items-center">
          <button
            className={`btn me-3 ${useIsMobile() ? "d-none" : ""}`}
            // data-bs-toggle="offcanvas"
            // data-bs-target="#offcanvasExample"
            // aria-controls="offcanvasExample"
            style={{ width: "28px" }}
            onClick={() => {
              setIsSideBarOpen(!isSideBarOpen);
            }}
          >
            {menu}
          </button>
          <div className="col-md-5 col-sm-12 position-relative">
            <input
              type="text"
              id="search"
              name="search"
              placeholder={t("collection.community.stokeImage.search")}
              className="m-0 w-100 mt-lg-0 mt-3"
              value={searchVal}
              onChange={(e) => {
                changeHandler(e.target.value);
              }}
            />
            {/* <ToolButton
            key="newPage"
            type="button"
            aria-label="close"
            className={`position-absolute closeIcon`}
            icon={searchIcon}
            onClick={() => changeHandler(searchVal)}
          ></ToolButton> */}
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : images.length > 0 ? (
        <div
          className="pt-2 d-flex flex-wrap gap-3 justify-content-center"
          style={{ maxHeight: "100%", overflow: "auto" }}
        >
          {images.map((image: StockImagesType, index) => (
            <div
              className="pt-3 card"
              style={{ width: 300 }}
              onClick={() => insertImage(image.src.medium)}
            >
              <img
                key={index}
                src={image.src.medium}
                alt=""
                style={{ objectFit: "contain" }}
                height={200}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="empty_img text-center my-auto">
          <img
            src="https://acvstorageprod.blob.core.windows.net/react-images/Empty-pana.svg"
            alt=""
            height={292}
            width={292}
          />
        </div>
      )}
    </div>
  );
};

export default StockImages;
