import {
  importLibraryFromJSON,
  saveLibraryAsJSON,
} from "../../../../../../data/json";
import Library from "../../../../../../data/library";
import { t } from "../../../../../../i18n";
import { AppState, LibraryItem, LibraryItems } from "../../../../../../types";
import { muteFSAbortError } from "../../../../../../utils";
import { useIsMobile } from "../../../../../App";
import { ToolButton, ToolButtonEnum } from "../../../../../ToolButton";
import { Tooltip } from "../../../../../Tooltip";
import {
  del_,
  download_,
  publish_,
  save,
  trash,
  upload_,
} from "../../../../../icons";
import restart from "../../../../../../assests/delete.png";

interface ActionButtonsProps {
  selectedItems: LibraryItem["id"][];
  libraryItems: LibraryItems;
  deleteElelement: number[];
  addToCollection: number[];
  setAddToCollection: (value: number[]) => void;
  setDeleteElement: (val: number[]) => void;
  library: Library;
  setAppState: React.Component<any, AppState>["setState"];
  setShowRemoveLibAlert: (val: boolean) => void;
  onPublish: () => void;
  isCollectionEdit: boolean;
  setSaveLib: (val: boolean) => void;
  saveLib: boolean;
}

export const ActionButtons = (props: ActionButtonsProps) => {
  const {
    selectedItems,
    libraryItems,
    deleteElelement,
    library,
    setAppState,
    setShowRemoveLibAlert,
    addToCollection,
    onPublish,
    isCollectionEdit = false,
    setSaveLib,
    saveLib,
  } = props;

  const isMobile = useIsMobile();

  const itemsSelected = !!selectedItems.length;
  const items = itemsSelected
    ? libraryItems.filter((item) => selectedItems.includes(item.id))
    : libraryItems;
  const resetLabel = deleteElelement.length
    ? t("buttons.remove")
    : t("buttons.resetLibrary");

  return (
    <>
      {!isCollectionEdit ? (
        <div className="library-actions">
          <button
            type="button"
            className="text-white ps-2 pe-2"
            disabled={addToCollection.length > 0}
            style={{
              backgroundColor:
                addToCollection.length > 0 ? "#cccccc" : "#494b83",
            }}
            value="Upload"
            onClick={() => {
              importLibraryFromJSON(library, "unpublished")
                .then(() => {
                  // Close and then open to get the libraries updated
                  setAppState({ isLibraryOpen: false });
                  setAppState({
                    isLibraryOpen: true,
                    // showElementOnCanvasOrNot: true,
                  });
                })
                .catch(muteFSAbortError)
                .catch((error) => {
                  setAppState({ errorMessage: error.message });
                });
            }}
          >
            {upload_} Upload
          </button>
          <div className="position-relative">
            <button
              type="button"
              className="text-white bg-brand ps-2 pe-2"
              value="Download"
              onClick={async () => {
                const libraryItems = itemsSelected
                  ? items
                  : await library.loadLibrary();
                saveLibraryAsJSON(libraryItems)
                  .catch(muteFSAbortError)
                  .catch((error) => {
                    setAppState({ errorMessage: error.message });
                  });
              }}
            >
              {download_} Download
            </button>
            {selectedItems.length > 0 && (
              <span
                className="counter position-absolute top-2 start-100 translate-middle p-2 rounded-circle"
                style={{
                  width: selectedItems.length > 99 ? "22px" : "18px",
                  height: selectedItems.length > 99 ? "22px" : "18px",
                  fontSize: selectedItems.length > 99 ? "10px" : "12px",
                  backgroundColor:
                    selectedItems.length > 0 ? "#6264a7" : "none",
                }}
                title={selectedItems.length.toString()}
              >
                {selectedItems.length > 99 ? "99+" : selectedItems.length}
              </span>
            )}
          </div>

          {addToCollection.length > 0 && (
            <div className="position-relative">
              <span
                className="counter position-absolute top-2 start-100 translate-middle p-2 rounded-circle"
                style={{
                  width: addToCollection.length > 99 ? "22px" : "18px",
                  height: addToCollection.length > 99 ? "22px" : "18px",
                  fontSize: addToCollection.length > 99 ? "10px" : "12px",
                  backgroundColor:
                    addToCollection.length > 0 ? "#6264a7" : "none",
                }}
                title={addToCollection.length.toString()}
              >
                {addToCollection.length > 99 ? "99+" : addToCollection.length}
              </span>
              <button
                type="button"
                className="text-white bg-brand ps-2 pe-2"
                value="Publish"
                onClick={onPublish}
              >
                {publish_} Publish
              </button>
            </div>
          )}
          {isCollectionEdit ? (
            <div className="d-flex" style={{ gap: "11px" }}>
              <div className="position-relative">
                {deleteElelement.length > 0 && (
                  <span
                    className="counter position-absolute top-2 start-100 translate-middle p-2 bg-danger rounded-circle"
                    style={{
                      width: addToCollection.length > 99 ? "22px" : "18px",
                      height: addToCollection.length > 99 ? "22px" : "18px",
                      fontSize: addToCollection.length > 99 ? "10px" : "12px",
                    }}
                    title={deleteElelement.length.toString()}
                  >
                    {deleteElelement.length > 99
                      ? "99+"
                      : deleteElelement.length}
                  </span>
                )}
                <button
                  type="button"
                  className="text-white ps-2 pe-2"
                  value="Delete"
                  onClick={() => setShowRemoveLibAlert(true)}
                  style={{ backgroundColor: "#C4314B" }}
                >
                  {del_} Delete
                </button>
              </div>
              <ToolButton
                key="reset"
                type={ToolButtonEnum.BUTTON}
                title={resetLabel}
                aria-label={resetLabel}
                icon={trash}
                className="library-actions--cancel text-white pe-3"
                style={{ height: "34px" }}
                onClick={() => {
                  setShowRemoveLibAlert(true);
                }}
              >
                Cancel Editing
              </ToolButton>
            </div>
          ) : (
            <div className="position-relative">
              {deleteElelement.length > 0 && (
                <span
                  className="counter position-absolute top-2 start-100 translate-middle p-2 bg-danger rounded-circle"
                  style={{
                    width: addToCollection.length > 99 ? "22px" : "18px",
                    height: addToCollection.length > 99 ? "22px" : "18px",
                    fontSize: addToCollection.length > 99 ? "10px" : "12px",
                  }}
                  title={deleteElelement.length.toString()}
                >
                  {deleteElelement.length > 99 ? "99+" : deleteElelement.length}
                </span>
              )}
              <button
                type="button"
                className="text-white ps-2 pe-2"
                value="Delete"
                onClick={() => setShowRemoveLibAlert(true)}
                style={{ backgroundColor: "#C4314B" }}
              >
                {del_} Delete
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="library-actions">
          <div className="d-flex" style={{ gap: "11px" }}>
            {saveLib ? (
              <div className="position-relative">
                <div id="edit-lib-btn"></div>
              </div>
            ) : (
              <>
                <div className="position-relative">
                  {deleteElelement.length > 0 && (
                    <span
                      className="counter position-absolute top-2 start-100 translate-middle p-2 bg-danger rounded-circle"
                      style={{
                        width: addToCollection.length > 99 ? "22px" : "18px",
                        height: addToCollection.length > 99 ? "22px" : "18px",
                        fontSize: addToCollection.length > 99 ? "10px" : "12px",
                      }}
                      title={deleteElelement.length.toString()}
                    >
                      {deleteElelement.length > 99
                        ? "99+"
                        : deleteElelement.length}
                    </span>
                  )}
                  <button
                    type="button"
                    className="text-white ps-2 pe-2"
                    value="Delete"
                    onClick={() => setShowRemoveLibAlert(true)}
                    style={{ backgroundColor: "#C4314B" }}
                  >
                    {del_} Delete
                  </button>
                </div>{" "}
                <div className="position-relative">
                  {deleteElelement.length > 0 && (
                    <span
                      className="counter position-absolute top-2 start-100 translate-middle p-2 bg-primary rounded-circle"
                      style={{
                        width: addToCollection.length > 99 ? "22px" : "18px",
                        height: addToCollection.length > 99 ? "22px" : "18px",
                        fontSize: addToCollection.length > 99 ? "10px" : "12px",
                      }}
                      title={deleteElelement.length.toString()}
                    >
                      {deleteElelement.length > 99
                        ? "99+"
                        : deleteElelement.length}
                    </span>
                  )}
                  <ToolButton
                    key="Save Library"
                    type={ToolButtonEnum.BUTTON}
                    title={
                      deleteElelement.length === 0
                        ? "Please select atlease one template"
                        : "Save Library"
                    }
                    aria-label={
                      deleteElelement.length === 0
                        ? "Please select atlease one template"
                        : "Save Library"
                    }
                    icon={save}
                    className="text-white pe-3 position-relative"
                    style={{
                      height: "34px",
                      backgroundColor:
                        deleteElelement.length === 0 ? "#cccccc" : "#494B83",
                    }}
                    onClick={() => setSaveLib(true)}
                    disabled={deleteElelement.length === 0}
                  >
                    Save Library
                  </ToolButton>
                </div>
                <ToolButton
                  key="reset"
                  type={ToolButtonEnum.BUTTON}
                  title={resetLabel}
                  aria-label={resetLabel}
                  icon={trash}
                  className="library-actions--cancel text-white pe-3"
                  style={{ height: "34px" }}
                  onClick={() => {
                    setShowRemoveLibAlert(true);
                  }}
                >
                  Cancel Editing
                </ToolButton>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
