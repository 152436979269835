import { createContext } from "react";

type UserProfileType = {
  image: string | null;
};

const UserProfileDefaultValue: UserProfileType = {
  image: null,
};

export const UserProfileContext = createContext<UserProfileType>(
  UserProfileDefaultValue,
);
