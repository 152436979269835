import axios from "axios";
import { useContext, useState } from "react";
import {
  LetsPlayQuestionEntriesAndResults,
  LetsPlayQuestionEntriesAndResultsProps,
} from "./components";
import { SpinnerContext } from "../../../../contexts";
import { SegmentType } from "../../../../types";
import { save } from "../../../../../icons";
import { Dialog } from "../../../../../Dialog";
import AddSpinnerWheel from "./components/addSpinnerWheel/AddSpinnerWheel";
import { useIsMobile } from "../../../../../App";

type QuestionGenerationPropsType = {
  segmentData: SegmentType;
  componentsProps: {
    questionEntriesAndResults: Pick<
      LetsPlayQuestionEntriesAndResultsProps,
      "componentsProps"
    >;
  };
  onClose: () => void;
  resultsData: string[];
  languages: string[];
  language: string;
  handleSelect: (value: string) => void;
  theme: string;
};

const topicGenerationLimits = [4, 5, 10, 15, 20, 25];

export const QuestionGeneration = ({
  componentsProps,
  segmentData,
  onClose,
  resultsData,
  languages,
  language,
  handleSelect,
  theme,
}: QuestionGenerationPropsType) => {
  const {
    segments,
    selectedWheelNumber,
    setSegments,
    openSaveDialog,
    setOpenSaveDialog,
    topic,
    setTopic,
    topicGenerationLimit,
    setTopicGenerationLimit,
  } = useContext(SpinnerContext);
  const isMobile = useIsMobile();

  const [loading, setLoading] = useState<boolean>(false);
  // const [topic, setTopic] = useState<string>();
  // const [topicGenerationLimit, setTopicGenerationLimit] = useState<number>(10);

  const generateTopics = () => {
    const promptMessage = `I would like to generate ${segmentData?.topicGenerationLimit} names related to ${segmentData?.topic}, Please give me the ${segmentData?.topic} name. please list the topics in a new line but don't use numbers, bullet points, hyphens or any other special characters. Can you please share content in only ${language} language.`;
    setLoading(true);

    const params = {
      model: "gpt-3.5-turbo",
      messages: [{ role: "user", content: promptMessage }],
      max_tokens: 2000,
      temperature: 0.7,
      n: 1,
    };
    axios
      .post(`${process.env.REACT_APP_EP_URL_WHITEBOARD}/gpt`, params)
      .then(
        async (res: {
          data: { result: { choices: { message: { content: string } }[] } };
        }) => {
          setLoading(false);
          const article = res.data.result.choices[0].message.content.trim();
          let topics = article.split("\n").slice(0, topicGenerationLimit);
          topics = topics.map((topic) => topic.replace(/^\d+\.\s*|\-\s*/g, "")); // Remove numbers, hyphens from the start of the topic
          onUpdateSelectedSegmentData("segment", topics);
          setTopic("");
          setTopicGenerationLimit(10);
        },
      )
      .catch((err: any) => {
        console.log("Error:", err);
      });
  };

  const onUpdateSelectedSegmentData = (fieldName: string, value: any) => {
    const selectedWheelIndex = segments.findIndex(
      (data: { wheel: number }) =>
        Number(data.wheel) === Number(selectedWheelNumber),
    );
    if (selectedWheelIndex !== -1) {
      const updatedSegments = [...segments];
      updatedSegments[selectedWheelIndex] = {
        ...updatedSegments[selectedWheelIndex],
        [fieldName]: value,
      };
      setSegments([...updatedSegments]);
    }
  };

  const questionsAndGenratesTopics = () => {
    return (
      <>
        {" "}
        <div className="addQuestion">
          <input
            type="number"
            className="form-control"
            placeholder="Enter value 1-1000"
            max={1000}
            min={1}
            onChange={(e) => {
              const val = e.target.value;
              if (val === "") {
                // @ts-ignore
                // When the input is empty, do not update the state
                setTopicGenerationLimit("");
              } else if (Number(val) < 1) {
                // Set the minimum value
                setTopicGenerationLimit(1);
              } else if (Number(val) > 1000) {
                // Set the maximum value
                setTopicGenerationLimit(1000);
              } else {
                // Set the value within range
                setTopicGenerationLimit(Number(val));
                onUpdateSelectedSegmentData(
                  "topicGenerationLimit",
                  Number(val),
                );
              }
            }}
            // onBlur={(e) => {
            //   console.log("topicGenerationLimit", topicGenerationLimit);
            //   if (
            //     // @ts-ignore
            //     topicGenerationLimit === "" ||
            //     Number(topicGenerationLimit) < 1
            //   ) {
            //     setTopicGenerationLimit(Number(1));
            //   } else if (Number(topicGenerationLimit) > 1000) {
            //     setTopicGenerationLimit(1000);
            //   }
            // }}
            value={topicGenerationLimit}
          />
        </div>
        {/* <select
          className="form-control w-50"
          value={topicGenerationLimit} // Set the selected value to the state value
          onChange={(event) => {
            setTopicGenerationLimit(Number(event.target.value));
            onUpdateSelectedSegmentData(
              "topicGenerationLimit",
              Number(event.target.value),
            );
          }} // Convert the value to a number before setting it as the state value
        >
          <option value={4}>Select list of topic</option>
          {topicGenerationLimits.map((limit) => (
            <option key={limit} value={limit}>
              {limit}
            </option>
          ))}
        </select> */}
        <button
          type="button"
          className="spinner-btn"
          onClick={generateTopics}
          disabled={loading || !segmentData?.topic}
        >
          {loading ? "Loading..." : "Generate Topics"}
        </button>
      </>
    );
  };

  return (
    <div className="col-lg-6 col-md-12 col-sm-12 mt-lg-0 mt-3">
      <div
        className={`${
          isMobile ? "d-context" : "d-flex"
        } justify-content-between gap-2`}
      >
        <input
          type="text"
          className="form-control"
          placeholder="Enter your Topic like science, math, etc."
          value={topic}
          onChange={(e) => {
            setTopic(e.target.value);
            onUpdateSelectedSegmentData("topic", e.target.value);
          }}
        />
        {isMobile ? (
          <div
            className="d-flex justify-content-between mt-2"
            style={{ gap: "10px" }}
          >
            {questionsAndGenratesTopics()}
          </div>
        ) : (
          questionsAndGenratesTopics()
        )}
      </div>
      {segmentData?.topic && (
        <div className="d-flex my-3 gap-5">
          <p className="font-weight-bold h6">
            Topic: <b>{segmentData?.topic}</b>
          </p>

          <p className="font-weight-bold h6">
            Topic generation Limit: <b>{topicGenerationLimit}</b>
            {/* Topic generation Limit: <b>{segmentData?.topicGenerationLimit}</b> */}
          </p>
        </div>
      )}
      <LetsPlayQuestionEntriesAndResults
        {...componentsProps.questionEntriesAndResults}
        languages={languages}
        language={language}
        handleSelect={handleSelect}
        theme={theme}
      />

      <div
        className={`${
          isMobile
            ? "position-static d-flex justify-content-end"
            : "position-fixed"
        } spin-save-div`}
        style={{ margin: isMobile ? "10px" : 0 }}
      >
        <button
          className={`btn-insert spin-save mt-3 d-flex gap-1`}
          // disabled={!responsePrompt}
          onClick={() => {
            setOpenSaveDialog(true);
          }}
        >
          <div className="icon">{save}</div>
          <span>Save</span>
        </button>
      </div>

      {openSaveDialog && (
        <Dialog
          setOpen={setOpenSaveDialog}
          open={openSaveDialog}
          onCloseRequest={() => {
            setOpenSaveDialog(false);
          }}
          closeOnClickOutside={false}
          small={true}
          className={`${
            isMobile ? "addSpinnerWheel-mobile" : "addSpinnerWheel"
          }`}
        >
          <AddSpinnerWheel
            setOpen={setOpenSaveDialog}
            onClose={onClose}
            results={resultsData}
          />
        </Dialog>
      )}
    </div>
  );
};
