/**
 * angles
 */

import { distance2d } from "../../math";
import { Curve, Ellipse, Line, Point, Polycurve, Polygon, Polyline } from "../../scene/Shape";

// convert radians to degress
export const angleToDegrees = (angle: number) => {
  return (angle * 180) / Math.PI;
};

// convert degrees to radians
export const angleToRadians = (angle: number) => {
  return (angle / 180) * Math.PI;
};

const isOrigin = (point: Point) => {
  return point[0] === 0 && point[1] === 0;
};

/**
 * points
 */

const rotate = (point: Point, angle: number): Point => {
  return [
    point[0] * Math.cos(angle) - point[1] * Math.sin(angle),
    point[0] * Math.sin(angle) + point[1] * Math.cos(angle),
  ];
};

// rotate a given point about a given origin at the given angle
export const pointRotate = (
  point: Point,
  angle: number,
  origin?: Point,
): Point => {
  const r = angleToRadians(angle);

  if (!origin || isOrigin(origin)) {
    return rotate(point, r);
  }
  return rotate(point.map((c, i) => c - origin[i]) as Point, r).map(
    (c, i) => c + origin[i],
  ) as Point;
};

export const isClosed = (polygon: Polygon) => {
  const first = polygon[0];
  const last = polygon[polygon.length - 1];
  return first[0] === last[0] && first[1] === last[1];
};

export const close = (polygon: Polygon) => {
  return isClosed(polygon) ? polygon : [...polygon, polygon[0]];
};

export const pointAdd = (pointA: Point, pointB: Point): Point => {
  return [pointA[0] + pointB[0], pointA[1] + pointB[1]];
};

export const pointInPolygon = (point: Point, polygon: Polygon) => {
  const x = point[0];
  const y = point[1];
  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0];
    const yi = polygon[i][1];
    const xj = polygon[j][0];
    const yj = polygon[j][1];

    if (
      ((yi > y && yj <= y) || (yi <= y && yj > y)) &&
      x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    ) {
      inside = !inside;
    }
  }

  return inside;
};

const DEFAULT_THRESHOLD = 10e-5;

export const distanceToSegment = (point: Point, line: Line) => {
  const [x, y] = point;
  const [[x1, y1], [x2, y2]] = line;

  const A = x - x1;
  const B = y - y1;
  const C = x2 - x1;
  const D = y2 - y1;

  const dot = A * C + B * D;
  const len_sq = C * C + D * D;
  let param = -1;
  if (len_sq !== 0) {
    param = dot / len_sq;
  }

  let xx;
  let yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  } else if (param > 1) {
    xx = x2;
    yy = y2;
  } else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  const dx = x - xx;
  const dy = y - yy;
  return Math.sqrt(dx * dx + dy * dy);
};

export const pointOnLine = (
  point: Point,
  line: Line,
  threshold = DEFAULT_THRESHOLD,
) => {
  const distance = distanceToSegment(point, line);

  if (distance === 0) {
    return true;
  }

  return distance < threshold;
};

export const pointOnPolygon = (
  point: Point,
  polygon: Polygon,
  threshold = DEFAULT_THRESHOLD,
) => {
  let on = false;
  const closed = close(polygon);

  for (let i = 0, l = closed.length - 1; i < l; i++) {
    if (pointOnLine(point, [closed[i], closed[i + 1]], threshold)) {
      on = true;
      break;
    }
  }

  return on;
};


export const pointInverse = (point: Point) => {
  return [-point[0], -point[1]] as Point;
};

export const pointRelativeToCenter = (
  point: Point,
  center: Point,
  angle: number,
): Point => {
  const translated = pointAdd(point, pointInverse(center));
  const rotated = pointRotate(translated, -angleToDegrees(angle));

  return rotated;
};


export const distanceToPoint = (p1: Point, p2: Point) => {
  return distance2d(...p1, ...p2);
};

const distanceToEllipse = (point: Point, ellipse: Ellipse) => {
  const { angle, halfWidth, halfHeight, center } = ellipse;
  const a = halfWidth;
  const b = halfHeight;
  const [rotatedPointX, rotatedPointY] = pointRelativeToCenter(
    point,
    center,
    angle,
  );

  const px = Math.abs(rotatedPointX);
  const py = Math.abs(rotatedPointY);

  let tx = 0.707;
  let ty = 0.707;

  for (let i = 0; i < 3; i++) {
    const x = a * tx;
    const y = b * ty;

    const ex = ((a * a - b * b) * tx ** 3) / a;
    const ey = ((b * b - a * a) * ty ** 3) / b;

    const rx = x - ex;
    const ry = y - ey;

    const qx = px - ex;
    const qy = py - ey;

    const r = Math.hypot(ry, rx);
    const q = Math.hypot(qy, qx);

    tx = Math.min(1, Math.max(0, ((qx * r) / q + ex) / a));
    ty = Math.min(1, Math.max(0, ((qy * r) / q + ey) / b));
    const t = Math.hypot(ty, tx);
    tx /= t;
    ty /= t;
  }

  const [minX, minY] = [
    a * tx * Math.sign(rotatedPointX),
    b * ty * Math.sign(rotatedPointY),
  ];

  return distanceToPoint([rotatedPointX, rotatedPointY], [minX, minY]);
};

export const pointOnEllipse = (
  point: Point,
  ellipse: Ellipse,
  threshold = DEFAULT_THRESHOLD,
) => {
  return distanceToEllipse(point, ellipse) <= threshold;
};

export const pointInEllipse = (point: Point, ellipse: Ellipse) => {
  const { center, angle, halfWidth, halfHeight } = ellipse;
  const [rotatedPointX, rotatedPointY] = pointRelativeToCenter(
    point,
    center,
    angle,
  );

  return (
    (rotatedPointX / halfWidth) * (rotatedPointX / halfWidth) +
      (rotatedPointY / halfHeight) * (rotatedPointY / halfHeight) <=
    1
  );
};

export const pointOnPolyline = (
  point: Point,
  polyline: Polyline,
  threshold = DEFAULT_THRESHOLD,
) => {
  return polyline.some((line) => pointOnLine(point, line, threshold));
};

export const cubicBezierEquation = (curve: Curve) => {
  const [p0, p1, p2, p3] = curve;
  // B(t) = p0 * (1-t)^3 + 3p1 * t * (1-t)^2 + 3p2 * t^2 * (1-t) + p3 * t^3
  return (t: number, idx: number) =>
    Math.pow(1 - t, 3) * p3[idx] +
    3 * t * Math.pow(1 - t, 2) * p2[idx] +
    3 * Math.pow(t, 2) * (1 - t) * p1[idx] +
    p0[idx] * Math.pow(t, 3);
};

export const polyLineFromCurve = (curve: Curve, segments = 10): Polyline => {
  const equation = cubicBezierEquation(curve);
  let startingPoint = [equation(0, 0), equation(0, 1)] as Point;
  const lineSegments: Polyline = [];
  let t = 0;
  const increment = 1 / segments;

  for (let i = 0; i < segments; i++) {
    t += increment;
    if (t <= 1) {
      const nextPoint: Point = [equation(t, 0), equation(t, 1)];
      lineSegments.push([startingPoint, nextPoint]);
      startingPoint = nextPoint;
    }
  }

  return lineSegments;
};

export const pointOnCurve = (
  point: Point,
  curve: Curve,
  threshold = DEFAULT_THRESHOLD,
) => {
  return pointOnPolyline(point, polyLineFromCurve(curve), threshold);
};

export const pointOnPolycurve = (
  point: Point,
  polycurve: Polycurve,
  threshold = DEFAULT_THRESHOLD,
) => {
  return polycurve.some((curve) => pointOnCurve(point, curve, threshold));
};