import React from "react";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import clsx from "clsx";
import { t } from "../i18n";
import { PlusIcon } from "./icons";

const AddButton = () => {
  return (
    <ToolButton
      className={"px-2 bg-brand add-button"}
      onClick={() => {}}
      icon={<PlusIcon color="#fff" />}
      type={ToolButtonEnum.BUTTON}
      title={t("labels.addGroup")}
      aria-label={t("labels.addGroup")}
    >
      <span className="text-white">{t("labels.addGroup")}</span>
    </ToolButton>
  );
};

export default AddButton;
