import { useContext } from "react";
import "./tabs.scss";
import { SpinnerContext } from "../spinner/contexts";
import { useIsMobile } from "../App";

type TabsProps = {
  tab: string;
  onChange: (tab: string) => void;
  tabs: TabItem[];
  className?: string;
  isShowAllContent?: boolean;
  isShowAddWheelButton?: boolean;
  startAgainBtn?: () => React.ReactNode;
  languages?: string[];
  language?: string;
  handleSelect?: (value: string) => void;
  theme?: string;
};

type TabItem = {
  label: React.ReactNode;
  value: string;
  content: React.ReactNode;
};

const DropDown = ({
  options,
  selected,
  onChange,
  classes,
  disabled,
  tab,
  isMobile,
}: {
  options: string[];
  selected: string;
  onChange: (value: string) => void;
  classes?: String;
  disabled: boolean;
  tab: string;
  isMobile: boolean;
}) => {
  return (
    <>
      <select
        disabled={disabled}
        className={`form-select form-select-sm ${classes}`}
        aria-label=".form-select-sm example"
        value={selected}
        onChange={(e) => onChange(e.target.value)}
        style={{
          width: 130,
          height: isMobile ? 37 : 36,
          borderRadius: 5,
          marginTop: 2,
        }}
      >
        {options.length &&
          options.map((option, index) => (
            <option value={option} key={index}>
              {option}
            </option>
          ))}
      </select>
    </>
  );
};

export const Tabs = (props: TabsProps) => {
  const { segments, setSegments, setSelectedWheelNumber } = useContext(
    SpinnerContext,
  );
  const isMobile = useIsMobile();
  return (
    <>
      <div className={`magic-tab ${props.className}`}>
        {props.tabs.map((tab, index) => (
          <a
            key={index}
            className={props.tab === tab.value ? "active-tab" : ""}
            onClick={() => props.onChange(tab.value)}
          >
            {tab.label}
          </a>
        ))}
        {props?.isShowAddWheelButton && segments?.length < 5 && (
          <div
            className="cursor-pointer"
            onClick={() => {
              if (segments?.length < 5) {
                setSegments([
                  ...segments,
                  {
                    wheel: segments.length + 1,
                    segment: [],
                    topic: "",
                    topicGenerationLimit: 10,
                    results: [],
                    timer: false,
                    timerDuration: {
                      minutes: 1,
                      seconds: 0,
                    },
                  },
                ]);
                setSelectedWheelNumber(segments.length + 1);
              }
            }}
          >
            <p className="mb-0">+ Add Wheel</p>
          </div>
        )}
        <div className="d-flex flex-grow-1 gap-2 justify-content-end">
          <div
            id="external_source"
            className="external-source-btn position-relative"
          ></div>
          {props.languages && props.language && props.handleSelect && (
            <DropDown
              options={props.languages}
              selected={props.language}
              onChange={props.handleSelect}
              classes={props.theme === "light" ? "light" : "dark"}
              disabled={false}
              tab={props.tab}
              isMobile={isMobile}
            />
          )}
          <div id="start-again-btn"></div>

          <hr />
        </div>
      </div>
      {props.tabs.find((tab) => tab.value === props.tab)?.content ?? null}
    </>
  );
};
/*
{props.tabs.map((tab, index) =>
        props.isShowAllContent ? (
          <div key={index}>{tab.content}</div>
        ) : tab.value === props.tab ? (
          <div key={index}>{tab.content}</div>
        ) : null,
      )}
*/
