import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { SortIcon, cancelIcon } from "../../../../../../../icons";
import { ToolButton, ToolButtonEnum } from "../../../../../../../ToolButton";
import { SpinnerContext } from "../../../../../../contexts";

export const QuestionGenerationResults = () => {
  const { segments, selectedWheelNumber } = useContext(SpinnerContext);
  const [results, setResults] = useState<string[]>([]);

  useEffect(() => {
    const segmentData = segments.findIndex(
      (data) => Number(data.wheel) === Number(selectedWheelNumber),
    );
    if (segmentData !== -1) {
      setResults(segments[segmentData].results);
    }
  }, [segments]);

  const onSortQuestions = () => {
    const sortedQuestions = [...results].sort(); // Create a sorted copy of the shuffled array
    setResults(sortedQuestions);
  };

  const onClearQuestions = () => {
    setResults([]);
  };

  const buttons = [
    {
      icon: SortIcon,
      onclick: onSortQuestions,
      title: "Sort",
      className: `sort-btn`,
      ariaLabel: "Sort",
      type: ToolButtonEnum.ICON,
      label: "Sort",
    },
    {
      icon: cancelIcon,
      onclick: onClearQuestions,
      title: "Clear Results",
      className: `clear-btn`,
      ariaLabel: "Clear Results",
      type: ToolButtonEnum.BUTTON,
      label: "Clear Results",
    },
  ];

  return (
    <>
      <div className="d-flex gap-3">
        {buttons.map((btn, index) => (
          <ToolButton
            type={btn.type as ToolButtonEnum.BUTTON | ToolButtonEnum.ICON} // Fix: Change the type to ToolButtonEnum.ICON
            title={btn.title}
            icon={btn.icon}
            onClick={btn.onclick}
            className={`${btn.className}`}
            aria-label={btn.ariaLabel}
            label={btn.label ?? ""}
            showAriaLabel={true}
          />
        ))}
      </div>
      {results.length > 0 &&
        results.map((result, index) => (
          <div className="question-result border d-flex text-wrap gap-2 ps-2">
            {result}
          </div>
        ))}
    </>
  );
};
