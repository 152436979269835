import { createContext } from "react";

export interface StudentMessageType {
  title: string;
  message: string;
  button: string;
}

type StudentMessageContextType = {
  studentMessage: StudentMessageType;
  setStudentMessage: (val: StudentMessageType) => void;
};

const StudentMessageContextDefaultValue: StudentMessageContextType = {
  studentMessage: {
    title: "",
    message: "",
    button: "",
  },
  setStudentMessage: () => {},
};

export const StudentMessageContext = createContext<StudentMessageContextType>(
  StudentMessageContextDefaultValue,
);
