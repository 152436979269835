import { MermaidOptions } from "@excalidraw/mermaid-to-excalidraw";
import { MermaidToExcalidrawResult } from "@excalidraw/mermaid-to-excalidraw/dist/interfaces";
import {
  DEFAULT_EXPORT_PADDING,
  DEFAULT_FONT_SIZE,
  // EDITOR_LS_KEYS,
} from "../../constants";
import { NonDeletedExcalidrawElement } from "../../element/types";
import { AppClassProperties, AppState, BinaryFiles } from "../../types";
import { canvasToBlob } from "../../data/blob";
import { exportToCanvas } from "../../packages/utils";
import { convertToExcalidrawElements } from "../../data/transform";
import { useState } from "react";
import { t } from "../../i18n";

// import { EditorLocalStorage } from "../../data/EditorLocalStorage";

const resetPreview = ({
  canvasRef,
  setError,
}: {
  canvasRef: React.RefObject<HTMLDivElement>;
  setError: (error: Error | null) => void;
}) => {
  const canvasNode = canvasRef.current;

  if (!canvasNode) {
    return;
  }
  const parent = canvasNode.parentElement;
  if (!parent) {
    return;
  }
  parent.style.background = "";
  setError(null);

  canvasNode.replaceChildren();
};

export interface MermaidToExcalidrawLibProps {
  loaded: boolean;
  api: Promise<{
    parseMermaidToExcalidraw: (
      definition: string,
      options: MermaidOptions,
    ) => Promise<MermaidToExcalidrawResult>;
  }>;
}

interface ConvertMermaidToExcalidrawFormatProps {
  canvasRef: React.RefObject<HTMLDivElement>;
  mermaidToExcalidrawLib: MermaidToExcalidrawLibProps;
  mermaidDefinition: string;
  setError: (error: Error | null) => void;
  data: React.MutableRefObject<{
    elements: readonly NonDeletedExcalidrawElement[];
    files: BinaryFiles | null;
  }>;
  zoomFactor: number;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}

export const convertMermaidToExcalidraw = async ({
  canvasRef,
  mermaidToExcalidrawLib,
  mermaidDefinition,
  setError,
  data,
  zoomFactor,
  appState,
  setAppState,
}: ConvertMermaidToExcalidrawFormatProps) => {
  const canvasNode = canvasRef.current;
  const parent = canvasNode?.parentElement;

  if (!canvasNode || !parent) {
    return;
  }

  if (!mermaidDefinition) {
    resetPreview({ canvasRef, setError });
    return;
  }

  try {
    const api = await mermaidToExcalidrawLib.api;

    let ret;
    try {
      ret = await api.parseMermaidToExcalidraw(mermaidDefinition, {
        fontSize: DEFAULT_FONT_SIZE,
      });
    } catch (err: any) {
      ret = await api.parseMermaidToExcalidraw(
        mermaidDefinition.replace(/"/g, "'"),
        {
          fontSize: DEFAULT_FONT_SIZE,
        },
      );
    }

    const { elements, files } = ret;

    setError(null);

    if (files && Object.keys(files || {}).length) {
      const file = {
        ...appState.files,
        ...files,
      };
      setAppState({
        ...appState,
        files: file,
      });
    }

    data.current = {
      elements: convertToExcalidrawElements(elements, {
        regenerateIds: true,
        type: "mermaidDiagram",
        syntax: mermaidDefinition,
      }),
      files,
    };

    const canvas = await exportToCanvas({
      elements: data.current.elements,
      files: data.current.files,
      exportPadding: DEFAULT_EXPORT_PADDING,
      maxWidthOrHeight:
        Math.max(parent.offsetWidth, parent.offsetHeight) *
        window.devicePixelRatio,
      zoomFactor,
    });

    // if converting to blob fails, there's some problem that will
    // likely prevent preview and export (e.g. canvas too big)
    try {
      await canvasToBlob(canvas);
    } catch (e: any) {
      if (e.name === "CANVAS_POSSIBLY_TOO_BIG") {
        throw new Error(t("canvasError.canvasTooBig"));
      }
      throw e;
    }
    parent.style.background = "var(--default-bg-color)";
    canvasNode.replaceChildren(canvas);
  } catch (err: any) {
    parent.style.background = "var(--default-bg-color)";
    if (mermaidDefinition) {
      setError(
        new Error(
          "Sorry, there seems to be an issue with the Mermaid syntax provided. Please double-check your input and try again.",
        ),
      );
    } else {
      setError(err);
    }
    // if (!mermaidDefinition) {
    //   parent.style.background =
    //     "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==)";
    //   setError(
    //     new Error(
    //       "Sorry, there seems to be an issue with the Mermaid syntax provided. Please double-check your input and try again.",
    //     ),
    //   );
    // }

    throw err;
  }
};

// export const saveMermaidDataToStorage = (mermaidDefinition: string) => {
//   EditorLocalStorage.set(
//     EDITOR_LS_KEYS.MERMAID_TO_EXCALIDRAW,
//     mermaidDefinition,
//   );
// };

export const insertToEditor = ({
  app,
  data,
  text,
  shouldSaveMermaidDataToStorage,
  handleCloseDialogue,
  updatedElement,
}: {
  app: AppClassProperties;
  data: React.MutableRefObject<{
    elements: readonly NonDeletedExcalidrawElement[];
    files: BinaryFiles | null;
  }>;
  text?: string;
  shouldSaveMermaidDataToStorage?: boolean;
  handleCloseDialogue?: () => void;
  updatedElement?: {
    open: boolean;
    syntax: string;
    id: string | null;
    x: number;
    y: number;
  };
}) => {
  const { elements: newElements, files } = data.current;

  if (!newElements.length) {
    return;
  }

  app.addMermaidElements({
    elements: newElements,
    files,
    position: updatedElement?.id
      ? {
          clientX: updatedElement.x,
          clientY: updatedElement.y,
        }
      : "center",
    fitToContent: true,
    updatedElementIds: {
      elementId: updatedElement?.id ?? null,
      fileId: updatedElement?.id ?? null,
    },
  });
  handleCloseDialogue?.();
  // app.setOpenDialog(null);

  if (shouldSaveMermaidDataToStorage && text) {
    // saveMermaidDataToStorage(text);
  }
};
