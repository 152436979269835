import { createRef, useEffect, useState } from "react";
import {
  AppState,
  CollocationType,
  CommentType,
  LibraryItem,
} from "../../../../types";
import { backIcon } from "../../../icons";
import "./style.scss";

import {
  bookmarkCollectionAPI,
  likeUnlikeCollectionAPI,
} from "../../../../excalidraw-app/api/collection";
import {
  createComments,
  deleteComments,
  editComments,
  getAuthorWiseTemplate,
  getComments,
  getTagWiseTemplate,
} from "../../../../excalidraw-app/api/storeElementData";
import { CardWithImage } from "../card";
import { ChatDrawer } from "./chatDrawer";
import { TemplateInfo } from "./templateInfo";
import { useIsMobile } from "../../../App";
import { Toast, ToastType } from "../../../Toast";
import { t } from "../../../../i18n";

interface AcvLibraryPreviewDialogProps {
  onBack: () => void;
  previewTemplateData: CollocationType | null;
  setPreviewTemplateData: (data: CollocationType) => void;
  setAppState: React.Component<any, AppState>["setState"];
  loadElementsFromDB: (url: string) => void;
  isShowTab: boolean;
  onInsertShape: (elements: LibraryItem["elements"]) => void;
  setFullScreenCollectionPreview: () => void;
  check_: boolean;
}

export const AcvLibraryPreviewDialog = ({
  onBack,
  previewTemplateData,
  setPreviewTemplateData,
  setAppState,
  loadElementsFromDB,
  isShowTab = true,
  onInsertShape,
  setFullScreenCollectionPreview,
  check_,
}: AcvLibraryPreviewDialogProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [commentData, setCommentData] = useState<CommentType[]>([]);
  const [commentDetail, setCommentDetail] = useState<{
    html: string;
    text: string;
  }>({
    html: "",
    text: "",
  });
  const [editCommentDetail, setEditCommentDetail] = useState<{
    html: string;
    text: string;
  }>({
    html: "",
    text: "",
  });
  const [check, setCheck] = useState<Number>(1);
  const [relatedData, setRelatedData] = useState<CollocationType[]>([]);
  const [selectedTag, setSelectedTag] = useState("");
  // const [libraryItems, setLibraryItems] = useState<LibraryType[]>([]);
  const [toastMessage, setToastMessage] = useState<{
    message: string;
    type: ToastType;
  } | null>(null);

  const myRef = createRef<HTMLDivElement>();

  const elem = document.getElementById("preview-dialog-fullscreen");

  const isMobileView = useIsMobile();

  const getAuthorWiseData = async () => {
    const response = await getAuthorWiseTemplate(
      previewTemplateData?.userId ?? "",
    );
    setRelatedData(response?.results);
  };

  const getComment = async () => {
    const response = await getComments(previewTemplateData?.id ?? "");
    setCommentData(response?.results);
  };

  useEffect(() => {
    getAuthorWiseData();
    getComment();
  }, []);

  useEffect(() => {
    setIsFullscreen(!!document.fullscreenElement);
    document.addEventListener("fullscreenchange", () => {
      setIsFullscreen(!!document.fullscreenElement);
    });
  }, []);

  useEffect(() => {
    myRef.current?.scrollIntoView({ behavior: "smooth" });
    getAuthorWiseData();
    getComment();
  }, [previewTemplateData]);

  const createComment = async (data: string) => {
    const body: CommentType = {
      name: user.displayName,
      comment: data,
      libraryId: previewTemplateData?.id,
      userId: user.userId ? user.userId : user.mail,
      image: user.UrlPhoto
        ? `https://${storage}.blob.core.windows.net/profiles/${
            user.UrlPhoto.split("/").pop().split("?")[0]
          }`
        : profile,
    };
    const response = await createComments(body);
    const cmtArray = commentData;
    cmtArray.unshift(response?.result);
    setCommentData([...cmtArray]);
    setCommentDetail({ html: "", text: "" });
  };

  const deleteComment = async (id: string) => {
    const response = await deleteComments(id);
    setCommentData([...commentData.filter((data) => data.id !== id)]);
  };
  const editCmt = async (id: string) => {
    const body: CommentType = {
      id: id,
      comment: editCommentDetail.html,
    };
    const response = await editComments(body);
    const index = commentData.findIndex((data) => data.id === id);
    if (index > -1) {
      commentData[index].comment = response?.result.comment;
    }
    setCommentData([...commentData]);
    setEditCommentDetail({ html: "", text: "" });
  };

  if (!previewTemplateData) {
    return null;
  }

  const handleCheck = (tabValue: Number) => {
    setCheck(tabValue);
  };

  const handleTag = async (data: string) => {
    const response = await getTagWiseTemplate(data);
    setRelatedData(response.results);
    handleCheck(3);
    setSelectedTag(data);
  };

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const profile = localStorage.getItem("profileBase64");
  const storage = process.env.REACT_APP_STORAGE || "";

  return (
    <>
      <div className="acvLibraryPreviewDialog" ref={myRef}>
        <div className="d-flex justify-content-between">
          <div className="mt-3">
            <button className="px-3 d-flex gap-2" onClick={onBack}>
              <div className="back-icon">{backIcon}</div>
              {t("previewDialog.backToTemplates")}
            </button>
          </div>
        </div>
      </div>
      <div
        className="w-100 d-flex justify-content-between"
        style={{ height: "93%" }}
      >
        <div
          className="m-4"
          style={{ height: "93%", width: isMobileView ? "95%" : "70%" }}
        >
          <div className="libraryItem_content h-100">
            <div
              id="tsum-tabs"
              className="d-flex flex-nowrap"
              style={{
                overflow: "auto hidden",
              }}
            >
              <input
                id="tab1"
                type="radio"
                name="tabs"
                checked={check === 1}
                onClick={(e) => {
                  handleCheck(1);
                  getAuthorWiseData();
                }}
              />
              <label htmlFor="tab1" className="text-nowrap">
                {t("previewDialog.templatesInfo")}
              </label>
              {isShowTab && (
                <>
                  <input
                    id="tab2"
                    type="radio"
                    name="tabs"
                    checked={check === 2}
                    onClick={(e) => {
                      handleCheck(2);
                      getAuthorWiseData();
                    }}
                  />
                  <label htmlFor="tab2" className="text-nowrap">
                    {t("previewDialog.templateByAuthor")}
                  </label>
                  <input
                    id="tab3"
                    type="radio"
                    name="tabs"
                    checked={check === 3}
                    onClick={(e) => {
                      handleCheck(3);
                      previewTemplateData?.tag?.length
                        ? handleTag(previewTemplateData.tag[0])
                        : setRelatedData([]);
                    }}
                  />
                  <label htmlFor="tab3" className="text-nowrap">
                    {t("previewDialog.relatedTemplates")}
                  </label>
                </>
              )}
              {isMobileView && (
                <>
                  <input
                    id="tab4"
                    type="radio"
                    name="tabs"
                    checked={check === 4}
                    onClick={(e) => {
                      handleCheck(4);
                    }}
                  />
                  <label htmlFor="tab4" className="text-nowrap">
                    {t("previewDialog.comments")}
                  </label>
                </>
              )}
            </div>

            <section
              id={`content${check}`}
              className="w-100 tab-data"
              style={{
                height: "calc(100% - 100px)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {check === 1 ? (
                <TemplateInfo
                  templateData={previewTemplateData}
                  loadElementsFromDB={loadElementsFromDB}
                  onInsertShape={onInsertShape}
                />
              ) : check === 4 ? (
                <ChatDrawer
                  commentData={commentData}
                  deleteComment={deleteComment}
                  editCmt={editCmt}
                  editCommentDetail={editCommentDetail}
                  setEditCommentDetail={setEditCommentDetail}
                  createComment={createComment}
                  setCommentDetail={setCommentDetail}
                  commentDetail={commentDetail}
                />
              ) : (
                <div className="row">
                  {relatedData.length > 0 ? (
                    relatedData.map((template, index) => {
                      if (
                        relatedData.length === 1 &&
                        template.id === previewTemplateData.id
                      ) {
                        return (
                          <span className="ml-3 text-center">
                            {t("previewDialog.noTemplates")}
                          </span>
                        );
                      } else {
                        return (
                          previewTemplateData.id !== template.id && (
                            <CardWithImage
                              key={index}
                              images={template.preview}
                              userProfile={template.authors[0]?.url}
                              userName={template.authors[0]?.name}
                              created={template.created}
                              name={template.name}
                              likes={template.likeCount}
                              isLikefill={template.isLiked}
                              onLike={async () => {
                                const res = await likeUnlikeCollectionAPI(
                                  template.id,
                                );
                                return res.result.likeCount;
                              }}
                              isBookMarkFill={template.isBookmark}
                              onBookmark={async () => {
                                await bookmarkCollectionAPI(template.id);
                              }}
                              setFullScreenCollectionPreview={
                                setFullScreenCollectionPreview
                              }
                              onPreview={() => {
                                setPreviewTemplateData(template);
                                check_ && setCheck(1);
                              }}
                              isHoverUseThisTemplateBtn={false}
                              isMove={true}
                              id={template.id}
                              onAfterMovedToCategory={(category: string[]) => {
                                if (category.length) {
                                  const addCategory = category?.filter(
                                    (category: string) =>
                                      category !== template.id,
                                  );
                                  const index = relatedData.findIndex(
                                    (data) => data.id === template.id,
                                  );
                                  if (addCategory) {
                                    const data = [...relatedData];

                                    data[index].category = [...addCategory];

                                    setRelatedData([...data]);
                                  }
                                }
                                setToastMessage({
                                  message: "Collection successfully imported.",
                                  type: ToastType.SUCCESS,
                                });
                              }}
                              templateCategory={template.category}
                              onAfterDeleteCategory={(id) => {
                                if (template.id !== id) {
                                  const updatedCategory = template.category?.filter(
                                    (category: string) => category !== id,
                                  );
                                  const index = relatedData.findIndex(
                                    (data) => data.id === template.id,
                                  );
                                  if (updatedCategory) {
                                    const data = [...relatedData];

                                    data[index].category = [...updatedCategory];

                                    setRelatedData([...data]);
                                  }
                                }
                              }}
                            />
                          )
                        );
                      }
                    })
                  ) : (
                    <span className="ml-3 text-center">
                      {t("previewDialog.noTemplates")}
                    </span>
                  )}
                  {toastMessage !== null && (
                    <Toast
                      type={toastMessage.type}
                      message={toastMessage.message}
                      clearToast={() => setToastMessage(null)}
                      className="style"
                    />
                  )}
                </div>
              )}
            </section>
          </div>
        </div>
        {!isMobileView && (
          <ChatDrawer
            commentData={commentData}
            deleteComment={deleteComment}
            editCmt={editCmt}
            editCommentDetail={editCommentDetail}
            setEditCommentDetail={setEditCommentDetail}
            createComment={createComment}
            setCommentDetail={setCommentDetail}
            commentDetail={commentDetail}
          />
        )}
      </div>
    </>
  );
};
