import { decryptData, generateEncryptionKey } from "../../data/encryption";
import { restore } from "../../data/restore";
import { ImportedDataState } from "../../data/types";
import { ExcalidrawElement } from "../../element/types";
import { t } from "../../i18n";
import { AppState, UserIdleState } from "../../types";
import {
  getElemetDataFromDatabase,
  storeElemetDataToDatabase,
} from "../api/storeElementData";

const byteToHex = (byte: number): string => `0${byte.toString(16)}`.slice(-2);

const BACKEND_GET = process.env.REACT_APP_BACKEND_V1_GET_URL;
const BACKEND_V2_GET = process.env.REACT_APP_BACKEND_V2_GET_URL;
const BACKEND_V2_POST = process.env.REACT_APP_BACKEND_V2_POST_URL;

const generateRandomID = async () => {
  const arr = new Uint8Array(10);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, byteToHex).join("");
};

export const SOCKET_SERVER = process.env.REACT_APP_SOCKET_SERVER_URL;

export type EncryptedData = {
  data: ArrayBuffer;
  iv: Uint8Array;
};

export type SocketUpdateDataSource = {
  SCENE_INIT: {
    type: "SCENE_INIT";
    payload: {
      elements: readonly ExcalidrawElement[];
    };
  };
  SCENE_UPDATE: {
    type: "SCENE_UPDATE";
    payload: {
      elements: readonly ExcalidrawElement[];
    };
  };
  PAGE_UPDATE: {
    type: "PAGE_UPDATE";
    payload: null;
  };
  MOUSE_LOCATION: {
    type: "MOUSE_LOCATION";
    payload: {
      socketId: string;
      pointer: { x: number; y: number };
      button: "down" | "up";
      selectedElementIds: AppState["selectedElementIds"];
      username: string;
    };
  };
  IDLE_STATUS: {
    type: "IDLE_STATUS";
    payload: {
      socketId: string;
      userState: UserIdleState;
      username: string;
    };
  };
};

export type SocketUpdateDataIncoming =
  | SocketUpdateDataSource[keyof SocketUpdateDataSource]
  | {
      type: "INVALID_RESPONSE";
    };

export type SocketUpdateData = SocketUpdateDataSource[keyof SocketUpdateDataSource] & {
  _brand: "socketUpdateData";
};

const IV_LENGTH_BYTES = 12; // 96 bits

export const createIV = () => {
  const arr = new Uint8Array(IV_LENGTH_BYTES);
  return window.crypto.getRandomValues(arr);
};

export const encryptAESGEM = async (
  data: Uint8Array,
  key: string,
): Promise<EncryptedData> => {
  const importedKey = await getImportedKey(key, "encrypt");
  const iv = createIV();
  return {
    data: await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv,
      },
      importedKey,
      data,
    ),
    iv,
  };
};

export const decryptAESGEM = async (
  data: ArrayBuffer,
  key: string,
  iv: Uint8Array,
): Promise<SocketUpdateDataIncoming> => {
  try {
    const importedKey = await getImportedKey(key, "decrypt");
    const decrypted = await window.crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv,
      },
      importedKey,
      data,
    );

    const decodedData = new TextDecoder("utf-8").decode(
      new Uint8Array(decrypted) as any,
    );
    return JSON.parse(decodedData);
  } catch (error) {
    window.alert(t("alerts.decryptFailed"));
    console.error(error);
  }
  return {
    type: "INVALID_RESPONSE",
  };
};

export const getCollaborationLinkData = (link: string) => {
  const hash = new URL(link).hash;
  const match = hash.match(/^#room=([a-zA-Z0-9_-]+),([a-zA-Z0-9_-]+)$/);
  if (match && match[2].length !== 22) {
    window.alert(t("alerts.invalidEncryptionKey"));
    return null;
  }
  return match ? { roomId: match[1], roomKey: match[2] } : null;
};

export const generateCollaborationLinkData = async () => {
  const roomId = await generateRandomID();
  const roomKey = await generateEncryptionKey();

  if (!roomKey) {
    throw new Error("Couldn't generate room key");
  }

  return { roomId, roomKey };
};

export const getCollaborationLink = (data: {
  roomId: string;
  roomKey: string;
}) => {
  // return `${window.location.origin}${window.location.pathname}#room=${data.roomId},${data.roomKey}`;
  return `${document.URL}${window.location.pathname}#room=${data.roomId},${data.roomKey}`;
};

export const getImportedKey = (key: string, usage: KeyUsage) =>
  window.crypto.subtle.importKey(
    "jwk",
    {
      alg: "A128GCM",
      ext: true,
      k: key,
      key_ops: ["encrypt", "decrypt"],
      kty: "oct",
    },
    {
      name: "AES-GCM",
      length: 128,
    },
    false, // extractable
    [usage],
  );

export const decryptImported = async (
  iv: ArrayBuffer,
  encrypted: ArrayBuffer,
  privateKey: string,
): Promise<ArrayBuffer> => {
  const key = await getImportedKey(privateKey, "decrypt");
  return window.crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv,
    },
    key,
    encrypted,
  );
};

// const importFromBackend = async (
//   id: string,
//   decryptionKey: string,
// ): Promise<ImportedDataState> => {
//   try {
//     const response = await fetch(`${BACKEND_V2_GET}${id}`);

//     if (!response.ok) {
//       window.alert(t("alerts.importBackendFailed"));
//       return {};
//     }
//     const buffer = await response.arrayBuffer();

//     try {
//       const { data: decodedBuffer } = await decompressData(
//         new Uint8Array(buffer),
//         {
//           decryptionKey,
//         },
//       );
//       const data: ImportedDataState = JSON.parse(
//         new TextDecoder().decode(decodedBuffer),
//       );

//       return {
//         elements: data.elements || null,
//         appState: data.appState || null,
//       };
//     } catch (error: any) {
//       console.warn(
//         "error when decoding shareLink data using the new format:",
//         error,
//       );
//       return legacy_decodeFromBackend({ buffer, decryptionKey });
//     }
//   } catch (error: any) {
//     window.alert(t("alerts.importBackendFailed"));
//     console.error(error);
//     return {};
//   }
// };

const legacy_decodeFromBackend = async ({
  buffer,
  decryptionKey,
}: {
  buffer: ArrayBuffer;
  decryptionKey: string;
}) => {
  let decrypted: ArrayBuffer;

  try {
    // Buffer should contain both the IV (fixed length) and encrypted data
    const iv = buffer.slice(0, IV_LENGTH_BYTES);
    const encrypted = buffer.slice(IV_LENGTH_BYTES, buffer.byteLength);
    decrypted = await decryptData(new Uint8Array(iv), encrypted, decryptionKey);
  } catch (error: any) {
    // Fixed IV (old format, backward compatibility)
    const fixedIv = new Uint8Array(IV_LENGTH_BYTES);
    decrypted = await decryptData(fixedIv, buffer, decryptionKey);
  }

  // We need to convert the decrypted array buffer to a string
  const string = new window.TextDecoder("utf-8").decode(
    new Uint8Array(decrypted),
  );
  const data: ImportedDataState = JSON.parse(string);

  return {
    elements: data.elements || null,
    appState: data.appState || null,
  };
};

export const createNewCanvas = async (
  lessonId: string,
  page: number,
  viewBackgroundColor: string,
) => {
  const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;
  const data = await storeElemetDataToDatabase(
    lessonId,
    page,
    userMail,
    [],
    viewBackgroundColor,
  );
  return data;
};

export const duplicateCanvas = async (
  lessonId: string,
  page: number,
  body: Array<object>,
  viewBackgroundColor: string,
) => {
  const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;

  const data = await storeElemetDataToDatabase(
    lessonId,
    page,
    userMail,
    body,
    viewBackgroundColor,
  );

  return data;
};

export const getPages = async (
  lessonId: string | null,
  page: number | boolean,
) => {
  const results = await getElemetDataFromDatabase(lessonId, page);
  localStorage.setItem("ACTIVE_PAGE", results.activePage);
  return results;
};

export const getPagesDetails = (LessonId: string, page: number) => {
  // console.log("getPagesDetails - ", LessonId, " ", page);
};

export const loadScene = async (
  id: string | null,
  privateKey: string | null,
  // Supply local state even if importing from backend to ensure we restore
  // localStorage user settings which we do not persist on server.
  // Non-optional so we don't forget to pass it even if `undefined`.
  localDataState: ImportedDataState | undefined | null,
) => {
  let data;
  if (id != null && privateKey != null) {
    // the private key is used to decrypt the content from the server, take
    // extra care not to leak it
    data = restore(
      { elements: [] }, //  await importFromBackend(id, privateKey)
      localDataState?.appState,
      localDataState?.elements,
      { repairBindings: true, refreshDimensions: false },
    );
  } else {
    data = restore(localDataState || null, null, null, {
      repairBindings: true,
    });
  }

  return {
    elements: data.elements,
    appState: data.appState,
    // note: this will always be empty because we're not storing files
    // in the scene database/localStorage, and instead fetch them async
    // from a different database
    files: data.files,
    commitToHistory: false,
  };
};
