// -----------------------------------------------------------------------------
// ExcalidrawImageElement & related helpers
// -----------------------------------------------------------------------------

import { MIME_TYPES } from "../constants";
import { t } from "../i18n";
import { AppClassProperties, DataURL, BinaryFiles, AppState } from "../types";
import { isInitializedAudioElement } from "./typeChecks";
import {
  ExcalidrawElement,
  FileId,
  InitializedExcalidrawAudioElement,
} from "./types";

export const loadHTMLAudioElement = (dataURL: DataURL) => {
  return new Promise<HTMLAudioElement>((resolve, reject) => {
    const audio = document.createElement("audio");

    // audio.addEventListener("loadedmetadata", function () {
    //   audio.play();
    // });

    audio.style.width = "300px";
    audio.style.height = "300px";
    audio.addEventListener("canplay", () => {
      resolve(audio);
    });

    audio.addEventListener("error", (error) => {
      console.log("error", error);
      reject(error);
    });
    audio.crossOrigin = "anonymous";
    audio.src = dataURL;
  });
};

/** NOTE: updates cache even if already populated with given image. Thus,
 * you should filter out the videos upstream if you want to optimize this. */

export const updateAudioCache = async ({
  fileIds,
  files,
  audioCache,
}: {
  fileIds: FileId[];
  files: BinaryFiles;
  audioCache: AppState["audioCache"];
}) => {
  const updatedFiles = new Map<FileId, true>();
  const erroredFiles = new Map<FileId, true>();

  await Promise.all(
    fileIds.reduce((promises, fileId) => {
      const fileData = files[fileId as string];
      if (fileData && !updatedFiles.has(fileId)) {
        updatedFiles.set(fileId, true);
        return promises.concat(
          (async () => {
            try {
              if (fileData.mimeType === MIME_TYPES.binary) {
                throw new Error("Only audio can be added to audioCache");
              }

              const audioPromise = loadHTMLAudioElement(fileData.dataURL);
              const data = {
                audio: audioPromise,
                mimeType: fileData.mimeType,
              } as const;

              // store the promise immediately to indicate there's an in-progress
              // initialization
              audioCache.set(fileId, data);

              const audio = await audioPromise;

              audioCache.set(fileId, { ...data, audio });
            } catch (error: any) {
              erroredFiles.set(fileId, true);
            }
          })(),
        );
      }
      return promises;
    }, [] as Promise<any>[]),
  );

  return {
    audioCache,
    /** includes errored files because they cache was updated nonetheless */
    updatedFiles,
    /** files that failed when creating HTMLImageElement */
    erroredFiles,
  };
};

export const getInitializedAudioElements = (
  elements: readonly ExcalidrawElement[],
) =>
  elements.filter((element) =>
    isInitializedAudioElement(element),
  ) as InitializedExcalidrawAudioElement[];
