export const GitGraphDiagramSyntax = `

Mermaid syntax for a gitgraph is very straight-forward and simple. It follows a declarative-approach, where each commit is drawn on the timeline in the diagram, in order of its occurrences/presence in code. Basically, it follows the insertion order for each command.

First thing you do is to declare your diagram type using the gitgraph keyword. This gitgraph keyword, tells Mermaid that you wish to draw a gitgraph, and parse the diagram code accordingly.

Each gitgraph, is initialized with main branch. So unless you create a different branch, by-default the commits will go to the main branch. This is driven with how git works, where in the beginning you always start with the main branch (formerly called as master branch). And by-default, main branch is set as your current branch.

You make use of commit keyword to register a commit on the current branch. Let see how this works:

A simple gitgraph showing three commits on the default (main) branch:

Code:

    gitGraph
       commit
       commit
       commit

If you look closely at the previous example, you can see the default branch main along with three commits. Also, notice that by default each commit has been given a unique & random ID. What if you wanted to give your own custom ID to a commit? Yes, it is possible to do that with Mermaid.

Adding custom commit id
For a given commit you may specify a custom ID at the time of declaring it using the id attribute, followed by : and your custom value within a "" quote. For example: commit id: "your_custom_id"

Let us see how this works with the help of the following diagram:

Code:

    gitGraph
       commit id: "Alpha"
       commit id: "Beta"
       commit id: "Gamma"

       In this example, we have given our custom IDs to the commits.

Modifying commit type
In Mermaid, a commit can be of three type, which render a bit different in the diagram. These types are:

NORMAL : Default commit type. Represented by a solid circle in the diagram
REVERSE : To emphasize a commit as a reverse commit. Represented by a crossed solid circle in the diagram.
HIGHLIGHT : To highlight a particular commit in the diagram. Represented by a filled rectangle in the diagram.
For a given commit you may specify its type at the time of declaring it using the type attribute, followed by : and the required type option discussed above. For example: commit type: HIGHLIGHT

NOTE: If no commit type is specified, NORMAL is picked as default.

Let us see how these different commit type look with the help of the following diagram:

Code:

    gitGraph
       commit id: "Normal"
       commit
       commit id: "Reverse" type: REVERSE
       commit
       commit id: "Highlight" type: HIGHLIGHT
       commit
In this example, we have specified different types to each commit. Also, see how we have included both id and type together at the time of declaring our commits.

Adding Tags
For a given commit you may decorate it as a tag, similar to the concept of tags or release version in git world. You can attach a custom tag at the time of declaring a commit using the tag attribute, followed by : and your custom value within "" quote. For example: commit tag: "your_custom_tag"

Let us see how this works with the help of the following diagram:

Code:

    gitGraph
       commit
       commit id: "Normal" tag: "v1.0.0"
       commit
       commit id: "Reverse" type: REVERSE tag: "RC_1"
       commit
       commit id: "Highlight" type: HIGHLIGHT tag: "8.8.4"
       commit

       In this example, we have given custom tags to the commits. Also, see how we have combined all these attributes in a single commit declaration. You can mix-match these attributes as you like.

Create a new branch
In Mermaid, in-order to create a new branch, you make use of the branch keyword. You also need to provide a name of the new branch. The name has to be unique and cannot be that of an existing branch. A branch name that could be confused for a keyword must be quoted within "". Usage examples: branch develop, branch "cherry-pick"

When Mermaid, reads the branch keyword, it creates a new branch and sets it as the current branch. Equivalent to you creating a new branch and checking it out in Git world.

Let see this in an example:

Code:

    gitGraph
       commit
       commit
       branch develop
       commit
       commit
       commit

       In this example, see how we started with default main branch, and pushed two commits on that. Then we created the develop branch, and all commits afterwards are put on the develop branch as it became the current branch.

Checking out an existing branch
In Mermaid, in order to switch to an existing branch, you make use of the checkout keyword. You also need to provide a name of an existing branch. If no branch is found with the given name, it will result in console error. Usage example: checkout develop

When Mermaid, reads the checkout keyword, it finds the given branch and sets it as the current branch. Equivalent to checking out a branch in the Git world.

Let see modify our previous example:

Code:

    gitGraph
       commit
       commit
       branch develop
       commit
       commit
       commit
       checkout main
       commit
       commit

       In this example, see how we started with default main branch, and pushed two commits on that. Then we created the develop branch, and all three commits afterwards are put on the develop branch as it became the current branch. After this we made use of the checkout keyword to set the current branch as main, and all commit that follow are registered against the current branch, i.e. main.

Merging two branches
In Mermaid, in order to merge or join to an existing branch, you make use of the merge keyword. You also need to provide the name of an existing branch to merge from. If no branch is found with the given name, it will result in console error. Also, you can only merge two separate branches, and cannot merge a branch with itself. In such case an error is throw.

Usage example: merge develop

When Mermaid, reads the merge keyword, it finds the given branch and its head commit (the last commit on that branch), and joins it with the head commit on the current branch. Each merge results in a merge commit, represented in the diagram with filled double circle.

Let us modify our previous example to merge our two branches:

Code:

    gitGraph
       commit
       commit
       branch develop
       commit
       commit
       commit
       checkout main
       commit
       commit
       merge develop
       commit
       commit

       In this example, see how we started with default main branch, and pushed two commits on that. Then we created the develop branch, and all three commits afterwards are put on the develop branch as it became the current branch. After this we made use of the checkout keyword to set the current branch as main, and all commits that follow are registered against the current branch, i.e. main. After this we merge the develop branch onto the current branch main, resulting in a merge commit. Since the current branch at this point is still main, the last two commits are registered against that.

You can also decorate your merge with similar attributes as you did for the commit using:

id--> To override the default ID with custom ID
tag--> To add a custom tag to your merge commit
type--> To override the default shape of merge commit. Here you can use other commit type mentioned earlier.
And you can choose to use none, some or all of these attributes together. For example: merge develop id: "my_custom_id" tag: "my_custom_tag" type: REVERSE

Let us see how this works with the help of the following diagram:

Code:

    gitGraph
       commit id: "1"
       commit id: "2"
       branch nice_feature
       checkout nice_feature
       commit id: "3"
       checkout main
       commit id: "4"
       checkout nice_feature
       branch very_nice_feature
       checkout very_nice_feature
       commit id: "5"
       checkout main
       commit id: "6"
       checkout nice_feature
       commit id: "7"
       checkout main
       merge nice_feature id: "customID" tag: "customTag" type: REVERSE
       checkout very_nice_feature
       commit id: "8"
       checkout main
       commit id: "9"

Cherry Pick commit from another branch
Similar to how 'git' allows you to cherry-pick a commit from another branch onto the current branch, Mermaid also supports this functionality. You can also cherry-pick a commit from another branch using the cherry-pick keyword.

To use the cherry-pick keyword, you must specify the id using the id attribute, followed by : and your desired commit id within a "" quote. For example:

cherry-pick id: "your_custom_id"

Here, a new commit representing the cherry-pick is created on the current branch, and is visually highlighted in the diagram with a cherry and a tag depicting the commit id from which it is cherry-picked from.

A few important rules to note here are:

You need to provide the id for an existing commit to be cherry-picked. If given commit id does not exist it will result in an error. For this, make use of the commit id:$value format of declaring commits. See the examples from above.
The given commit must not exist on the current branch. The cherry-picked commit must always be a different branch than the current branch.
Current branch must have at least one commit, before you can cherry-pick, otherwise it will cause an error is throw.
When cherry-picking a merge commit, providing a parent commit ID is mandatory. If the parent attribute is omitted or an invalid parent commit ID is provided, an error will be thrown.
The specified parent commit must be an immediate parent of the merge commit being cherry-picked.
Let see an example:

Code:

    gitGraph
        commit id: "ZERO"
        branch develop
        branch release
        commit id:"A"
        checkout main
        commit id:"ONE"
        checkout develop
        commit id:"B"
        checkout main
        merge develop id:"MERGE"
        commit id:"TWO"
        checkout release
        cherry-pick id:"MERGE" parent:"B"
        commit id:"THREE"
        checkout develop
        commit id:"C"

Gitgraph specific configuration options
In Mermaid, you have the option to configure the gitgraph diagram. You can configure the following options:

showBranches : Boolean, default is true. If set to false, the branches are not shown in the diagram.
showCommitLabel : Boolean, default is true. If set to false, the commit labels are not shown in the diagram.
mainBranchName : String, default is main. The name of the default/root branch.
mainBranchOrder : Position of the main branch in the list of branches. default is 0, meaning, by default main branch is the first in the order.
parallelCommits: Boolean, default is false. If set to true, commits x distance away from the parent are shown at the same level in the diagram.
Let's look at them one by one.

Hiding Branch names and lines
Sometimes you may want to hide the branch names and lines from the diagram. You can do this by using the showBranches keyword. By default its value is true. You can set it to false using directives.

Usage example:

Code:

%%{init: { 'logLevel': 'debug', 'theme': 'base', 'gitGraph': {'showBranches': false}} }%%
      gitGraph
        commit
        branch hotfix
        checkout hotfix
        commit
        branch develop
        checkout develop
        commit id:"ash" tag:"abc"
        branch featureB
        checkout featureB
        commit type:HIGHLIGHT
        checkout main
        checkout hotfix
        commit type:NORMAL
        checkout develop
        commit type:REVERSE
        checkout featureB
        commit
        checkout main
        merge hotfix
        checkout featureB
        commit
        checkout develop
        branch featureA
        commit
        checkout develop
        merge hotfix
        checkout featureA
        commit
        checkout featureB
        commit
        checkout develop
        merge featureA
        branch release
        checkout release
        commit
        checkout main
        commit
        checkout release
        merge main
        checkout develop
        merge release

        Commit labels Layout: Rotated or Horizontal
Mermaid supports two types of commit labels layout. The default layout is rotated, which means the labels are placed below the commit circle, rotated at 45 degrees for better readability. This is particularly useful for commits with long labels.

The other option is horizontal, which means the labels are placed below the commit circle centred horizontally, and are not rotated. This is particularly useful for commits with short labels.

You can change the layout of the commit labels by using the rotateCommitLabel keyword in the directive. It defaults to true, which means the commit labels are rotated.

Usage example: Rotated commit labels

Code:

%%{init: { 'logLevel': 'debug', 'theme': 'base', 'gitGraph': {'rotateCommitLabel': true}} }%%
gitGraph
  commit id: "feat(api): ..."
  commit id: "a"
  commit id: "b"
  commit id: "fix(client): .extra long label.."
  branch c2
  commit id: "feat(modules): ..."
  commit id: "test(client): ..."
  checkout main
  commit id: "fix(api): ..."
  commit id: "ci: ..."
  branch b1
  commit
  branch b2
  commit

  Usage example: Horizontal commit labels

Code:

%%{init: { 'logLevel': 'debug', 'theme': 'base', 'gitGraph': {'rotateCommitLabel': false}} }%%
gitGraph
  commit id: "feat(api): ..."
  commit id: "a"
  commit id: "b"
  commit id: "fix(client): .extra long label.."
  branch c2
  commit id: "feat(modules): ..."
  commit id: "test(client): ..."
  checkout main
  commit id: "fix(api): ..."
  commit id: "ci: ..."
  branch b1
  commit
  branch b2
  commit


  Hiding commit labels
Sometimes you may want to hide the commit labels from the diagram. You can do this by using the showCommitLabel keyword. By default its value is true. You can set it to false using directives.

Usage example:

Code:

%%{init: { 'logLevel': 'debug', 'theme': 'base', 'gitGraph': {'showBranches': false,'showCommitLabel': false}} }%%
      gitGraph
        commit
        branch hotfix
        checkout hotfix
        commit
        branch develop
        checkout develop
        commit id:"ash"
        branch featureB
        checkout featureB
        commit type:HIGHLIGHT
        checkout main
        checkout hotfix
        commit type:NORMAL
        checkout develop
        commit type:REVERSE
        checkout featureB
        commit
        checkout main
        merge hotfix
        checkout featureB
        commit
        checkout develop
        branch featureA
        commit
        checkout develop
        merge hotfix
        checkout featureA
        commit
        checkout featureB
        commit
        checkout develop
        merge featureA
        branch release
        checkout release
        commit
        checkout main
        commit
        checkout release
        merge main
        checkout develop
        merge release

Customizing main branch name
Sometimes you may want to customize the name of the main/default branch. You can do this by using the mainBranchName keyword. By default its value is main. You can set it to any string using directives.

Usage example:

Code:

%%{init: { 'logLevel': 'debug', 'theme': 'base', 'gitGraph': {'showBranches': true, 'showCommitLabel':true,'mainBranchName': 'MetroLine1'}} }%%
      gitGraph
        commit id:"NewYork"
        commit id:"Dallas"
        branch MetroLine2
        commit id:"LosAngeles"
        commit id:"Chicago"
        commit id:"Houston"
        branch MetroLine3
        commit id:"Phoenix"
        commit type: HIGHLIGHT id:"Denver"
        commit id:"Boston"
        checkout MetroLine1
        commit id:"Atlanta"
        merge MetroLine3
        commit id:"Miami"
        commit id:"Washington"
        merge MetroLine2 tag:"MY JUNCTION"
        commit id:"Boston"
        commit id:"Detroit"
        commit type:REVERSE id:"SanFrancisco"

        Look at the imaginary railroad map created using Mermaid. Here, we have changed the default main branch name to MetroLine1.

Customizing branch ordering
In Mermaid, by default the branches are shown in the order of their definition or appearance in the diagram code.

Sometimes you may want to customize the order of the branches. You can do this by using the order keyword next the branch definition. You can set it to a positive number.

Mermaid follows the given precedence order of the order keyword.

Main branch is always shown first as it has default order value of 0. (unless its order is modified and changed from 0 using the mainBranchOrder keyword in the config)
Next, All branches without an order are shown in the order of their appearance in the diagram code.
Next, All branches with an order are shown in the order of their order value.
To fully control the order of all the branches, you must define order for all the branches.

Usage example:

Code:

%%{init: { 'logLevel': 'debug', 'theme': 'base', 'gitGraph': {'showBranches': true, 'showCommitLabel':true}} }%%
      gitGraph
      commit
      branch test1 order: 3
      branch test2 order: 2
      branch test3 order: 1

Look at the diagram, all the branches are following the order defined.

Usage example:

Code:
mermaid

%%{init: { 'logLevel': 'debug', 'theme': 'base', 'gitGraph': {'showBranches': true, 'showCommitLabel':true,'mainBranchOrder': 2}} }%%
      gitGraph
      commit
      branch test1 order: 3
      branch test2
      branch test3
      branch test4 order: 1

Look at the diagram, here, all the branches without a specified order are drawn in their order of definition. Then, test4 branch is drawn because the order of 1. Then, main branch is drawn because the order of 2. And, lastly test1is drawn because the order of 3.

NOTE: Because we have overridden the mainBranchOrder to 2, the main branch is not drawn in the beginning, instead follows the ordering.

Here, we have changed the default main branch name to MetroLine1.

`;
